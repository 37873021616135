<template>
  <div class="profile flex-columns">
      <div class="img-wrapper" :class="{'hide': noavatar}">
        <img :src="require('@/assets/default-user-image.png')" class="user">
      </div>      

      <p>Uživatel: 
        <span class="" v-text="username"></span>
      </p>
      
      <p>Role: 
        <span class="" v-text="role"></span>
      </p>

      <p>Přistupový token: 
        <span class="token" v-text="accessToken"></span>
      </p>

      <p>Obnovovací token: 
        <span class="token" v-text="refreshToken"></span>
      </p>

      <p>
        <button @click="LOGOUT" v-show="refreshToken" class="primary">
          odhlásit
        </button>
      </p>
     
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {  },
  props: {
    noavatar: {
      type: Boolean,
      default: false,
    }
  },
  name: "TopNavbar",
  data() {
    return {
      showDialog: false,
      heading: "",
      success: false,
      url: null,
      error: "",
      showMenu: false,
    };
  },
  methods: {
    ...mapActions(["LOGOUT"])
  },
  computed: { ...mapGetters('user',  ["refreshToken", "accessToken", "username", "role"]) },
  watch: {
    $route() {
      this.showMenu = false;
    },
  },
};
</script>

<style lang="scss">
.profile {
  width: 100vw;
  justify-content: center;
  align-items: center;
  .img-wrapper {
    text-align: center;
    align-content: center;
    &.hide {
      display: none;
    }
  }
  img.user {
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }
  p {
    text-align: center;
    a {
      color: rgb(38, 155, 194);
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }

      
    }
    .token {
        color: grey;
        font: 300 14px/20px Roboto;
    }
  }
}
</style>