<template>
  <div class="article-view" v-if="articleId">
    <i @click="open()" v-show="articleId" class="clickable far fa-newspaper"></i>
  </div>
</template>

<script>
  
export default {
  props: {
    articleId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    open() {
      this.eventBus.emit('open-article', this.articleId);
    }
  },
}
</script>

<style lang="scss">
.article-view {
  .controls {
    display: flex;
    justify-content: end;  
  }

}
</style>
