<template>
  <div class="new-version flex-column">
      <p>Zadejte údáje pro nový prázdný soubor kontroly projektu.</p>
      <p class="p-label">Název kontroly projektu</p>
      <text-input :value="PC.name" @input=" PC.name = $event" />
      <p class="p-label">Krátká poznámka k verzi</p>
      <text-input :value="PC.description" @input="PC.description = $event" />
      
      
      <div class="flex-row centered">
        <button class="primary  bottom-button-margin" @click="onSubmit()" :disabled="PC.name == ''">
          Založit novou verzi
        </button>
      </div>
    </div>
</template>

<script>
import { TextInput } from '@/views/components/customElements';
import { mapActions } from 'vuex';

export default {
  components: { TextInput },
  
  methods: {
    ...mapActions("PC", [
      "NEW_PC",
      "OPEN_PC",
    ]),
    async onSubmit() {
      const conf =  await this.NEW_PC(this.PC);
      await this.OPEN_PC(conf._id);
      this.$router.push("/PC/architecture");
    },
  },
  data() {
    return {
      PC: {
        name: "",
        description: "",
      }
    };
  },
}
</script>

<style lang="scss">
.new-version {
  width: 1000px;
  .user-input-style {
    width: 100%;
    height: 40px;
    font: normal normal 600 16px/22px Noto Sans;
  }
}
</style>
