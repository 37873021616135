<template>
  <component :is="tag" class="editable" v-if="editing == false" v-text="textInside" @click.stop="startEdit">
  </component>
  <div  v-else class="editable editing">
    <input
      type="text"
      v-model="textInside"
      @click.stop
      @keyup.enter="editString"
      @keyup.esc="resetString"
    >
    <i class="clickable fas fa-check" @click.stop="editString"></i>
    <i class="clickable fas fa-times" @click.stop="resetString"></i>
  </div>
</template>

<script>
export default {
  emits: {'edit-text': null},
  props: {
    text: {type: String, required: true},
    tag: {type: String, default: 'span'},
  },
  created() {
    this.resetString();
  },
  data() {
    return {
      textInside: '',
      editing: false,
    }
  },
  methods: {
    resetString() {
      this.textInside = this.text;
      if (this.editing) {
        this.stopEdit();
      }
    },
    editString() {
      this.$emit('edit-text', this.textInside);
      this.stopEdit();
    },
    startEdit() {
      this.editing = true;
      document.addEventListener('click', this.resetString);
    },
    stopEdit() {
      this.editing = false;
      document.removeEventListener('click', this.resetString)
    }
  },
  watch: {
    text: function (oldVal, newVal) {
      console.log(`text changed ${oldVal} ${newVal}`);
      this.resetString();
    }
  }
}
</script>

<style lang="scss">
$good-color: #ffe3af;
$col-sec: hsla(0, 100%, 85%, 0.35);
$col-prim: lighten($col-sec, 8%);
//$col-sec: #ffe6d1;
$col-border: hsl(39, 100%, 74%);
.editable {
  display: inline-block;
  background-image: linear-gradient(-45deg, $col-prim 40%, $col-sec 40%, $col-sec 50%, $col-prim 50%, $col-prim 90%, $col-sec 90%, $col-sec 100%);
  //background-size: 35.36px 35.36px;
  background-size: 20px 20px;
  border-radius: 5px;
  //border: 1px solid $col-border;
}
.editable.editing {
  //width: inherit;
  //height: inherit;
  //overflow: hidden;
  display: inline-flex;
  > input[type=text] {
    font: inherit;
    flex-grow: 1;
    width:         calc(100% - 50px);
    border: none;
    margin: -2px;
  }
} 
  
</style>
