
const formatterForInteger = new Intl.NumberFormat("cs-CZ", {
  style: "decimal",
  maximumFractionDigits: 0,
});

const formatterForFloat = new Intl.NumberFormat("cs-CZ", {
  style: "decimal",
  maximumFractionDigits: 1,
});

const formatInteger = (value : number) => {
  if (Number.isNaN(value)) return '-';
  return formatterForInteger.format(value);
}

const formatFloat = (value : number) => {
  if (Number.isNaN(value)) return '-';
  return formatterForFloat.format(value);
}

export {
  formatInteger,
  formatFloat,
}
