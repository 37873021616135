<template>
  <div class="toggle-button">
    <label class="switch">
      <input type="checkbox" :checked="value" @input.stop="onInput()" ref="inputElement" />
      <span class="slider round"></span>
    </label>
    <span class="btn-label">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "ToggleButton",
  props: {
    value: {
      type: [Boolean, Number],
      required: true,
    },
  },
  methods: {
    onInput() {
      this.$emit("input", !this.value);
    },
  },
};
</script>
<style lang="scss">
$frame-color: $primary-color;
$swith-color: white;

.toggle-button {
  padding: 0 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 1.25em;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c7c7c7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  background: $swith-color;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  top: 1px;
  background-color: white;
  box-shadow: 0px 1px 2px lightgrey;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $frame-color;
}

/* input:focus + .slider {
  box-shadow: 0 0 1px black;
} */

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.btn-label {
  padding: 0 10px 0 18px;
}
</style>
