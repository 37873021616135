<template>
  <tr
    class="data-row"
  >
    <td>
      <div class="flex-row space-between">
        {{room.name}}
      </div>
    </td>

    <ConnectionSettings :fromId="fromId" :toId="room.id" :mode="mode" />
    
  </tr>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ConnectionSettings from './ConnectionSettings.vue';
export default {
  components: { ConnectionSettings },
  props: {
    fromId: Number,
    room: Object,
    mode: Number,
  },
  computed: {
    ...mapGetters('configuration', [
      'getConnection',
    ]),
    connection () {
      return this.getConnection(this.fromId, this.room.id);
    },
    exists () {
      return this.connection != null;
    },
  },
  methods: {
    ...mapMutations("configuration", [
      'addConnection',
      'updateConnection',
      'removeConnection',
    ]),
    ...mapActions("configuration", [
      'addConnectionRequirement',
    ]),
  }
}
</script>

<style lang="scss">
.check-cell {
  display: flex;
  justify-content: center;
}
.width100 {
  width: 100%;
}
</style>
