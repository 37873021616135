<template>
  <Dialog :heading="getHeading()" :show="show" :width="1300" class="room-connection-dialog" @close="close"> 
    <div 
      v-for="(subObject, idx) in functionConfigurations" 
      :key="idx" 
      class="function-kind"
      >
      <div class="zone-name flex-row">
        <h4 v-text="functionKindName(subObject.functionKindId)"></h4>
      </div>
      <table>
        <tr class="heading-row">
          <th>Typ provozu: <span v-text="functionKindName(subObject.functionKindId)"></span></th>
          <th colspan="6">Typ konstrukce</th>          
        </tr>
        <tr class="heading-row">
          <th>Místnost pro vytvoření vazby</th>
          <th></th>
          <th>Stěna</th>
          <th>Strop</th>
          <th>Podlaha</th>
          <th>Výplň</th>
          <th>Jiná vazba</th>
        </tr>

        <ConnectionRecord 
          v-for="room in subObject.rooms"
          :key="room.name"
          :fromId="roomId"
          :room="room"
          :mode="mode"
        />

      </table>
      
    </div>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Dialog from '../../components/Dialog.vue'
import ConnectionRecord from './ConnectionRecord.vue';
export default {
  components: { Dialog, ConnectionRecord },
  props: ['mode'],
  data () {
    return  {
      roomId: 1,
      show: false,
      roomName: '',
    }
  },
  computed: {
    ...mapGetters('definitions', [
      'functionKinds',
      'functionKindName',
      'rooms',
      'requirements',
    ]),
    ...mapGetters('configuration', [
      'functionConfigurations',
      'showFunctionKind',
      'isRoomInProject',
      'findFunctionIndexByRoomId',
      'connections',
      'functionKindRequirements',
      'getConnection',
    ]),
  },
  methods: {
    openRoom(room) {
      this.roomId = room.id;
      this.roomName = room.name;
      this.show = true;
    },
    close() {
      this.roomId = 0;
      this.show = false;
      this.sendRefresh();
    },
    getHeading() {
      return `Požadavky na propojení místnosti ${this.roomName}`;
    },
    sendRefresh() {
      setTimeout(()=> this.$emit('refresh'), 10);
    },
  }
}
</script>

<style lang="scss">
.room-connection-dialog {
  .zone {
    width: 100%;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    line-height: 30px;
  }
  td,
  th {
    border: 1px solid white;
    padding: 0 8px;
  }
  td.check {
    background: white;
  }
  .heading-row {
    background: $primary-color;
    color: white;
    border-width: 0;
  }
  .data-row {
    background: $secondary-color;
    //font-family: Roboto;
    &:nth-child(2n+1) {
      background: white;
    }
  }
}
</style>
