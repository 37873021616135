<template>
  <div class="connection-to-exterior">
    
    <label v-if="connection.fromId">
      Konstrukce s vazbou na <strong v-text="toId==-1?'terén':'venkovní vzduch'"></strong> z místnosti
      <strong v-text="displayRoomName(connection.fromId)"></strong>  
    </label> 
    
    

    <div class="node flags">
      <label>vazba konstrukcí</label>
      <div class="flex-row">
        <CheckboxInput :disabled="isRequirementsMode" :value="connection.wall" @input="setConnection('wall', $event)" :width="200" >stěnou</CheckboxInput>
        <RequirementsDialog v-if="isRequirementsMode" v-show="connection.wall"
          :activeRequirements="connection.wallRequirements"
          @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'wall'})"
          level="connection"
          activator="button"
          :headingIdentificator="headingIdentificator('stěnou')"
        />
      </div>

      <div class="flex-row">
        <CheckboxInput :disabled="isRequirementsMode" :value="connection.ceiling" @input="setConnection('ceiling', $event)" :width="200">stropem</CheckboxInput>
        <RequirementsDialog v-if="isRequirementsMode" v-show="connection.ceiling"
          :activeRequirements="connection.ceilingRequirements"
          @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'ceiling'})"
          level="connection"
          activator="button"
          :headingIdentificator="headingIdentificator('stropem')"
        />
      </div>
      
      <div class="flex-row">
        <CheckboxInput :disabled="isRequirementsMode" :value="connection.floor" @input="setConnection('floor', $event)" :width="200">podlahou</CheckboxInput>
        <RequirementsDialog v-if="isRequirementsMode" v-show="connection.floor"
          :activeRequirements="connection.floorRequirements"
          @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'floor'})"
          level="connection"
          activator="button"
          :headingIdentificator="headingIdentificator('podlahou')"
        />
      </div>

      <div class="flex-row" v-show="toId != -1">
        <CheckboxInput :disabled="isRequirementsMode" :value="connection.opening" @input="setConnection('opening', $event)" :width="200" >výplní otvoru</CheckboxInput>
        <RequirementsDialog v-if="isRequirementsMode"  v-show="connection.opening"
          :activeRequirements="connection.openingRequirements"
          @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'opening'})"
          level="connection"
          activator="button"
          :headingIdentificator="headingIdentificator('výplní otvoru')"
        />
      </div>

      <div class="flex-row">
        <CheckboxInput :disabled="isRequirementsMode" :value="connection.virtual" @input="setConnection('virtual', $event)" :width="200" >bez konstrukce</CheckboxInput>
        <RequirementsDialog v-if="isRequirementsMode"  v-show="connection.virtual"
          :activeRequirements="connection.virtualRequirements"
          @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'virtual'})"
          level="connection"
          activator="button"
          :headingIdentificator="headingIdentificator('bez konstrukce')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ESchemaMode } from '@/@types/definitions/mode';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CheckboxInput from '../components/fundamentals/CheckboxInput.vue';
import RequirementsDialog from './RequirementsDialog.vue';

export default {
  components: { CheckboxInput, RequirementsDialog },
  props: {
    mode: {
      type: Number,
      default: ESchemaMode.EDIT,
    },
    fromId: {
      type: Number,
      required: true,
    },
    toId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...mapMutations("configuration", [
      'updateConnection',
    ]),
    ...mapActions("configuration", [
      'addConnectionRequirement',
      'removeConnectionRequirement',
    ]),
    setConnection(what, value) {
      const clone = {...this.connection}
      clone[what] = value;
      this.updateAction(clone);
    },
    displayRoomName(id) {
      const room = this.getRoomById(id);
      if (room) return `${room.name} (${room.id})`;
      return `nenalezená místnost - ${room.id}`;
    },
    updateAction(clone) {
      console.log("updateConnecitonAction", clone.fromId, clone.toId);
      if (clone.fromId && clone.toId) {
        this.updateConnection(clone); 
      }
    },
    headingIdentificator(constructionName) {
      const fromRoomName = this.getRoomById(this.connection.fromId).name;
      const toRoomName = this.connection.toId == -1? 'terén' : 'venkovní vzduch';
      const fromIdx = this.getIdxFromRoomId(this.connection.fromId);
      const fromFunctionKindId = this.functionConfigurations[fromIdx].functionKindId;
      return `${constructionName}: ${fromRoomName} (${this.functionKindName(fromFunctionKindId)}) na ${toRoomName}`;
    },
  },
  computed: {
    ...mapGetters('definitions', [
        'functionKinds',
        'rooms',
        'requirements',
    ]),
    ...mapGetters('configuration', [
        'getRoomById',
        'getIdxFromRoomId',
        'getConnection',
    ]),
    connection() {
      return this.getConnection(this.fromId, this.toId);
    },
    ...mapGetters('definitions', [
      'functionKindName',
    ]),
    ...mapGetters('configuration', [
      'functionConfigurations',
    ]),
    isEditMode() {
      return this.mode == ESchemaMode.EDIT;
    },
    isRequirementsMode() {
      return this.mode == ESchemaMode.REQUIREMENTS;
    },
  },
}
</script>

<style lang="scss">
.connection-to-exterior {
  .flags {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
