import { AppliedRequirement } from "@/@types/definitions/requirements";
import { Module } from "vuex";
import { IState } from "./index";

//let level, type, field, state, functionKind, ;
class FilterValues {
  values: Record<string, Array<string>> // Sets would be better

  constructor() {
    this.values = {};
    AppliedRequirement.filterParameters.forEach(param => this.values[param] = []);
  }
  ensureParameter(parameter: string) {
    if (!(parameter in this.values)) {
      this.values[parameter] = [];
    }
  }
  setFilterValue(parameter:string, value : string) {
    this.ensureParameter(parameter);
    this.values[parameter].push(value);
  }

  unsetFilterValue(parameter:string, value: string) {
    this.ensureParameter(parameter);
    this.values[parameter] = this.values[parameter].filter(val => val != value);
  }

  clearFilter(parameter:string) {
    this.values[parameter] = [];
  }

  getFilterValues(parameter: string) {
    if (!(parameter in this.values)) {
      return [];
    }
    //console.log(this.values[parameter]);
    return this.values[parameter];
  }
}

const state = {
  showChecklist: false,
  filter: new FilterValues(),
  connectionSettings: {
    wall: true,
    ceiling: true,
    floor: true,
    noConstruction: true,
    opening: true,
  },
  distribution: {
    parameters: Array<string>('level'),
  }
};

type State = typeof state;

const prezentation:Module<State, IState> = {
  namespaced: true,
  state: ():State => state,
  getters: {
    showChecklist: state => state.showChecklist,
    //parameterValues:,
    getFilterValues: state => (parameter:string) => state.filter.getFilterValues(parameter),
    getFilterValuesMap: state => state.filter.values,
    getDistribution: state => state.distribution.parameters,
    getDistributionCriterion: state => (idx:number) => state.distribution.parameters[idx],
    // connection settings
    showWalls: state => state.connectionSettings.wall,
    showCeilings: state => state.connectionSettings.ceiling,
    showFloors: state => state.connectionSettings.floor,
    showNoConstructions: state => state.connectionSettings.noConstruction,
    showOpenings: state => state.connectionSettings.opening,
    showAll: state => state.connectionSettings.wall &&
                      state.connectionSettings.ceiling &&
                      state.connectionSettings.floor &&
                      state.connectionSettings.noConstruction &&
                      state.connectionSettings.opening,
  },
  mutations: {
    setDistributionCriterion(state:State, {idx, value}:{idx:number, value:string}) {
      state.distribution.parameters[idx] = value;
    },
    addDistributionCriterion(state:State) {
      state.distribution.parameters.push('level');
    },
    removeDistributionCriterion(state:State, idx) {
      state.distribution.parameters.splice(idx, 1);
    },
    setShowChecklist(state:State, val) {
      state.showChecklist = val;
    },
    setFilterValue(state:State, payload : {parameter:string, value:string}) {
      state.filter.setFilterValue(payload.parameter, payload.value);
    },
    unsetFilterValue(state:State, payload : {parameter:string, value:string}) {
      state.filter.unsetFilterValue(payload.parameter, payload.value);
    },
    clearFilterValues(state:State, parameter:string) {
      state.filter.clearFilter(parameter);
    },
    ensureFilterValues(state:State, parameter:string) {
      state.filter.ensureParameter(parameter);
    },

    // connection settings
    setShowWalls(state: State, val) { state.connectionSettings.wall = val; },
    setShowCeilings(state: State, val) { state.connectionSettings.ceiling = val; },
    setShowFloors(state: State, val) { state.connectionSettings.floor = val; },
    setShowNoConstructions(state: State, val) { state.connectionSettings.noConstruction = val; },
    setShowOpenings(state: State, val) { state.connectionSettings.opening = val; },
    setShowAll(state: State, val) {
      console.log("setShowAll", val);
      state.connectionSettings.wall = val;
      state.connectionSettings.ceiling = val;
      state.connectionSettings.floor = val;
      state.connectionSettings.noConstruction = val;
      state.connectionSettings.opening = val;
    },
  },
  actions: {
    
  },
};

export default prezentation;
