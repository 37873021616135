<template>
  <td v-show="exists == false" :colspan="createButtonColspan">
    <div class="check-cell">
      <button class="btn black-btn width100" @click="createConnectionClick($event)" v-if="isEditMode"
      >propojit</button>
    </div>
  </td>
  
  <td v-if="exists">
    <i v-show="exists" @click="removeConnectionClick($event)" class="far fa-trash-alt clickable" v-if="isEditMode" ></i>
  </td>

  <td v-if="exists">
    <div class="check-cell">
      <CheckboxInput  v-if="isEditMode" :value="connection.wall" @input="setConnection('wall', $event)" />
      <RequirementsDialog v-show="connection.wall" v-if="isRequirementsMode"
        :activeRequirements="connection.wallRequirements"
        @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'wall'})"
        level="connection"
        activator="icon"
        :headingIdentificator="headingIdentificator('stěnou')"
      />
    </div>
  </td>

  <td v-if="exists">
    <div class="check-cell">
      <CheckboxInput v-if="isEditMode" :value="ceiling" @input="setConnection('ceiling', $event)" />
      <RequirementsDialog v-show="ceiling" v-if="isRequirementsMode"
          :activeRequirements="connection.ceilingRequirements"
          @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'ceiling'})"
          level="connection"
          activator="icon"
          :heading-identificator="headingIdentificator('stropem')"
        />
    </div>
  </td>
  
  <td v-if="exists">
    <div class="check-cell">
      <CheckboxInput  v-if="isEditMode" :value="floor" @input="setConnection('floor', $event)" />
      <RequirementsDialog v-show="floor" v-if="isRequirementsMode"
          :activeRequirements="connection.floorRequirements"
          @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'floor'})"
          level="connection"
          activator="icon"
          :heading-identificator="headingIdentificator('podlahou')"
        />
    </div>
  </td>

  <td v-if="exists && isNotGround" >
    <div class="check-cell">
      <CheckboxInput  v-if="isEditMode" :value="connection.opening" @input="setConnection('opening', $event)" />
      <RequirementsDialog v-show="connection.opening" v-if="isRequirementsMode"
        :activeRequirements="connection.openingRequirements"
        @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'opening'})"
        level="connection"
        activator="icon"
        :heading-identificator="headingIdentificator('výplní otvoru')"
      />
    </div>
  </td>

  <td v-if="exists">
    <div class="check-cell">
      <CheckboxInput  v-if="isEditMode" :value="connection.virtual" @input="setConnection('virtual', $event)" />
      <RequirementsDialog v-show="connection.virtual" v-if="isRequirementsMode"
          :activeRequirements="connection.virtualRequirements"
          @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'virtual'})"
          level="connection"
          activator="icon"
          :heading-identificator="headingIdentificator('bez konstrukce')"
        />
    </div>
  </td>
  <!-- 
  <td v-if="exists && hasLegacySupport">
    <div class="check-cell">
      <RequirementsDialog v-if="isRequirementsMode"
        :activeRequirements="connection.requirements"
        @add-requirement="addConnectionRequirement({
          from: connection.fromId, 
          to: connection.toId,
          requirement: $event
        })"
        level="connection"
        activator="icon"
      />
    </div>
  </td> -->
  
</template>

<script>
import { Connection, EExteriorType } from '@/@types/definitions/connections';
import { ESchemaMode } from '@/@types/definitions/mode';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CheckboxInput from '../../components/fundamentals/CheckboxInput.vue';
import RequirementsDialog from '../RequirementsDialog.vue';
export default {
  components: { RequirementsDialog, CheckboxInput },
  props: {
    fromId: Number,
    toId: Number,
    mode: {
      type: Number,
      default: ESchemaMode.EDIT,
    },
    hasLegacySupport: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('configuration', [
      'getConnection',
      'getRoomById',
      'getIdxFromRoomId',
    ]),
    ...mapGetters('definitions', [
      'functionKindName',
    ]),
    ...mapGetters('configuration', [
      'functionConfigurations',
    ]),
    connection () {
      return this.getConnection(this.fromId, this.toId);
    },
    exists () {
      return this.connection != null;
    },
    floor () {
      if (this.connection.fromId == this.fromId) {
        return this.connection.floor;
      }
      return this.connection.ceiling;
    },
    ceiling () {
      if (this.connection.fromId == this.fromId) {
        return this.connection.ceiling;
      }
      return this.connection.floor;
    },
    createButtonColspan() {
      if (this.toId == EExteriorType.GROUND) {
        return 5;
      }
      return 6;
    },
    isNotGround() {
      return this.toId != EExteriorType.GROUND;
    },
    isEditMode() {
      return this.mode == ESchemaMode.EDIT;
    },
    isRequirementsMode() {
      return this.mode == ESchemaMode.REQUIREMENTS;
    },
  },
  methods: {
    ...mapMutations("configuration", [
      'addConnection',
      'updateConnection',
      'removeConnection',
    ]),
    ...mapActions("configuration", [
      'addConnectionRequirement',
    ]),
    setConnection(what, value) {
      const clone = {...this.connection}
      if (clone.fromId != this.fromId) {
        if (what == 'floor') what = 'ceiling';
        else if (what == 'ceiling') what = 'floor';
      }
      clone[what] = value;
      this.updateAction(clone);
    },
    updateAction(clone) {
      console.log("updateConnecitonAction", clone.fromId, clone.toId);
      if (clone.fromId && clone.toId) {
        this.updateConnection(clone); 
      }
    },
    // remove or create connection
    removeConnectionClick() {
      console.log('remove connection');
      const message = "Opravdu si přejete smazat vazbu obsahující požadavky?";
        if (this.connection.requirements.length == 0
         || (this.connection.requirements.length > 0
          && confirm(message))) {
            this.removeConnection({
              fromId: this.connection.fromId, 
              toId: this.connection.toId
            });
        }
    },
    createConnectionClick() {
      console.log('new connection');
      const newConnection = new Connection(this.fromId, this.toId);
        this.addConnection(newConnection);
    },
    headingIdentificator(constructionName) {
      const fromRoomName = this.getRoomById(this.connection.fromId).name;
      if (this.connection.toId < 0) {
        const toRoomName = this.connection.toId == EExteriorType.GROUND? 'terén' : 'venkovní vzduch';
        const fromIdx = this.getIdxFromRoomId(this.connection.fromId);
        const fromFunctionKindId = this.functionConfigurations[fromIdx].functionKindId;
        return `${constructionName}: ${fromRoomName} (${this.functionKindName(fromFunctionKindId)}) na ${toRoomName}`;
      }
      const toRoomName = this.getRoomById(this.connection.toId).name;
      const fromIdx = this.getIdxFromRoomId(this.connection.fromId);
      const toIdx = this.getIdxFromRoomId(this.connection.toId);

      const fromFunctionKindId = this.functionConfigurations[fromIdx].functionKindId;
      const toFunctionKindId = this.functionConfigurations[toIdx].functionKindId;
      return `${constructionName}: ${fromRoomName} (${this.functionKindName(fromFunctionKindId)}) ➞ ${toRoomName} (${this.functionKindName(toFunctionKindId)})`;
    },
  },
  data() {
    return {
      ESchemaMode,
    };
  },
}
</script>

<style lang="scss">
.check-cell {
  display: flex;
  justify-content: center;
}
.width100 {
  width: 100%;
}
</style>
