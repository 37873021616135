<template>
  <div class="template-management">
    <h1>Šablony</h1>
    <table>
      <tr class="heading-row">
        <th>id</th>
        <th>název šablony</th>
        <th>autor</th>
        <th>poznámka</th>
      </tr>
      <tr 
        v-for="(temp, idx) in templates" 
        :key="idx"
        class="data-row"
      >
        <td>{{ temp._id }}</td>
        <td>{{ temp.name }}</td>
        <td>{{ temp.author?temp.author.username: '' }}</td>
        <td>{{ temp.description }}</td>
        <td>
          <!-- functionality is not implemented yet 
            <div class="flex-inline">
            <button 
              class="btn black-btn"
              @click="openTemplate(temp._id)"
            >
              otevřít šablonu
            </button>
            <button 
              class="btn black-btn"
              @click="removeTemplate(temp._id)"
            >
              smazat šablonu
            </button>

            <button 
              class="btn black-btn"
              @click="duplicateTemplate(temp._id)"
            >
              duplikovat
            </button>

          </div> -->
        </td>
        
      </tr>
    </table>
  </div>
</template>

<script>
import { EActionStatus } from '@/store';
import { mapActions, mapGetters } from 'vuex';
import api from '../../store/api';
export default {
  mounted() {
    this.getAll();  
  },
  computed: mapGetters(['actionStatus']),
  methods: {
    ...mapActions('template',['OPEN_TEMPLATE', 'DUPLICATE', 'RENAME_TEMPLATE', 'REMOVE_TEMPLATE']),
    async getAll() {
      const templates = await api.get('/api/templates');
      this.templates = templates;
    },
    async removeTemplate(_id) {
      await this.REMOVE_TEMPLATE(_id);
      await this.getAll();
    },
    async duplicateTemplate(_id) {
      await this.DUPLICATE(_id);
      await this.getAll();
    },
    async renameTemplate(_id, name) {
      await this.RENAME_TEMPLATE({_id, name});
      await this.getAll();
    },

    async openTemplate(id) {
      await this.OPEN_TEMPLATE(id);
      if (this.actionStatus == EActionStatus.OK) {
        // TODO: jump to the schema when posible
        this.$router.push('project');
      }
      // route
    }
  },
  data() {
    return {
      templates: [],
    };
  }
}
</script>

<style lang="scss">
.template-management {
  .flex-inline {
    gap: 10px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    line-height: 30px;
  }
  td,
  th {
    border: 1px solid white;
    padding: 0 8px;
  }
  td.check {
    background: white;
  }
  .heading-row {
    background: $primary-color;
    color: white;
    border-width: 0;
  }
  .data-row {
    background: $secondary-color;
    &:nth-child(2n+1) {
      background: white;
    }
  }
}
</style>
