import api from '.';
import { IUserData } from '../users';

export interface IUpdateUserPayload {
  password: string,
  username: string,
  role: string,
}

class UserService {
  private apiBaseUrl = '/api/users';

  async getAllUsers(): Promise<IUserData[]> {
    return api.get<IUserData[]>(`${this.apiBaseUrl}/`);
  }

  async createUser(payload: IUpdateUserPayload): Promise<IUserData> {
    return api.post<IUserData>(this.apiBaseUrl, payload);
  }

  async getUserById(id: string): Promise<IUserData> {
    return api.get<IUserData>(`${this.apiBaseUrl}/${id}`);
  }

  async updateUser(id: string, payload: IUpdateUserPayload): Promise<IUserData> {
    return api.put<IUserData>(`${this.apiBaseUrl}/${id}`, payload);
  }

  async deleteUser(id: string): Promise<void> {
    await api.delete(`${this.apiBaseUrl}/${id}`);
  }
}


export default new UserService();
