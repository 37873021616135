import { EExteriorType } from "./connections";
import { RequirementList } from "./requirements";

export interface IFunctionKind {
    id: number,
    name: string,
}

export interface IRoom {
    id: number,
    name: string,
    code: string,
    functionKind: number,
}

export interface IUsedRoom extends IRoom {
  requirements: RequirementList,
  data: Record<string, any>,
}

export class UsedRoom implements IUsedRoom {
  id: number;
  name: string;
  code: string;
  functionKind: number;
  requirements: RequirementList;
  data: Record<string, any>;
  computed: Record<string, (arg?: any) => any>;

  constructor(id:number, name:string, code:string, functionKind:number) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.functionKind = functionKind;
    this.requirements = [];
    this.data = {
    };
    this.computed = {
    };
    this.initFlags();
    this.initComputed();
  }

  static Create(json: UsedRoom) {
    const object = new UsedRoom(json.id, json.name, json.code, json.functionKind);
    object.data = {...json.data};
    object.requirements = [...json.requirements];

    return object;
  }

  setParam(name:string, value:any) {
    if (name in this.data) {
      this.data[name] = value;
    } else {
      console.error('setParameter', name, value);
    }
  }
  getParam(name:string, getters?: any) {
    if (name in this.computed) {
      return this.computed[name](getters);
    }


    if (name in this.data) {
      return this.data[name];
    }
    // console.log('getParam',name, name in this.computed);
    // console.log(this.computed[name]);
  }
  initFlags() {
    // this.data.air = false;
    // this.data.ground = false;
    /* // air connection
    this.data.hasAirWall = false;
    this.data.hasAirFloor = false;
    this.data.hasAirCeiling = false;
    this.data.hasAirOpening = false;
    // ground connection
    this.data.hasGroundWall = false;
    this.data.hasGroundFloor = false;
    this.data.hasGroundCeiling = false;
    this.data.hasGroundOpening = false;  */
  }
  initComputed() {
    this.computed.air = (getters) =>  getters['getConnection'](this.id, EExteriorType.AIR);
    this.computed.ground = (getters) =>  getters['getConnection'](this.id, EExteriorType.GROUND);

    /*
    this.computed.air = () => this.getParam('hasAirWall') || 
                              this.getParam('hasAirFloor') ||
                              this.getParam('hasAirCeiling') ||
                              this.getParam('hasAirOpening');
    this.computed.ground = () => this.getParam('hasGroundWall') ||
                                this.getParam('hasGroundFloor') ||
                                this.getParam('hasGroundCeiling') ||
                                this.getParam('hasGroundOpening');
    */
    
  }
  toggleFlag(name:string) {
    this.data[name] = !this.data[name];
  }
  getFlag(name:string, getters:any) {
    return this.getParam(name, getters);
  }
}
