import api from '.';
type IConfiguration = any;


class ConfigurationService {

  private projectId: string;

  constructor(projectId = 'unknown') {
    this.projectId = projectId;
  }

  project(projectId: string) {
    this.projectId = projectId;
    return this;
  }

  async createConfiguration(name: string, description: string) {
    const url = `/api/projects/${this.projectId}/configurations`;
    return await api.post<IConfiguration>(url, { name, description });
  }

  async createConfigurationFromTemplate(templateId: string, name: string, description: string) {
    const url = `/api/projects/${this.projectId}/configurations/from-template`;
    return await api.post<IConfiguration>(url, { templateId, name, description });
  }

  async getAllConfigurations() {
    const url = `/api/projects/${this.projectId}/configurations`;
    return await api.get<IConfiguration[]>(url);
  }

  async getConfigurationById(configurationId: string) {
    const url = `/api/projects/${this.projectId}/configurations/${configurationId}`;
    return await api.get<IConfiguration>(url);
  }

  async renameConfiguration(configurationId: string, name: string) {
    const url = `/api/projects/${this.projectId}/configurations/${configurationId}/rename`;
    return await api.post<IConfiguration>(url, { name });
  }

  async duplicateConfiguration(configurationId: string, name: string) {
    const url = `/api/projects/${this.projectId}/configurations/${configurationId}/duplicate`;
    return await api.post<IConfiguration>(url, {name});
  }

  async updateConfiguration(configurationId: string, data: any) {
    const url = `/api/projects/${this.projectId}/configurations/${configurationId}`;
    return await api.put<IConfiguration>(url, data);
  }

  async removeConfiguration(configurationId: string) {
    const url = `/api/projects/${this.projectId}/configurations/${configurationId}`;
    return await api.delete<IConfiguration>(url);
  }
}

export default ConfigurationService;
