<template>
  <div class="project-manager">

    <teleport to="#left-sider">
      <LeftSider @new-project="showDialog()" @filterUpdated="setFilter" />
    </teleport>

    <div class="viewport" >
      
      <div class="column main">

        <h1>Uložené projekty</h1>
        <div class="projects-header flex-row">
          <div class="icons-stack">
            <div class="icon-button"></div>
            <div class="icon-button"></div>
            <div class="icon-button"></div>
          </div>
          <div class="name">Název projektu</div>
          <div class="people">Vlastník projektu</div>
          <div class="date">Datum vytvoření</div>
        </div>

        <div 
          class="projects-item flex-row"
          v-for="project in projects"
          :key="project._id"
          @click="openProject(project._id)"
        >
          <div class="icons-stack">
            <div class="icon-button">
              <i v-show="project.stages.CC" class="fas fa-calculator"></i>
            </div>
            <div class="icon-button">
              <i v-show="project.stages.PC" class="fas fa-pencil-ruler"></i>
            </div>
            <div class="icon-button">
              <i v-show="project.stages.M3D" class="fas fa-desktop"></i>
            </div>
          </div>
          <div class="name" v-text="project.name"></div>
          <div class="people" :title="project.owners.map(owner => owner.username).join(', ')" >
            <div class="ellipsis-line-2" v-text="project.owners.map(owner => owner.username).join(', ')" ></div>
          </div>
          <div class="date" v-text="new Date(project.createdAt).ddmmyyyy()"></div>
          <!-- <button @click="remove(project._id)">X</button> -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LeftSider from './LeftSider.vue';

Date.prototype.ddmmyyyy = function() {
  var yyyy = this.getFullYear();
  var mm = this.getMonth() < 9 ? "" + (this.getMonth() + 1) : (this.getMonth() + 1); // getMonth() is zero-based
  var dd = this.getDate() < 10 ? "" + this.getDate() : this.getDate();
  return `${dd}.${mm}. ${yyyy}`;
};

export default {
  components: {
    LeftSider,
  },
  created() {
    this.getAll();
  },
  methods: {
    ...mapActions('project', ['GET_ALL', 'REMOVE_PROJECT', 'OPEN_PROJECT']),
    async getAll(filter) {
      this.projects = await this.GET_ALL(filter);
    },
    async remove(id) {
      await this.REMOVE_PROJECT(id);
      await this.getAll();
    },
    async openProject(id) {
      await this.OPEN_PROJECT(id);
      this.$router.push('/project')
    },
    setFilter(filter) {
      this.getAll(filter);
    },
  },
  data() {
    return {
      projects: [],
    }
  }
}
</script>

<style lang="scss">
.projects-header.flex-row,
.projects-item.flex-row {
  gap: 30px;
  div.icons-stack {
    display: flex;
    
    .icon-button {
      min-width: 30px;
      display: flex;
      justify-content: center;
      align-items: middle;
      vertical-align: middle;
      i {
        color: #3d3d3d;
        line-height: 64px;
      }

      //background-color: pink;
      flex: 0 0 30px;
    }
    //flex: 0 0 90px;
    height: 64px;
    //flex-grow: 0;
  }
  .name {
    flex: 1 0 500px;
    display: flex;
    align-items: center;
  }
  .date {
    text-align: center;
    flex: 0 0 200px;
    line-height: 64px;
  }
  .people {
    //display: table;
    flex: 0 0 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

}

.project-manager {
  height: 100%;
  //overflow: auto;
.viewport {
  display: flex;
  
  .main {
    width: 100%;
    .projects-header {
      //justify-content: space-around;
      div {
        color: darken($secondary-color, 30);
        font-weight: 700;
      }
    }
    .projects-item {
      //justify-content: space-around;
      div {
        color: black;
        font-weight: 700;
      }
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #777777;
      height: 64px;
      cursor: pointer;
      &:hover {
        background-color: lighten($color: $primary-color, $amount: 30);
      }
    }
    
  }
}

.centered {
  margin: 25px 0 0 0;
  align-items: center;
  justify-content: center;
}
}
</style>
