<template>
  <div>
    <div 
      class="criterion-item" 
      v-for="(x, idx) in getDistribution" 
      :key="idx"
    >
      <h5 class="criterion">Na základě požadavku </h5>
      <div class="flex-inline">
        <DropdownInput 
          :options="requirementParameterOptions" 
          :value="getDistributionCriterion(idx)" 
          @input="setDistributionCriterion({idx, value: $event})"
          small 
          :width="300"
        />
        <button class="dark-btn" @click="removeDistributionCriterion(idx)">
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>
    <div class="flex-row centered">
      
      <button class="grey-btn" @click="addDistributionCriterion">
        <i class="fas fa-plus" style="padding-right: 10px;"></i>
        přidat úroveň</button>
      </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import DropdownInput from '../../components/fundamentals/DropdownInput.vue'
export default {
  components: {DropdownInput},
  computed: {
    ...mapGetters('definitions', [
      'requirementParameters',
      'requirementParameterOptions',
    ]),
    ...mapGetters('configuration', [
      'getParameterAnalysis',
    ]),
    ...mapGetters('prezentation', [
      'getFilterValuesMap',
      'getDistributionCriterion',
      'getDistribution',
    ]),
  },
  methods: {
    ...mapMutations('prezentation', [
      'setDistributionCriterion',
      'setDistributionCriterion',
      'addDistributionCriterion',
      'removeDistributionCriterion',
    ]),
  }
}
</script>

<style lang="scss">
.criterion-item {
  display: flex;
  align-items: baseline;
  /* gap: 50px; */
  justify-content: space-between;
  .flex-inline {
    gap: 10px;
  }

  .dropdown-input {
    color: black;
    min-width: 308px;
  }
}
</style>
