<template>
  
    <div class="residential-apartments-building">
      <render-gui :gui="guiTop" />

      <residential-apartments-dispositions :cs="cs" />

      <render-gui :gui="guiNext" />

      <apartment-dialog ref='apartmentDialog'/>
    </div>
 
</template>

<script>
import RenderGui from '@/GUI/RenderGui.vue';
import {
  ActivableParameter,
  GUI,
  Heading,
  LabeledCounterParameter,
  Line,
  ParameterOutput,
  Section,
  SelectParameter,
  ToggleButtonComponent,
} from '@/GUI';
import ApartmentDialog from './ApartmentDialog.vue'
import { composeDefault1kk } from './apartment';
import ResidentialApartmentsDispositions from './ResidentialApartmentsDispositions.vue';


function createResidentialApartmentsGUITop(residentialApartmentsCS) {
  const gui = new GUI(residentialApartmentsCS);
  gui.add(new Heading('Konfigurace bytového domu'))
  gui.add(new ActivableParameter('Koeficient navýšení plochy místností oproti minimálním požadavkům', 'roomFloorAreaCoef', '', {min: 1}));
  gui.add(new Line())
  
  return gui;
}

function createResidentialApartmentsGUINext(residentialApartmentsCS) {
  const gui = new GUI(residentialApartmentsCS);
  const sectionClasses = {'flex-row': true, 'gap-10': true};
  
  gui.add(new Heading('Parkovací místa'))
  //gui.add(new CheckboxParameter('Započítat vliv lokálních součinitelů pro stanovení počtu potřebných parkovacích míst', 'useParkingMultipliers'))
  gui.add(new ParameterOutput('Počet parkovacích míst pro bytové jednotky', 'numberParkingSpacesFlatsBoF', 'ks'))
  gui.add((new Section(gui.cs, sectionClasses))
        .add(new Heading('Umístění parkovacích míst pro bytové jednotky'))
        .add(new LabeledCounterParameter('Nadzemní podlaží', 'parkingSpacesFlatsAboveGroundPercentage', '%', {min: 0, max: 100, step: 5}))
        .add(new LabeledCounterParameter('Podzemní podlaží', 'parkingSpacesFlatsBelowGroundPercentage', '%', {min: 0, max: 100, step: 5}))
        .add(new LabeledCounterParameter('Mimo objekt', 'parkingSpacesFlatsOutsidePercentage', '%', {min: 0, max: 100, readonly: true})))
  gui.add(new Line())
  gui.add(new ParameterOutput('Počet dalších parkovacích míst v blízkosti domu', 'numberParkingSpacesNearbyBoF', 'ks'))
  gui.add((new Section(gui.cs, sectionClasses))
        .add(new Heading('Umístění parkovacích míst pro bytové jednotky'))
        .add(new LabeledCounterParameter('Nadzemní podlaží', 'parkingSpacesNearbyAboveGroundPercentage', '%', {min: 0, max: 100, step: 5}))
        .add(new LabeledCounterParameter('Podzemní podlaží', 'parkingSpacesNearbyBelowGroundPercentage', '%', {min: 0, max: 100, step: 5}))
        .add(new LabeledCounterParameter('Mimo objekt', 'parkingSpacesNearbyOutsidePercentage', '%', {min: 0, max: 100, readonly: true})))
  gui.add(new Line())
  
  sectionClasses['space-between'] = true;
  gui.add(new Heading('Volitelná vybavenost společných prostorů'))
  
  gui.add((new Section(gui.cs, sectionClasses))
      .add(new ToggleButtonComponent('Výtah', 'hasLift')))

  gui.add((new Section(gui.cs, sectionClasses))
      .add(new ToggleButtonComponent('Sklepní kóje', 'hasCellarCubicle'))
      .add(new SelectParameter('', 'cellarCubicleLocation', {showIfParameter: 'hasCellarCubicle'})))
      

  gui.add((new Section(gui.cs, sectionClasses))
      .add(new ToggleButtonComponent('Kolárna / kočárkárna', 'hasCarriageStore'))
      .add(new SelectParameter('', 'carriageStoreLocation', {showIfParameter: 'hasCarriageStore'})))
  
  gui.add((new Section(gui.cs, sectionClasses))
      .add(new ToggleButtonComponent('Prádelna', 'hasLaundry'))
      .add(new SelectParameter('', 'laundryLocation', {showIfParameter: 'hasLaundry'})))

  gui.add((new Section(gui.cs, sectionClasses))
      .add(new ToggleButtonComponent('Kotelna', 'hasBoilerRoom'))
      .add(new SelectParameter('', 'boilerRoomLocation', {showIfParameter: 'hasBoilerRoom'})))

  gui.add((new Section(gui.cs, sectionClasses))
      .add(new ToggleButtonComponent('Technická místnost', 'hasTechnicalRoom'))
      .add(new SelectParameter('', 'technicalRoomLocation', {showIfParameter: 'hasTechnicalRoom'})))

  gui.add((new Section(gui.cs, sectionClasses))
      .add(new ToggleButtonComponent('Úklidová místnost', 'hasCleaningRoom'))
      .add(new SelectParameter('', 'cleaningRoomLocation', {showIfParameter: 'hasCleaningRoom'})))
    
  return gui;
}

export default {
  components: {
    RenderGui,
    ApartmentDialog,
    ResidentialApartmentsDispositions,
  },
  props: ['cs'],
  methods: {
    openApartmentOnClick() {
      this.$refs.apartmentDialog.open(composeDefault1kk());
    },
  },
  created() {
    const residentialApartmentsCS = this.cs;
    this.guiTop = createResidentialApartmentsGUITop(residentialApartmentsCS);
    this.guiNext = createResidentialApartmentsGUINext(residentialApartmentsCS);
  },
  data() {
    return {
      guiTop: null,
      guiNext: null,
    }
  }
}

</script>

<style lang="scss">
.cadastral-plan {
  display: flex;
  flex-direction: row;
}
.next {
  margin: 15px;
  justify-content: end;
}
.cadastral-results {
  padding: 15px;
  background: lighten(black, 50%);
  color: white;
  .view-limits {
    align-items: baseline;
    gap: 35px;
  }

  .result-item {
    display: flex;
    p {
      flex-grow: 1;
    }
  }
  //display: flex;
  //flex-direction: row;
  
  flex-grow: 1;
}
</style>
