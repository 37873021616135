<template>
  <button class="primary" style="width:unset" @click="open()">
    <i class="fas fa-tasks"></i> Hromadné přiřazování požadavků
  </button>
  <Dialog heading="Hromadné přiřazování požadavků" :show="show" @close="close()" :width="1760">
    <BatchAssignment />
  </Dialog>
</template>

<script>
import Dialog from '@/views/components/Dialog.vue';
import BatchAssignment from './BatchAssignment.vue';

export default {
  components: {
    Dialog,
    BatchAssignment
  },
  methods: {
    close() {
      this.show = false;
    },
    open() {
      this.show = true;
    }
  },
  data() {
    return {
      show: false,
    };
  }
}
</script>
