import { Requirement } from "@/@types/definitions/requirements";
import { Module, ActionContext } from "vuex";

import { IState } from "../index"; 

const state = {
  customRequirements: Array<Requirement>(),
  lastId: 100000,
};

export type ICustomRequirement = typeof state;
type State = ICustomRequirement;

const customRequirements:Module<ICustomRequirement, IState> = {
  state: ():State => state,
  namespaced: true,
  getters: {
    customRequirements: (state: State) => state.customRequirements,
    lastCustomRequirementId: (state: State) => state.lastId,
  },
  mutations: {
    set(state: State, data: ICustomRequirement) {
      state.customRequirements = data.customRequirements;
      state.lastId = data.lastId;
    },
    addCustomRequirements(state: State, data: any) {
      state.customRequirements.push(data);
    },
    increaseLastCustomRequirementId(state: State) {
      state.lastId ++;
    }
  },
  actions: {
    registerCustomRequirement(context: ActionContext<State, IState>, req: Requirement) {
      context.commit('increaseLastCustomRequirementId');
      const lastId = context.getters['lastCustomRequirementId'];
      req.id = lastId;
      context.commit('addCustomRequirements', req);
      context.commit('definitions/addRequirementTemplate', req, {root: true});
    },
    loadCustumRequirements(context: ActionContext<State, IState>, data: ICustomRequirement) {
      context.commit('set',  data);
      data.customRequirements.forEach(req => {
        context.commit('definitions/addRequirementTemplate', req, {root: true});
      });
    }
  }
}


export default customRequirements;
