<template>
  <div v-if="itsMe()">
    <i @click="openCalc()" class="clickable fas fa-calculator"></i>
    <Dialog heading="Pokles dotykové teploty podlahy" class="floor-contact-temperature-drop" :show="show" @close="closeCalc()">
      <Section name="Druh budovy">
        <DropdownInput
          :options="staticData.categoryOptions"
          :value="category"
          @input="setCategory($event)"
          :width="700"
          small
        />
      </Section>

      <Section name="Účel místnosti">
        <DropdownInput
          :options="getSubacategoryOptions()"
          :value="subcategory"
          @input="subcategory = $event"
          :width="700"
          small
        />
      </Section>

      <Section name="Kategorie podlahy">
        <div class="flex-row floor-category">
          <div class="flex-column">
            <h3>Požadovaná úroveň</h3>
            <span v-text="displayFloorCategory(floorCategory().required)"></span>
          </div>
          <div class="flex-column">
            <h3>Doporučená úroveň</h3>
            <span v-text="displayFloorCategory(floorCategory().suggested)"></span>
          </div>
        </div>
      </Section>

      <Section name="Volba přísnosti požadavku">
        <DropdownInput
          :options="staticData.requiredParameterOptions"
          :value="parameter"
          @input="parameter = $event"
          :width="700"
          small
        />
      </Section>

      <Section
        name="Δθ10,N"
      >
        {{ result() }}
      </Section>
      <div class="controls">
        <button @click="show = false" class="secondary">zrušit</button>
        <button @click="use()"  class="primary">použít</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
const catAOptions = [
  { name: "Dětský pokoj, ložnice",
    value: "Dětský pokoj, ložnice"},
  { name: "Obývací pokoj, pracovna, předsíň sousedící s pokoji, kuchyň",
    value: "Obývací pokoj, pracovna, předsíň sousedící s pokoji, kuchyň"},
  { name: "Koupelna, WC",
    value: "Koupelna, WC"},
  { name: "Předsíň před vstupem do bytu",
    value: "Předsíň před vstupem do bytu"},
];

const catBOptions = [
  {name: "Učebna, kabinet", value: "Učebna, kabinet"},
  {name: "Tělocvična", value: "Tělocvična"},
  {name: "Dětská místnost jeslí a školky", value: "Dětská místnost jeslí a školky"},
  {name: "Operační sál, předsálí, ordinace, přípravna, vyšetřovna, služební místnost", value: "Operační sál, předsálí, ordinace, přípravna, vyšetřovna, služební místnost"},
  {name: "Chodba a předsíň nemocnice", value: "Chodba a předsíň nemocnice"},
  {name: "Pokoj dospělých nemocných", value: "Pokoj dospělých nemocných"},
  {name: "Pokoj nemocných dětí", value: "Pokoj nemocných dětí"},
  {name: "Pokoj intenzivní péče", value: "Pokoj intenzivní péče"},
  {name: "Kancelář", value: "Kancelář"},
  {name: "Hotelový pokoj", value: "Hotelový pokoj"},
  {name: "Pokoj v ubytovně", value: "Pokoj v ubytovně"},
  {name: "Sál kina, divadla", value: "Sál kina, divadla"},
  {name: "Místa pro hosty v restauraci", value: "Místa pro hosty v restauraci"},
  {name: "Prodejna potravin", value: "Prodejna potravin"},
]

const catCOptions = [
  {name: "Trvalé pracovní místo při sedavé práci", value: "Trvalé pracovní místo při sedavé práci"},
  {name: "Trvalé pracovní místo bez podlážky nebo předepsané teplé obuvi", value: "Trvalé pracovní místo bez podlážky nebo předepsané teplé obuvi"},
  {name: "Sklad se stálou obsluhou", value: "Sklad se stálou obsluhou"},
]

const categoryOptions = [
 {name: 'Obytná budova', value: 'A',},
 {name: 'Občanská budova', value: 'B',},
 {name: 'Výrobní budova', value: 'C',},
]

const results = [
  {value: "Dětský pokoj, ložnice",	required: "I.",	suggested: "-"},
  {value: "Obývací pokoj, pracovna, předsíň sousedící s pokoji, kuchyň",	required: "II.",	suggested: "I."},
  {value: "Koupelna, WC",	required: "III.",	suggested: "II."},
  {value: "Předsíň před vstupem do bytu",	required: "IV.",	suggested: "III."},
  {value: "Učebna, kabinet",	required: "II.",	suggested: "-"},
  {value: "Tělocvična",	required: "II.",	suggested: "-"},
  {value: "Dětská místnost jeslí a školky",	required: "I.",	suggested: "-"},
  {value: "Operační sál, předsálí, ordinace, přípravna, vyšetřovna, služební místnost",	required: "II.",	suggested: "-"},
  {value: "Chodba a předsíň nemocnice",	required: "III.",	suggested: "II."},
  {value: "Pokoj dospělých nemocných",	required: "II.",	suggested: "I."},
  {value: "Pokoj nemocných dětí",	required: "I.",	suggested: "-"},
  {value: "Pokoj intenzivní péče",	required: "II.",	suggested: "I."},
  {value: "Kancelář",	required: "II.",	suggested: "-"},
  {value: "Hotelový pokoj",	required: "II.",	suggested: "-"},
  {value: "Pokoj v ubytovně",	required: "III.",	suggested: "II."},
  {value: "Sál kina, divadla",	required: "II.",	suggested: "-"},
  {value: "Místa pro hosty v restauraci",	required: "III.",	suggested: "II."},
  {value: "Prodejna potravin",	required: "III.",	suggested: "-"},
  {value: "Trvalé pracovní místo při sedavé práci",	required: "II.",	suggested: "-"},
  {value: "Trvalé pracovní místo bez podlážky nebo předepsané teplé obuvi",	required: "III.",	suggested: "II."},
  {value: "Sklad se stálou obsluhou",	required: "IV.",	suggested: "III."},
]

const resultTable = [
  {cat: "I.", catName:	"I. (Velmi teplé)", value:	3.8},
  {cat: "II.", catName:	"II. (Teplé)", value:	5.5},
  {cat: "III.", catName:	"III. (Méně teplé)", value:	6.9},
  {cat: "IV.", catName:	"IV. (Studené)", value:	16},
  {cat: "-", catName: "-", value: "-"},
  {cat: "missing", catName: "chybí", value: "-"},
]
const requiredParameterOptions = [
  {name: "Požadovaná úroveň"	, value: "required"},
  {name: "Doporučená úroveň", value: "suggested"},
]

import Dialog from '../components/Dialog.vue'
import DropdownInput from '../components/fundamentals/DropdownInput.vue'
import Section from '../components/Section.vue'
export default {
  components: { Dialog, DropdownInput, Section },
  props: {
    desiredCalculator: {
      type: String,
      default: "no-calc"
    }
  },
  data() {
    return {
      show: false,
      buildingType: 'non-manufacturing',
      category: 'A',
      subcategory: '',
      parameter: 'required',
      staticData: {
        categoryOptions,
        requiredParameterOptions,
      },
    }
  },
  methods: {
    itsMe() {
      return this.desiredCalculator == "FloorContactTemperatureDrop"
    },
    openCalc() {
      this.show = true
    },
    closeCalc() {
      this.show = false
    },
    setCategory(category) {
      this.category = category;
    },
    getSubacategoryOptions() {
      if (this.category === 'A') return catAOptions;
      if (this.category === 'B') return catBOptions;
      if (this.category === 'C') return catCOptions;
      return [];
    },
    floorCategory() {
      if (this.getSubacategoryOptions().some(cat => cat.value === this.subcategory)) {
        const [record] = results.filter(item => item.value === this.subcategory);
        if (!record) { 
          alert(`to je divny '${this.subcategory}'`);
          return;
        }
        return record;
      }
      return {required: 'missing', suggested: 'missing'};
    },
    displayFloorCategory(floorCat) {
      const [res] = resultTable.filter(res => res.cat === floorCat);
      if (! res) return 'no way';
      return res.catName;
    },
    result() {
      const record = this.floorCategory();
      if (this.parameter in record) {
        const floorCat = record[this.parameter]
        const [res] = resultTable.filter(res => res.cat === floorCat);
        return res.value;
      } else {
        alert(`to je divny '${this.subcategory}, ${this.parameter}, ${record}'`);
      }
      return "-";
    },
    use () {
      const result = this.result();
      if (!isNaN(parseInt(result))) {
        this.show = false;
        const requiredValue1 = result;
        this.$emit("set-requirement", {requiredValue1});
      } else {
        confirm('Pro zadanou kombinaci neexistuje nelze určit číselný výsledek.');
      }
    },
  },
}
</script>

<style lang="scss">
.floor-contact-temperature-drop {
  .section {
    .flex-inline {
      align-items: baseline;
    }
  }
  .floor-category {
    width: 700px;
    justify-content: space-around;
    span {
      text-align: center;
    }
  }
  .controls {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}
</style>
