type State = any;

interface IUserData {
  id:string,
  username:string,
  role:string
}

const user = {
  namespaced: true,
  state: () => ({
    id: "",
    username: "",
    role: "",
    refreshToken: "",
    accessToken: "",
  }),
  getters: {
    id:(state:State) => state.id,
    username:(state:State) => state.username,
    role:(state:State) => state.role,
    isLoggedIn: (state:State) => !!state.refreshToken,
    refreshToken: (state:State) => state.refreshToken,
    accessToken: (state:State) => state.accessToken,
  },
  mutations: {
    setUser: (state:State, {id, username, role}: IUserData) => {
      state.id = id;
      state.username = username;
      state.role = role;
    },
    unsetUser: (state:State) => {
      state.id = '';
      state.username = '';
      state.role = '';
    },
    clearTokens: (state:State) => {
      state.refreshToken = "";
      state.accessToken = "";
    },
    setRefreshToken: (state:State, refreshToken:string) => { 
      state.refreshToken = refreshToken;
      localStorage.setItem('refreshToken', refreshToken);
    },
    setAccessToken: (state:State, accessToken:string) => {
      state.accessToken = accessToken;
      localStorage.setItem('accessToken', accessToken);
    },
  },
  actions: {},
};

export default user;
