<template>
  <h1>model3d list</h1>

</template>


<script>


</script>


<style lang="scss">


</style>
