<template>
  <div class="input-group">
    <div class="counter flex-inline">
      <div class="button left" :class="{disabled: disableMinus}" @click="decrement">
        <i class="fa fa-minus-circle"></i>
      </div>
      <input type="number" :class="{error: offRange}" :value="value" @input.stop="onInput($event)" :disabled="disabled" :step="step" ref="inputElement" />
      <div class="button right" :class="{disabled: disablePlus}" @click="increment">
        <i class="fa fa-plus-circle"></i>
      </div>
    </div>
    <span class="input-group-text" v-html="unit">
    </span>
  </div>
</template>

<script>
export default {
  name: "CounterInput",
  props: {
    value: {
      type: Number,
      required: true,
    },
    unit: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: -1e10
    },
    max: {
      type: Number,
      default: 1e10
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    clip(num) {
      return Math.min(this.max, Math.max(this.min, num));
    },
    onInput(event) {
      if (this.disabled) {
        event.target.value = this.value;
        return;
      }
      let value = parseFloat(event.target.value);
      if (Number.isNaN(value)) {
        value = 0;
        event.target.value = '';
      }
      this.$emit("input", this.clip(value));
    },
    increment() {
      if (this.disabled) return;
      this.$emit("input", this.clip(this.value + this.step));
    },
    decrement() {
      if (this.disabled) return;
      this.$emit("input", this.clip(this.value - this.step));
    },
  },
  computed: {
    disableMinus() {
      return this.disabled || this.value <= this.min;
    },
    disablePlus() {
      return this.disabled || this.value >= this.max;
    },
    offRange() {
      return this.value < this.min
          || this.value > this.max;
    },
  }
};
</script>

<style lang="scss">
$height: 38px;
$gap-size: 6px;
$font-size: 24px;
$width: 120px;
$border-color: #c2c2c2;

/* remove chrome's outline */
textarea:focus,
input:focus,
output:focus,
.button:focus {
  outline: none;
  border: none;
}

.counter {
  align-self: center;
  width: $width;
  font-size: $font-size;
  .button {
    padding: auto;
    margin: auto auto;
    border: 0px solid;
    vertical-align: middle;
    line-height: $height;
    height: $height;
    width: 0;
    //width: $height;
    i {
      line-height: $height;
    }
    font-weight: 900;
    color: lighten(black, 50%);
    background: transparent;
    &.disabled {
        color: lighten(black, 88%);
    }
    //background: lighten(green, 50%);
  }

  input[type="number"],
  output {
    font-weight: 900;
    font-size: $font-size * 0.9;
    border: solid 1px $border-color;
    border-radius: 4px;
    height: $height;
    padding: 0px;
    width: $width;
    text-align: center;
    vertical-align: center;
    &.error {
      border-color: red;
      border-width: 3px;
    }
  }
  .button.left {
    /*position: relative;
    left: $height/2;
*/
    transform: translateX($gap-size);
  }
  .button.right {
    /* position: relative;
    right: $height; */
    transform: translateX(-$font-size - $gap-size);
  }

  .button:not(.disabled) > i {
    cursor: pointer;
  }
  /**/
}

/** Remove spinners */
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
