<template>
  <div class="loading-content">
      <h1 v-text="text"></h1>
  </div>
</template>

<script>
export default {
    mounted() {
        this.interval = setInterval(() => {
            this.update();
        },
        500);
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
    methods: {
        update() {
            this.text = "Loading ";
            for (let i = 0; i < 3; i ++) {
                if (i < this.dots) {
                    this.text += '.';
                } else {
                    this.text += '\u{00A0}';
                }
            }

            this.dots = (this.dots + 1) % 4;
        }
    },
    data() {
        return {
            text: "Loading ...",
            dots: 1,
            interval: null,
        };
    }
}
</script>

<style lang="scss">
.loading-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    h1 {
        vertical-align: middle;
        font-family: 'Roboto Mono';
        padding: auto;
        color: rgb(124, 120, 120);
    }
}
</style>