<template>
  <div class="checkbox">
    <label class="container"
      ><slot></slot>
      <input type="checkbox" :checked="checked" @input.stop="()=>{}" @change.stop="onChange()" />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  emits: {'unset': null, 'set': null},
  props: {
    value: { type: [Number, String, Boolean, Object ], required: false, },
    list: { type: Array, required: true, },
  },
  methods: {
    onChange() {
      if (this.list.includes(this.value)) {
        return this.$emit('unset', this.value);
      }
      this.$emit('set', this.value);
    }
  },
  computed: {
    
      checked() {
        //console.log(this.list);
        return this.list.includes(this.value);
      },
    
  }
};
</script>

<style lang="scss">
.checkbox {
  /* Customize the label (the container) */
  .container {
    display: block;
    position: relative;
    //width: 25px;
    padding-left: 32px;
    height: 25px;
    //margin-bottom: 12px;
    cursor: pointer;
    //font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: $primary-color;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>
