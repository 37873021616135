<template>
  <button class="btn primary" @click="open()"> 
    <i class="fas fa-bolt"></i> <span v-text="heading"></span>
  </button>
  <Dialog :heading="heading" :show="show" :width="1300" class="room-connection-dialog" @close="close"> 
    
    <div 
      v-for="(subObject, idx) in functionConfigurations" 
      :key="idx" 
      class="function-kind"
      >
      <table>
        <tr class="heading-row">
          <th>Typ provozu: <span v-text="functionKindName(subObject.functionKindId)"></span></th>
          <th colspan="5">Exteriér - terén</th>
          <th colspan="6">Exteriér - venkovní vzduch</th>
        </tr>
        <tr class="heading-row">
          <th>Místnost</th>
          <!-- <th>Vazba?</th> -->
          <th></th>
          <th>Stěna</th>
          <th>Strop</th>
          <th>Podlaha</th>
          <th>Bez konstr.</th>
          <!-- <th>Vazba?</th> -->
          <th></th>
          <th>Stěna</th>
          <th>Strop</th>
          <th>Podlaha</th>
          <th>Výplň</th>
          <th>Jiná vazba</th>
        </tr>

        <ExteriorConnectionRecord 
          v-for="room in subObject.rooms"
          :key="room.name"
          :room="room"
          :mode="mode"
        />

      </table>
      
    </div>
  </Dialog>
</template>

<script>
import { ESchemaMode } from '@/@types/definitions/mode';
import { mapGetters } from 'vuex';
import Dialog from '../../components/Dialog.vue'
import ExteriorConnectionRecord from './ExteriorConnectionRecord.vue';
export default {
  components: { Dialog, ExteriorConnectionRecord },
  props: ['mode'],
  data () {
    return  {
      show: false,
      roomName: '',
    }
  },
  computed: {
    ...mapGetters('definitions', [
      'functionKinds',
      'functionKindName',
      'rooms',
      'requirements',
    ]),
    ...mapGetters('configuration', [
      'functionConfigurations',
      'showFunctionKind',
      'isRoomInProject',
      'findFunctionIndexByRoomId',
      'connections',
      'functionKindRequirements',
      'getConnection',
    ]),
    heading() {
        if (this.mode == ESchemaMode.EDIT) return `Rychlé vazby k exteriéru`;
        if (this.mode == ESchemaMode.REQUIREMENTS) return "Požadavky na vazby k exteriéru";
        return `unknown mode ${this.mode}`;
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
      this.sendRefresh();
    },
    getHeading() {
        if (this.mode == ESchemaMode.EDIT) return "Rychlé vazby k exteriéru";
        if (this.mode == ESchemaMode.REQUIREMENTS) return "Požadavky na vazby k exteriéru";
        return `unknown mode ${this.mode}`;
    },
    sendRefresh() {
      setTimeout(()=> this.$emit('refresh'), 10);
    },
  }
}
</script>

<style lang="scss">
.room-connection-dialog {
  .zone {
    width: 100%;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    line-height: 30px;
  }
  td,
  th {
    border: 1px solid white;
    padding: 0 8px;
  }
  td.check {
    background: white;
  }
  .heading-row {
    background: $primary-color;
    color: white;
    border-width: 0;
  }
  .data-row {
    background: $secondary-color;
    //font-family: Roboto;
    &:nth-child(2n+1) {
      background: white;
    }
  }
}
</style>
