<template>
  <tr
    v-if="requirement"
    class="data-row requirement-row"
  >
    <td v-text="requirement.displayParam('id') === 0? '-' : requirement.displayParam('id')"></td>
    <td class="name"> 
      <span v-text="requirement.displayParam('name')"></span> 
      <div class="overlay" >
        <div 
            class="identification" 
            v-if="getRequirementDisplayParam(requirementId,'identification')" 
            v-html="getRequirementDisplayParam(requirementId,'identification')"
        ></div>
      </div>
    </td>
    <!-- <td v-text="writeLevel(requirement)"></td> -->
    <td v-text="requirement.displayParam('type')"></td>
    <td v-text="requirement.displayParam('technicalField')"></td>
    <td v-text="requirement.displayParam('typologicalField')"></td>
    <td v-text="requirement.displayParam('rule')"></td>
    <td v-text="'ruční'"></td>
    <td class="check">
      <div class="flex-row">
        <RequirementStatus 
          :requirement="requirement"
        />
      </div>
    </td>
    <td class="note-cell">
        <RequirementNote 
          :requirement="requirement"
        />
    </td>
    <td class="controls">
      <div class="flex-row">
        <Calculator v-if="requirement.displayParam('calculatorId')"
          :name="requirement.displayParam('calculatorId')"
          :requirementId="requirement.instanceId"
          @set-requirement="setRequirementParameters({
            changes: $event, 
            instanceId: requirement.instanceId
          })"
          :owner="requirement.owner"
        />
        <ShowArticle v-if="requirement.displayParam('articleId')" :articleId="requirement.displayParam('articleId')" />
        <i @click="openSettings(requirement)" class="clickable fas fa-cogs"></i>
        <i @click="removeRequirementAction()" class="clickable far fa-trash-alt"></i>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import RequirementStatus from './RequirementStatus.vue'
import ShowArticle from '../components/showArticle.vue'
import Calculator from '../calculators/Calculator.vue'
import {
  writeLevel,
  writeType,
  writeRule,
} from '@/@types/definitions/requirements'
import RequirementNote from './RequirementNote.vue'

export default {
  emits: {
    'remove-requirement'(requirementId) {
      console.log('Validation ', requirementId);
      return true;
    },
    'open-settings'(requirement) {
      if ('instanceId' in requirement) return true;
      return false;
    },
  },
  components: {
    RequirementStatus,
    ShowArticle,
    Calculator,
    RequirementNote,
  },
  props: {
    requirementId: {
      type: Number,
    }
  },
  methods: {
    ...mapMutations('configuration', [
      'setRequirementParameters',
      'removeRequirement',
    ]),
    openSettings(requirement) {
      this.$emit('open-settings', requirement);
    },
    removeRequirementAction() {
      console.log("remove-requirement", this.requirementId);
      this.removeRequirement(this.requirementId);
      //this.$emit('remove-requirement', this.requirement.instanceId);
    },
    writeLevel,
    writeType,
    writeRule,
  },
  computed: {
    ...mapGetters('configuration', [
      'getRequirement',
      'getRequirementDisplayParam',
    ]),
    requirement() {
      return this.getRequirement(this.requirementId);
    }
  },
}
</script>

<style lang="scss">
.requirement-row {

  td.name {
    position: relative;
    &:hover {
      background-color: lighten($primary-color, 40%);
      cursor: default;
    }

    .overlay {
      position: absolute;
      left: 0;
      top: 100%;

      width: 800px;
      z-index: 1000;
      opacity: 0;
      transition: .5s ease;
      background-color: white;
      border: 1px solid #404040;
    }
    .identification {
      padding: 25px;
      width: fit-content;
    }
  }

  td.name:hover .overlay:not(:empty) {
    opacity: 1;
  }

}
</style>
