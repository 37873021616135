<template>
  <div class="opened-project" v-if="isProjectOpened">


    <teleport to="#left-sider">
      <LeftSiderProject @new-project="showDialog()" @filterUpdated="setFilter" />
    </teleport>

    <div class="flex-row space-between">
      <span>
        Projekt: 
        <EditableText :text="openedProject.name" v-on:edit-text="RENAME_PROJECT($event)" />
        , Adresa:
        <EditableText :text="openedProject.address" v-on:edit-text="CHANGE_ADDRESS_PROJECT($event)" />
        
      </span>

      <div class="flex-row gap10">
        <div>podrobnosti o projektu</div>
        <div @click="removeProjectClick()" class="clickable">smazat celý projekt</div>
      </div>
    </div>
    
    <router-view></router-view>
    
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { EditableText } from '../components/customElements';
import LeftSiderProject from './LeftSiderProject.vue';

export default {
    components: {
    LeftSiderProject,
    EditableText
},
    data() {
        return {
            objectFunctions: [
                "living-function",
            ],
        }
    },
    computed: {
        ...mapGetters('project', ['isProjectOpened', 'openedProject']),
    },
    methods: {
        ...mapActions('project', [
          'RENAME_PROJECT',
          'CHANGE_ADDRESS_PROJECT',
          'REMOVE_PROJECT' 
        ]),
        openConcepts() {
          this.$router.push('/project/concepts');
        },
        openConfigurations() {
          this.$router.push('/project/configurations');
        },
        openModels3D() {
          this.$router.push('/project/models3d');
        },
        removeProjectClick() {
          const message = "Tato akce nelze vrátit, opravdu chcete smazat celý projekt?";
          if (confirm(message)) {
            this.REMOVE_PROJECT(this.openedProject._id);
          }
        }
    }
}

</script>

<style lang="scss" scoped>
.opened-project {

  .editable {
    padding: 6px;
    border-radius: 2px;
  }

  padding: 40px 100px;
    .gap10 {
      gap: 10px;
    }

    .modules {
      gap: 65px;
    }
    .modules-item {
      position: relative;
      border: solid 1px black;
      padding: 20px;
      height: 200px;
      width: 400px;
      background-color: white;
      h3 {
        font: normal normal bold 16px/22px Noto Sans;
      }

      i.big-icon {
        font-size: 40px;
        position: absolute;
        right: 25px;
      }
      .number {
        font: normal normal 600 32px/43px Noto Sans;
      }
      .number-desc {
        font: normal normal 600 16px/22px Noto Sans;
      }
    }
    h1.title {
        //font: normal normal bold 26px/35px Roboto;
        //letter-spacing: 0.42px;
    }
    .around {
        margin-bottom: 20px;
    }
    .input-row {
        justify-content: space-around;
    }
    .group {
        display: inline-flex;
        line-height: 50px;
    }
}
</style>
