<template>
  <div class="applied-requirement-list">
    <div class="active-list" v-if="activeRequirements.length > 0">
      <h1 v-text="heading"></h1>
      <table>
        <tr class="heading-row">
          <th>id</th>
          <th>název</th>
          <th>typ</th>
          <th>tech. obor</th>
          <th>typ. obor</th>
          <th>pravidlo</th>
          <th>vyhodnocení</th>
          <th>splněnost</th>
          <th>důkaz splnění</th>
          <th>správa</th>
        </tr>
        <AppliedRequirementRecord 
          v-for="requirementId in activeRequirements.slice(page.offset, page.offset + page.pageSize)" 
          v-bind="$attrs" 
          :key="requirementId" 
          :requirementId="requirementId" 
          @open-settings="openSettings" 
        />
      </table>

      <div class="pagination" v-if="activeRequirements.length > page.pageSize">
        <span>strana:          
          <i @click="goPrevPage()" v-show="hasPrevPage" class="clickable fas fa-chevron-left"></i>
          {{currentPage}} / {{ maxPage }}
          <i @click="goNextPage()" v-show="hasNextPage" class="clickable fas fa-chevron-right"></i>
        </span>
        <span> vidíte položky {{ page.offset+1 }} až {{ Math.min(page.offset+page.pageSize, activeRequirements.length) }} z celkem {{ activeRequirements.length }}</span>
        <p> počet položek na stránku: 
          <button @click="setPageSize(10)" :disabled="hasPageSize(10)">10</button> 
          <button @click="setPageSize(20)" :disabled="hasPageSize(20)">20</button> 
          <button @click="setPageSize(40)" :disabled="hasPageSize(40)">40</button> 
          <button @click="setPageSize(80)" :disabled="hasPageSize(80)">80</button> 
        </p>
      </div>
    </div>
    <div class="active-list" v-else>
      <div class="gray-div">
        <h1>žádné aktivní požadavky</h1>
      </div>
    </div>

    <RequirementSettingDialog 
      ref="requirementSettingDialog"
    />
  </div>
</template>

<script>
import RequirementSettingDialog from './RequirementSettingDialog.vue'
import AppliedRequirementRecord from './AppliedRequirementRecord.vue'
export default {
  props: {
    activeRequirements: {
      type: Array,
    },
    heading: {
      type:String,
      default: 'Aktivní požadavky',
    }
  },
  components: {
    RequirementSettingDialog,
    AppliedRequirementRecord,
  },
  data() {
    return {
      page: {
        pageSize: 10,
        offset: 0,
      }
    };
  },
  computed: {
    currentPage() {
      return Math.floor(this.page.offset / this.page.pageSize) + 1;
    },
    maxPage() {
      return Math.floor(this.activeRequirements.length / this.page.pageSize) + 1;
    },
    hasNextPage() {
      return this.page.offset + this.page.pageSize < this.activeRequirements.length;
    },
    hasPrevPage() {
      return this.page.offset > 0;
    }
  },
  methods: {
    goNextPage() {
      this.page.offset += this.page.pageSize;
    },
    goPrevPage() {
      this.page.offset = Math.max(0, this.page.offset - this.page.pageSize);
    },
    setPageSize(size) {
      this.page.pageSize = size;
    },
    hasPageSize(size) {
      return this.page.pageSize == size;
    },
    openSettings(req) {
      console.log(this.$refs)
      console.log(this.$refs.requirementSettingDialog)
      console.log(req);
      this.$refs.requirementSettingDialog.openRequirement(req);
    },
  },
}
</script>

<style lang="scss">
.applied-requirement-list {
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    line-height: 30px;
  }
  td,
  th {
    border: 1px solid white;
    padding: 0 8px;
  }
  td.check {
    background: white;
  }
  .heading-row {
    background: $primary-color;
    color: white;
    border-width: 0;
  }
  .data-row {
    background: $secondary-color;
    //font-family: Roboto;
    &:nth-child(2n+1) {
      background: white;
    }
  }
  .controls {
    
    .clickable {
      padding: 0 10px;
    }
  }
  .btn {
    line-height: 30px;
    //height: 30px;
    padding: 0 5px;
  }
}

.gray-div {
  background-color: $secondary-color;
  padding: 25px;
  text-align: center;
}
</style>
