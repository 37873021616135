<template>
  <div class="layout flex-row slider-for-one-column">

    <div id="left-sider"   class="column"></div>
    <div class="column main">
      <div v-if="this.isLoggedIn">
        <teleport to="#topper">
          <TopNavbar />
        </teleport>
        <div class="slider-for-one-column no-scrollbar">
          <div class="wrapper-for-overlay no-scrollbar">
            <!-- <ProcessNavbar />  -->
            <Loading v-if="loadingContent" />
            <AccessIssue v-else-if="role === 'none'" />
            <router-view v-else class="config-panel" />
          </div>
        </div>

      </div>
      <!-- <Loading v-else-if="loadingContent" /> -->
      <Login v-else />
      <ActionStatus />   
    </div>
    <div id="right-sider"  class="column"></div>
    <ResizableColumn v-if="false">
      <template v-slot:content >
      </template>
    </ResizableColumn>
  </div>
  
</template>

<script>
import AccessIssue from './views/AcessIssue.vue';
import TopNavbar from './views/TopNavbar.vue';
import Login from "./views/Login.vue";
import Loading from './views/Loading.vue'
import {mapActions, mapGetters } from 'vuex';
import ActionStatus from './views/ActionStatus.vue';
import ResizableColumn from './views/concept-calculators/ResizableColumn.vue';
export default {
  components: {
    TopNavbar,
    Loading,
    Login,
    AccessIssue,
    ActionStatus,
    ResizableColumn
},
  created() {
    // LOCAL STORAGE
    this.TRY_RESTORE_LOGIN();
  },
  computed: { 
    ...mapGetters(['loadingContent']), 
    ...mapGetters('user', ['isLoggedIn', 'role']),
    ...mapGetters('PC', ['isPCOpened']),
  },
  methods: {
    ...mapActions(['TRY_RESTORE_LOGIN']),
    shouldOpenApplication() {
      return true;
    }
  },
  data() {
    return {
      start: false,
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/_base.scss";

* {
  box-sizing: border-box;
}

html,
body {
  //font-family: 'Noto Sans', sans-serif;
  font-family: 'Noto Sans';
  //font-family: 'Noto Sans Display', sans-serif;
  //font-family: Roboto;
  //line-height: 19;
  //letter-spacing: 9.6;
  margin: 0;
  //height: 100%;
  //overflow: hidden;
}
/* #left-sider, #right-sider {
  flex-grow: 0;
} */
#left-sider:empty,
#right-sider:empty {
  display: none;
}
.layout {
  .main {
    flex-grow: 1;
  }
  .column {
    
    //height: calc(100vh - 80px);

    box-sizing: border-box;

    &.configuration {
      position: relative;

      .wrapper-for-overlay {
        overflow: auto;
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .config-panel {
          padding: 20px;
        }
        /* .overlay-configuration {
          top: 0;
          bottom: 0;
          position: absolute;
          background: transparent;
          pointer-events: none;
          width: 100%;
          height: 100%;
          z-index: 10;
          box-shadow: -5px 20px 20px 0px rgba($color: #000000, $alpha: 0.14)
            inset;
        } */
      }
    }

    &.result {
      overflow-y: auto;
    }
  }
}

.config-panel {
  h1.title {
    //font: 500 20px/26px Roboto;
    letter-spacing: 0.26px;
    margin-top: 17px;
    color: #000000de;
  }
  p.description {
    //font: 300 14px/19px Roboto;
    letter-spacing: 0.13px;
    color: #00000099;
  }
}
</style>
