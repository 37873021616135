<template>
  <div >
    <Dialog 
      heading="Nastavit vazby na exteriér" 
      class="connection-to-exterior"
      :show="addDialog.show" 
      @close="addDialog.show = false" 
      :width="640"
    >
      <div class="node choose" v-if="addDialog.state == 'choose'">
        <div class="flex-row halves">
          <!--connection to ground representative-->
          <button 
            @click="openConnectionSetting('ground')" 
            class="btn black-btn"
            :disabled="!getRoomFlag({name: 'ground', idx, room})"
          >
            <div class="btn-column">
              <i class="fas fa-seedling"></i>
              <span>na zeminu</span>
            </div>
          </button>

          <!--connection to air representative-->
          <button 
            @click="openConnectionSetting('air')" 
            class="btn black-btn"
            :disabled="!getRoomFlag({name: 'air', idx, room})"
          >
            <div class="btn-column">
              <i class="fas fa-cloud-sun"></i>
              <span>na vzduch</span>
            </div>
          </button>
        </div>
        <!-- Add and remove connection controls -->
        <div class="flex-row halves" v-if="isEditMode">
          <button 
            v-if="!getRoomFlag({name: 'ground', idx, room})"
            @click="addConnectionToExteriorAction('ground')" 
            class="btn black-btn"
          >
            přidat vazbu na zeminu
          </button>
          <button
            v-else
            @click="removeConnectionToExteriorAction('ground')" 
            class="btn black-btn"
          >
            odebrat vazbu na zeminu
          </button>


          <button 
            v-if="!getRoomFlag({name: 'air', idx, room})"
            @click="addConnectionToExteriorAction('air')" 
            class="btn black-btn"
          >
            přidat vazbu na vzduch
          </button>
          <button 
            v-else
            @click="removeConnectionToExteriorAction('air')" 
            class="btn black-btn"
          >
            odebrat vazbu na vzduch
          </button>
        </div>
      </div>
      <div class="node flags" v-else-if="addDialog.state == 'air'">        
          <ConnectionToExteriorSettings :fromId="room.id" :toId="-2" :mode="mode" />
          <div class="controls">
            <button @click="addDialog.state = 'choose'" class="black-btn">zpět</button>
            <button @click="close" class="black-btn">ok</button>
          </div>
      </div>
      <div class="node flags" v-else-if="addDialog.state == 'ground'">
          <ConnectionToExteriorSettings :fromId="room.id" :toId="-1" :mode="mode" />
          <div class="controls">
            <button @click="addDialog.state = 'choose'" class="black-btn">zpět</button>
            <button @click="close" class="black-btn">ok</button>
          </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { ESchemaMode } from '@/@types/definitions/mode';
import { mapGetters, mapMutations } from 'vuex';
import Dialog from '../components/Dialog.vue'
import ConnectionToExteriorSettings from './ConnectionToExteriorSettings.vue';
export default {
  components: { 
    Dialog, 
    ConnectionToExteriorSettings
  },
  mounted() {
    this.eventBus.on('open-exterior-connection-add-menu', ({idx, room, mode}) => {
      this.idx = idx;
      this.room = room;
      this.mode = mode;
      this.openAddMenu();
    });
    this.eventBus.on('open-exterior-connection-setting', ({idx, room, mode, state}) => {
      this.idx = idx;
      this.room = room;
      this.mode = mode;
      this.openConnectionSetting(state);
    })
  },
  unmounted() {
    this.eventBus.off('open-exterior-connection-add-menu');
    this.eventBus.off('open-exterior-connection-setting');
  },
  data() {
    return {
      mode: ESchemaMode.EDIT,
      idx: 0,
      room: {},
      addDialog: { 
        show: false,
        state: 'choose',
      }
    }
  },
  methods: {
    ...mapMutations("configuration", [
      'addConnectionToExterior',
      'removeConnectionToExterior',
    ]),
    close() {
      this.addDialog.show = false;
    },
    openAddMenu() {
      this.addDialog.show = true;
      this.addDialog.state = 'choose';
    },
    openConnectionSetting(state) {
      this.addDialog.show = true;
      this.addDialog.state = state;
    },
    addConnectionToExteriorAction(exteriorName) {
      const toId = exteriorName == 'ground'? -1 : -2;
      this.addConnectionToExterior({
        idx: this.idx,
        fromId: this.room.id,
        toId
      });
    },
    removeConnectionToExteriorAction(exteriorName) {
      const toId = exteriorName == 'ground'? -1 : -2;
      this.removeConnectionToExterior({
        idx: this.idx,
        fromId: this.room.id,
        toId
      });
    },
  },
  computed: {
    ...mapGetters('configuration', [
      'getRoomFlag',
    ]),
    isEditMode() {
      return this.mode == ESchemaMode.EDIT;
    },
    isRequirementsMode() {
      return this.mode == ESchemaMode.REQUIREMENTS;
    },
  },
}
</script>

<style>

</style>
