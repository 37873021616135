import axios, { AxiosError } from "axios";
import store from "../index";
import deploymentConfiguration from "../../../configuration/load";

type Method = "get" | "post" | "put" | "patch" | "delete";

class Api {
  host : string;
  constructor() {
    //this.host = location.origin;
    this.host = deploymentConfiguration.api_base_url || location.origin;
  }

  /**
   * Send a HTTP request to the given route.
   *
   * @param {string} requestType
   * @param {string} route
   * @param {object} payload data
   * @param {object} ajaxConfig axios configuration
   * @param {object} headers additional headers
   */
  request<T>(requestType : Method, route : string, data = {}, ajaxConfig = {}, headers = {}, repeat = true) : Promise<T> {
    
    const accessToken = store.getters['user/accessToken'];
    let xtoken;
    if (accessToken) {
      xtoken = `Bearer ${accessToken}`;
    }

    const config = {
      headers: {
        "x-token": xtoken, 
        ...headers,
      },
      ...ajaxConfig,
    };
    //console.log("config", config);
    return new Promise((resolve, reject) => {
      let promise;
      if (requestType === "get" || requestType === "delete") {
        promise = axios[requestType]<T>(this.host + route, config);
      } else {
        promise = axios[requestType]<T>(this.host + route, data, config);
      }
      promise
        .then((response) => {
          resolve(response.data);
        })
        .catch(async (error) => {
          console.error('api', error);
          if (this.didTokenExpired(error) && repeat) {
            try {
              await this.renewToken();
            } catch {
              console.log('Logout: Token could not be renewed.');
              store.commit("user/setAccessToken", "");
              store.commit("user/setRefreshToken", "");
              localStorage.clear();
              return reject(error.response.data);
            }
            try {
              // Repeting the Query
              const res = await this.request<T>(requestType, route, data, ajaxConfig, headers, false);
              // success
              return resolve(res);
            } catch(error) {
              // fail
              return reject(error)
            }
          } else {
            reject(error.response.data);
          }
        });
    });
  }

  didTokenExpired(error : AxiosError) {
    return error.response 
     && error.response.status === 401
     && typeof error.response.data === 'string' 
     && error.response.data === "token expired"; 
  }

  async renewToken() {  
    // this action works here, but better it should be in same module as auth actions
    const res = await axios.post<any>("/api/auth/token", {token: store.getters['user/refreshToken']});
    if (res.status === 200) {
      
      store.commit("user/setAccessToken", res.data.accessToken);
      store.commit("user/setRefreshToken", res.data.refreshToken);
      const {id, username, role} = res.data.user;
      store.commit('user/setUser', {id, username, role});
    }
    else throw "bad token renew";
  }

  /**
   * Send a GET request to the given route.
   * .
   * @param {string} route
   */

  get<T = any>(route:string, ajaxConfig = {}) {
    return this.request<T>("get", route, {}, ajaxConfig);
  }

  /**
   * Send a POST request to the given route.
   * .
   * @param {string} route
   */
  post<T = any>(route:string, data : any) {
    return this.request<T>("post", route, data);
  }

  /**
   * Send a PUT request to the given route.
   * .
   * @param {string} route
   * @param {object} data
   */
  put<T = any>(route:string, data:any) {
    return this.request<T>("put", route, data);
  }

  /**
   * Send a PATCH request to the given route.
   * .
   * @param {string} route
   * @param {object} data
   */
  patch<T = any>(route:string, data:any) {
    return this.request<T>("patch", route, data);
  }

  /**
   * Send a DELETE request to the given route.
   * .
   * @param {string} route
   * @param {object} data
   */
  delete<T = any>(route:string) {
    return this.request<T>("delete", route);
  }
}

const api = new Api();
Object.seal(api);

export default api;
