<template>
  <div class="column left-menu">
    <div class="paddinged">
      <h3>Projekt</h3>
    </div>

    <div class="flex-column side-filter">
      <div :class="{active: isRouteActive('/project')}" class="flex-row filter-item" @click="goRouter('/project')">
        <i class="fas fa-th-large"></i>
        <div class="item">Přehled</div>
      </div>
    </div>
    
    <hr>
    <div class="flex-column side-filter">          
      <div :class="{active: isRouteActive('/project/concepts')}" class="flex-row filter-item" @click="goRouter('/project/concepts')">
        <i class="fas fa-calculator"></i>
        <div class="item">Koncepční kalkulačky</div>
      </div>

      <div :class="{active: isRouteActive('/project/configurations')}" class="flex-row filter-item" @click="goRouter('/project/configurations')">
        <i class="fas fa-pencil-ruler"></i>
        <div class="item">Projekční fáze</div>
      </div>

      <div :class="{active: isRouteActive('/project/models3d')}" class="flex-row filter-item" @click="goRouter('/project/models3d')">
        <i class="fas fa-desktop"></i>
        <div class="item">3D model</div>
      </div>
    </div>

    <hr>
    <div class="flex-column side-filter">
      <div :class="{active: isRouteActive('/project/share')}" class="flex-row filter-item"  @click="goRouter('/project/share')">
        <i class="fas fa-user-friends"></i>
        <div class="item">Sdílení projektu</div>
      </div>
    </div>

    
    
    <div class="paddinged">
      <h3>Navigace</h3>
    </div>

    <div class="flex-column side-filter">
      <div class="flex-row filter-item" @click="goRouter('/project-manager')">
        <i class="fas fa-home"></i>
        <div class="item">Přehled projektů</div>
      </div>
    </div>

      {{  $router.currentRoute.fullPath }}
  </div>
</template>

<script>
export default {
  emits: ['filterUpdated'],
    methods: {
        goRouter(route) {
          this.$router.push(route);
        },
        isRouteActive(route) {
          return this.$router.currentRoute.value.fullPath == route;
        },
    },
    computed: {
    },
    data() {
      return {
        filter: {
          name: '',
          owning: 'all',
          CC: false,
          PC: false,
          M3D: false,
        }
      };
    },
}
</script>

<style lang="scss">
.left-menu {
  //width: 300px;
  .paddinged {
    padding: 30px 30px 0 30px;

  }
  .search {
    padding: 20px 0;
    input[type="text"] {
      width: calc(100%);
    }
  }

  .filter-item {
    padding: 5px 0;
    i {
      padding: 5px 0 5px 52px ;
    }
    div.item {
      flex-grow: 1;
      text-align: center;
    }
    &:hover,
    &.active {
      background: lightgrey;
    }
  }
}
</style>
