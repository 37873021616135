<template>
  <div class="input-group" >
      <input 
        :value="value"
        @input.stop="onEdit($event)"
        :class="{ 
          edited: changed, 
          wrong: offRange,
        }"
        type="number"
        :step="step"
        :min="min"
        :max="max"
        :disabled="disabled"
        class="group"
      />

      <span class="input-group-text" v-html="unit">
      </span>
    </div>
</template>

<script>
export default {
    props: {
        value: {
          type: Number,
          required: true,
        },
        unit: String,
        changed: {
            type: Boolean,
            default: false,
        },
        step: {
          type: Number,
          default: 0.001,
        },
        min: {
          type: Number,
          default: -1e10
        },
        max: {
          type: Number,
          default: 1e10
        },
        /* small: {
          type: Boolean,
          default: false,
        }, */
        disabled: {
          type: Boolean,
          default: false,
        },
        width: {
            type: Number,
            default: 80,
        },
    },
    methods: {
        clip(num) {
          return Math.min(this.max, Math.max(this.min, num));
        },
        onEdit(event) {
          this.lastEventValue = event.target.value;
          if (this.disabled) {
            event.target.value = this.value;
            return;
          }
          let value = parseFloat(event.target.value);
          if (Number.isNaN(value)) {
            value = 0;
            event.target.value = '';
          }
          
          this.$emit('input', this.clip(value));
        }
    },
    created() {
      this.lastEventValue = this.value;
    },
    data() {
      return {
        lastEventValue: 0,
      }
    },
    computed: {
      offRange() {
        return this.lastEventValue < this.min
            || this.lastEventValue > this.max;
      }
    }
}
</script>

<style lang="scss">
.input-group > input.group {
  height: 40px;
  width: 100px;

}
.input-group > .input-group-text {
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: baseline;
}
input.wrong {
  //background-color: lightcoral;
  border-width: 2px;
}
.input-group {
    border: $secondary-color solid 1px;
    border-radius: 4px;
    //border: auto;
    position: relative;
    display: flex;
    &:focus {
      outline: none;
      border: none;
    }
    input.group {
        text-align: center;
        flex-grow: 1;
    }

  .input-group-text {
    //display: -webkit-box;
    //display: -ms-flexbox;
    //display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    //padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    //font-size: 1.2rem;
    font-weight: 400;
    //line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    // border: 1px solid #ced4da;
    border-radius: 0.25rem;
    min-width: 42px;
  }
}
</style>
