<template>
  
    <div class="ground-calculator basic-props">
      <render-gui :gui="gui" /> 

      <teleport to="#right-sider">
        <div class="cadastral-results">
          <div class="list flex-column">
            <render-gui :gui="results" />
          </div>
          <hr>
        </div>
      </teleport>
    </div>
 
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import RenderGui from '../../GUI/RenderGui.vue';

import {ActivableParameter, GUI, GUICollapsible, Heading, Line, Parameter, ParameterOutput, } from '../../GUI';

function makeGroundCalculatorGUI(groundCS) {
  const gui = new GUI(groundCS);
  gui.add(new Heading('Vlastnosti stavebního pozemku'))
  gui.add(new Parameter('Plocha stavebního pozemku', 'estateArea', 'm<sup>2</sup>'));
  gui.add(new Heading('Omezení výstavby plynoucí z územního plánu'))
  gui.add(new ActivableParameter('Maximální výška objektu', 'LPmaxHeight', 'm'));
  gui.add(new Line());
  gui.add(new ActivableParameter('KZP = Koeficient zastavení pozemku (celkový)', 'LPmaxBuildUpTotal'));
  gui.add(new ActivableParameter('KZP1 = Koeficient zastavěné plochy (pouze hlavní budovy)', 'LPmaxBuildUpMainBuilding'));
  gui.add(new ActivableParameter('KPP - Koeficient podlažních ploch (celkový)', 'LPmaxFloorCoefTotal'));
  gui.add(new ActivableParameter('KPP1 - Koeficient podlažní plochy (pouze hlavní budovy)', 'LPmaxFloorCoefMainBuilding'));
  gui.add(new Line());
  gui.add(new ActivableParameter('KOP - Koeficient obestavěného prostoru', 'LPmaxEnclosedVolume'));
  gui.add(new Line());
  gui.add(new ActivableParameter('KZ Koeficient zeleně', 'LPminGreenVegetation'));
  gui.add(new Heading('Další parametry pro základní výpočty k územní regulaci'))
  gui.add(new Parameter('Konstrukční výška podlaží', 'constructionalHeight', 'm'))
  gui.add(new Heading('Parametry lokality pro výpočet parkovacích míst'))
  gui.add(new Parameter('Součinitel redukce počtu stání (k<sub>p</sub>)', 'parkingSpacesReductionCoef', '-', {calculatorId: 'ParkingSpacesReductionCoef'}))
  gui.add(new Parameter('Součinitel vlivu stupně automobilizace (k<sub>a</sub>)', 'parkingSpacesRegisteredCarsCoef', '-', {calculatorId: 'ParkingSpacesRegisteredCarsCoef'}))
    return gui;
}

function makeGroundCalculatorResultGUI(groundCS) {
  const section = new GUICollapsible(groundCS, 'Přehled omezení plynoucích z územního plánu.');
  section.add(new ParameterOutput('Maximální počet nadzemních podlaží', 'LPlimitMaxFloorNumber', 'NP'))
  section.add(new ParameterOutput('Maximální celková hrubá podlahová plocha nadzemních podlaží', 'LPlimitMaxFloorAreaAboveGround', 'm<sup>2</sup>'))
  section.add(new ParameterOutput('Maximální objem stavby', 'LPlimitMaxVolume', 'm<sup>3</sup>'))
  section.add(new ParameterOutput('Minimální plocha zeleně na pozemku', 'LPlimitMinGreenVegetation', 'm<sup>2</sup>'))
  const results = new GUI(groundCS);
  results.add(section);
  return results;
}


export default {
  components: {
    RenderGui,
  },
  props: ['cs'],
  computed: {
    ...mapGetters('configuration', [
      'getEntityParameter',
      'hasEntityParameter',
    ]),
  },
  methods: {
    ...mapMutations('configuration', [
      'activationOfEntityParameter',
      'setEntityParameter',
    ]),
  },
  created() {
    const cs = this.cs;
    this.gui = makeGroundCalculatorGUI(cs);
    this.results = makeGroundCalculatorResultGUI(cs);
  },
  data() {
    return {
      gui: null,
      results: null,
    }
  }
}
</script>

<style lang="scss">
div.parameter > div.checkbox > label.container {
  font: normal normal 600 16px/22px Noto Sans;
}
div.parameter > div.name {
  font: normal normal 600 16px/22px Noto Sans;
}

.cadastral-plan {
  display: flex;
  flex-direction: row;
}
.next {
  margin: 15px;
  justify-content: end;
}
#right-sider > .cadastral-results {
  background: lighten(black, 50%);
  color: white;
}
.cadastral-results {
  padding: 15px;
  
  .view-limits {
    align-items: baseline;
    gap: 35px;
  }

  .result-item {
    display: flex;
    p {
      flex-grow: 1;
    }
  }
}

.basic-props {
  flex-grow: 1;
  .parameter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .activable-section-x {
    padding-left: 35px;
    margin: 15px 0;
     
    width: calc(100% - 45px);

    //align-items: flex-start;
    .section-column { 
      //width: calc(100% - 35px);
      //display: flex;
      //flex-direction: column;
      //@at-rootgap: 10px;
     }
    .section-name {
      height: 25px;
      line-height: 25px;
    }
    .activable-parameter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    textarea {
      width: 100%;
    }
  }
}
</style>
