<template>
  <div v-if="itsMe()">
    <i @click="openCalc()" class="clickable fas fa-calculator"></i>
    <Dialog heading="Kalkulačka - Radon" class="radon-volume-activity" :show="show" @close="closeCalc()">
      <Section name="Způsob určení">
        <DropdownInput
          :options="staticData.categoryOptions"
          :value="category"
          @input="setCategory($event)"
          :width="700"
          small
        />
      </Section>

      <Section
        name="Spodní mez"
      >
        <span v-if="notCustom()">
          <span v-text="lowerBound"></span> <span v-html="unit"></span>
        </span>
        <number-with-unit-input v-else :unit="unit" :step="1" :value="lowerBound" @input="setLowerBound" width="260"/>
      </Section>

      <Section
        name="Horní mez"
      >
        <span v-if="notCustom()">
          <span v-text="upperBound"></span> <span v-html="unit"></span>
        </span>
        <number-with-unit-input v-else :unit="unit" :step="1" :value="upperBound" @input="setUpperBound" width="260"/>
      </Section>
      <p class="error right" v-show="lowerBound >= upperBound">Horní mez musí být stanovena na vyšší hodnotu než spodní mez.</p>

      <div class="controls">
        <button @click="show = false" class="secondary">zrušit</button>
        <button @click="use()"  class="primary">použít</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
const categoryOptions = [
{value: 'Referenční úroveň',
 name: 'Referenční úroveň',	min:0,	max: 300},
{value: 'for new buildings',
 name: 'Doporučená hodnota pro novostavby',	min:100,	max: 200},
{value: 'Doporučená hodnota pro stávající stavby',
 name: 'Doporučená hodnota pro stávající stavby',	min:150,	max: 250},
{value: 'custom',
 name: 'Vlastní zadání'		
},
];

import Dialog from '../components/Dialog.vue'
import DropdownInput from '../components/fundamentals/DropdownInput.vue'
import NumberWithUnitInput from '../components/fundamentals/NumberWithUnitInput.vue'
import Section from '../components/Section.vue'
export default {
  components: { Dialog, DropdownInput, Section, NumberWithUnitInput },
  props: {
    desiredCalculator: {
      type: String,
      default: "no-calc"
    }
  },
  data() {
    return {
      show: false,
      unit: 'Bq/m<sup>3</sup>',
      category: 'for new buildings',
      customLowerBound: 100,
      customUpperBound: 200,
      staticData: {
        categoryOptions,
      },
    }
  },
  computed: {
    activeOption() {
      const [option] = categoryOptions.filter(cat => cat.value === this.category);
      return option;
    },
    lowerBound() {
      if (this.notCustom()) {
        return this.activeOption.min;
      }
      return this.customLowerBound;
    },
    upperBound() {
      if (this.notCustom()) {
        return this.activeOption.max;
      }
      return this.customUpperBound;
    },
  },
  methods: {
    itsMe() {
      return this.desiredCalculator == "RadonVolumeActivity"
    },
    openCalc() {
      this.show = true
    },
    closeCalc() {
      this.show = false
    },
    setCategory(category) {
      this.category = category;
    },
    notCustom() {
      return this.category !== 'custom';
    },
    setLowerBound(val) {
      this.customLowerBound = val;
    },
    setUpperBound(val) {
      this.customUpperBound = val;
    },
    use () {
      const requiredValue1 = parseFloat(this.lowerBound);
      const requiredValue2 = parseFloat(this.upperBound);
      if (!isNaN(requiredValue1) && !isNaN(requiredValue2) && requiredValue1 < requiredValue2) {
        this.show = false;
        this.$emit("set-requirement", {requiredValue1, requiredValue2});
      } else {
        confirm('Spodní a horní mez musí být nezáporné číselné hodnoty. Horní mez musí být stanovena na vyšší hodnotu než spodní mez.');
      }
    },
  },
}
</script>

<style lang="scss">
p.error {
  color: red;
  font-weight: 500;
  &.right {
    text-align: right;
  }
}

.radon-volume-activity {
  .section {
    .flex-inline {
      align-items: baseline;
    }
  }

  .controls {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}
</style>
