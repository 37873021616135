<template>
  <div class="spatial-interconnection">
    <div class="spacial-interconnection-controls">
      <div class="flex-row gap-10">
        <AddFunctionKind  @refresh="refreshArrows" v-if="isEditMode" />
        <FastExteriorConnectionDialog :mode="mode" />
        <button class="btn primary" @click="toggleNewConnection" v-if="isEditMode"><i class="fas fa-link"></i> vytvořit vazbu</button>
      </div>
      <ConnectionFilterControls @refresh="refreshArrows" />
    </div>
    <div class="spacial-interconnection-form">
      <CreateConnection ref="createConnection" @refresh="refreshArrows" v-if="isEditMode"/>
      <EditConnection ref="editConnection" @unselect="unselect" @refresh="refreshArrows" :mode="mode" />
    </div>
    <div class="zone-container flex-row" 
      :class="{
        picking: picking
      }"
    >
      <div 
        v-for="(subObject, idx) in functionConfigurations" 
        :key="idx" 
        class="zone"
        >
        <div class="zone-name flex-row">
          <h4 v-text="functionKindName(subObject.functionKindId)"></h4>
          <div class="zone-controls">

            <RequirementsDialog v-if="!isEditMode"
              :activeRequirements="functionKindRequirements(idx)" 
              @add-requirement="addFunctionKindRequirement({idx, requirement: $event})"
              level="function-type"
              :headingIdentificator="functionKindName(subObject.functionKindId)"
            />

            <i 
              @click.stop="toggleFunctionKindAction(idx)" 
              class="toggle-icon on-white clickable fa fa-eye" 
              :class="{show: showFunctionKind(idx), active: showFunctionKind(idx)}"
            ></i>

            <i v-if="isEditMode" @click.stop="removeFunctionKindAction(idx)" class="clickable far fa-trash-alt"></i>

          </div>
        </div>

        <div class="rooms" v-show="showFunctionKind(idx)">
          <div 
            v-for="room in subObject.rooms"
            :key="room.name"
            class="room flex-row"
            @click.stop="pick"
            :data-id="room.id"
          >
            <span class="room-name" v-text="room.name"></span>
            <RoomControls 
              :idx="idx" 
              :mode="mode" 
              :room="room" 
              @refresh="refreshArrows()"
              @open-connections="openConnections(room)"
            />
          </div>

          <div class="add-row flex-row" v-if="isEditMode">
            <button class="grey-btn" @click="plusButton(idx, subObject.functionKindId)" :fontSize="22" :size="50">
              <i class="fa fa-plus"></i> Přidat místnost
            </button>
          </div>
        </div>
      </div>

      <Dialog class="add-room-dialog"
          :heading="addRoomDialog.heading" 
          :show="addRoomDialog.show"
          @close="toggleAddRoomDialog()"
      > 
        <div class="list">
          <button 
            class="secondary" 
            v-for="room in getRoomsForFunctionType(addRoomDialog.functionType)" 
            v-text="room.name" 
            :key="room.name"
            :class="{'room-used': isRoomInProject({idx: addRoomDialog.subObjectIdx, room})}"
            @click="isRoomInProject({idx: addRoomDialog.subObjectIdx, room})? removeRoomAction(addRoomDialog.subObjectIdx, room) : addRoomAction(room.name)"
          ></button>
          <div class="custom">
            <h2>Vlastní místnost</h2>
            <div class="param">
              <p class="p-label">název</p> 
              <TextInput 
                :value="addRoomDialog.custom.name" 
                @input="addRoomDialog.custom.name = $event" small/>
            </div>
            <div class="param">
              <p class="p-label">kod</p> 
              <TextInput 
                :value="addRoomDialog.custom.code" 
                @input="addRoomDialog.custom.code = $event" small/>
            </div>
            <div class="buttons">
              <button class="black-btn" @click="addCustomRoomAction()">přidat vlastní místnost</button>
            </div>
          </div>
        </div>
      </Dialog>

    </div>

    <RoomConnectionDialog :mode="mode" ref="roomConnectionDialog" @refresh="refreshArrows()"/>
    

    <div class="connection-container" ref="connectionContainer">
      
    </div>

    <button @click="refreshArrows">obnovit spojení</button>
    <div class="flex-row">
      <CheckboxInput :value="hideShadowedConnections" @input="hideShadowedConnectionsInput($event)" /> 
      <span>skrýt upozaděné vazby</span>
    </div>
    <div class="flex-row">
      <CheckboxInput :value="highlighRoomsConnections" @input="highlighRoomsConnectionsInput($event)" /> 
      <span>zvýrazňovat vazby z označené místnosti</span>
    </div>
    <div class="flex-row">
      <CheckboxInput :value="shadowNonHighlighted" @input="shadowNonHighlightedInput($event)" /> 
      <span>upozadit vazby z neoznačené místnosti</span>
    </div>
  </div>
</template>

<script>
import { CurvyPath } from '@/libs/svg-dom-arrows/src';
import { mapActions, mapGetters, mapMutations } from "vuex";
import Dialog from "../components/Dialog.vue";
import CheckboxInput from '../components/fundamentals/CheckboxInput.vue';
import { nextTick } from '@vue/runtime-core';
import RequirementsDialog from './RequirementsDialog.vue';
import CreateConnection from './CreateConnection.vue';
import EditConnection from './EditConnection.vue';
import RoomControls from './RoomControls.vue';
import TextInput from '../components/fundamentals/TextInput.vue';
import ConnectionFilterControls from './ConnectionFilterControls.vue';
import RoomConnectionDialog from './connections/RoomConnectionDialog.vue';
import { ESchemaMode } from '@/@types/definitions/mode';
import FastExteriorConnectionDialog from "./connections/FastExteriorConnectionDialog.vue";
import AddFunctionKind from "./objectSchema/AddFunctionKind.vue";


export default {
  components: { 
    Dialog,
    CreateConnection,
    RequirementsDialog,
    EditConnection,
    RoomControls,
    TextInput,
    ConnectionFilterControls,
    CheckboxInput,
    RoomConnectionDialog,
    FastExteriorConnectionDialog,
    AddFunctionKind,
  },
  created() {
    window.addEventListener("resize", this.refreshArrows);
    console.log("created");
  },
  unmounted() {
    console.log("unmounted");
    window.removeEventListener("resize", this.refreshArrows);
  },
  mounted() {
    this.mounted = true;
    console.log("mounted");
    this.refreshArrows();
  },
  props: {
    mode:{ 
      type: Number,
      default: ESchemaMode.EDIT,
    }
  },
  methods: {
    ...mapMutations("configuration", [
      'removeFunctionKind',
      "addRoom", 
      "removeRoom",
      'toggleFunctionKind',
    ]),
    ...mapActions("configuration", [
      'addRoomRequirement',
      'addFunctionKindRequirement',
    ]),
    removeFunctionKindAction(idx) {
      const message = `Smazat ${
        this.functionKindName(
          this.functionConfigurations[idx].functionKindId
        )}?`
      if (confirm(message)) {
        this.removeFunctionKind(idx);
        this.refresh();
      }
    },
    toggleFunctionKindAction(idx) {
      this.toggleFunctionKind(idx);
      this.refresh();
    },
    toggleNewConnection() {
      this.$refs.createConnection.toggleNewConnection();
      /* nextTick(() => {
        this.refreshArrows();
      }); */
      
    },
    pick (event) {
      if (this.isRequiremetsMode) {
        console.warn(`There is not createConnection in requirement mode.`);
        return;
      }
      console.log("pick");
      let element = event.target.closest('.room');
      let roomId = parseFloat(element.getAttribute("data-id"))
      if (this.$refs.createConnection.isPicking()) {
        this.$refs.createConnection.pickedId(roomId);
      } else if (this.highlighRoomsConnections) {
        this.highlightId = roomId;
        console.log(this.highlightId);
        this.refresh();
      }
    },
    writeConnection(from, to, classList) {
      const options = {
        start: {
          // You can use `ref` to get your element you want to target and it works pretty fine
          element: from,
          position: {
            top: 0.5,
            left: 1
          }
        },
        end: {
          element: to,
          position: {
            top: 0.5,
            left: 0
          }
        },
        pickCallback: (fromId, toId) => {
          this.selectConnection(fromId, toId);
          this.boldFromId = fromId;
          this.boldToId = toId;
          this.refresh();
        },
        style: 'fill:transparent',
        classList,
        //style: 'stroke:black;stroke-width:1;fill:transparent',
        appendTo: this.$refs.connectionContainer
      };
      if (!options.appendTo) {
        //console.log("Yeah yeah, it happens.");
        options.appendTo = document.querySelector('.connection-container');
      }
      //console.log(options);
      this.curves.push(new CurvyPath(options));
    },
    selectConnection(fromId, toId) {
      this.$refs.editConnection.openConnection(fromId, toId);
    },
    removeRoomAction(idx, room) {
      if (this.addRoomDialog.memo.includes(room.name)
       || confirm(`Přejete si smazat místnost ${room.name}.`)) {
         this.removeRoom({idx, room})
         this.refresh();
         this.addRoomDialog.memo = this.addRoomDialog.memo.filter(roomName => roomName == room.name);
      }
    },
    toggleAddRoomDialog() {
      this.addRoomDialog.show = !this.addRoomDialog.show;
      nextTick(this.refreshArrows);
    },
    addRoomAction(roomName) {
      const idx = this.addRoomDialog.subObjectIdx;
      const [room] = this.getRoomsForFunctionType(this.addRoomDialog.functionType).filter(room => room.name == roomName); 
      this.addRoom({idx, room})
      this.addRoomDialog.memo.push(roomName);
      this.refresh();
    },
    addCustomRoomAction() {
      const idx = this.addRoomDialog.subObjectIdx;
      const room = {
        name: this.addRoomDialog.custom.name,
        code: this.addRoomDialog.custom.code,
      };
      this.addRoomDialog.custom.name = '';
      this.addRoomDialog.custom.code = '';
      this.addRoom({idx, room});
      this.refresh();
    },
    plusButton(idx, functionType) {
      console.log(functionType);
      this.addRoomDialog.functionType = functionType;
      this.addRoomDialog.heading = `Místnosti pro ${this.functionKindName(functionType).toLowerCase()}`;
      this.addRoomDialog.subObjectIdx = idx;
      this.addRoomDialog.show = true;
      this.addRoomDialog.memo = [];
    },
    connectionToClass(connection) {
      const classList = [];
      if (connection.fromId == this.boldFromId && connection.toId == this.boldToId
       || connection.fromId == this.boldToId && connection.toId == this.boldFromId) {
        classList.push("marked");
      }
      if (connection.wall && this.showWalls
       || connection.ceiling && this.showCeilings
       || connection.floor && this.showFloors
       || connection.opening && this.showOpenings
       || (!connection.wall &&
           !connection.ceiling &&
           !connection.floor &&
           !connection.opening) && this.showNoConstructions) {
        //classList.push("marked");
        if (this.highlighRoomsConnections &&
          (connection.fromId == this.highlightId || connection.toId == this.highlightId)) {
          classList.push("bold");
        } else if (this.highlighRoomsConnections && this.shadowNonHighlighted && this.highlightId > 0) {
          classList.push("shadowed");
        }
      } else {
        classList.push("shadowed");
        ///
      }
      return classList;
    },
    refreshArrows() {
      console.log("refresh arrow");
      this.curves.forEach(curve => curve.release())
      this.curves = [];
      const markedConnection = [];
      const backgroundConnections = [];
      const foregroundConnections = [];
      this.connections.filter(conn => conn.toId > 0).forEach(conn => {
        const idxA = this.findFunctionIndexByRoomId(conn.fromId);
        const idxB = this.findFunctionIndexByRoomId(conn.toId);
        if (!this.showFunctionKind(idxA) || !this.showFunctionKind(idxB)) return;
        const from = document.querySelector(`.room[data-id="${conn.fromId}"]`);
        const to = document.querySelector(`.room[data-id="${conn.toId}"]`);
        if (from && to) {
          const classList = this.connectionToClass(conn);
          if (classList.includes('marked')) {
            markedConnection.push({from, to, classList});
          } else if (classList.includes('shadowed')) {
            backgroundConnections.push({from, to, classList});
          } else {
            foregroundConnections.push({from, to, classList});
          }
        }
      });
      if (!this.hideShadowedConnections) {
        backgroundConnections.forEach(({from, to, classList}) => {
          this.writeConnection(from, to, classList);
        });
      }
      foregroundConnections.forEach(({from, to, classList}) => {
        this.writeConnection(from, to, classList);
      })
      markedConnection.forEach(({from, to, classList}) => {
        this.writeConnection(from, to, classList);
      })
    },
    getRoomsForFunctionType(functionType) {
      return this.rooms.filter(room => room.functionKind == functionType);
    },
    refresh() {
      nextTick(this.refreshArrows);
    },
    hideShadowedConnectionsInput(value) {
      this.hideShadowedConnections = value;
      this.refresh();
    },
    highlighRoomsConnectionsInput(value) {
      this.highlighRoomsConnections = value;
      this.highlightId = 0;
      this.refresh();
    },
    shadowNonHighlightedInput(value) {
      this.shadowNonHighlighted = value;
      this.refresh();
    },
    unselect() {
      this.boldFromId = 0;
      this.boldToId = 0;
    },
    openConnections(room) {
      this.$refs.roomConnectionDialog.openRoom(room);
    }
  },
  computed: {
    ...mapGetters('definitions', [
      'functionKinds',
      'functionKindName',
      'rooms',
      'requirements',
    ]),
    ...mapGetters('configuration', [
      'functionConfigurations',
      'showFunctionKind',
      'isRoomInProject',
      'findFunctionIndexByRoomId',
      'connections',
      'functionKindRequirements',
    ]),
    ...mapGetters('prezentation', [
      'showWalls',
      'showCeilings',
      'showFloors',
      'showNoConstructions',
      'showOpenings',
    ]),
    picking() {
      if (this.mounted && this.$refs.createConnection) {
        if (this.$refs.createConnection.isPicking()) return true;
      }
      return false;
    },
    isEditMode() {
      return this.mode === ESchemaMode.EDIT;
    },
    isRequiremetsMode() {
      return this.mode === ESchemaMode.REQUIREMENTS;
    },
  },
  data() {
    return {
      addRoomDialog : {
        heading: 'sdf',
        show: false,
        functionType: 1,
        subObjectIdx: 0,
        memo: [],
        custom: {
          name: '',
          code: '',
        },
      },
      boldFromId: 0,
      boldToId: 0,
      highlightId: 0,
      curves: [],
      mounted: false,
      hideShadowedConnections: false,
      shadowNonHighlighted: false,
      highlighRoomsConnections: null,

    }
  }
};
</script>

<style lang="scss">
.toggle-icon.active {
  &.on-white {
    color: black;
  }
  //color: gold;
}
.toggle-icon:not(.active) {
  color: gray;
}

.black-btn {
  padding: 7px;
  border-radius: 6px;
  font-size: 1.2em;
  background: $primary-color;
  color: white;
}

$room-dot-size: 16px;
.add-room-dialog {
  .list button {
    margin: 8px 20px;

    &.room-used {
      background: rgba($color: $primary-color, $alpha: 0.35);
    }
  }
}
/* .arrow {
  pointer-events: none;
  z-index: 2;
}
.arrow__path {
  stroke: black;
  fill: transparent;
  //stroke-dasharray: 8 4;
  stroke-width: 2;
}
.arrow__head line {
  stroke: black;
  stroke-width: 2px;
} */

.connection-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background: transparent;
}


.connection-container {
  svg {
    path {
      pointer-events: auto;
      stroke:black;
      stroke-width:1px;
      fill: transparent;
      pointer-events: stroke;
    }
    path.shadowed {
      stroke: lighten(#000000, 70%);
      //stroke: rgba(#000000, 30%);
      //mix-blend-mode: ;
      stroke-width: 1px;
      pointer-events: none;
    }
    path.bold {
      stroke-width: 2px;
    }
    path:hover, path.marked {
      cursor: pointer;
      stroke: red;
      stroke-width: 2px;
    }
    /* path[data-from="1"] {
      stroke: blue;
    } */
  }
}


.spatial-interconnection {
  position: relative;
  background: #d7dcde;
  //width: 100%;
  min-height: 30em;
  
  .requirements > span, .requirements > div {
    margin-right: 25px;
    font-size: 1.2em;
    line-height: 2em;
  }
  .spacial-interconnection-controls {
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //background: rgba($primary-color, 30%);
  }
  .zone-container.picking .rooms .room {
    border: solid 5px $primary-color;
    background: $secondary-color;
    color: black;
  }
  .zone-container {
    padding: 20px;
    justify-content: space-between;
    align-items: flex-start;
  }
  .zone {
    background: $general-background;
    border-radius: 8px;
    border: 1px solid #707070;
    
    padding: 0px 10px;
    width: 344px;

    .zone-name {
      align-items: center;
      justify-content: space-between;

      h4 {
        margin-left: 20px;
      }
    }

    .zone-controls {
      display: inline-flex;
      gap: 10px;
    }

    .rooms {
      display: flex;
      flex-direction: column;
      align-items: center;
      .room {
        position: relative;
        background: $primary-color;
        border-radius: 8px;

        min-height: 60px;
        width: 274px;
        margin: 20px;
        font: normal normal 600 16px/22px Noto Sans;

        align-items: center;
        //padding: 0 20px;
        justify-content: space-between;
        color: white;
        .room-name {
          margin-left: 20px;
        }

        &::before {
          position: absolute;
          left: -$room-dot-size * 0.5;
          content: "";
          background: black;
          width: $room-dot-size * 0.5;
          height: $room-dot-size;
          border-top-left-radius: $room-dot-size;
          border-bottom-left-radius: $room-dot-size;
        }

        &::after {
          position: absolute;
          right: -$room-dot-size * 0.5;
          content: "";
          background: black;
          width: $room-dot-size * 0.5;
          height: $room-dot-size;
          //border-radius: 50%;
          border-top-right-radius: $room-dot-size;
          border-bottom-right-radius: $room-dot-size;
        }
      }
      .add-row {
          padding-bottom: 30px;        
      }
      .icon-button {
        margin: 0 36px;
      }
    }
  }
}
</style>
