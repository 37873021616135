<template>
  <div class="requirement-status">
    <div v-if="!this.requirement.isOk()" class="problems" >
      <i class="warning fas fa-exclamation-triangle" >
      </i>
      <div class="tooltip">
        <p v-for="(message, idx) in this.requirement.listProblems()" :key="idx">
          {{idx+1}}. {{message}}
        </p>
      </div>
    </div>
    <CheckboxInput v-else
      :value="getRequirementParam(requirement.instanceId, 'fulfilled')"
      @input="toggleFulfilled()"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CheckboxInput from '../components/fundamentals/CheckboxInput.vue'

export default {
  components: {CheckboxInput},
  props: {
    requirement: Object,
  },
  methods: {
    ...mapMutations('configuration', [
      'setRequirementParameter'
    ]),
    
    toggleFulfilled() {
      //this.$emit('set-requirement-parameter', 
      this.setRequirementParameter({
        parameter:'fulfilled', 
        value: !this.getRequirementParam(this.requirement.instanceId, 'fulfilled'), 
        instanceId: this.requirement.instanceId
      });
    },
  },
  computed: {
    ...mapGetters('configuration', [
      'getRequirementParam',
    ]),
  }
}
</script>

<style lang="scss">
$warning-color: rgb(230, 5, 5);
.requirement-status {
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  i.warning {
    position: relative;
    color: $warning-color;
  }

  .problems:hover {
    color: lighten($warning-color, 10%);
    .tooltip {
      visibility: visible;
    }
  }

  // TODO: extract outside to general css
  .tooltip {
    font-size: 0.9em;
    visibility: hidden;
    position: absolute;
    color: white;
    background: rgba($color: #333, $alpha: 1);
    z-index: 1;
    text-align: center;
    border-radius: 6px;
    padding: 6px;
    p {
      line-height: 1.2em;
    }
  }

  i {
    font-size: 22px;
  }
}
</style>
