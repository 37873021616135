import deepClone from './clone';

abstract class AbstractParameter {
    
  private _name: string;
  protected _isActive: boolean
 
  constructor(name:string, isActive = true) {
      this._name = name;
      this._isActive = isActive;
  }

  public abstract setup(data: any) : void;
  public abstract value (cs:CalculationSystem) : any;

  public getString(): string {
    return "";
  }
  
  public dependencies (): Array<string> {
    return []
  }
  
  public get name () : string {
    return this._name;
  }

  public isActiveDeep (_cs:CalculationSystem) : boolean {
    return this._isActive;
  }

  public get isActive () : boolean {
    return this._isActive;
  }
  
  public set isActive (v : boolean) {
    this._isActive = v;
  }

  // for MultipleChoice parameter
  public is (_alias: string): boolean {
    throw new Error(`AbstractParameter.is is just for MultipleChoice parameter '${this._name}'.`);
  }
  public getOptions(): Array<IOption> {
    return [];
  }
  // for array
  getArray(): Array<any> {throw new Error(`AbstractParameter.getArray '${this._name}'.`);}
  getItem(idx: number): any {throw new Error(`AbstractParameter.getItem(${idx}) '${this._name}'.`);}
  setItem(idx: number, value: any): void {throw new Error(`AbstractParameter.setItem(${idx},${value}) ${this._name}'.`);}
  push(item: any) {throw new Error(`AbstractParameter.push(${typeof item}) ${this._name}'.`);}
}

class ArrayParameter extends AbstractParameter {
  protected _array: Array<any>;
  constructor(name: string, value: Array<any>, isActive=true) {
    super(name, isActive);
    this._array = value;
  }

  setup(value: Array<any>): void {
    this._array = value;
  }

  getArray(): Array<any> {
    return this._array;
  }
  getItem(idx: number): any {
    return this._array[idx];
  }
  setItem(idx: number, value: any): void {
    this._array[idx] = value;
  }
  push(item: any) {
    this._array.push(item);
  }

  value(): number {
    return 0;
  }
}

class StringParameter extends AbstractParameter {
  protected _str : string;
  constructor (name: string, value: string, isActive = true) {
    super(name, isActive);
    this._str = value;
  }

  setup(value: string): void {
    this._str = value;
  }

  getString(): string {
    return this._str;
  }

  value(): string {
    return this._str;
  }
}

class SimpleParameter extends AbstractParameter {
  protected _value: number;
  constructor (name:string, value: number, isActive = true) {
    super(name, isActive);
    this._value = value;
  }
  
  setup(value: number) : void {
    this._value = value;
    this.isActive = true;
  }

  value() {
    return this._value;
  }
}

class FallbackParameter extends SimpleParameter {
  private _fallbackValue: number;
  private _storedValue: number;
  constructor (name:string, value: number, fallbackValue: number, isActive = true) {
    super(name, value, isActive);
    this._storedValue = value;
    this._fallbackValue = fallbackValue;
    if (!isActive) {
      this._value = this._fallbackValue
    }
  }

  value() {
    return this._value;
  }

  public set isActive (v: boolean) {
    if (this._isActive == v) return;
    this._isActive = v;
    if (this._isActive) {
      this._value = this._storedValue;
    } else {
      this._storedValue = this._value;
      this._value = this._fallbackValue;
    }
  }
}


class ComputedParameter extends AbstractParameter {
  static no_Computed = 0;
  private _computedLastTime = 0;
  private _computedLastValue = 0;

  private _compFn: (cs:CalculationSystem) => number;
  private _dependencies: string[];

  constructor (name:string, compFn: (cs:CalculationSystem)=> number) {
    super(name, true);
    this._compFn = compFn;
    this._dependencies = [];
    this.recomputeDependencies();
  }
  
  setup(compFn: (cs:CalculationSystem) => number) : void {
    this._compFn = compFn;
    this.recomputeDependencies();
  }

  value(cs:CalculationSystem) {
    ComputedParameter.no_Computed += 1;
    if (cs.lastUpdateSharedTime.get() == this._computedLastTime) return this._computedLastValue;
    if (!this._compFn || typeof this._compFn !== 'function') 
      throw new Error(`ComputedParameter.value no fn '${this.name}'`);
    this._computedLastTime = cs.lastUpdateSharedTime.get();
    return this._computedLastValue = this._compFn(cs);
  }
  
  private recomputeDependencies() {
    const reg = this._compFn.toString().matchAll(/cs\.getValue\w*\(\w*['"]([^'"]+)['"]/g);
    const array = [...reg]
    this._dependencies = array.map(x => x[1]);
  }

  dependencies(): string[] {
    return this._dependencies;
  }

  isActiveDeep(cs:CalculationSystem): boolean {
    this.dependencies().forEach(dep => {
      try {
        cs.getParameter(dep);
      } catch {
        const msg = `Invalid dependency '${dep}' on '${this.name}'`;
        console.error(msg);
        throw new Error(msg);
      }
    })
    return !this.dependencies().some(dep => !cs.isActive(dep));
  }
}

interface IOption {
  name: string;
  value: number;
  alias: string;
}

class MultiplechoiceParameter extends AbstractParameter {
  protected _value: number;
  protected _options: Array<IOption>;
  constructor (name:string, value: number, options:Array<IOption>, isActive = true) {
    super(name, isActive);
    this._value = value;
    this._options = options;
  }
  
  setup(value: number) : void {
    this._value = value;
    this.isActive = true;
  }

  value() {
    return this._value;
  }

  is(alias: string): boolean {
    const [active] = this._options.filter(opt => opt.value == this._value);
    if (!active) throw new Error('OptionsParameter.is WTF no value.');
    return active.alias === alias;
  }

  getOptions(): Array<IOption> {
    return this._options;
  }
}
import { v4 as uuidv4 } from 'uuid';

class LastUpdateSharedTime {
  public lastUpdateTime = 1;

  notifyUpdate() {
    this.lastUpdateTime += 1;
  }

  get() : number {
    return this.lastUpdateTime;
  }

  split() : LastUpdateSharedTime {
    const obj = new LastUpdateSharedTime();
    obj.lastUpdateTime = this.lastUpdateTime;
    return obj;
  }
}

class CalculationSystem {
    public lastUpdateSharedTime = new LastUpdateSharedTime();
    id: any;
    parameters: Record<string, AbstractParameter>;
    modules: Record<string, CalculationSystem>;
    parent?: CalculationSystem;

    constructor () {
        this.parameters = {};
        this.modules = {};
        this.parent = undefined;
        this.id = uuidv4();
    }

    static parseName(parameterName : string) {
      const [first, ...rest] = parameterName.split('.')
      if (rest.length === 0) return {module: null, name: first};
      return {
        module: first,
        name: rest.join('.'),
      }
    }

    addParameter (parameter:AbstractParameter) {
        if (parameter.name in this.parameters) throw new Error(`CalculationSystem.addParameter parameter '${parameter.name}' is already registered.`);
        this.parameters[parameter.name] = parameter;
    }

    getParameter (parameterName:string): AbstractParameter {
        const {module, name} = CalculationSystem.parseName(parameterName);
        if (Object.prototype.hasOwnProperty.call(this.parameters, parameterName)) return this.parameters[parameterName];
        else if (module && Object.prototype.hasOwnProperty.call(this.modules, module)) {
          return this.modules[module].getParameter(name);
        }
        else throw new Error(`CalculationSystem.getParameter unknown '${parameterName}'.`);
    }

    getValue (parameterName:string): number {
        const {module, name} = CalculationSystem.parseName(parameterName);
        if (Object.prototype.hasOwnProperty.call(this.parameters, parameterName)) return this.getParameter(parameterName).value(this)
        else if (module && Object.prototype.hasOwnProperty.call(this.modules, module)) {
          return this.modules[module].getValue(name);
        }
        else throw new Error(`CalculationSystem.getValue unknown '${parameterName}'.`);
    }

    // function for debugging last update times in submodules
    static showMeTheLife(cs:CalculationSystem, name = 'root', depth = 0) {
      const time = cs.lastUpdateSharedTime.get();
      console.log(`${depth} ${name}: ${time}`);
      for (const key in cs.modules) {
        if (Object.prototype.hasOwnProperty.call(cs.modules, key)) {
          const module = cs.modules[key];
          CalculationSystem.showMeTheLife(module, key, depth+1);          
        }
      }
    }

    setValue (parameterName:string, change:any) {
        this.lastUpdateSharedTime.notifyUpdate();
        //console.log('number of calculations', ComputedParameter.no_Computed);
        const {module, name} = CalculationSystem.parseName(parameterName);
        if (Object.prototype.hasOwnProperty.call(this.parameters, parameterName)) this.parameters[parameterName].setup(change);
        else if (module && Object.prototype.hasOwnProperty.call(this.modules, module)) {
          this.modules[module].setValue(name, change);
        }
        else throw new Error(`CalculationSystem.setValue unknown '${parameterName}'.`);
    }

    is (parameterName: string, alias: string): boolean {
      const {module, name} = CalculationSystem.parseName(parameterName);
        if (Object.prototype.hasOwnProperty.call(this.parameters, parameterName)) return this.parameters[parameterName].is(alias);
        else if (module && Object.prototype.hasOwnProperty.call(this.modules, module)) {
          return this.modules[module].is(name, alias);
        }
        else throw new Error(`CalculationSystem.isActive unknown '${parameterName}'.`);
    }
    getOptions (parameterName: string): Array<IOption> {
      const {module, name} = CalculationSystem.parseName(parameterName);
        if (Object.prototype.hasOwnProperty.call(this.parameters, parameterName)) return this.parameters[parameterName].getOptions();
        else if (module && Object.prototype.hasOwnProperty.call(this.modules, module)) {
          return this.modules[module].getOptions(name);
        }
        else throw new Error(`CalculationSystem.isActive unknown '${parameterName}'.`);
    }
    // string
    getString(parameterName:string): string {
      const parameter = this.getParameter(parameterName);
      return parameter.getString();
    }
    // array
    getArray(parameterName:string): Array<any> {
      const parameter = this.getParameter(parameterName);
      return parameter.getArray();
    }
    getItem(parameterName:string, idx: number): any {
      const parameter = this.getParameter(parameterName);
      return parameter.getItem(idx);
    }
    setItem(parameterName:string, idx: number, value: any): void {
      this.lastUpdateSharedTime.notifyUpdate();
      const parameter = this.getParameter(parameterName);
      parameter.setItem(idx, value);
    }
    push(parameterName:string, item: any) {
      this.lastUpdateSharedTime.notifyUpdate();
      const parameter = this.getParameter(parameterName);
      return parameter.push(item);
    }
    
    isActive (parameterName:string): boolean {
        const {module, name} = CalculationSystem.parseName(parameterName);
        if (Object.prototype.hasOwnProperty.call(this.parameters, parameterName)) return this.parameters[parameterName].isActiveDeep(this);
        else if (module && Object.prototype.hasOwnProperty.call(this.modules, module)) {
          return this.modules[module].isActive(name);
        }
        else throw new Error(`CalculationSystem.isActive unknown '${parameterName}'.`);
    }

    setActivity (parameterName:string, activity: boolean):void {
        this.lastUpdateSharedTime.notifyUpdate();
        const {module, name} = CalculationSystem.parseName(parameterName);
        if (Object.prototype.hasOwnProperty.call(this.parameters, parameterName)) this.parameters[parameterName].isActive = activity;
        else if (module && Object.prototype.hasOwnProperty.call(this.modules, module)) {
          this.modules[module].setActivity(name, activity);
        }
        else throw new Error(`CalculationSystem.setActivity unknown '${parameterName}'.`);
    }
    
    setLastUpdateShareTime(lUST : LastUpdateSharedTime) {
      this.lastUpdateSharedTime = lUST;
      for (const key in this.modules) {
        if (Object.prototype.hasOwnProperty.call(this.modules, key)) {
          const module = this.modules[key];
          module.setLastUpdateShareTime(lUST);
        }
      }
    }

    registerModule (moduleName: string, cs: CalculationSystem) : void {
      this.lastUpdateSharedTime.notifyUpdate();
      if (Object.prototype.hasOwnProperty.call(this.modules, moduleName)) throw new Error(`CalculationSystem.registerModule already exists '${moduleName}'.`);
      this.modules[moduleName] = cs;
      if (cs.lastUpdateSharedTime.get() > this.lastUpdateSharedTime.get()) {
        this.lastUpdateSharedTime.lastUpdateTime = cs.lastUpdateSharedTime.lastUpdateTime;
      }
      
      cs.setLastUpdateShareTime(this.lastUpdateSharedTime);
      cs.parent = this;
    }

    unregisterModule (moduleName: string): void {
      this.lastUpdateSharedTime.notifyUpdate();
      if (!Object.prototype.hasOwnProperty.call(this.modules, moduleName)) throw new Error(`CalculationSystem.unregisterModule unknown '${moduleName}'.`);
      this.modules[moduleName].lastUpdateSharedTime = this.lastUpdateSharedTime.split();
      delete this.modules[moduleName].parent;
      delete this.modules[moduleName];
    }


    dump(submodules = true) {
        for (const key in this.parameters) {
            if (Object.hasOwnProperty.call(this.parameters, key)) {
                const parameter = this.parameters[key];
                const symbol = parameter.isActiveDeep(this)? '✔️' : '❌';
                console.log(`${symbol} ${key}: ${parameter.value(this)},`);
            }
        }
        if (!submodules) return;
        for (const moduleName in this.modules) {
          if (Object.hasOwnProperty.call(this.modules, moduleName)) {
            this.dumpModule(moduleName)
          }
        }
    }
    
    dumpModule(moduleName: string): void {
      const module = this.modules[moduleName];
      
      for (const key in module.parameters) {
        if (Object.hasOwnProperty.call(module.parameters, key)) {
            const parameter = module.parameters[key];
            const symbol = parameter.isActiveDeep(module)? '✔️' : '❌';
            console.log(`${symbol} ${moduleName}.${key}: ${parameter.value(module)},`);
        }
      }        
    }

    exportToJSON(): Record<string, any> {
      const res:Record<string, any> = {};
      for (const key in this.parameters) {
        if (Object.hasOwnProperty.call(this.parameters, key)) {
            const parameter = this.parameters[key];
            res[key] = parameter.value(this);
        }
      }
      
      for (const moduleName in this.modules) {
        if (Object.hasOwnProperty.call(this.modules, moduleName)) {
          if (!Object.hasOwnProperty.call(res, 'modules')) res['modules'] = {};
          res.modules[moduleName] = this.modules[moduleName].exportToJSON();
        }
      }
      return res;
    }

    clone():CalculationSystem {
      return deepClone(this, true);
    }

    soakIn(sinthy:CalculationSystem) {
      this.parameters = sinthy.parameters;
      this.modules = sinthy.modules;
      this.id = sinthy.id;
      this.parent = sinthy.parent;
    }

}

function testIt() {
    const cs = new CalculationSystem();
    cs.addParameter(new SimpleParameter('PI', Math.PI));
    cs.addParameter(new SimpleParameter('radius', 3, true));
    cs.addParameter(new ComputedParameter('circleArea', cs => Math.pow(cs.getValue('radius'), 2)*cs.getValue('PI')));
    cs.addParameter(new SimpleParameter('A', 4, true));
    cs.addParameter(new SimpleParameter('B', 5));
    cs.addParameter(new ComputedParameter('rectangleCircumfence', cs =>  2 * (cs.getValue('A') + cs.getValue('B'))));
    cs.addParameter(new ComputedParameter('rectangleArea', cs => cs.getValue('A') * cs.getValue('B')));
    cs.addParameter(new ComputedParameter('rectangleAreaCircumfenceRatio', cs => cs.getValue('rectangleArea') / cs.getValue('rectangleCircumfence')));
    const param = new ComputedParameter('rectangleAreaCircumfenceRatio', cs => cs.getValue('rectangleArea') / cs.getValue('rectangleCircumfence'))
    console.log(param.dependencies())
    cs.dump();
    cs.setValue('radius', 10);
    cs.setValue('A', 8);
    cs.setValue('B', 10);
    cs.setActivity('A', false);
    cs.dump();

    const cs2 = new CalculationSystem();
    cs2.addParameter(new SimpleParameter('PI', Math.PI));
    cs2.addParameter(new SimpleParameter('radius', 3, true));
    cs2.addParameter(new ComputedParameter('circleArea', cs => Math.pow(cs.getValue('radius'), 2)*cs.getValue('PI')));
    
    cs.registerModule('submoduleCS2', cs2);

    cs.dump();
    cs.setActivity('A', true);
    cs.unregisterModule('submoduleCS2')
    cs.dump();
}
export {
  testIt,
  SimpleParameter,
  FallbackParameter,
  ComputedParameter,
  MultiplechoiceParameter,
  StringParameter,
  ArrayParameter,
  CalculationSystem,
}
