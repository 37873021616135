import { CalculationSystem, SimpleParameter, ComputedParameter, FallbackParameter } 
from "@/calculations/calculationSystem";

function composeGroundPlan() : CalculationSystem {
  const cs = new CalculationSystem();

  cs.addParameter(new SimpleParameter('estateArea', 5123.6));

  cs.addParameter(new FallbackParameter('LPmaxHeight', 12.5, 200, true));

  cs.addParameter(new FallbackParameter('LPmaxBuildUpTotal', 0.5, 150, false));
  cs.addParameter(new FallbackParameter('LPmaxBuildUpMainBuilding', 0.4, 100, true));

  cs.addParameter(new FallbackParameter('LPmaxFloorCoefTotal', 0.7, 300, false));
  cs.addParameter(new FallbackParameter('LPmaxFloorCoefMainBuilding', 0.6, 200, false));

  cs.addParameter(new FallbackParameter('LPmaxEnclosedVolume', 3.5, 200, true));

  cs.addParameter(new SimpleParameter('LPminGreenVegetation', 0.3, true));


  /* cs.addParameter(new SimpleParameter('constructionalHeightDefinitionType', -666));
  cs.addParameter(new SimpleParameter('constructionalHeightUser', -666));
  cs.addParameter(new SimpleParameter('constructionalHeightDatabase', -666)); */
  cs.addParameter(new SimpleParameter('constructionalHeight', 3.0));


  cs.addParameter(new SimpleParameter('parkingSpacesReductionCoef', 0.25));
  cs.addParameter(new SimpleParameter('parkingSpacesRegisteredCarsCoef', 1.75));


  cs.addParameter(new ComputedParameter('LPlimitMaxFloorNumber',
    cs => cs.isActive('LPmaxHeight') 
    ? Math.floor(cs.getValue('LPmaxHeight')/cs.getValue('constructionalHeight'))
    : Number.NaN));
  cs.addParameter(new ComputedParameter('LPlimitMaxFloorAreaAboveGround', 
    cs => cs.getValue('LPlimitMaxFloorAreaAboveGroundActive')
    ? Math.min(cs.getValue('LPlimitMaxFloorAreaAboveGroundA'), cs.getValue('LPlimitMaxFloorAreaAboveGroundB'), cs.getValue('LPlimitMaxFloorAreaAboveGroundC'))
    : Number.NaN
  ));
  cs.addParameter(new ComputedParameter('LPlimitMaxFloorAreaAboveGroundActive', 
    cs => (cs.isActive('LPmaxBuildUpTotal')
      || cs.isActive('LPmaxBuildUpMainBuilding')
      || cs.isActive('LPmaxFloorCoefTotal')
      || cs.isActive('LPmaxFloorCoefMainBuilding')
      || cs.isActive('LPmaxEnclosedVolume')
      ) as unknown as number
  ));
  cs.addParameter(new ComputedParameter('LPlimitMaxVolume',
    cs => cs.isActive('LPmaxEnclosedVolume')
    ? cs.getValue('estateArea')*cs.getValue('LPmaxEnclosedVolume')
    : Number.NaN));
  cs.addParameter(new ComputedParameter('LPlimitMinGreenVegetation',
    cs => cs.isActive('LPminGreenVegetation')
    ? cs.getValue('estateArea')*cs.getValue('LPminGreenVegetation')
    : Number.NaN));

  //cs.addParameter(new SimpleParameter('LPmaxHeightCV', -666));
  //cs.addParameter(new SimpleParameter('LPlimitMaxFloorNumberCV', -666));

  //cs.addParameter(new SimpleParameter('LPmaxBuildUpMainBuildingCV', -666));
  //cs.addParameter(new SimpleParameter('LPmaxBuildUpTotalCV', -666));
  cs.addParameter(new ComputedParameter('LPlimitMaxFloorAreaAboveGroundA',
    cs => cs.getValue('estateArea') * cs.getValue('LPlimitMaxFloorNumber') * Math.min(cs.getValue('LPmaxBuildUpMainBuilding'), cs.getValue('LPmaxBuildUpTotal'))));
  //cs.addParameter(new SimpleParameter('LPmaxFloorCoefMainBuildingCV', -666));
  //cs.addParameter(new SimpleParameter('LPmaxFloorCoefTotalCV', -666));
  cs.addParameter(new ComputedParameter('LPlimitMaxFloorAreaAboveGroundB',
    cs => cs.getValue('estateArea') * Math.min(cs.getValue('LPmaxFloorCoefMainBuilding'), cs.getValue('LPmaxFloorCoefTotal'))));
  //cs.addParameter(new SimpleParameter('LPmaxEnclosedVolumeCV', -666));
  cs.addParameter(new ComputedParameter('LPlimitMaxFloorAreaAboveGroundC', 
    cs => cs.getValue('LPmaxEnclosedVolume') * cs.getValue('estateArea') / cs.getValue('constructionalHeight')));

  cs.dump();
  return cs;
}


export {composeGroundPlan }
