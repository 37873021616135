import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";

import Project from "../views/projects/Project.vue"

import PC from "@/views/configuration/PC.vue"
import Architecture from "../views/configuration/Architecture.vue"
import Requirements from "../views/configuration/Requirements.vue"
import Checklist from "../views/configuration/Checklist.vue"
//import Construction from "../views/configuration/Construction.vue"
import Profile from "../views/Profile.vue"

import Settings from "../views/admin/Settings.vue"
import Articles from "../views/admin/Articles.vue";
import Calculators from "../views/admin/Calculators.vue";
import Templates from "../views/admin/Templates.vue";
import BuildingLibrary from "../views/admin/BuildingLibrary.vue";
import ProjectManager from "../views/projects/ProjectManager.vue";
import BatchAssignment from "../views/configuration/batchAssignment/BatchAssignment.vue"
import ResidentialApartments from "../views/concept-calculators/apartments/ResidentialApartments.vue"
import ConceptList from "@/views/projects/ConceptList.vue";
import PCView from "@/views/projects/PC/PCView.vue";
import ConfigurationList from "@/views/projects/PC/ConfigurationList.vue";
import CreateConfigurationEmpty from '@/views/projects/PC/CreateConfigurationEmpty.vue'
import CreateConfigurationTemplate from '@/views/projects/PC/CreateConfigurationTemplate.vue'

import Model3DList from "@/views/projects/Model3DList.vue";
import OpenedProject from "@/views/projects/OpenedProject.vue";
import ShareProject from "@/views/projects/ShareProject.vue";
import Users from "@/views/admin/Users.vue"

//import Entrance from "../views/Entrance.vue";
//import Cards from "../views/admin/Cards.vue";
//import Load from "../views/Load.vue";

const routes : RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/project-manager",
  },
  {
    path: "/project",
    name: "project",
    component: OpenedProject,

    children: [
      {
        path: "",
        name: "project",
        component: Project,
      },
      {
        path: 'concepts',
        component: ConceptList,
      },
      {
        path: 'configurations',
        component: PCView,

        children: [
          {
            path: '',
            component: ConfigurationList,
          },
          {
            path: 'empty',
            component: CreateConfigurationEmpty,
          },
          {
            path: 'template',
            component: CreateConfigurationTemplate,
          },
        ]
      },
      {
        path: 'models3d',
        component: Model3DList,
      },
      {
        path: 'share',
        component: ShareProject,
      },
    ]
  },
  {
    path: "/PC",
    name: "PC",
    component: PC,
    children: [
      {
        path: "architecture",
        name: "architecture",
        component: Architecture,
      },
      {
        path: "requirements",
        name: "requirements",
        component: Requirements,
      },
      {
        path: "checklist",
        name: "checklist",
        component: Checklist,
      },/*
      {
        path: "location",
        name: "location",
        component: Location,
      },
      {
        path: "construction",
        name: "construction",
        component: Construction,
      },
      {
        path: "hvac",
        name: "hvac",
        component: HVAC,
      },
      {
        path: "protocol",
        name: "protocol",
        component: Protocol,
      },*/
    ]
  },
  
  {
    path: "/settings",
    name: "settings",
    component: Settings,
  },
  {
    path: "/articles",
    name: "articles",
    component: Articles,
  },
  {
    path: "/calculators",
    name: "calculators",
    component: Calculators,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: '/templates',
    name: "templates",
    component: Templates,
  },
  {
    path: '/building-library',
    name: 'building-library',
    component: BuildingLibrary,
  },
  {
    path: '/project-manager',
    name: 'project-manager',
    component: ProjectManager,
  },
  {
    path: '/batch-assignment',
    name: 'batch-assignment',
    component: BatchAssignment,
  },
  {
    path: '/residential-apartments',
    name: 'residential-apartments',
    component: ResidentialApartments,
  },

  {
    path: '/users',
    name: 'users',
    component: Users,
  }

  /*{
    path: "/:owner/load/:hash",
    name: "Load",
    component: Load,
  },
  {
    path: "/:apiKey/load",
    name: "LoadVisitor",
    component: Load,
  }*/
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
