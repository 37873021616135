import { IConnection, EExteriorType, Connection } from "@/@types/definitions/connections";
import { AppliedRequirement, ERequirementLevel, Requirement, RequirementList } from "@/@types/definitions/requirements";
import { IUsedRoom, UsedRoom } from "@/@types/definitions/rooms";
import { Module, ActionContext } from "vuex";
import {IState} from "../index"; 

function testlog(message:string) {
  console.warn(`testLog: ${message}`);
}

/* class ParameterList {
  [index: string] : any;
  //data: Record<string, any>;

  constructor() {
    //this.data = {};
  }
  setParameters (params: Record<string, any>) {
    for (const key in params) {
      this[key] = params[key];
    }
  }
  hasParameter (name: string) {
    return name in this;
  }
  activateParameter (name: string) {
    this[name] = null;
  }
  deactivateParameter (name: string) {
    delete this[name];
  }
  setParameter (name: string, value: any) {
    this[name] = value;
  }
  getParameter (name: string) {
    return this[name];
  }
}
 */

interface IFunctionConfiguration {
  functionKindId: number,
  rooms: Array<UsedRoom>,
  show: boolean,
  requirements: RequirementList,
  parameters: Record<string, any>,
  functionKindName: string,
}

class FunctionConfiguration implements IFunctionConfiguration {
  functionKindId: number;
  rooms: Array<UsedRoom>;
  show: boolean;
  requirements: RequirementList;
  parameters: Record<string, any>;
  functionKindName: string;

  constructor(functionKindId = 1, functionKindName = 'Obytné prostory') {
    this.functionKindId = functionKindId;
    this.rooms = Array<UsedRoom>();
    this.show = true;
    this.requirements = Array<any>();
    this.parameters = {};
    this.functionKindName = functionKindName;
  }

  static Create(fcdb: FunctionConfiguration) {
    const obj = new FunctionConfiguration(fcdb.functionKindId, fcdb.functionKindName || '');
    
    if (fcdb.parameters) {
      obj.parameters = fcdb.parameters;
    }

    obj.rooms = fcdb.rooms.map(roomdb => {
      const room = UsedRoom.Create(roomdb);
      return room;
    });
    
    obj.show = fcdb.show;
    obj.requirements = [...fcdb.requirements];
    return obj;
  }
}

class Location {
  parameters: Record<string, any>;
  requirements: RequirementList;

  constructor() {
    this.parameters = {};
    this.requirements = [];
  }
}

class Building extends Location {
  constructor() {
    super();
  }
}

interface IConstruction {
  deksoftId: number,
  connectionType: string,
  constructionType: string,
}

/*
const activationOfParameter = (parameters:Record<string, any>, parameter: string, what:any) => {
  if (what) {
    parameters[parameter] = null;
  } else {
    delete parameters[parameter];
  }
}

const setParameter = (parameters:Record<string, any>, parameter: string, value:any) => {
  parameters[parameter] = value;
}

const getParameter = (parameters:Record<string, any>, param: string) => {
  return parameters[param];
}

const hasParameter = (parameters:Record<string, any>, param: string) => {
  return param in parameters;
}
*/

import { batchAssignment } from "./batchAssignment";
import customRequirements from "./customRequirements";
import { addRequirementBatch, addRequirementUniversal, assignRequirement, createRequirement, removeRequirement, setRequirementParameter, setRequirementParameters, updateStateAfterBatchAssignment } from "./requierements";
import { IEntityLocation } from "@/@types/definitions/entity";
const state = {
  projectName: '',
  functionConfigurations: [new FunctionConfiguration(1)] as Array<IFunctionConfiguration>,
  helper: {
    nextRoomId: 1,
    nextRequirementId: 1,
  },
  roomById: {} as Record<number, UsedRoom>,
  roomIdToIdx: {} as Record<number, number>,
  requirementById: {} as Record<number, AppliedRequirement>,
  location: new Location(),
  building: new Building(),
  connections: Array<Connection>(),
  requirementCollection: Array<AppliedRequirement>(),
  constructions: Array<IConstruction>(),
  batchAssignment: batchAssignment.state,
};

export type IConfigurationState = typeof state;
type State = IConfigurationState;

const removeListOfRequirements = (state:State, requirements: number[]) => {
  state.requirementCollection = state.requirementCollection.filter((requirement:AppliedRequirement) => {
    return !requirements.includes(requirement.instanceId);
  })
}

const removeRoom = (state:State, {idx, room}:{idx:number, room:IUsedRoom}) => {
  if (idx < 0 || idx >= state.functionConfigurations.length) throw Error(`functionConfiguration idx '${idx}'.`);
  state.functionConfigurations[idx].rooms = state.functionConfigurations[idx].rooms.filter(listedRoom => listedRoom.name !== room.name);
  state.connections = state.connections.filter(conn => conn.fromId !== room.id && conn.toId !== room.id)
  state.requirementCollection = state.requirementCollection.filter((requirement : AppliedRequirement) => {
    return requirement.owner.roomId != room.id && requirement.owner.fromId != room.id && requirement.owner.toId != room.id;
  })
};

const configuration:Module<IConfigurationState, IState> = {
  namespaced: true,
  state: ():State => state,
  modules: {
    customRequirements,
  },
  getters: {
    getData: (state:State) => state, 
    projectName: (state:State) => state.projectName,
    functionConfigurations: (state:State) : IFunctionConfiguration[] => state.functionConfigurations,
    getFunctionConfiguration: (state:State) => (index:number) => state.functionConfigurations[index],
    isRoomInProject: (state:State) => ({idx, room}:{idx:number, room:IUsedRoom}) => { 
      return state.functionConfigurations[idx].rooms.some(listedRoom => listedRoom.name === room.name)
    },
    showFunctionKind: (state:State) => (idx:number) => state.functionConfigurations[idx].show,
    nextRoomId: (state:State) => state.helper.nextRoomId,
    nextRequirementId: (state:State) => state.helper.nextRequirementId,
    getRoomById: (state: State) => (id:number) => state.roomById[id],
    getIdxFromRoomId: (state: State) => (id:number) => state.roomIdToIdx[id],
    getRequirementById: (state: State) => (id:number) => state.requirementById[id],
    connections: (state:State) => state.connections,
    getConnection: (state:State) => (fromId:number, toId:number) => {
      const [connection] = state.connections.filter(con => {        
        return con.fromId == fromId && con.toId == toId
        || con.toId == fromId && con.fromId == toId;
      });
      return connection;
    },
    findFunctionIndexByRoomId: (state:State) => (id:number) => {
      let result = null;
      state.functionConfigurations.some((fc, idx) => {
        const [room] = fc.rooms.filter(r => r.id == id);
        if (room) {
          result = idx;
          return true;
        }
        return false;
      })
      return result;
    },
    functionKindName: (state:State, getters:any, rootState:IState, rootGetters:any) => (functionKindId:number) => {
      return rootGetters['definitions/functionKindName'](functionKindId);
    },
    getRoom: (state:State) => ({idx, room}:{idx:number, room:UsedRoom}) => {
      const [roomHere] = state.functionConfigurations[idx].rooms.filter(r => r.id == room.id);
      if (roomHere) {
        return roomHere;
      }
      return null;
    },
    getRoomFlag: (state:State, getters:any) => ({idx, room, name}:{idx:number, room:UsedRoom, name: string}) => {
      const roomHere : UsedRoom = getters.getRoom({idx, room});
      //console.log(roomHere);
      
      if (roomHere) {
        return roomHere.getFlag(name, getters);
      }
      return "error";
    },
    locationRequirements: (state:State) => state.location.requirements,
    buildingRequirements: (state:State) => state.building.requirements,
    functionKindRequirements: (state:State) => (idx:number) => state.functionConfigurations[idx].requirements,
    roomRequirements: (state:State) => ({idx, room}:{idx:number, room:UsedRoom}) => {
      const [roomHere] = state.functionConfigurations[idx].rooms.filter(r => r.id == room.id);
      if (roomHere) {
        return roomHere.requirements;
      }
      return [];
    },
    getRequirement: (state: State) => (requirementId: number) => {
      const [requirement] = state.requirementCollection.filter(req => req.instanceId == requirementId);
      return requirement;
    },
    getRequirementParam: (state: State, getters:any) => (requirementId: number, parameter:string) => {
      const [requirement] = state.requirementCollection.filter(req => req.instanceId == requirementId);
      return requirement.getParam(parameter, getters);
    },
    getRequirementDisplayParam: (state: State, getters:any) => (requirementId: number, parameter:string) => {
      const requirement = state.requirementById[requirementId];
      return requirement.displayParam(parameter, getters);
    },
    getRequirementCollection: (state: State) => {
      return state.requirementCollection;
    },
    getRequirementFiltered: (state:State, getters, rootState, rootGetters) => {
      const getFilterValues = rootGetters['prezentation/getFilterValues'];
      testlog("getRequirementFiltered -- room for improvements");
      
      return state.requirementCollection.filter((requirement:AppliedRequirement) => {
        for (const param of AppliedRequirement.filterParameters) {
          if (Array.isArray(getFilterValues(param)) && getFilterValues(param).length > 0) {
            if (param === 'constructions') {
              // since every requirement binds to just one construction, this can be simplified. Intersetion of two sets is not necessary.
              const values = requirement.getParam(param, getters);
              if (!values) return false;
              if (getFilterValues(param).filter((val:string) => values.includes(val)).length == 0) {
                return false;
              }
            } else if (param === 'source') {
              if (requirement.doesIntersectWithSources(getFilterValues('source')) == false)
                return false; 
            } else if(getFilterValues(param).includes(requirement.getParam(param, getters)) == false) {
              return false;
            }
          }
        }
        return true;
      });
    },
    getSortedRequirements: (state:State, getters, rootState, rootGetters) => {
      testlog('getSortedRequirements')
      const list = getters.getRequirementFiltered;
      const criteria = rootGetters['prezentation/getDistribution'];
      
      return list.sort((reqA:AppliedRequirement, reqB:AppliedRequirement) => {
        for (const criterion of criteria) {
          if (reqA.getParam(criterion) != reqB.getParam(criterion)) {
            return reqA.getParam(criterion) < reqB.getParam(criterion)? -1 : 1;
          }
        }
        return 0;
      });
    },
    getRequirementTree: (state:State, getters, rootState, rootGetters) => {
      const list = getters.getSortedRequirements;
      const criteria = rootGetters['prezentation/getDistribution'];
      
      function makeTree(list:any[], criteria:string[]) {
        const node:any = {
          data: list,
          children: [],
        }
        if (list.length == 0) return;
        if (criteria.length <= 0) {
          return node;
        }
        const criterion = criteria[0];
        let prev = list[0].getParam(criterion);
        let lo = 0;

        for (let i = 1; i <= list.length; ++ i) {
          let current;
          if (i < list.length) {
            current = list[i].getParam(criterion);
          }
          if (prev != current || i == list.length) {
            const child = makeTree(list.slice(lo,i), criteria.slice(1));
            child.value = `${criterion}#${prev}`;
            node.children.push(child);
            lo = i;
          }
          prev = current;
        }
        return node;
      }

      return makeTree(list, criteria);
    },
    /* getRequirementCollectionSorted: (state:State, getters, rootState, rootGetters) => {
      const filtered = getters.getRequirementFiltered();

      return filtered.sort();
    }, */
    getParameterAnalysis: (state:State, getters:any, rootState:IState, rootGetters:any) => {
      testlog("getParameterAnalysis");
      return AppliedRequirement.filterParameters.map(parameter => {
        let values;
        if (parameter === 'constructions') {
          values = [];
          if (state.requirementCollection.some(req => { const val = req.getParam(parameter, getters); return Array.isArray(val) && val.includes('wall')}))
          values.push({value: 'wall', name: 'stěna'});
          if (state.requirementCollection.some(req => { const val = req.getParam(parameter, getters); return Array.isArray(val) && val.includes('floor')}))
          values.push({value: 'floor', name: 'podlaha'});
          if (state.requirementCollection.some(req => { const val = req.getParam(parameter, getters); return Array.isArray(val) && val.includes('ceiling')}))
          values.push({value: 'ceiling', name: 'strop'});
          if (state.requirementCollection.some(req => { const val = req.getParam(parameter, getters); return Array.isArray(val) && val.includes('opening')}))
          values.push({value: 'opening', name: 'výplní otvoru'});
          if (state.requirementCollection.some(req => { const val = req.getParam(parameter, getters); return Array.isArray(val) && val.includes('virtual')}))
          values.push({value: 'virtual', name: 'bez konstrukce'});
        } else if (parameter === 'source') { 
          const sourceSet = new Set<string>();
          state.requirementCollection.forEach(req => {
            const sourceList = req.getParam('source') as string[];
            sourceList.forEach(source => sourceSet.add(source));
          })
          values = Array.from(sourceSet.values()).map(value => ({value, name: value}));
        } else {
          const map = new Map();
          state.requirementCollection.forEach(req => {
            const val = req.getParam(parameter, getters)
            if (val === null || map.has(val)) return;
            map.set(val, req.displayParam(parameter, getters));
          })
          
          values = Array.from(map.entries()).map(([value, name]) => ({value, name}));
        }
        return {
          parameter,
          displayName: rootGetters['definitions/requirementParameterDisplayName'](parameter),
          name: parameter,
          values,
        }
      })
    },
    constructions: (state: State) => state.constructions,
    constructionsBySlot: (state: State) => (connectionType: string, constructionType:string) => {
      return state.constructions.filter(con => 
        con.connectionType == connectionType && 
        con.constructionType == constructionType
      );
    },
    ...batchAssignment.getters,
  },
  mutations: {
    set(state:any, configurationRecv:any) {
      if (!configurationRecv) configurationRecv = {};
      if (!configurationRecv.helper) {
        configurationRecv.helper = {
          nextRoomId: 1,
          nextRequirementId: 1,
        };
      }
      if (!configurationRecv.location) configurationRecv.location = new Location();
      if (!configurationRecv.building) configurationRecv.building = new Building();
      if (!configurationRecv.connections) configurationRecv.connections = Array<Connection>();
      if (!configurationRecv.requirementCollection) configurationRecv.requirementCollection = Array<AppliedRequirement>();
      if (!configurationRecv.constructions) configurationRecv.constructions = Array<IConstruction>();
      if (!configurationRecv.functionConfigurations) configurationRecv.functionConfigurations = [new FunctionConfiguration(1)] as Array<IFunctionConfiguration>;
      console.log(configurationRecv);
      
      state.projectName = configurationRecv.projectName;
      state.helper = configurationRecv.helper;

      // Recreate Location
      state.location = configurationRecv.location;

      // Recreate Building
      state.building = configurationRecv.building;

      // Recreate Connections
      state.connections = configurationRecv.connections.map((connection:IConnection) => {
        return Connection.CreateFromJSON(connection);
      });

      // Recreate Function Configurations
      state.functionConfigurations = configurationRecv.functionConfigurations.map(
        (fcdb:IFunctionConfiguration) => {
          return FunctionConfiguration.Create(fcdb);          
        }
      );

      // Memorize Rooms By Id and make Set Of Rooms for validation.
      const roomById_local = {} as Record<number, UsedRoom>;
      const roomIdToIdx_local = {} as Record<number, number>
      const roomIds = new Set<number>();
      state.functionConfigurations.forEach((fc:FunctionConfiguration, idx: number) => {
        fc.rooms.forEach(room => {
          roomIds.add(room.id);
          roomById_local[room.id] = room;
          roomIdToIdx_local[room.id] = idx;
        })
      })
      state.roomById = roomById_local;
      state.roomIdToIdx = roomIdToIdx_local;

      // Recreate Requirement List
      state.requirementCollection = configurationRecv.requirementCollection.map(
        (requirement:AppliedRequirement) => AppliedRequirement.Create(requirement)
      );
      // Memorize Requirements by Id
      const requirementById_local = {} as Record<number, AppliedRequirement>;
      state.requirementCollection.forEach((requirement:AppliedRequirement) => {
        requirementById_local[requirement.instanceId] = requirement;
      })
      state.requirementById = requirementById_local;

      // filter out invalid requirement
      state.requirementCollection = state.requirementCollection.filter((requirement:AppliedRequirement) => {
        return requirement.owner.has('fromId') && roomIds.has(requirement.owner.fromId!)
        || requirement.owner.has('toId') && roomIds.has(requirement.owner.toId!)
        || requirement.owner.has('roomId') && roomIds.has(requirement.owner.roomId!)
        || requirement.data.level != ERequirementLevel.CONNECTION && requirement.data.level != ERequirementLevel.ROOM;
      });
    },
    insertConstruction(state:State, val:IConstruction) {
      if (state.constructions.filter(con => con.deksoftId == val.deksoftId).length == 0) { 
        state.constructions.push(val);
      }
    },
    setProjectName(state:State, val:string) {
      state.projectName = val;
    },
    addFunctionConfiguration(state:State, {functionKindId, functionKindName}: {functionKindId:number, functionKindName:string}) {
      state.functionConfigurations.push(new FunctionConfiguration(functionKindId, functionKindName));
    },
    setFunctionKind(state:State, {idx, functionKindId, functionKindName}: {idx:number, functionKindId:number, functionKindName:string}) {
      state.functionConfigurations[idx].functionKindId = functionKindId;
      state.functionConfigurations[idx].functionKindName = functionKindName;
    },
    removeFunctionKind(state:State, idx:number) {
      const fk = state.functionConfigurations[idx];
      const roomsToDelete = [...fk.rooms];
      roomsToDelete.forEach(room => removeRoom(state, {idx, room}));
      removeListOfRequirements(state, fk.requirements);
      state.functionConfigurations.splice(idx, 1);
    },
    setFunctionKindAsMain(state:State, idx:number) {
      const tmp = state.functionConfigurations[0];
      state.functionConfigurations[0] = state.functionConfigurations[idx];
      state.functionConfigurations[idx] = tmp;
    },
    toggleFunctionKind(state:State, idx: number) {
      state.functionConfigurations[idx].show = ! state.functionConfigurations[idx].show;
    },
    addRoom(state:State, {idx, room}:{idx:number, room:IUsedRoom}) {
      const id = state.helper.nextRoomId ++;
      const newRoom = new UsedRoom(id, room.name, room.code, room.functionKind);
      state.roomById[id] = newRoom;
      state.roomIdToIdx[id] = idx;
      state.functionConfigurations[idx].rooms.push(newRoom);
    },
    setRoomFlag(state:State, {idx, room, name, value}) {
      if (idx < 0 || idx >= state.functionConfigurations.length) throw Error(`functionConfiguration idx '${idx}'.`);
      const [roomHere] = state.functionConfigurations[idx].rooms.filter(listedRoom => listedRoom.id === room.id);
      roomHere.setParam(name, value);
    },
    removeRoom,
    addConnectionToExterior(state:State, {idx, fromId, toId}:{idx: number, fromId:number, toId:number}) {
      if (toId != EExteriorType.AIR && toId != EExteriorType.GROUND) {
        console.error("addConnectionToExterior wrong toId ", toId);
        return;
      }

      const connection = new Connection(fromId, toId);
      state.connections.push(connection);
      const exteriorType = toId == EExteriorType.AIR? 'air' : 'ground';
      const [room] = state.functionConfigurations[idx].rooms.filter(listedRoom => listedRoom.id === fromId);
      room.setParam(exteriorType, true);
      console.log(connection);
    },
    removeConnectionToExterior(state:State, {fromId, toId}:{fromId:number, toId:number}) {
      if (toId != EExteriorType.AIR && toId != EExteriorType.GROUND) {
        console.error("removeConnectionToExterior wrong toId ", toId);
        return;
      }
      const [connection] = state.connections.filter(conn => conn.toId == toId && conn.fromId == fromId);
      state.connections = state.connections.filter(conn => conn.toId != toId || conn.fromId != fromId);
      if (connection.wall   ) removeListOfRequirements(state, connection.wallRequirements);
      if (connection.ceiling) removeListOfRequirements(state, connection.ceilingRequirements);
      if (connection.floor  ) removeListOfRequirements(state, connection.floorRequirements);
      if (connection.opening) removeListOfRequirements(state, connection.openingRequirements);
      if (connection.virtual) removeListOfRequirements(state, connection.virtualRequirements);
    },
    addConnection(state:State, connection) {
      state.connections.push(connection);
    },
    removeConnection(state:State, {fromId, toId}) {
      const [connection] = state.connections.filter(conn => conn.toId == toId && conn.fromId == fromId);
      state.connections = state.connections.filter(conn => conn.toId != toId || conn.fromId != fromId);
      removeListOfRequirements(state, connection.requirements);
    },
    updateConnection(state:State, item:IConnection) {
      const [connection] = state.connections.filter(
        con => con.fromId == item.fromId && con.toId == item.toId ||con.fromId == item.toId && con.toId == item.fromId
      );
      if (connection.wall    && !item.wall   ) removeListOfRequirements(state, connection.wallRequirements);
      if (connection.ceiling && !item.ceiling) removeListOfRequirements(state, connection.ceilingRequirements);
      if (connection.floor   && !item.floor  ) removeListOfRequirements(state, connection.floorRequirements);
      if (connection.opening && !item.opening) removeListOfRequirements(state, connection.openingRequirements);
      if (connection.virtual && !item.virtual) removeListOfRequirements(state, connection.virtualRequirements);
      connection.updateData(item);
    },
    createRequirement,
    setRequirementParameter,
    setRequirementParameters,
    assignRequirement,
    removeRequirement,
    updateStateAfterBatchAssignment,
    ...batchAssignment.mutations,
  },
  actions: {
    addLocationRequirement(context: ActionContext<State, IState>, {requirement}) {
      addRequirementUniversal(context, {
        requirement: requirement as Requirement,
        location: {level: ERequirementLevel.LOCATION} as IEntityLocation
      });
    },
    addBuildingRequirement(context: ActionContext<State, IState>, {requirement}) {
      addRequirementUniversal(context, {
        requirement: requirement as Requirement,
        location: {level: ERequirementLevel.BUILDING} as IEntityLocation
      });
    },
    addFunctionKindRequirement(context: ActionContext<State, IState>, {idx, requirement}) {
      addRequirementUniversal(context, {
        requirement: requirement as Requirement,
        location: {level: ERequirementLevel.FUNCTION_TYPE, idx} as IEntityLocation
      });
    },
    addRoomRequirement(context: ActionContext<State, IState>, {idx, room, requirement}) {
      addRequirementUniversal(context, {
        requirement: requirement as Requirement,
        location: {
          level: ERequirementLevel.ROOM,
          idx: idx,
          roomId: room.id
        } as IEntityLocation
      });
    },
    addConnectionRequirement(context: ActionContext<State, IState>, {from, to, requirement, connType}) {
      addRequirementUniversal(context, {
        requirement: requirement as Requirement,
        location: {
          level: ERequirementLevel.CONNECTION,
          fromId: from,
          toId: to,
          connType: connType
        } as IEntityLocation
      });
    },
    addRequirementUniversal,
    addRequirementBatch,
    createFunctionKind(context: ActionContext<State, IState>) {
      const functionKindId = 1;
      const functionKindName = context.getters['definitions/functionKindName'](functionKindId);
      context.commit('addFunctionKind', {functionKindId, functionKindName});
    },
    changeFunctionKind(context: ActionContext<State, IState>, {idx, functionKindId}) {
      const functionKindName = context.getters['definitions/functionKindName'](functionKindId);
      context.commit('setFunctionKind', {idx, functionKindId, functionKindName});
    },
    ...batchAssignment.actions,
  },
};

export default configuration;
