import { ActionContext, Module } from "vuex";
import ConfigurationService from "./api/configuration.service";
import { IConfigurationState } from "./configuration/configuration";
import { ICustomRequirement } from "./configuration/customRequirements";
import { EActionStatus, IState } from "./index";

const state = {
  isOpened: false,
  _id: "",
  author: "",
  name: "",
  listOfPC: [] as Record<string, any>[]
};

type State = typeof state;

const PC:Module<State, IState> = {
  namespaced: true,
  state: ():State => state,
  getters: {
    openedPC: (state:State) => {
      if (state.isOpened) return state;
      return {};
    },
    isPCOpened: (state:State) => state.isOpened,
    listOfPC: (state:State) => state.listOfPC,
  },
  mutations: {
    openPC: (state:State, template:any) => { 
      state._id = template._id;
      state.author = template.author;
      state.name = template.name;
      state.isOpened = true;
    },
    closePC: (state:State) => { 
      state._id = "";
      state.author = "";
      state.name = "";
      state.isOpened = false;
    },
    setListOfPC: (state:State, value: Record<string, any>[]) => { 
      state.listOfPC = value;
    },
    addPC: (state:State, value: any) => {
      state.listOfPC.push(value);
    },
  },
  actions: {

    async LIST_PC(context: ActionContext<State, IState>) {      
      try {
        const projectId = context.rootGetters['project/projectId'];
        console.log(`projectId '${projectId}'`);
        
        const configurationService = new ConfigurationService(projectId);
        const list = await configurationService.getAllConfigurations();
        context.commit('setListOfPC', list);
      } catch {
        context.commit('setListOfPC', []);
      }
    },
    

    async NEW_PC_FROM_TEMPLATE(context: ActionContext<State, IState>, {name, description, templateId}: {name: string, description: string, templateId: string}) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      
      try {
        const projectId = context.rootGetters['project/projectId'];       
        const configurationService = new ConfigurationService(projectId);
        const pc = await configurationService.createConfigurationFromTemplate(templateId, name, description);
        return pc;
        //context.commit('addPC', conf);
        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },


    async NEW_PC(context: ActionContext<State, IState>, {name, description}: {name: string, description: string}) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      
      try {
        const projectId = context.rootGetters['project/projectId'];
        console.log(`projectId '${projectId}'`);
        
        const configurationService = new ConfigurationService(projectId);
        const pc = await configurationService.createConfiguration(name, description);
        return pc;
        //context.commit('addPC', conf);
        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

    async RENAME_PC(context: ActionContext<State, IState>, data: {_id:string, name:string}){
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      try {
        const projectId = context.rootGetters['project/projectId'];
        const configurationService = new ConfigurationService(projectId);
        
        await configurationService.renameConfiguration(data._id, data.name);
        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

    async SET_IS_TEMPLATE(context: ActionContext<State, IState>, {id, isTemplate}:{id: string, isTemplate: boolean}) {
      await context.dispatch('UPDATE_UNOPENED_PC', {id, asTemplate: true, isTemplate});
      await context.dispatch('LIST_PC');
    },

    async CHANGE_DESCRIPTION_PC(context: ActionContext<State, IState>, {id, description}:{id: string, description: boolean}) {
      await context.dispatch('UPDATE_UNOPENED_PC', {id: id, description});
      await context.dispatch('LIST_PC');
    },

    async UPDATE_UNOPENED_PC(
      context: ActionContext<State, IState>, 
      options: {id: string, asTemplate?: boolean, isTemplate?:boolean, description?: string, share?: string[]}
    ) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      
      const data = {
        _id: options.id,
        isTemplate: options.isTemplate,
        description: options.description,
        share: options.share
      };
      
      try {
        console.log("UPDATE_UNOPENED_PC", data);
        const projectId = context.rootGetters['project/projectId'];
        const configurationService = new ConfigurationService(projectId);
        
        await configurationService.updateConfiguration(data._id, data);

        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

    async SAVE_PC(context: ActionContext<State, IState>) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      const rootState:any = context.rootState;
      
      const configuration = rootState.configuration;
      const configurationClone = JSON.parse(JSON.stringify(configuration));      
            
      const { _id } = context.getters['openedPC'];
      
      const data = {
        _id,
      //  name,
      //  author,
        configuration:configurationClone,
      };
      
      try {
        console.log("save configuration", data);
        const projectId = context.rootGetters['project/projectId'];
        const configurationService = new ConfigurationService(projectId);
        
        await configurationService.updateConfiguration(data._id, data);

        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

    async DUPLICATE_PC(context: ActionContext<State, IState>, data: {id:string, name:string}) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      try {
        const projectId = context.rootGetters['project/projectId'];
        const configurationService = new ConfigurationService(projectId);

        await configurationService.duplicateConfiguration(data.id, data.name);

        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

    async OPEN_PC(context: ActionContext<State, IState>, id:string) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      try {
        const projectId = context.rootGetters['project/projectId'];
        const configurationService = new ConfigurationService(projectId);

        const savedConfiguration:any = await configurationService.getConfigurationById(id);

        const configuration = savedConfiguration.configuration as IConfigurationState;
        
        let customRequirements:ICustomRequirement = {
          customRequirements: [],
          lastId: 100000,
        }

        if (configuration) {
          configuration.functionConfigurations.forEach(fc => {
            fc.functionKindName = context.rootGetters['definitions/functionKindName'](fc.functionKindId);
          })
          
          if (savedConfiguration.configuration.customRequirements) {
            customRequirements = savedConfiguration.configuration.customRequirements;
          }
        } else {
          savedConfiguration.configuration = {};
        }
        savedConfiguration.configuration.projectName = savedConfiguration.name;
        
        console.log(savedConfiguration);
        context.commit('configuration/set', savedConfiguration.configuration, {root: true});
        context.dispatch('configuration/customRequirements/loadCustumRequirements', customRequirements, {root: true});
        context.commit('openPC', savedConfiguration);
        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch (e) {
        console.error(e);
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

    async REMOVE_PC(context: ActionContext<State, IState>, id:string) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      try {
        const projectId = context.rootGetters['project/projectId'];
        const configurationService = new ConfigurationService(projectId);
        
        await configurationService.removeConfiguration(id);
        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

  },
};

export default PC;
