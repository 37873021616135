<template>
  <div>
    <Dialog
      :heading="'NASTAVENÍ POŽADAVKU '+ requirement.getParam('name')"
      class="requirement-setting-dialog"
      :show="showDialog"
      @close="showDialog = false"
    >
      <div class="flex-column" v-if="requirement.getParam('name')">
        <p class="description">
          Nastavení požadavku číslo {{requirement.getParam('id')}} se jménem {{requirement.getParam('name')}}.
        </p>
        
        <div class="flex-inline">
          <span class="param">způsob vyhodnocení</span>
          <DropdownInput
            :value="requirement.getParam('evaluation')"
            @input="setEvaluation"
            :options="requirementEvaluationOptions" 
            small 
          />
        </div>
        
        <div v-show="hasRequiredValue1" class="flex-inline">
          <span class="param">požadovaná hodnota 
            <span v-show="isOperatorTernary(requirement.getParam('operator'))">(spodní mez)</span> 
            <i v-if="requirement.getParam('unit')" v-text="'[ '+requirement.getParam('unit')+' ]'"></i>
          </span>

          <TextInput 
            :disabled="requirement.data.operator == ERequirementOperator.EQUAL"
            v-if="isString(requirement.getParam('requiredValue1'))"
            :value="requirement.getParam('requiredValue1')" 
            @input="setRequiredValue1" 
            :width="266"
            small
          />
          <NumericInput 
            v-else
            :value="requirement.getParam('requiredValue1')" 
            @input="setRequiredValue1" 
            :width="266"
            small
          />
          
        </div>

        <div v-show="isOperatorTernary(requirement.getParam('operator'))" class="flex-inline">
          <span class="param">požadovaná hodnota (horní mez) <i v-if="requirement.getParam('unit')" v-text="'[ '+requirement.getParam('unit')+' ]'"></i></span>
          
          <NumericInput 
            :value="requirement.getParam('requiredValue2')" 
            @input="setRequiredValue2" 
            small
          />
        </div>

        <Section name="">
          <div class="dialog-buttons flex-row">
            <button @click="clickOk" class="primary">OK</button>
          </div>
        </Section>
     

      </div>
    </Dialog>
  </div>
</template>

<script >
import { mapGetters, mapMutations } from 'vuex'
import Dialog from '../components/Dialog.vue'
//import CheckboxInput from '../components/fundamentals/CheckboxInput.vue'
import {
  writeLevel,
  writeType,
  writeRule,
  typeOptions,
  isOperatorTernary,
  ERequirementOperator,
  requirementEvaluationOptions
} from '../../@types/definitions/requirements'
import DropdownInput from '../components/fundamentals/DropdownInput.vue'
import TextInput from '../components/fundamentals/TextInput.vue'
import NumericInput from '../components/fundamentals/NumericInput.vue'
import Section from '../components/Section.vue'

export default {
  props: {},
  components: { Dialog, /* CheckboxInput, */ DropdownInput, /* TextInput, */ NumericInput, Section, TextInput },
  computed: {
    ...mapGetters('configuration', [
      'getRequirement',
    ]),
    requirement() {
      if (this.instanceId) {
        console.log(this.instanceId);
        console.log(this.getRequirement(this.instanceId));
        return this.getRequirement(this.instanceId) ||  {getParam: ()=> ''};
      }
      return {
        getParam: ()=> '',
      };
    }
  },
  methods: {
    ...mapMutations('configuration', [
      'setRequirementParameter',
    ]),
    openRequirement(req) {
      console.log(req);
      this.instanceId = req.instanceId;
      this.showDialog = true;
    },
    setEvaluation(value) {
      this.setRequirementParameter({parameter: 'evaluation', value, instanceId: this.instanceId})
      //this.$emit('set-requirement-parameter', );
    },
    setRequiredValue1(value) {
      this.setRequirementParameter({parameter: 'requiredValue1', value, instanceId: this.instanceId});
      //this.$emit('set-requirement-parameter', {parameter: 'requiredValue1', value, instanceId: this.instanceId});
    },
    setRequiredValue2(value) {
      this.setRequirementParameter({parameter: 'requiredValue2', value, instanceId: this.instanceId});
      //this.$emit('set-requirement-parameter', {parameter: 'requiredValue2', value, instanceId: this.instanceId});
    },
    hasRequiredValue1() {
      return true;
    },
    isString(data) {
      return typeof data === 'string' || data instanceof String;
    },
    clickOk() {
      //this.$emit('input', this.req);
      this.showDialog = false;
    },
    writeLevel,
    writeType,
    writeRule,
    isOperatorTernary,
  },
  data() {
    return {
      showDialog: false,
      instanceId: 0,
      typeOptions,
      ERequirementOperator,
      requirementEvaluationOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.requirement-setting-dialog {
  .flex-inline {
    align-items: baseline;
    gap: 50px;
    padding: 10px 0;

    span.param {
      width: 320px;
    }
  }
  .dialog-buttons {
    gap: 10px;
  }
}
</style>
