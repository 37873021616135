<template>
  <div class="calculator">
    <TemperatureStability ref="TemperatureStability" :desired-calculator="name" @set-requirement="processChanges($event)" />
    <ImposedLoads ref="ImposedLoads" :desired-calculator="name" @set-requirement="processChanges($event)" />
    <RadonVolumeActivity ref="RadonVolumeActivity" :desiredCalculator="name" @set-requirement="processChanges($event)" />
    <FloorContactTemperatureDrop ref="FloorContactTemperatureDrop" :desiredCalculator="name" @set-requirement="processChanges($event)" />
    <ThermalTransmittance ref="ThermalTransmittance"  :desiredCalculator="name" @set-requirement="processChanges($event)" />
    <QuantityWC ref="QuantityWC" :owner="owner" :desiredCalculator="name" @set-requirement="processChanges($event)" />
    <ParkingSpacesReductionCoef ref="ParkingSpacesReductionCoef" :owner="owner" :desiredCalculator="name" @set-requirement="processChanges($event)" />
    <ParkingSpacesRegisteredCarsCoef ref="ParkingSpacesRegisteredCarsCoef" :owner="owner" :desiredCalculator="name" @set-requirement="processChanges($event)" />
  </div>
</template>

<script>
import FloorContactTemperatureDrop from './FloorContactTemperatureDrop.vue'
import ImposedLoads from './ImposedLoads.vue'
import ParkingSpacesReductionCoef from './ParkingSpacesReductionCoef.vue'
import ParkingSpacesRegisteredCarsCoef from './ParkingSpacesRegisteredCarsCoef.vue'
import QuantityWC from './QuantityWC.vue'
import RadonVolumeActivity from './RadonVolumeActivity.vue'
import TemperatureStability from './TemperatureStability.vue'
import ThermalTransmittance from './ThermalTransmittance.vue'

export default {
  components: { TemperatureStability, ImposedLoads, RadonVolumeActivity, FloorContactTemperatureDrop, ThermalTransmittance, QuantityWC, ParkingSpacesReductionCoef, ParkingSpacesRegisteredCarsCoef },
  props: {
    name: {type: String, required: true},
  },
  mounted() {
    //this.openCalculator();
  },
  data() {
    return {
    }
  },
  methods: {
    openCalculator() {
      console.log(this.$refs);
      this.$refs[this.name].openCalc();
    },
    processChanges(changes) {
      this.$emit('set-requirement', changes);
    },
  },
}
</script>

<style>

</style>
