<template>

  <teleport to="#topper">
    <ProcessNavbar />
  </teleport>

  <router-view></router-view>

  <div class="flex-row next-prev">
      <button @click="goPrev" v-if="hasPrev()" class="primary prev">Předchozí</button>
      <button @click="goNext" v-if="hasNext()" class="primary next">Další</button>
  </div>

  <connection-to-exterior-dialog />
  <room-requirements-opener />
  <calculator-dialog />
  <requirement-note-dialog />
  <show-article-dialog />

</template>

<script>
import ProcessNavbar from './ProcessNavbar.vue';

const order = [
  '/PC/architecture', 
  '/PC/requirements', 
  '/PC/checklist',
];

import CalculatorDialog from '../calculators/CalculatorDialog.vue'
import ShowArticleDialog from '../components/showArticleDialog.vue'
import ConnectionToExteriorDialog from './ConnectionToExteriorDialog.vue'
import RequirementNoteDialog from './RequirementNoteDialog.vue'
import RoomRequirementsOpener from './RoomRequirementsOpener.vue'

export default {
  components: { 
    ProcessNavbar,
    RequirementNoteDialog, 
    ShowArticleDialog, 
    CalculatorDialog, 
    RoomRequirementsOpener, 
    ConnectionToExteriorDialog, 
  },
  computed: {
    currentPath() {
      return this.$route.path;
    }
  },
created() {
      this.initGraph();
  },
  data() {
    return {
      order: order,
      graph: {},
    }
  },
  methods: {
    initGraph() {
      for (let i = 0; i < order.length; i += 1) {
        const node = order[i];
        let prev = null, next = null;
        if (i > 0) prev = order[i-1];
        if (i+1 < order.length) next = order[i+1];
        this.graph[node] = {
          prev, next
        }
      }
    },
    hasNext() {
      return (this.currentPath in this.graph) 
          && this.graph[this.currentPath].next;
    },
    hasPrev() {
      return (this.currentPath in this.graph) 
          && this.graph[this.currentPath].prev;
    },
    goNext() {
      if (!this.hasNext()) return;
      this.$router.push(this.graph[this.currentPath].next);
    },
    goPrev() {
      if (!this.hasPrev()) return;
      this.$router.push(this.graph[this.currentPath].prev);
    }
  }
}
</script>

<style lang="scss">

</style>
