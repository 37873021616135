<template>
  <div>
    <Dialog :heading="heading" class="note" v-if="show" :show="true" :width="600" @close="show = false">
      <textarea cols="60" rows="10" v-text="requirement.note" @change="onChange"></textarea>
      <div class="controls">
        <button @click="show = false" class="black-btn">OK</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Dialog from '../components/Dialog.vue'
export default {
  components: { Dialog },
  data() {
    return {
      show: false,
      requirement: {
        name: '',
        instanceId: 0,
      }
    }
  },
  mounted() {
    console.log('requirementNoteDialog mounted');
    this.eventBus.on('open-note-dialog', requirement => {
      console.log('requirementNoteDialog event');
      this.requirement = requirement;
      this.show = true;
    });
  },
  unmounted() {
    this.eventBus.off('open-note-dialog');
  },
  methods: {
    ...mapMutations('configuration', [
      'setRequirementParameter'
    ]),
    onChange(event) {
      const value = event.target.value;
      this.setRequirementParameter({
        parameter: 'note', 
        value, 
        instanceId: this.requirement.instanceId
      });
    }
  },
  computed: {
    heading() {
      if (this.requirement.name) {
        return `Důkaz splnění pořažadavku ${this.requirement.name}`;
      }
      return 'Důkaz';
    }
  },
}
</script>

<style lang="scss" scoped>
.note {
  display: flex;
  justify-content: center;
}

.controls {
  display: flex;
  justify-content: end;
  gap: 10px;
}

</style>
