import { createApp } from 'vue'
import mitt from 'mitt'
import store from './store'
import router from './router'
import App from './App.vue'
import { formatFloat, formatInteger } from './GUI/numericFormater'
// import { composeApartments, composeGroundPlan } from './views/concept-calculators/groundPlan'
// import { testIt } from './calculations/calculationSystem'

// const groundPlanCS = composeGroundPlan()
// const aparmentsCS = composeApartments(groundPlanCS);

const app = createApp(App);
app.use(store);
app.use(router);

router.replace({ path: '/project-manager' })

// bus emitter
app.config.globalProperties.eventBus = mitt();
app.config.globalProperties.formatFloat = formatFloat;
app.config.globalProperties.formatInteger = formatInteger;

app.mount('#app')
