import { IProject } from '../project';
import api from '.';

interface CreateProjectPayload {
  name: string;
  // add other properties here
}

interface UpdateProjectPayload {
  name?: string;
  address?: string;
  // add other properties here
}

class ProjectService {
  private apiBaseUrl = '/api/projects';

  async getAllProjects(filters: Record<string, string>): Promise<IProject[]> {
    const query = new URLSearchParams(filters);
    return api.get<IProject[]>(`${this.apiBaseUrl}/?${query.toString()}`);
  }

  async createProject(payload: CreateProjectPayload): Promise<IProject> {
    return api.post<IProject>(this.apiBaseUrl, payload);
  }

  async getProjectById(id: string): Promise<IProject> {
    return api.get<IProject>(`${this.apiBaseUrl}/${id}`);
  }

  async updateProject(id: string, payload: UpdateProjectPayload): Promise<IProject> {
    return api.put<IProject>(`${this.apiBaseUrl}/${id}`, payload);
  }

  async deleteProject(id: string): Promise<void> {
    await api.delete(`${this.apiBaseUrl}/${id}`);
  }
}


export default new ProjectService();
