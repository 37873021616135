<template>
  <div class="column left-menu">
    <div class="paddinged">
      <NewProjectDialog />
      <div class="search">
        <h3>Vyhledávání projektu</h3>
        <input type="text" v-model="nameFilter">
      </div>
      <h3>Filtrovat projekty</h3>
    </div>

    <div class="flex-column side-filter">
      <div :class="{active: isOwningActive('all')}" class="flex-row filter-item" @click="setProjectOwning('all')">
        <i class="fa fa-home"></i>
        <div class="item">Všechny projekty</div>
      </div>
    </div>
    
    <hr>
    <div class="flex-column side-filter">          
      <div :class="{active: isStageActive('CC')}" class="flex-row filter-item" @click="toggleStage('CC')">
        <i class="fas fa-calculator"></i>
        <div class="item">Koncepční kalkulačky</div>
      </div>

      <div :class="{active: isStageActive('PC')}" class="flex-row filter-item" @click="toggleStage('PC')">
        <i class="fas fa-pencil-ruler"></i>
        <div class="item">Projekční fáze</div>
      </div>

      <div :class="{active: isStageActive('M3D')}" class="flex-row filter-item" @click="toggleStage('M3D')">
        <i class="fas fa-desktop"></i>
        <div class="item">3D model</div>
      </div>
    </div>

    <hr>
    <div class="flex-column side-filter">
      <div :class="{active: isOwningActive('my')}" class="flex-row filter-item" @click="setProjectOwning('my')">
        <i class="fas fa-user"></i>
        <div class="item">Moje projekty</div>
      </div>

      <div :class="{active: isOwningActive('forMe')}" class="flex-row filter-item"  @click="setProjectOwning('forMe')">
        <i class="fas fa-user-friends"></i>
        <div class="item">Sdílené se mnou</div>
      </div>
    </div>
  </div>
</template>

<script>
import NewProjectDialog from './NewProjectDialog.vue';

export default {
  emits: ['filterUpdated'],
  /* props: {
    filters: {type: Object, default: {}},
  }, */
    methods: {
        pushFilter() {
          this.$emit('filterUpdated', this.filter);
        },
        setProjectOwning(owning) {
          this.filter.owning = owning;
          this.pushFilter();
        },
        isOwningActive(owning) {
          return this.filter.owning === owning;
        },
        toggleStage(stage) {
          if (stage == 'CC' || stage == 'PC' || stage == 'M3D') {
            this.filter[stage] = !this.filter[stage];
            this.pushFilter();
          }
        },
        isStageActive(stage) {
          if (stage == 'CC' || stage == 'PC' || stage == 'M3D') {
            return this.filter[stage];
          }
          return false;
        },
    },
    computed: {
      nameFilter: {
        get() {
          return this.filter.name;
        },
        set(value) {
          this.filter.name = value;
          this.pushFilter();
        }
      }
    },
    data() {
      return {
        filter: {
          name: '',
          owning: 'all',
          CC: false,
          PC: false,
          M3D: false,
        }
      };
    },
    components: { NewProjectDialog }
}
</script>

<style lang="scss">
.left-menu {
  //width: 300px;
  .paddinged {
    padding: 30px 30px 0 30px;

  }
  .search {
    padding: 20px 0;
    input[type="text"] {
      width: calc(100%);
    }
  }

  .filter-item {
    padding: 5px 0;
    i {
      padding: 5px 0 5px 52px ;
    }
    div.item {
      flex-grow: 1;
      text-align: center;
    }
    &:hover,
    &.active {
      background: lightgrey;
    }
  }
}
</style>
