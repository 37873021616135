<template>
  <div class="edit-connection" v-if="show">
    <h1 class="new-connection">nastavení vazby</h1>
    <div class="connection-settings-interace flex-row">
      <div class="node from">
        <label>vazba vedoucí z</label>
        <div v-if="connection.fromId" class="select-msg">
          <span v-text="displayRoomName(connection.fromId)"></span> 
          
        </div>
        <div v-else class="select-msg">
          Zaklikněte myší místnost, <strong>ze</strong> které povede vazba.
        </div>
      </div>
      <div class="node to">
        <label>vazba vedoucí do</label>
        <div v-if="!connection.fromId && !connection.toId" class="select-msg"></div>
        <div v-else-if="connection.toId" class="select-msg">
          <span v-text="displayRoomName(connection.toId)"></span> 
          
        </div>
        <div v-else class="select-msg">
          Zaklikněte myší místnost, <strong>do</strong> které povede vazba.
        </div>
      </div>
      <div  class="node flags">
        <label>vazba konstrukcí</label>
        <div class="flex-row">
          <CheckboxInput  :disabled="isRequirementsMode" :value="connection.wall" @input="setConnection('wall', $event)" :width="200" >stěnou</CheckboxInput>
          <RequirementsDialog v-if="isRequirementsMode" v-show="connection.wall"
            :activeRequirements="connection.wallRequirements"
            @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'wall'})"
            level="connection"
            activator="button"
            :heading-identificator="headingIdentificator('stěnou')"
          />
        </div>
        <div class="flex-row">
          <CheckboxInput  :disabled="isRequirementsMode" :value="connection.ceiling" @input="setConnection('ceiling', $event)" :width="200">stropem</CheckboxInput>
          <RequirementsDialog v-if="isRequirementsMode" v-show="connection.ceiling"
            :activeRequirements="connection.ceilingRequirements"
            @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'ceiling'})"
            level="connection"
            activator="button"
            :heading-identificator="headingIdentificator('stropem')"
          />
        </div>
        <div class="flex-row">
          <CheckboxInput  :disabled="isRequirementsMode" :value="connection.floor" @input="setConnection('floor', $event)" :width="200">podlahou</CheckboxInput>
          <RequirementsDialog v-if="isRequirementsMode" v-show="connection.floor"
            :activeRequirements="connection.floorRequirements"
            @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'floor'})"
            level="connection"
            activator="button"
            :heading-identificator="headingIdentificator('podlahou')"
          />
        </div>
        <div class="flex-row">
          <CheckboxInput  :disabled="isRequirementsMode" :value="connection.opening" @input="setConnection('opening', $event)" :width="200" >výplní otvoru</CheckboxInput>
          <RequirementsDialog v-if="isRequirementsMode"  v-show="connection.opening"
            :activeRequirements="connection.openingRequirements"
            @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'opening'})"
            level="connection"
            activator="button"
            :heading-identificator="headingIdentificator('výplní otvoru')"
          />
        </div>
        <div class="flex-row">
          <CheckboxInput  :disabled="isRequirementsMode" :value="connection.virtual" @input="setConnection('virtual', $event)" :width="200" >bez konstrukce</CheckboxInput>
          <RequirementsDialog v-if="isRequirementsMode"  v-show="connection.virtual"
            :activeRequirements="connection.virtualRequirements"
            @add-requirement="addConnectionRequirement({from: connection.fromId, to: connection.toId, requirement: $event, connType: 'virtual'})"
            level="connection"
            activator="button"
            :heading-identificator="headingIdentificator('bez konstrukce')"
          />
        </div>
      </div>
    </div>
    <div class="buttons flex-row">
      <button class="primary" @click="removeAction()" :disabled="isRequirementsMode">SMAZAT</button>
      <button class="primary" @click="close()">ZAVŘÍT</button>
    </div>
  </div>
</template>

<script>
import { ESchemaMode } from '@/@types/definitions/mode';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CheckboxInput from '../components/fundamentals/CheckboxInput.vue';
import RequirementsDialog from './RequirementsDialog.vue';

export default {
  components: { CheckboxInput, RequirementsDialog },
  props: {
    mode: {
      type: Number,
      default: ESchemaMode.EDIT,
    },
  },
  data() {
    return {
      show: false,
      fromId: 0,
      toId: 0,
    };
  },
  methods: {
    ...mapMutations("configuration", [
      'addConnection',
      'updateConnection',
      'removeConnection',
    ]),
    ...mapActions("configuration", [
      'addConnectionRequirement',
    ]),
    close() {
      this.show = false;
      this.$emit('unselect');
      this.sendRefresh();
    },
    openConnection(fromId, toId) {
      this.fromId = fromId,
      this.toId = toId;
      if (this.connection) {
        this.show = true;
      }
      this.sendRefresh();
    },
    setConnection(what, value) {
      const clone = {...this.connection}
      clone[what] = value;
      this.updateAction(clone);
    },
    updateAction(clone) {
      console.log("updateConnecitonAction", clone.fromId, clone.toId);
      if (clone.fromId && clone.toId) {
        this.updateConnection(clone); 
      }
    },
    removeAction() {
      const message = "Chcete smazat vazbu?";
      if (confirm(message)) {
        this.removeConnection({
          fromId: this.fromId, 
          toId: this.toId
        });
        this.close();
      }
    },
    displayRoomName(id) {
      const room = this.getRoomById(id);
      if (room) return `${room.name} (${room.id})`;
      return `nenalezená místnost - ${room.id}`;
    },
    sendRefresh() {
      setTimeout(()=> this.$emit('refresh'), 10);
    },
    /*setConnectionRequirementsAction(requirements) {
      this.connection.requirements = requirements;
    }*/
    headingIdentificator(constructionName) {
      const fromRoomName = this.getRoomById(this.connection.fromId).name;
      const toRoomName = this.getRoomById(this.connection.toId).name;
      const fromIdx = this.getIdxFromRoomId(this.connection.fromId);
      const toIdx = this.getIdxFromRoomId(this.connection.toId);

      const fromFunctionKindId = this.functionConfigurations[fromIdx].functionKindId;
      const toFunctionKindId = this.functionConfigurations[toIdx].functionKindId;
      return `${constructionName}: ${fromRoomName} (${this.functionKindName(fromFunctionKindId)}) ➞ ${toRoomName} (${this.functionKindName(toFunctionKindId)})`;
    },
  },
  
  computed: {
    ...mapGetters('definitions', [
        'functionKinds',
        'rooms',
        'requirements',
    ]),
    ...mapGetters('configuration', [
        'getRoomById',
        'getIdxFromRoomId',
        'connections',
        'getConnection',
    ]),
    connection() {
      return this.getConnection(this.fromId, this.toId);
    },
    ...mapGetters('definitions', [
      'functionKindName',
    ]),
    ...mapGetters('configuration', [
      'functionConfigurations',
    ]),
    isEditMode() {
      return this.mode == ESchemaMode.EDIT;
    },
    isRequirementsMode() {
      return this.mode == ESchemaMode.REQUIREMENTS;
    },
  },
}
</script>

<style lang="scss" scoped>
.error {
  padding: 0px 10px 10px 10px;
  margin: 0 auto;
  color: red;
  font-weight: 600;
  text-align: center;
}

.edit-connection {
  background: $general-background;
    h1.new-connection {
      margin: 0;
      padding: 5px;
    }
    .node {
      flex-grow: 1;
      flex-basis: 0;
      &:not(.flags) {
        flex-grow: 0.5;
      }
      &.flags {
        .checkbox {
          margin-top: 5px;
        }
      }
      margin: 10px;
      label {
        font-weight: bold;
      }
      .select-msg {
        background: $input-color;
        border-radius: 30px;
        text-align: center;
        line-height: 50px;
        height: 50px;
        padding: 5px;
      }
    }
    .buttons {
      display: flex;
      //justify-content: end;
      gap: 10px;
      padding-bottom: 10px;
    }
}
</style>
