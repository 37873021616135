<template>
<div>
  <div v-if="batchAssignmentLevel == 'location'">
    <button class="primary" style="width: unset;" @click="executeBatchAssignmentProxy()">Dokončit hromadné přiřazování požadavků</button>
  </div>
  <div v-if="batchAssignmentLevel == 'building'">
    <button class="primary" style="width: unset;" @click="executeBatchAssignmentProxy()">Dokončit hromadné přiřazování požadavků</button>
  </div>
  <div v-if="batchAssignmentLevel == 'function-type'">
    <h1>Umístění požadavku</h1>
    <div class="flex-row">
      <div class="col">
        <h3>
          Stromová struktura konfigurace objektu:
        </h3>

        <Tree-Structure :node="batchAssignmentTreeStructureFunctionKinds" :apply="batchAssignmentTreeStructureFunctionKindsCheck" :depth="0" :root="false" />
        
      </div>  
      <div class="col">
        <h3>
          Soupis vybraných vazeb (ze stromové struktury):
        </h3>
        <div v-if="batchAssignmentTreeStructureFunctionKinds.name">
          <ul>
            <li
              v-for="(functionConf, idx) in batchAssignmentTreeStructureFunctionKinds.children[0].children.filter(cf => cf.checked)"
              :key="idx"
            >
              <span v-text="functionConf.name"></span>
            </li>
          </ul>
        </div>
        <button class="primary" style="width: unset;" @click="executeBatchAssignmentProxy()">Dokončit hromadné přiřazování požadavků</button>
      </div>
    </div>
  </div>

  <div v-if="batchAssignmentLevel == 'room'">
    <h1>Umístění požadavku</h1>
    <div class="flex-row">
      <div class="col">
        <h3>
          Stromová struktura konfigurace objektu:
        </h3>

        <Tree-Structure :node="batchAssignmentTreeStructureRooms" :apply="batchAssignmentTreeStructureRoomsCheck" :depth="0" :root="false" />
        
      </div>  
      <div class="col">
        <h3>
          Soupis vybraných vazeb (ze stromové struktury):
        </h3>
        <div v-if="batchAssignmentTreeStructureRooms.name">
          <ul>
            <li
              v-for="(functionConf, idx) in batchAssignmentTreeStructureRooms.children[0].children.filter(cf => cf.children.some(room=>room.checked))"
              :key="idx"
            >
              <span v-text="functionConf.name"></span>
              <ul>
                <li 
                  class="" 
                  v-for="room in functionConf.children.filter(room=>room.checked)" 
                  :key="room.name" 
                  v-text="room.name">
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <button class="primary" style="width: unset;" @click="executeBatchAssignmentProxy()">Dokončit hromadné přiřazování požadavků</button>
      </div>
    </div>

  </div>
  <div v-if="batchAssignmentLevel == 'connection'">
    <h1>Umístění požadavku</h1>
    <div class="flex-row">
      <div class="col">
        <h3>
          Stromová struktura konfigurace objektu:
        </h3>

        <Tree-Structure :node="batchAssignmentTreeStructureConnections" :apply="batchAssignmentTreeStructureConnectionsCheck" :depth="0" :root="false" />
        
      </div>  
      <div class="col">
        <h3>
          Soupis vybraných vazeb (ze stromové struktury):
        </h3>
        <div v-if="batchAssignmentTreeStructureConnections.name">
          <ul>
            <li
              v-for="(connection, idx) in batchAssignmentSelectedConnectionsPreview"
              :key="idx"
            >
              Z {{ connection.from }} do {{ connection.to }} vazba {{connection.how}}
            </li>
          </ul>
        </div>
        <button class="primary" style="width: unset;" @click="executeBatchAssignmentProxy()">Dokončit hromadné přiřazování požadavků</button>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { levelOptions } from '../../../@types/definitions/requirements'
import TreeStructure from './TreeStructure.vue'

export default {
  components: {TreeStructure  },
  data() {
    return {
      levelOptions: levelOptions.filter(x => !['', 'function-unit', 'inapplicable'].includes(x.value))
    }
  },
  computed: {
    ...mapGetters('configuration', [
      'batchAssignmentLevel',
      'batchAssignmentTreeStructureFunctionKinds',
      'batchAssignmentTreeStructureRooms',
      'batchAssignmentTreeStructureConnections',
      'batchAssignmentSelectedConnectionsPreview',
    ]),
  },
  methods: {
    ...mapMutations('configuration', [
      'batchAssignmentTreeStructureFunctionKindsCheck',
      'batchAssignmentTreeStructureRoomsCheck',
      'batchAssignmentTreeStructureConnectionsCheck',
      
    ]),
    ...mapActions('configuration', [
      'executeBatchAssignmentProxy',
    ]),
    getConnectionsRecursive(node) {
      const res = [];
      if (node.location.level === 'connection'
        && node.checked) {
        res.push(node.location);
        return res;
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          const subres = this.getConnectionsRecursive(child);
          res.push(...subres);
        });
      }
      return res;
    },
  }
}
</script>

<style lang="scss" scoped>
.col {
  flex-grow: 1;
}
</style>
