<template>
  <div>
    <button class="primary" @click="dialog.show = true">
      přidat vlastní požadavek
    </button>
    <Dialog  class="custom-requirement" :show="dialog.show" @close="dialog.show = false" :width="940" >
      
        
      <table class="requirement-templates" v-if="customRequirements.length > 0" >
        <tr class="heading-row">
          <th colspan="5">Moje vlastní požadavky</th>
        </tr>
        <tr class="heading-row">
          <th>id</th>
          <th>název požadavku</th>
          <th>typ</th>
          <th>obor</th>
          <th>akce</th>
        </tr>
        <tr 
          v-for="(req, rid) in customRequirements"
          :key="rid"
          class="data-row"
        >
          <td>{{ req.id }}.</td>
          <td>{{ req.name }}</td>
          <td v-text="writeType(req)"></td>
          <td v-text="writeField(req)"></td>
          
          <td>
            <button 
              class="btn black-btn" 
              @click="submit(req)"
            >
              použít
            </button>
          </td>
        </tr>
      </table>
     
      
      <div class="param">
        <h4>název</h4> 
        <TextInput :value="requirement.name" @input="requirement.name = $event" :width="300" small/>
        <p class="error" v-if="errors.name">Název nesmí být prázdný</p>
      </div>

      <div class="param">
        <h4>typ</h4>
        <DropdownInput :value="requirement.type" @input="requirement.type = $event" :options="typeOptions" :width="300" small/>
      </div>

      <div class="param" v-if="requirement.type == ERequirementType.TECHNICAL">
        <h4>technický obor</h4>
        <DropdownInput 
          :value="requirement.technicalField" 
          @input="requirement.technicalField = $event" 
          :options="techincalFieldOptions"
          :width="300" small/>
      </div>

      <div class="param" v-if="requirement.type == ERequirementType.TYPOLOGICAL">
        <h4>typologický obor</h4>
        <DropdownInput 
          :value="requirement.typologicalField" 
          @input="requirement.typologicalField = $event" 
          :options="typologicalFieldOptions" :width="300" small/>
      </div>

      <div class="param">
        <h4>operátor</h4>
        <DropdownInput :value="requirement.operator" @input="requirement.operator = $event" :options="operatorOptions" :width="300" small/>
      </div>

      <div class="param" v-if="requirement.operator != ERequirementOperator.EQUAL">
        <h4>požadovaná hodnota <span v-show="isOperatorTernary(requirement.operator)">(spodní mez)</span></h4>
        <NumericInput :value="requirement.requiredValue1" @input="requirement.requiredValue1 = $event" :width="300" small/>
      </div>

      <div class="param" v-if="isOperatorTernary(requirement.operator)">
        <h4>požadovaná hodnota <span>(horní mez)</span></h4>
        <NumericInput :value="requirement.requiredValue2" @input="requirement.requiredValue2 = $event" :width="300" small/>
      </div>

      <div class="buttons">
        <button class="black-btn" @click="addRequirement()">přidat vlastní požadavek</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { 
  Requirement, 
  typeOptions, 
  operatorOptions, 
  isOperatorTernary,
  ERequirementType,
  ERequirementOperator,
  techincalFieldOptions,
  typologicalFieldOptions,
  writeType,
  writeField,
} from '../../@types/definitions/requirements';
import Dialog from '../components/Dialog.vue';
import DropdownInput from '../components/fundamentals/DropdownInput.vue';
import NumericInput from '../components/fundamentals/NumericInput.vue';
import TextInput from '../components/fundamentals/TextInput.vue';

export default {
  components: { Dialog, TextInput, DropdownInput, NumericInput },
  props: {
    level: String
  },
  methods: {
    addRequirement() {
      if (!this.requirement.name) {
        this.errors.name = true;
        return;
      }
      this.errors.name = false;

      if (this.requirement.operator == ERequirementOperator.EQUAL) {
        this.requirement.requiredValue1 = 'Ano';
      }

      if (this.requirement.type === ERequirementType.TECHNICAL) {
        this.requirement.typologicalField = 'nelze zařadit';
      } else if (this.requirement.type === ERequirementType.TYPOLOGICAL) {
        this.requirement.technicalField = 'nelze zařadit';
      } else {
        this.requirement.typologicalField = 'nelze zařadit';
        this.requirement.technicalField = 'nelze zařadit';
      }

      if (!isOperatorTernary(this.requirement.operator)) {
        this.requirement.requiredValue2 = null;
      }

      const requirement = Requirement.Create({
        name: this.requirement.name,
        level: this.level,
        type: this.requirement.type,
        technicalField: this.requirement.technicalField,
        typologicalField: this.requirement.typologicalField,
        operator: this.requirement.operator,
        watchedParameter: this.requirement.watchedParameter,
        unit: this.requirement.unit,
        requiredValue1: this.requirement.requiredValue1,
        requiredValue2: this.requirement.requiredValue2,
      })

      this.registerCustomRequirement(requirement);

      this.submit(requirement);
    },
    submit(requirement) {
      this.$emit('take-requirement', requirement);
      this.requirement = new Requirement();
      this.dialog.show = false;
    },
    isOperatorTernary,
    writeType,
    writeField,
    ...mapActions('configuration/customRequirements', [
      'registerCustomRequirement',
    ])
  },
  computed: {
    ...mapGetters('configuration/customRequirements', [
      'customRequirements',
    ]),
  },
  data() {
    return {
      requirement: new Requirement(),
      dialog: {
        show: false,
      },
      errors: {
        name: false,
      },
      typeOptions,
      techincalFieldOptions,
      typologicalFieldOptions,
      operatorOptions,
      ERequirementType,
      ERequirementOperator,
    }
  }
}
</script>

<style lang="scss">
.custom-requirement {
  .param {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: baseline;
  }

  table {
    padding-left: 20px;
    background-color: white;
    border: 1px solid black;
    width: 100%;
  }
}
</style>
