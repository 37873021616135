<template>
  <div>   
    <nav class="main-navbar">
      <div class="row">
        <div
          class="hamburger-button"
          :class="{ close: showMenu }"
          @click.stop="toggleMenu"
        >
          <div class="menu-line"></div>
          <div class="menu-line"></div>
          <div class="menu-line"></div>
        </div>
        <div class="rest">
          <h1 class="app-name">
            <span>OBECNÝ KONFIGURÁTOR STAVEB</span>
          </h1>
        </div>
        <div class="diagram">
          <SaveConfiguration v-if="isPCOpened" />
        </div>
        <div v-show="false" class="help">
          <i class="fas fa-question-circle"></i>
        </div>
        <router-link class="user-nav" v-show="refreshToken" to="/profile">
          <i class="fas fa-user"></i>
        </router-link>

        <div class="help">
          <i class="clickable fas fa-grip-horizontal"></i>
        </div>
        
      </div>
    </nav>
    <div class="menu-dropdown" v-show="refreshToken" :class="{ show: showMenu }">
      <div class="menu-list" >
        <router-link class="menu-item" to="/project-manager">Projekty</router-link>
        <router-link class="menu-item" to="/residential-apartments">KK bytové domy</router-link>
        <router-link class="menu-item" v-if="role === 'admin'" to="/users">Uživatelé</router-link>
        <router-link class="menu-item" v-if="role === 'admin'" to="/settings">Nastavení aplikace</router-link>
        <router-link class="menu-item" v-if="role === 'admin'" to="/articles">Odborné články</router-link>
        <router-link class="menu-item" v-if="role === 'admin'" to="/calculators">Kalkulačky</router-link>
        <router-link class="menu-item" v-if="role === 'admin'" to="/templates">Šablony</router-link>
        <router-link class="menu-item" v-if="role === 'admin'" to="/building-library">Napojení na Stavební knihovnu</router-link>
        <a class="menu-item" href="https://dek.cz/"
          >Dek</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import SaveConfiguration from "./projects/SaveConfiguration.vue";
export default {
  components: {
    SaveConfiguration
  },
  name: "TopNavbar",
  data() {
    return {
      showMenu: false,
    };
  },
  created() {
    document.addEventListener('click', this.hideMenu);
  },
  methods: {
    copyToClip(data) {
      const tempInput = document.createElement("input");
      tempInput.value = data;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    },
    hideMenu() {
      if (this.showMenu) {
        this.showMenu = false;
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    ...mapActions(["LOGOUT"])
  },
  computed: { 
    ...mapGetters('user',  ["refreshToken", 'role']),
    ...mapGetters('PC', ['isPCOpened']), 
  },
  watch: {
    $route() {
      this.showMenu = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$navbar-padding: 8px;
$top-menu-height: 40px;

.main-navbar {
      h1.app-name > span {
        //font: 500 16px/20px Roboto;
        font: normal normal bold 14px/35px Arial;
      }
    }

.main-navbar {
  background: $primary-color;
  height: $top-menu-height;
  margin: 0;

  .row {
    height: $top-menu-height;
    display: flex;

    .hamburger-button {
      margin: auto 23px;
      width: 18px;
      height: auto;
      cursor: pointer;
      .menu-line {
        width: 100%;
        height: 2px;
        margin: 0px 0 3px 0;
        background: white;
        transition: all 300ms ease-out;
      }

      &.close .menu-line:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }

      &.close .menu-line:nth-child(2) {
        height: 2px;
        opacity: 0;
      }

      &.close .menu-line:nth-child(3) {
        transform: translateY(-5px) rotate(-45deg);
      }
    }

    .rest {
      flex-grow: 1;
      text-align: center;
      align-self: center;
      h1 {
        //font-size: 1.33em;
        //font: 500 20px/26px Roboto;
        font: normal normal bold 26px/35px Noto Sans;
        letter-spacing: 0.42px;
        color: $input-color;
        margin: 0 0;
      }
    }

    .diagram,
    .help {
      align-self: center;
      margin-right: 19px;
      color: white;
    }
    .help > i {
      font-size: 19px;
    }
    .code {
      background-color: rgb(104, 101, 101);
      p {
        color: white;
      }
      padding: 8px;
      margin-bottom: 16px;
      .fa-copy {
        font-size: 1.2em;
        color: $primary-color;
        &:hover {
          color:lighten($color: $primary-color, $amount: 20%);
          cursor: pointer;
        }
      }
    }
    .logout,
    .diagram > i {
      display: none;
      font-size: 19px;
      &:hover {
        //text-shadow: 0 0 5px rgb(255, 210, 199);
        color: lighten($primary-color, 40%);
        cursor: pointer;
      }
      //transform: rotateZ(-30deg) translateY(5px);
    }

    a.user-nav > i {
      line-height: $top-menu-height;
      font-size: 19px;
      color: white;
      margin-right: 20px;
      &:hover {
        //text-shadow: 0 0 5px rgb(255, 210, 199);
        color: lighten($primary-color, 40%);
        cursor: pointer;
      }
      //transform: rotateZ(-30deg) translateY(5px);
    }
  }
}
div.menu-dropdown {
  transition: transform 200ms ease-out 0ms;
}
.menu-dropdown {
  position: absolute;
  padding: 12px;
  z-index: 100;
  background: $general-background;
  transform: translateX(-100%);
  border: solid 1px $primary-color;

  &.show {
    transform: translateX(0);
  }

  .menu-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .menu-item {
      padding: 12px;
      color: $primary-color;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: white;
        background: $primary-color;
      }
    }
  }
}
</style>
