import { 
  CalculationSystem, 
  SimpleParameter, 
  ComputedParameter, 
  FallbackParameter, 
  MultiplechoiceParameter, 
  StringParameter 
} from "@/calculations/calculationSystem";

function composeResidentialApartments(groundPlanCS: CalculationSystem): CalculationSystem {
  const cs = groundPlanCS;

  cs.addParameter(new ComputedParameter('constructionalHeightSpecificBoF', cs => cs.getValue('constructionalHeight')));
  cs.addParameter(new FallbackParameter('roomFloorAreaCoef', 1.25, 1.0));

  cs.addParameter(new ComputedParameter('grossInternalAreaFlatsBoF',
    cs => Object.values(cs.modules).filter(module => module.getString('type') == 'apartment')
      .reduce((acc:number, module) => acc + module.getValue('count') * module.getValue('grossInternalAreaFlatA'), 0)
  ));

  cs.addParameter(new ComputedParameter('grossFloorAreaFlatsBoF',
    cs => Object.values(cs.modules).filter(module => module.getString('type') == 'apartment')
      .reduce((acc:number, module) => acc + module.getValue('count') * module.getValue('grossFloorAreaFlatA'), 0)
  ));

  cs.addParameter(new SimpleParameter('commonAreasFlatsBoFcoef', 0.10));
  cs.addParameter(new SimpleParameter('constructionAreaAboveGroundCommonAreasBoFcoef', 1.05));
  cs.addParameter(new SimpleParameter('constructionAreaBelowGroundCommonAreasBoFcoef', 1.05));
  cs.addParameter(new SimpleParameter('aboveGroundLevelCoef', 1.10));
  cs.addParameter(new SimpleParameter('belowGroundLevelCoef', 1.10));

  cs.addParameter(new ComputedParameter('grossFloorAreaAboveGroundCommonAreasBoF', 
    cs => cs.getValue('commonAreasFlatsBoFcoef') * cs.getValue('grossFloorAreaFlatsBoF')
        + cs.getValue('commonAreaAboveGround') * cs.getValue('constructionAreaAboveGroundCommonAreasBoFcoef')
  ));

  cs.addParameter(new ComputedParameter('grossFloorAreaBelowGroundCommonAreasBoF', 
    cs => cs.getValue('commonAreaBelowGround') * cs.getValue('constructionAreaBelowGroundCommonAreasBoFcoef')
  ));

  // grossFloorAreaAboveGroundBoF = grossFloorAreaFlatsBoF+grossFloorAreaAboveGoundCommonAreasBoF
  cs.addParameter(new ComputedParameter('grossFloorAreaAboveGroundBoF', 
    cs => cs.getValue('grossFloorAreaFlatsBoF')
        + cs.getValue('grossFloorAreaAboveGroundCommonAreasBoF')
  ));
  // grossFloorAreaBelowGroundBoF = grossFloorAreaBelowGroundCommonAreasBoF
  cs.addParameter(new ComputedParameter('grossFloorAreaBelowGroundBoF', 
    cs => cs.getValue('grossFloorAreaBelowGroundCommonAreasBoF')
  ));

  cs.addParameter(new ComputedParameter('flatTotalCount',
  cs => Object.values(cs.modules).filter(module => module.getString('type') == 'apartment')
  .reduce((acc:number, module) => acc + module.getValue('count'), 0)
  ));

  // Accessories
  const accessoriesLocation = [
    {value: 1, alias: 'above', name: 'Nadzemní podlaží'},
    {value: 2, alias: 'below', name: 'Podzemní podlaží'},
    {value: 3, alias: 'outside', name: 'Mimo objekt'},
  ];

  const accessoriesLocationInside = accessoriesLocation.filter(loc => loc.alias !== 'outside');

  cs.addParameter(new ComputedParameter('parkingSpacesFlatsArea', cs => cs.getValue('numberParkingSpacesFlatsBoF') * cs.getValue('parkingSpotArea')));
  cs.addParameter(new ComputedParameter('parkingSpacesNearbyArea', cs => cs.getValue('numberParkingSpacesNearbyBoF') * cs.getValue('parkingSpotArea')));

  cs.addParameter(new SimpleParameter('parkingSpotArea', 2.7*5.3+2.7*3.25));
  cs.addParameter(new SimpleParameter('cellarCubicleArea', 5));
  cs.addParameter(new SimpleParameter('carriageStoreArea', 1));
  cs.addParameter(new SimpleParameter('laundryArea', 0.5));
  cs.addParameter(new SimpleParameter('liftArea', 2.5*2.5));
  cs.addParameter(new SimpleParameter('boilerRoomArea', 16));
  cs.addParameter(new SimpleParameter('technicalRoomArea', 14));
  cs.addParameter(new SimpleParameter('cleaningRoomArea', 4));

  cs.addParameter(new SimpleParameter('hasCellarCubicle', 1));
  cs.addParameter(new SimpleParameter('hasCarriageStore', 1));
  cs.addParameter(new SimpleParameter('hasLaundry', 1));
  cs.addParameter(new SimpleParameter('hasLift', 1));
  cs.addParameter(new SimpleParameter('hasBoilerRoom', 16));
  cs.addParameter(new SimpleParameter('hasTechnicalRoom', 14));
  cs.addParameter(new SimpleParameter('hasCleaningRoom', 4));

  cs.addParameter(new ComputedParameter('cellarTotalArea', 
    cs => cs.getValue('hasCellarCubicle') * cs.getValue('cellarCubicleArea') * cs.getValue('flatTotalCount')
  ));
  cs.addParameter(new ComputedParameter('carriageStoreTotalArea', 
    cs => cs.getValue('hasCarriageStore') * cs.getValue('carriageStoreArea') * cs.getValue('flatTotalCount')
  ));
  cs.addParameter(new ComputedParameter('laundryTotalArea', 
    cs => cs.getValue('hasLaundry') * cs.getValue('laundryArea') * cs.getValue('flatTotalCount')
  ));
  cs.addParameter(new ComputedParameter('boilerRoomTotalArea', 
    cs => cs.getValue('hasBoilerRoom') * cs.getValue('boilerRoomArea')
  ));
  cs.addParameter(new ComputedParameter('technicalRoomTotalArea', 
    cs => cs.getValue('hasTechnicalRoom') * cs.getValue('technicalRoomArea')
  ));
  cs.addParameter(new ComputedParameter('cleaningRoomTotalArea', 
    cs => cs.getValue('hasCleaningRoom') * cs.getValue('cleaningRoomArea')
  ));

  cs.addParameter(new MultiplechoiceParameter('cellarCubicleLocation', 2, accessoriesLocationInside));
  cs.addParameter(new MultiplechoiceParameter('carriageStoreLocation', 2, accessoriesLocationInside));
  cs.addParameter(new MultiplechoiceParameter('laundryLocation', 2, accessoriesLocationInside));
  cs.addParameter(new MultiplechoiceParameter('boilerRoomLocation', 2, accessoriesLocationInside));
  cs.addParameter(new MultiplechoiceParameter('technicalRoomLocation', 2, accessoriesLocationInside));
  cs.addParameter(new MultiplechoiceParameter('cleaningRoomLocation', 2, accessoriesLocationInside));

  // Parking
  cs.addParameter(new SimpleParameter('useParkingMultipliers', 1));

  cs.addParameter(new ComputedParameter('totalApartmentParkingSlots',
    cs => Object.values(cs.modules).filter(module => module.getString('type') == 'apartment')
      .reduce((acc:number, module) => acc + module.getValue('count') * module.getValue('requiredParkingSpacesFlatA'), 0)
  ));

  cs.addParameter(new ComputedParameter('numberParkingSpacesFlatsBoF',
    cs => cs.getValue('useParkingMultipliers') 
      ? Math.ceil(cs.getValue('parkingSpacesRegisteredCarsCoef') * cs.getValue('totalApartmentParkingSlots'))
      : cs.getValue('totalApartmentParkingSlots')
  ));

  cs.addParameter(new ComputedParameter('numberParkingSpacesNearbyBoF',
    cs => cs.getValue('useParkingMultipliers')
    ? Math.ceil(cs.getValue('parkingSpacesRegisteredCarsCoef') * cs.getValue('flatTotalCount')/10)
    : Math.ceil(cs.getValue('flatTotalCount') / 10)
  ));

  cs.addParameter(new ComputedParameter('numberParkingSpacesTotalBoF',
    cs => cs.getValue('numberParkingSpacesFlatsBoF') + cs.getValue('numberParkingSpacesNearbyBoF')
  ));

  cs.addParameter(new SimpleParameter('parkingSpacesFlatsBelowGroundPercentage', 100));
  cs.addParameter(new SimpleParameter('parkingSpacesFlatsAboveGroundPercentage', 0));
  cs.addParameter(new ComputedParameter('parkingSpacesFlatsOutsidePercentage',
    cs => 100 - cs.getValue('parkingSpacesFlatsBelowGroundPercentage') - cs.getValue('parkingSpacesFlatsAboveGroundPercentage')
  ))
  cs.addParameter(new SimpleParameter('parkingSpacesNearbyBelowGroundPercentage', 0));
  cs.addParameter(new SimpleParameter('parkingSpacesNearbyAboveGroundPercentage', 0));
  cs.addParameter(new ComputedParameter('parkingSpacesNearbyOutsidePercentage',
    cs => 100 - cs.getValue('parkingSpacesNearbyBelowGroundPercentage') - cs.getValue('parkingSpacesNearbyAboveGroundPercentage')
  ))

  cs.addParameter(new ComputedParameter('numberParkingSpacesFlatsBelowGround', 
    cs => cs.getValue('parkingSpacesFlatsBelowGroundPercentage') * cs.getValue('parkingSpacesFlatsArea') / 100.0 
  ))
  cs.addParameter(new ComputedParameter('numberParkingSpacesFlatsAboveGround', 
    cs => cs.getValue('parkingSpacesFlatsAboveGroundPercentage') * cs.getValue('parkingSpacesFlatsArea') / 100.0 
  ))
  cs.addParameter(new ComputedParameter('numberParkingSpacesNearbyBelowGround', 
    cs => cs.getValue('parkingSpacesNearbyBelowGroundPercentage') * cs.getValue('parkingSpacesNearbyArea') / 100.0 
  ))
  cs.addParameter(new ComputedParameter('numberParkingSpacesNearbyAboveGround', 
    cs => cs.getValue('parkingSpacesNearbyAboveGroundPercentage') * cs.getValue('parkingSpacesNearbyArea') / 100.0 
  ))
  // building storyes
  cs.addParameter(new ComputedParameter('numberLevelAboveGround', 
    cs => Math.ceil(cs.getValue('aboveGroundLevelCoef') * cs.getValue('grossFloorAreaFlatsBoF') / (cs.getValue('LPlimitMaxFloorAreaAboveGround')/cs.getValue('LPlimitMaxFloorNumber')))
  ));
  
  cs.addParameter(new ComputedParameter('numberLevelBelowGround', 
    cs => Math.ceil(
      cs.getValue('belowGroundLevelCoef') * cs.getValue('constructionAreaBelowGroundCommonAreasBoFcoef') 
      * (cs.getValue('numberParkingSpacesFlatsBelowGround') + cs.getValue('numberParkingSpacesNearbyBelowGround'))
      / (cs.getValue('LPlimitMaxFloorAreaAboveGround') / cs.getValue('LPlimitMaxFloorNumber')))
  ));

  cs.addParameter(new ComputedParameter('volumeCostBoF', 
    cs => Math.ceil(
      (cs.getValue('grossFloorAreaFlatsBoF') * cs.getValue('constructionalHeightSpecificBoF') * 6000 + 
      cs.getValue('grossFloorAreaAboveGroundCommonAreasBoF') * cs.getValue('constructionalHeightSpecificBoF') * 5500 +
      cs.getValue('grossFloorAreaBelowGroundCommonAreasBoF') * cs.getValue('constructionalHeightSpecificBoF') * 4500
      ) /250000
    ) * 250000
  ));

  cs.addParameter(new ComputedParameter('enclosedVolumeBoF', 
    cs => 
      cs.getValue('grossFloorAreaFlatsBoF') * cs.getValue('constructionalHeightSpecificBoF') + 
      cs.getValue('grossFloorAreaAboveGroundCommonAreasBoF') * cs.getValue('constructionalHeightSpecificBoF') +
      cs.getValue('grossFloorAreaBelowGroundCommonAreasBoF') * cs.getValue('constructionalHeightSpecificBoF')
  ));

  cs.addParameter(new ComputedParameter('commonAreaAboveGround', 
    cs => cs.getValue('numberParkingSpacesFlatsAboveGround')
        + cs.getValue('numberParkingSpacesNearbyAboveGround')
        + Number(cs.is('cellarCubicleLocation', 'above')) * cs.getValue('cellarTotalArea')
        + Number(cs.is('carriageStoreLocation', 'above')) * cs.getValue('carriageStoreTotalArea')
        + Number(cs.is('laundryLocation', 'above')) * cs.getValue('laundryTotalArea')
        + cs.getValue('hasLift') * cs.getValue('liftArea') * cs.getValue('numberLevelAboveGround')
        + Number(cs.is('boilerRoomLocation', 'above')) * cs.getValue('boilerRoomTotalArea')
        + Number(cs.is('technicalRoomLocation', 'above')) * cs.getValue('technicalRoomTotalArea')
        + Number(cs.is('cleaningRoomLocation', 'above')) * cs.getValue('cleaningRoomTotalArea')
  ));
  
  cs.addParameter(new ComputedParameter('commonAreaBelowGround', 
    cs => cs.getValue('numberParkingSpacesFlatsBelowGround')
        + cs.getValue('numberParkingSpacesNearbyBelowGround')
        + Number(cs.is('cellarCubicleLocation', 'below')) * cs.getValue('cellarTotalArea')
        + Number(cs.is('carriageStoreLocation', 'below')) * cs.getValue('carriageStoreTotalArea')
        + Number(cs.is('laundryLocation', 'below')) * cs.getValue('laundryTotalArea')
        + cs.getValue('hasLift') * cs.getValue('liftArea') * cs.getValue('numberLevelBelowGround')
        + Number(cs.is('boilerRoomLocation', 'below')) * cs.getValue('boilerRoomTotalArea')
        + Number(cs.is('technicalRoomLocation', 'below')) * cs.getValue('technicalRoomTotalArea')
        + Number(cs.is('cleaningRoomLocation', 'below')) * cs.getValue('cleaningRoomTotalArea')
  ));

  
  // grossFloorAreaFlatsBoF*constructionalHeightSpecificBoF
  // grossFloorAreaAboveGroundCommonAreasBoF*constructionalHeightSpecificBoF
  // grossFloorAreaBelowGroundCommonAreasBoF*constructionalHeightSpecificBoF

  //belowGroundLevelCoef*constructionAreaBelowGroundCommonAreasBoFcoef*(K46+K47)/(LPlimitMaxFloorAreaAboveGround/LPlimitMaxFloorNumber);1)
  cs.dump(false);
  console.log(cs.exportToJSON())
  return cs;
}



function legislativeMinAreaFnComposer(...legislativeTable: number[]) {
  return (indexFrom1: number) => {
    if (indexFrom1 < 0) throw new Error(`legislativeMinAreaFnComposer invalid indexFrom1`)
    if (indexFrom1 == 0) return 0;
    if (indexFrom1 > legislativeTable.length) indexFrom1 = legislativeTable.length;
    return legislativeTable[indexFrom1-1];
  }
} 
const X = -1;
const rooms = [ 
  {
    name: 'Obývací pokoj bez stolování',
    id: 'livingRoomWithoutDiningTable',
    isHabitable: true,
    area: legislativeMinAreaFnComposer(16, 16, 18, 18, 20),
  },
  {
    name: 'Obývací pokoj se stolováním',
    id: 'livingRoomWithDiningTable',
    isHabitable: true,
    area: legislativeMinAreaFnComposer(16, 16, 21, 21, 24),
  },
  {
    name: 'Obývací pokoj bez stolování s 1 lůžkem',
    id: 'livingRoomWithBedWithoutDiningTable',
    isHabitable: true,
    area: legislativeMinAreaFnComposer(16, 16, 20, X, X),
  },
  {
    name: 'Obývací pokoj se stolováním s 1 lůžkem',
    id: 'livingRoomWithBedWithDiningTable',
    isHabitable: true,
    area: legislativeMinAreaFnComposer(18, 18, X, X, X),
  },
  {
    name: 'Ložnice s 1 lůžkem',
    id: 'bedroomSingleBed',
    isHabitable: true,
    area: legislativeMinAreaFnComposer(8, 8, 8, 8, 8),
  },
  {
    name: 'Ložnice se 2 lůžky',
    id: 'bedroomTwinBeds',
    isHabitable: true,
    area: legislativeMinAreaFnComposer(12, 12, 12, 12, 12),
  },
  {
    name: 'Kuchyně pracovní',
    id: 'kitchen',
    isHabitable: true,
    area: legislativeMinAreaFnComposer(5, 5, 5, 6, 8),
  },
  {
    name: 'Kuchyně se stolováním',
    id: 'kitchenWithDiningTable',
    isHabitable: true,
    area: legislativeMinAreaFnComposer(6, 6, 10, 12, 15),
  },
  {
    name: 'Obytná kuchyně nahrazující obývací pokoj',
    id: 'habitableKitchen',
    isHabitable: true,
    area: legislativeMinAreaFnComposer(16, 18, X, X, X),
  },
  {
    name: 'Obytná kuchyně s 1 lůžkem, nahrazující obývací pokoj',
    id: 'habitableKitchenSingleBed',
    isHabitable: true,
    area: legislativeMinAreaFnComposer(16, X, X, X, X),
  },
  /// minor rooms - socialni zarizeni
  {
    name: 'Koupelna bez WC',
    id: 'bathroom',
    isHabitable: false,
    area: legislativeMinAreaFnComposer(4.00, 4.00, 4.00, 4.00, 4.00),
  },
  {
    name: 'Koupelna s WC',
    id: 'bathroomWithWC',
    isHabitable: false,
    area: legislativeMinAreaFnComposer(6.00, 6.00, 6.00, 6.00, 6.00),
  },
  {
    name: 'WC (samostatné)',
    id: 'WC',
    isHabitable: false,
    area: legislativeMinAreaFnComposer(0.99, 0.99, 0.99, 0.99, 0.99),
  },
  {
    name: 'Sprcha',
    id: 'shower',
    isHabitable: false,
    area: legislativeMinAreaFnComposer(2.00, 2.00, 2.00, 2.00, 2.00),
  },
  /// Comminication rooms
  {
    name: 'Chodba uvnitř bytu',
    id: 'hall',
    isHabitable: false,
    area: legislativeMinAreaFnComposer(2.25, 4.50, 6.75, 9.00, 11.25),
  },
  {
    name: 'Předsíň',
    id: 'vestibule',
    isHabitable: false,
    area: legislativeMinAreaFnComposer(1.32, 1.32, 1.32, 1.32, 1.32),
  },
  {
    name: 'Schodiště bytové',
    id: 'staircase',
    isHabitable: false,
    area: legislativeMinAreaFnComposer(3.2, 3.2, 3.2, 3.2, 3.2),
  },
  /// Other rooms
  {
    name: 'Šatna',
    id: 'WIC',
    isHabitable: false,
    area: legislativeMinAreaFnComposer(4.00, 4.00, 4.00, 4.00, 4.00),
  },
  {
    name: 'Komora',
    id: 'pantry',
    isHabitable: false,
    area: legislativeMinAreaFnComposer(2.20, 2.40, 2.60, 2.80, 3.00),
  },
  {
    name: 'Jídelna',
    id: 'diningRoom',
    isHabitable: true,
    area: legislativeMinAreaFnComposer(8.0, 8.0, 8.0, 8.0, 8.0),
  },
  {
    name: 'Pracovna',
    id: 'study',
    isHabitable: true,
    area: legislativeMinAreaFnComposer(8, 8, 8, 8, 8),
  },
  
]

const roomIdList = rooms.map(room => room.id);

function composeRoom(roomId: string, count: number) : CalculationSystem {
  const roomMap = new Map<string, typeof rooms[0]>();
  rooms.forEach(room => roomMap.set(room.id, room));
  const cs = new CalculationSystem();
  cs.addParameter(new StringParameter("roomId", roomId))
  cs.addParameter(new StringParameter("name", roomMap.get(roomId)!.name));
  cs.addParameter(new ComputedParameter("roomMinArea", cs => {
    const habRooms = cs.parent!.getValue('numberAccommodationRoomsFlatA');
    const area = roomMap.get(cs.getString('roomId'))!.area(habRooms);
    if (area) return area;
    return 0;
  }));
  cs.addParameter(new ComputedParameter("roomArea", cs => {
    const roomMinArea = cs.getValue('roomMinArea');
    if (roomMinArea) return roomMinArea * cs.parent!.getValue('roomFloorAreaCoef');
    return 0;
  }));
  cs.addParameter(new ComputedParameter("isRoomHabitable", cs => roomMap.get(cs.getString('roomId'))!.isHabitable?1:0));
  cs.addParameter(new ComputedParameter("roomPerimeter", cs => {
      if (cs.getValue('roomArea') <= 0) return 0;
      const roomShapeRatio = cs.parent!.getValue('roomShapeRatio');
      return 2*(1+1/roomShapeRatio)*Math.sqrt(roomShapeRatio*cs.getValue('roomArea'));
  }));
  cs.addParameter(new SimpleParameter("count", count));

  return cs;
}


function composeApartment(roomsToAdd: Record<string, number> = {}, name = '', quantity = 1) : CalculationSystem {
  const cs = new CalculationSystem();
  // livingRoomWithoutDiningTableCount
  // livingRoomWithDiningTableCount
  // livingRoomWithBedWithoutDiningTableCount
  // livingRoomWithBedWithDiningTableCount
  // 
  cs.addParameter(new StringParameter('name', name));
  cs.addParameter(new StringParameter('type', 'apartment'));
  cs.addParameter(new SimpleParameter('count', quantity));
  //cs.addParameter(new ComputedStringParameter('definitionFlatA', cs => '1kk'));
  cs.addParameter(new ComputedParameter('numberAccommodationRoomsFlatA', cs => {
    return Object.values(cs.modules).map(module => module.getValue('isRoomHabitable') * module.getValue('count')).reduce((acc, b) => acc + b, 0);
  }));
  const kitchens = [
    'kitchen',
    'kitchenWithDiningTable',
    'habitableKitchen',
    'habitableKitchenSingleBed',
  ];
  const toiletValue = {
    bathroomWithWC: 0.8,
    WC: 1.0,
  }
  cs.addParameter(new ComputedParameter('separateKitchenFlatA', 
    cs => {
      return (kitchens.some(kitchen =>(kitchen in cs.modules) && cs.modules[kitchen].getValue('count') > 0)?1:0)
    }
  ));
  cs.addParameter(new ComputedParameter('requiredToiletsFlatA', cs => {
    const M = cs.getValue('numberAccommodationRoomsFlatA');
    if (M < 3) return 0.8;
    return (M < 5)? 1 : 1.8;
  }));
  cs.addParameter(new ComputedParameter('numberToiletsFlatA', cs => {
    return Object.entries(toiletValue).reduce((acc, [toilet, value]) => {
      if (toilet in cs.modules) return acc + cs.modules[toilet].getValue('count') * value;
      return acc;
    }, 0);
  }));
  cs.addParameter(new ComputedParameter('missingToiletSeparated', cs => {
    if (cs.getValue('requiredToiletsFlatA') >= 1 && cs.getValue('WC.count') < 1) return 1;
    return 0;
  }));
  cs.addParameter(new ComputedParameter('missingToilet', cs => {
    return Math.max(0, cs.getValue('requiredToiletsFlatA') - cs.getValue('numberToiletsFlatA'))
  }));
  cs.addParameter(new ComputedParameter('missingBathroom', cs => {
    return Math.max(0, 1 - cs.getValue('bathroom.count') - cs.getValue('bathroomWithWC.count'))
  }))
  cs.addParameter(new ComputedParameter('roomFloorAreaCoef', cs => cs.parent!.getValue('roomFloorAreaCoef'))); /// from level under conceptionCalculator

  cs.addParameter(new ComputedParameter('grossInternalAreaFlatA', cs => {
    return Object.values(cs.modules).map(module => module.getValue('roomArea') * module.getValue('count')).reduce((acc, b) => acc + b, 0);
  }));
  cs.addParameter(new SimpleParameter('thicknessPartitionBoF', 150.0));
  cs.addParameter(new SimpleParameter('thicknessInternalWallBoF', 300.0));
  cs.addParameter(new SimpleParameter('thicknessExternalWallBoF', 450.0));
  cs.addParameter(new SimpleParameter('roomShapeRatio', 2));
  cs.addParameter(new ComputedParameter('perimeterRoomsFlatA', cs => {
    return Object.values(cs.modules).map(module => module.getValue('roomPerimeter') * module.getValue('count')).reduce((acc, b) => acc + b, 0);
  }));
  cs.addParameter(new ComputedParameter('perimeterFlatA', cs => {
    const roomShapeRatio = cs.getValue('roomShapeRatio');
    return 2*(1+1/roomShapeRatio)*Math.sqrt(roomShapeRatio*cs.getValue('grossInternalAreaFlatA'));
  }));
  cs.addParameter(new SimpleParameter('externalWallPerimeterRatio', 1.0/3));
  cs.addParameter(new ComputedParameter('perimeterExternalFlatA', cs => cs.getValue('perimeterFlatA')*cs.getValue('externalWallPerimeterRatio')));
  cs.addParameter(new ComputedParameter('perimeterInternalFlatA', cs => cs.getValue('perimeterFlatA')*(1.0 - cs.getValue('externalWallPerimeterRatio'))));
  cs.addParameter(new ComputedParameter('perimeterPartitionFlatA', cs => (cs.getValue('perimeterRoomsFlatA') - cs.getValue('perimeterFlatA'))/2));
  cs.addParameter(new ComputedParameter('constructionAreaExternalFlatA', cs => cs.getValue('perimeterExternalFlatA')*cs.getValue('thicknessExternalWallBoF') / 1000.0));
  cs.addParameter(new ComputedParameter('constructionAreaInternalFlatA', cs => cs.getValue('perimeterInternalFlatA')*cs.getValue('thicknessInternalWallBoF') / 1000.0 / 2.0));
  cs.addParameter(new ComputedParameter('constructionAreaPartitionFlatA', cs => cs.getValue('perimeterPartitionFlatA')*cs.getValue('thicknessPartitionBoF') / 1000.0));
  cs.addParameter(new ComputedParameter('grossFloorAreaFlatA', 
    cs => cs.getValue('grossInternalAreaFlatA')
        + cs.getValue('constructionAreaExternalFlatA')
        + cs.getValue('constructionAreaInternalFlatA')
        + cs.getValue('constructionAreaPartitionFlatA')));
  
  cs.addParameter(new ComputedParameter('requiredParkingSpacesFlatA', cs => {
    if (cs.getValue('numberAccommodationRoomsFlatA') === 1) return 0.5;
    return cs.getValue('grossInternalAreaFlatA') > 100? 2 : 1; 
  }));

  roomIdList.forEach(roomId => {
    let count = 0;
    if (roomId in roomsToAdd) count = roomsToAdd[roomId];
    const roomCS = composeRoom(roomId, count);
    cs.registerModule(roomCS.getString('roomId'), roomCS);
  })
  //cs.dump();
  return cs;
}

function composeDefault1kk(quantity: number, name = '1+kk') {
  const roomsToAdd = {
    livingRoomWithDiningTable: 1,
    bathroomWithWC: 1,
  };
  return composeApartment(roomsToAdd, name, quantity);
}

function composeDefault2kk(quantity: number, name = '2+kk') {
  const roomsToAdd = {
    livingRoomWithDiningTable: 1,
    bedroomTwinBeds: 1,
    bathroomWithWC: 1,
    hall: 1,
    pantry: 1,
  };
  return composeApartment(roomsToAdd, name, quantity);
}

function composeDefault3kk(quantity: number, name = '3+kk') {
  const roomsToAdd = {
    livingRoomWithDiningTable: 1,
    bedroomSingleBed: 1,
    bedroomTwinBeds: 1,
    bathroom: 1,
    WC: 1,
    hall: 1,
    pantry: 1,
  };
  return composeApartment(roomsToAdd, name, quantity);
}

export {
  composeResidentialApartments,
  composeApartment,
  composeRoom,
  composeDefault1kk,
  composeDefault2kk,
  composeDefault3kk,
}
