<template>
  <div class="tree-view" v-if="node">
    <div @click="showChildren = !showChildren">
      <Heading :level="depth+1" class="clickable">
        <i class="icon-collapsable far fa-minus-square" v-if="showChildren"></i>
        <i class="icon-collapsable far fa-plus-square" v-else></i>
        <span v-if="root"> 
          Požadavky
        </span>
        <span v-else>
          <span v-text="pretty(node.value)"></span>
        </span>
       (zkontrolováno {{countFulfilled()}} z {{node.data.length}})
      </Heading>
    </div>

    <div class="leaf" v-if="node && node.data && node.data.length && node.children && node.children.length === 0 && showChildren">

      <AppliedRequirementList 
        :activeRequirements="node.data.map(req => req.instanceId)" 
        :heading="''"
      />
    </div>
    <div class="collapsable-item" v-if="node && node.children && node.children.length" v-show="showChildren">
      <TreeView v-for="child in node.children" :node="child" :key="child.value" :root="false" :depth="depth+1" :tracker="tracker"/>
    </div>
  </div>
  <div v-else-if="depth == 0">
    Zadanému filtru neodpovídají žádné požadavky.
  </div>
</template>

<script>
import Heading from '../../components/fundamentals/Heading.vue';
import AppliedRequirementList from '../AppliedRequirementList.vue';

import {
  writeLevel,
  writeType,
} from '@/@types/definitions/requirements'

export default {
  components: { AppliedRequirementList, Heading },
  name: "TreeView",
  props: {
    node: {
      type: Object,
    },
    root: {
      type: Boolean,
      default: true,
    },
    depth: {
      type: Number,
      default: 0,
    }
  },
  mounted() {
    if (this.root) {
      this.showChildren = true;
    }
  },
  methods: {
    pretty (condensedValuePair) {
      const writeMap = {
        level: writeLevel,
        type: writeType,
        fulfilled: (val) => (val.fulfilled == 'true'? 'Ano' : 'Ne'),
      };
      const [parameter, value] = condensedValuePair.split('#');
      if (parameter in writeMap) {
        return writeMap[parameter]({level: value, type: value, fulfilled: value});
      }
      return value;
    },
    countFulfilled() {
      return this.node.data.reduce((acc, b) => acc + (b.getParam('fulfilled')?1:0), 0);
    }
  },
  data() {
    return {
      showChildren : false,
    };
  }
}
</script>

<style lang="scss">
.tree-view {
  .icon-collapsable {
    padding: 5px;
  }

  .collapsable-item {
    margin-left: 10px;
  }
}

</style>
