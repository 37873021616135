<template>
  <div v-if="itsMe()">
    <i @click="openCalc()" class="clickable fas fa-calculator"></i>
    <Dialog heading="Součinitel prostupu tepla"  class="thermal-transmittance" :show="show" @close="closeCalc()">

      <Section name="Typ konstrukce">
        <DropdownInput
          :options="staticData.constructionTypeOptions"
          :value="constructionType"
          @input="setFilter('constructionType', $event)"
          :width="700"
          small
        />
      </Section>

      <Section name="Umístění">
        <DropdownInput
          :options="staticData.environmentOptions"
          :value="environment"
          @input="setFilter('environment', $event)"
          :width="700"
          small
        />
      </Section>
      <p v-show="error('environment')" class="error right">Kombinace neexistuje.</p>

      <Section name="Prostředí za konstrukcí" v-show="environment == 'exterior'">
        <DropdownInput
          :options="staticData.exteriorTypeOptions"
          :value="exteriorType"
          @input="setFilter('exteriorType', $event)"
          :width="700"
          small
        />
      </Section>
      <p v-show="environment == 'exterior' && error('exteriorType')" class="error right">Kombinace neexistuje.</p>

      <Section name="Upřesnění zadání">
        <DropdownInput
          :options="getDecisiveCategoryOptions()"
          :value="decisiveCategory"
          @input="decisiveCategory = $event"
          :width="700"
          small
        />
      </Section>
      
      <Section name="Procento průsvitné plochy lehkého obvodového pláště" v-show="isLOP()">
        <NumberWithUnitInput
          :value="windowPercentage"
          @input="windowPercentage = $event"
          :step="0.01"
          :min="0"
          :max="1"
          :width="250"
          unit="m<sup>2</sup>/m<sup>2</sup>"
        />
      </Section>

      <Section name="Úroveň požadavku">
        <DropdownInput
          :options="staticData.parameterOptions"
          :value="parameter"
          @input="parameter = $event"
          :width="700"
          small
        />
      </Section>

      <Section name="Vnitřní teplota">
        <NumberWithUnitInput
          :value="innerTemperature"
          @input="innerTemperature = $event"
          :step="0.25"
          :min="0"
          :max="40"
          :width="250"
          unit="°C"
        />
      </Section>
     <!--  <div v-for="record in filteredStage1()" :key="record.name" > 
        {{record}}
      </div> -->
      <Section
        name="Výsledný součinitel prostupu tepla"
      >
        {{ result() }}
      </Section>
      <div class="controls">
        <button @click="show = false" class="secondary">zrušit</button>
        <button @click="use()"  class="primary">použít</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
const constructionTypeOptions = [
  {name: "Stěna",   value: "wall"},
  {name: "Strop",   value: "ceiling"},
  {name: "Podlaha",   value: "floor"},
  {name: "Výplň otvoru",  value: "opening"},
];

const environmentOptions = [
  {name: "Vnější", value: "exterior"},
  {name: "Vnitřní", value: "interior"},
]

const exteriorTypeOptions = [
  {name: "Vzduch", value: "air"},
  {name: "Zemina", value: "ground"},
]

const decisiveCategoryOptions = [
  {name: "Nevytápěný prostor", value: 1},
  {name: "Nevytápěná půda (střecha bez tepelné izolace)", value: 2},
  {name: "Prostor s rozdílem teplot do 10 °C", value: 3},
  {name: "Prostor s rozdílem teplot do 5 °C", value: 4},
  {name: "Sousední budova", value: 5},
]

const parameterOptions = [
  {name: "Požadovaná hodnota", value: "required"},
  {name: "Doporučená hodnota", value: "suggested"},
  {name: "Doporučená hodnota pro pasivní domy - mírnější", value: "pasiveMax"},
  {name: "Doporučená hodnota pro pasivní domy - přísnější", value: "pasiveMin"},
  {name: "Referenční hodnota", value: "reference"},
]

const resultTable = [
  //Typ	Umístění	Prostředí	decisive	Požadavek	Doporučení	Pasiv Max	Pasiv Min	Reference
  { constructionType: "wall", environment:	"exterior", exteriorType:	"air", decisiveCategory: "Těžká", name:	"Stěna vnější těžká",	required: 0.3, suggested:	0.25, pasiveMax:	0.18, pasiveMin:	0.12, reference:	0.21},
  { constructionType: "wall", environment:	"exterior", exteriorType:	"air", decisiveCategory: "Lehká", name:	"Stěna vnější lehká",	required: 0.3, suggested:	0.2, pasiveMax:	0.18, pasiveMin:	0.12, reference:	0.21},
  { constructionType: "wall", environment:	"exterior", exteriorType:	"ground", decisiveCategory: "", name:	"Stěna vytápěného prostoru přilehlá k zemině",	required: 0.45, suggested:	0.3, pasiveMax:	0.22, pasiveMin:	0.15, reference:	0.315},
  { constructionType: "ceiling", environment:	"exterior", exteriorType:	"air", decisiveCategory: "se sklonem do 45°C včetně", name:	"Střecha plochá a šikmá se sklonem do 45° včetně",	required: 0.24, suggested:	0.16, pasiveMax:	0.15, pasiveMin:	0.1, reference:	0.168},
  { constructionType: "ceiling", environment:	"exterior", exteriorType:	"air", decisiveCategory: "se sklonem nad 45°", name:	"Střecha strmá se sklonem nad 45°",	required: 0.3, suggested:	0.2, pasiveMax:	0.18, pasiveMin:	0.12, reference:	0.21},
  { constructionType: "ceiling", environment:	"exterior", exteriorType:	"ground", decisiveCategory: "", name:	"Strop vytápěného prostoru přilehlý k zemině",	required: 0.45, suggested:	0.3, pasiveMax:	0.22, pasiveMin:	0.15, reference:	0.315},
  { constructionType: "floor", environment:	"exterior", exteriorType:	"air", decisiveCategory: "", name:	"Podlaha nad venkovním prostorem",	required: 0.24, suggested:	0.16, pasiveMax:	0.15, pasiveMin:	0.1, reference:	0.168},
  { constructionType: "floor", environment:	"exterior", exteriorType:	"ground", decisiveCategory: "", name:	"Podlaha vytápěného prostoru přilehlá k zemině",	required: 0.45, suggested:	0.3, pasiveMax:	0.22, pasiveMin:	0.15, reference:	0.315},
  { constructionType: "opening", environment:	"exterior", exteriorType:	"air", decisiveCategory: "", name:	"Výplň otvoru ve vnější stěně a strmé střeše, z vytápěného prostoru do venkovního prostředí, kromě dveří",	required: 1.5, suggested:	1.2, pasiveMax:	0.8, pasiveMin:	0.6, reference:	1.05},
  { constructionType: "opening", environment:	"exterior", exteriorType:	"air", decisiveCategory: "", name:	"Šikmá výplň otvoru se sklonem do 45°, z vytápěného prostoru do venkovního prostředí",	required: 1.4, suggested:	1.1, pasiveMax:	0.9, pasiveMin:	0.9, reference:	0.98},
  { constructionType: "opening", environment:	"exterior", exteriorType:	"air", decisiveCategory: "", name:	"Dveřní výplň otvoru z vytápěného prostoru do venkovního prostředí",	required: 1.7, suggested:	1.2, pasiveMax:	0.9, pasiveMin:	0.9, reference:	1.19},
  { constructionType: "opening", environment:	"exterior", exteriorType:	"air", decisiveCategory: "", name:	"Lehký obvodový plášť",	required: "Podrobný výpočet dle % prosklení",	suggested: "Podrobný výpočet dle % prosklení",	masivMax: "Podrobný výpočet dle % prosklení",	pasivMin: "Podrobný výpočet dle % prosklení", reference: "Podrobný výpočet dle % prosklení"},
  { constructionType: "ceiling", environment:	"interior", decisiveCategory:	"Nevytápěný prostor", name:	"Strop vnitřní z vytápěného k nevytápěnému prostoru",	required: 0.6, suggested:	0.4, pasiveMax:	0.3, pasivMin:	0.2, reference:	0.42},
  { constructionType: "ceiling", environment:	"interior", decisiveCategory:	"Nevytápěná půda (střecha bez tepelné izolace)", name:	"Strop pod nevytápěnou půdou (se střechou bez tepelné izolace)",	required: 0.3, suggested:	0.2, pasiveMax:	0.15, pasivMin:	0.1, reference:	0.21},
  { constructionType: "ceiling", environment:	"interior", decisiveCategory:	"Prostor s rozdílem teplot do 10 °C", name:	"Strop mezi prostory s rozdílem teplot do 10 °C včetně",	required: 1.05, suggested:	0.7, pasiveMax:	"Nedefinováno", pasivMin:	"Nedefinováno", reference:	0.735},
  { constructionType: "ceiling", environment:	"interior", decisiveCategory:	"Prostor s rozdílem teplot do 5 °C", name:	"Strop mezi prostory s rozdílem teplot do 5 °C včetně",	required: 2.2, suggested:	1.45, pasiveMax:	"Nedefinováno", pasivMin:	"Nedefinováno", reference:	1.54},
  { constructionType: "floor", environment:	"interior", decisiveCategory:	"Nevytápěný prostor", name:	"Podlaha vnitřní z vytápěného do nevytápěného prostoru",	required: 0.6, suggested:	0.4, pasiveMax:	0.3, pasivMin:	0.2, reference:	0.42},
  { constructionType: "floor", environment:	"interior", decisiveCategory:	"Prostor s rozdílem teplot do 10 °C", name:	"Podlaha mezi prostory s rozdílem teplot do 10°C včetně",	required: 1.05, suggested:	0.7, pasiveMax:	"Nedefinováno", pasivMin:	"Nedefinováno", reference:	0.735},
  { constructionType: "floor", environment:	"interior", decisiveCategory:	"Prostor s rozdílem teplot do 5 °C", name:	"Podlaha mezi prostory s rozdílem teplot do 5°C včetně",	required: 2.2, suggested:	1.45, pasiveMax:	"Nedefinováno", pasivMin:	"Nedefinováno", reference:	1.54},
  { constructionType: "wall", environment:	"interior", decisiveCategory:	"Nevytápěný prostor", name:	"Stěna vnitřní z vytápěného k nevytápěnému prostoru",	required: 0.6, suggested:	0.4, pasiveMax:	0.3, pasivMin:	0.2, reference:	0.42},
  { constructionType: "wall", environment:	"interior", decisiveCategory:	"Nevytápěná půda (střecha bez tepelné izolace)", name:	"Stěna těžká k nevytápěné půdě (se střechou bez tepelné izolace)",	required: 0.3, suggested:	0.25, pasiveMax:	0.18, pasivMin:	0.12, reference:	0.21},
  { constructionType: "wall", environment:	"interior", decisiveCategory:	"Nevytápěná půda (střecha bez tepelné izolace)", name:	"Stěna lehká k nevytápěné půdě (se střechou bez tepelné izolace)",	required: 0.3, suggested:	0.2, pasiveMax:	0.18, pasivMin:	0.12, reference:	0.21},
  { constructionType: "wall", environment:	"interior", decisiveCategory:	"Prostor s rozdílem teplot do 10 °C", name:	"Stěna mezi prostory s rozdílem teplot do 10 °C včetně",	required: 1.3, suggested:	0.9, pasiveMax:	"Nedefinováno", pasivMin:	"Nedefinováno", reference:	0.91},
  { constructionType: "wall", environment:	"interior", decisiveCategory:	"Prostor s rozdílem teplot do 5 °C", name:	"Stěna mezi prostory s rozdílem teplot do 5 °C včetně",	required: 2.7, suggested:	1.8, pasiveMax:	"Nedefinováno", pasivMin:	"Nedefinováno", reference:	1.89},
  { constructionType: "wall", environment:	"interior", decisiveCategory:	"Sousední budova", name:	"Stěna mezi sousedními budovami",	required: 1.05, suggested:	0.7, pasiveMax:	0.5, pasivMin:	0.5, reference:	0.735},
]

import Dialog from '../components/Dialog.vue'
import DropdownInput from '../components/fundamentals/DropdownInput.vue'
import NumberWithUnitInput from '../components/fundamentals/NumberWithUnitInput.vue';
import Section from '../components/Section.vue'
export default {
  components: { Dialog, DropdownInput, Section, NumberWithUnitInput },
  props: {
    desiredCalculator: {
      type: String,
      default: "no-calc"
    }
  },
  data() {
    return {
      show: false,
      constructionType: 'wall',
      environment: 'interior',
      exteriorType: 'air',
      decisiveCategory: "",
      parameter: 'required',
      innerTemperature: 20,
      windowPercentage: 0.6,
      staticData: {
        constructionTypeOptions,
        environmentOptions,
        exteriorTypeOptions,
        decisiveCategoryOptions,
        parameterOptions,
      },
    }
  },
  computed: {
    error() {
      const filters = [
        'constructionType',
        'environment',
        'exteriorType',
      ]
      return (parameter) => {
        let array = resultTable;
        for (const filter of filters) {
          if (this.environment == 'interior' && filter == 'exteriorType') continue;
          array = array.filter(item => item[filter] == this[filter]);
          if (parameter == filter) break;
        }
        return array.length == 0;
      }
    }
  },
  methods: {
    itsMe() {
      return this.desiredCalculator == "ThermalTransmittance"
    },
    openCalc() {
      this.show = true
    },
    closeCalc() {
      this.show = false
    },
    setFilter(parameter, value) {
      if (![
          'constructionType',
          'environment',
          'exteriorType',
        ].includes(parameter)
      ) return alert(`WTF set parameter ${parameter}`);

      if (this.constructionType == 'opening' && this.environment == 'exterior') {
        this.exteriorType = 'air';
      }
      
      this[parameter] = value;
      // set selected result to the first in list
      const stage1 = this.filteredStage1();
      if (stage1.length > 0) {
        this.decisiveCategory = stage1[0].name;
      }
    },
    filteredStage1() {
      return resultTable.filter(record => {
        return record.constructionType == this.constructionType 
            && record.environment == this.environment
            && (record.environment == 'interior' || (record.environment == 'exterior' && record.exteriorType == this.exteriorType));
      });
    },
    getDecisiveCategoryOptions() {
      return this.filteredStage1().map(record => ({name: record.name, value: record.name}));
    },
    getListedRecord() {
      const [record] = resultTable.filter(rec => rec.name === this.decisiveCategory);
      return record;
    },
    getCoefficient() {
      const record = this.getListedRecord();
      if (record && this.parameter in record) {
        return record[this.parameter];
      }
    },
    getLOPCoefficient() {
      const required = this.windowPercentage <= 0.5? 0.3 + 1.4*this.windowPercentage : 0.7 + 0.6*this.windowPercentage,
      suggested =	0.20 + this.windowPercentage,
      pasiveMax =	0.15 + 0.85*this.windowPercentage, 
      pasiveMin =	0.15 + 0.85*this.windowPercentage, 
      reference =	0.7 * required;
      const record = {
        required,
        suggested,
        pasiveMin,
        pasiveMax,
        reference,
      }
      return record[this.parameter];
    },
    isLOP() {
      const record = this.getListedRecord();
      if (record) {
        return record.name === "Lehký obvodový plášť";
      }
      return false;
    },
    result() {
      let e1 = 1;
      if (18 > this.innerTemperature || this.innerTemperature > 22) {
        e1 = Math.min(Math.max(16/Math.abs(this.innerTemperature - 4), 0.75), 1.75);
      }
      const coefficient = this.isLOP()? this.getLOPCoefficient() : this.getCoefficient();
      if (coefficient) {
        return Math.round(coefficient * e1 * 1000)/1000;
      }
      return "-";
    },
    use () {
      const result = this.result();
      if (!isNaN(parseInt(result))) {
        this.show = false;
        const requiredValue1 = result;
        this.$emit("set-requirement", {requiredValue1});
      } else {
        confirm('Pro zadanou kombinaci neexistuje nelze určit číselný výsledek.');
      }
    },
  },
}
</script>

<style lang="scss">
.thermal-transmittance {
  .section {
    .flex-inline {
      align-items: baseline;
    }
  }
  .floor-category {
    width: 700px;
    justify-content: space-around;
    span {
      text-align: center;
    }
  }
  .controls {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}
</style>
