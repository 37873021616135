<template>
  <div>
    <button v-if="activator === 'button'" class="primary" @click="showDialog = true">
      <i class="fas fa-tasks"></i> Požadavky
    </button>
    <i v-else-if="activator === 'icon'" class="fas fa-tasks clickable" @click="showDialog = true"></i>  

    <Dialog
      :heading="makeHeading()"
      :show="showDialog"
      @close="showDialog = false"
      class="requirements-dialog"
    >
      <AppliedRequirementList v-if="activeRequirements" :activeRequirements="activeRequirements" />
      

      <h1>Seznam šablon požadavků</h1>
      <div class="filtration">
        <!-- <label>filtrace požadavků</label> -->
        <div class="filters flex-row">
          <div class="flex-inline">
            <p class="filter-label">dle typu</p> 
            <dropdown-input :value="filterData.type" @input="filterData.type = $event" :options="typeOptions" small/>
          </div>
          <div class="flex-inline" v-show="filterData.type != 'typological'">
            <p class="filter-label">dle tech. oboru</p> 
            <dropdown-input :value="filterData.technicalField" @input="filterData.technicalField = $event" :options="getParameterOptions('technicalField')" small/>
          </div>
          <div class="flex-inline" v-show="filterData.type != 'technical'">
            <p class="filter-label">dle typ. oboru</p> 
            <dropdown-input :value="filterData.typologicalField" @input="filterData.typologicalField = $event" :options="getParameterOptions('typologicalField')" small/>
          </div>
          <div class="flex-inline">
            <p class="filter-label">text</p> 
            <text-input :value="filterData.text" @input="filterData.text = $event" :width="266" small/>
          </div>
          <button @click.stop="clearFilters()" class="clear-filters btn black-btn">zrušit filtraci</button>
        </div>
      </div>

      <table class="requirement-templates">
        <tr class="heading-row">
          <th>id</th>
          <th>název požadavku</th>
          <th>typ</th>
          <th>obor</th>
          <th>článek</th>
          <th>akce</th>
        </tr>
        <tr 
          v-for="(req, rid) in requirements.filter(filterFunction)"
          :key="rid"
          class="data-row"
        >
          <td>{{ req.id }}.</td>
          <td>{{ req.name }}</td>
          <td v-text="writeType(req)"></td>
          <td v-text="writeField(req)"></td>
          <td><ShowArticle v-if="req.articleId" :articleId="req.articleId" /></td>
          <td>
            <button 
              class="btn black-btn" 
              @click="addRequirement(req)" 
              :disabled="used(req)"
            >
              přidat
            </button>
          </td>
        </tr>
      </table>
      <CustomRequirement
        :level="level"
        @take-requirement="addRequirement($event)" 
      />
    </Dialog>
  </div>
</template>

<script >
import { mapGetters } from 'vuex'
import Dialog from '../components/Dialog.vue'
import {
  writeLevel,
  writeType,
  writeField,
  writeRule,
  typeOptions,
  isTechnical,
  isTypological,
} from '@/@types/definitions/requirements'
import DropdownInput from '../components/fundamentals/DropdownInput.vue'
import TextInput from '../components/fundamentals/TextInput.vue'

import CustomRequirement from './CustomRequirement.vue';
import AppliedRequirementList from './AppliedRequirementList.vue'
import ShowArticle from '../components/showArticle.vue'

export default {
  inheritAttrs: false,
  emits: {
    'add-requirement'(requirement){
      console.log('Validation ', requirement);
      if (requirement) {
        return true;
      }
      return false;
    },
    /* 'remove-requirement'(requirementId) {
      console.log('Validation2  ', requirementId);
      return true;
    }, */
  },
  props: {
    level: {
      type: String,
      default: '',
    },
    activeRequirements: {
      type: Array,
    },
    activator: {
      type: String,
      default: 'icon',
    },
    headingIdentificator: {
      type: String,
      required: false,
    },
  },
  components: { 
    Dialog,
   /* CheckboxInput,*/ 
    DropdownInput,
    TextInput,
    CustomRequirement,
    AppliedRequirementList,
    ShowArticle,
  },
  computed: {
    ...mapGetters('definitions', [
      'requirements',
      'getParameterOptions',
    ]),
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    makeHeading () {
      let where = this.writeLevel({level: this.level});
      if (this.headingIdentificator) {
        where += ` - ${this.headingIdentificator}`
      }
      return `SPRÁVA POŽADAVKŮ pro ${where}`
    },
    filterFunction(requirement) {
      if (this.level) {
        if (requirement.level != this.level) return false;
      }
      if (this.filterData.type) {
        if (requirement.type != this.filterData.type) return false;
      }
      if (this.isTechnical(this.filterData) && this.filterData.technicalField) {
        if (requirement.technicalField != this.filterData.technicalField) return false;
      }
      if (this.isTypological(this.filterData) && this.filterData.typologicalField) {
        if (requirement.typologicalField != this.filterData.typologicalField) return false;
      }
      if (this.filterData.text) {
        const regex = new RegExp(this.filterData.text.normalize("NFD").replace(/\p{Diacritic}/gu, ""), 'i');
        const name = requirement.name.normalize("NFD").replace(/\p{Diacritic}/gu, "");
        if (name.search(regex) < 0) return false;
      }

      return true;
    },
    writeField,
    clearFilters() {
      this.filterData.type = '';
      this.filterData.technicalField = '';
      this.filterData.typologicalField = '';
      this.filterData.text = '';
    },
    addRequirement(req) {
      console.log("add requirement emit");
      const requirement = { ...req, fulfilled: false, evaluation: 'user' };
      this.$emit('add-requirement', requirement);
    },
    
    setValueFromCalculator(req, val) {
      console.log("setValue from calculator", req, val);
      const r = {...req};
      r.requiredValue1 = val;
      this.changedRequirement(r);
    },
    changedRequirement(req) {
      console.log('changedRequirement');
      console.log(req);
      console.log(req.id);
      const array = [];
      this.activeRequirements.forEach((r) => {
        if (r.id == req.id) {
          //const rIndepenedent = { ...req }
          array.push(req);
        } else {
          array.push(r)
        }
      })
      //this.$emit('set:requirements', array)
    },
    used(req) {
      //console.log(this.activeRequirements);
      if (!this.activeRequirements) return false;
      return this.activeRequirements.some(r => r.id == req.id);
    },
    isTechnical,
    isTypological,
    writeLevel,
    writeType,
    writeRule,
  },
  data() {
    return {
      showDialog: false,
      filterData: {
        type: "",
        technicalField: "",
        typologicalField: "",
        text: "",
      },
      typeOptions,
    }
  },
}
</script>

<style lang="scss">
.requirements-dialog {
  .visualization-popup {
    color: black;
  }
  .filtration {
    .filters {
      //justify-content: space-between;
      gap: 25px;
      .clear-filters {
        //line-height: 30px;
        align-self: center;
        //vertical-align: middle;
      }
    }
    .flex-inline {
      align-items: baseline;
      gap: 10px;
      p.filter-label {
        flex: 1 1 1;
      }
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    line-height: 30px;
  }
  td,
  th {
    border: 1px solid white;
    padding: 0 8px;
  }
  td.check {
    background: white;
  }
  .heading-row {
    background: $primary-color;
    color: white;
    border-width: 0;
  }
  .data-row {
    background: $secondary-color;
    //font-family: Roboto;
    &:nth-child(2n+1) {
      background: white;
    }
  }
  .controls {
    
    .clickable {
      padding: 0 10px;
    }
  }
  .btn {
    line-height: 30px;
    //height: 30px;
    padding: 0 5px;
  }
}
</style>
