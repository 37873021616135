<template>
  <div class="create-connection" v-if="show">
    <h1 class="new-connection">nastavení nové vazby</h1>
    <div class="connection-settings-interace flex-row">
      <div class="node from">
        <label>vazba vedoucí z</label>
        <div v-if="newConnection.fromId" class="select-msg">
          <span v-text="displayRoomName(newConnection.fromId)"></span> 
          <i class="clickable far fa-trash-alt" @click="clearFrom"></i>
        </div>
        <div v-else class="select-msg">
          Zaklikněte myší místnost, <strong>ze</strong> které povede vazba.
        </div>
      </div>
      <div class="node to">
        <label>vazba vedoucí do</label>
        <div v-if="!newConnection.fromId && !newConnection.toId" class="select-msg"></div>
        <div v-else-if="newConnection.toId" class="select-msg">
          <span v-text="displayRoomName(newConnection.toId)"></span> 
          <i class="clickable far fa-trash-alt" @click="clearTo"></i>
        </div>
        <div v-else class="select-msg">
          Zaklikněte myší místnost, <strong>do</strong> které povede vazba.
        </div>
      </div>
      <div class="node flags">
        <label>vazba konstrukcí</label>
        <CheckboxInput :value="newConnection.wall" @input="newConnection.wall = $event" >stěnou</CheckboxInput>
        <CheckboxInput :value="newConnection.ceiling" @input="newConnection.ceiling = $event">stropem</CheckboxInput>
        <CheckboxInput :value="newConnection.floor" @input="newConnection.floor = $event">podlahou</CheckboxInput>
        <CheckboxInput :value="newConnection.opening" @input="newConnection.opening = $event" >výplní otvoru</CheckboxInput>
        <CheckboxInput :value="newConnection.virtual" @input="newConnection.virtual = $event" >bez konstrukce</CheckboxInput>
      </div>
    </div>
    <p class="error" v-show="doesExist()">Vazba již existuje. Nelze přidat znovu.</p>
    <div class="buttons flex-row">
      <button class="primary" @click="toggleNewConnection">zrušit</button>
      <button class="primary" @click="addConnectionAction(false)" :disabled="!canCreate()">přidat</button>
      <button class="primary" @click="addConnectionAction(true )" :disabled="!canCreate()">přidat a vytvořit novou</button>
    </div>
  </div>
</template>

<script>
import { Connection } from '@/@types/definitions/connections';
import { mapGetters, mapMutations } from 'vuex';
import CheckboxInput from '../components/fundamentals/CheckboxInput.vue';

export default {
  components: { CheckboxInput },
  data() {
    return {
      show: false,
      newConnection: new Connection(0, 0),
    };
  },
  methods: {
    ...mapMutations("configuration", [
      'addConnection',
    ]),
    toggleNewConnection() {
      this.show = !this.show;
      this.newConnection = new Connection(0, 0);
      this.sendRefresh();
    },
    isPicking() {
      return this.show && (!this.newConnection.fromId || !this.newConnection.toId);
    },
    doesExist() {
      return this.connections.some(con => {
        return con.fromId == this.newConnection.fromId && con.toId == this.newConnection.toId
        || con.toId == this.newConnection.fromId && con.fromId == this.newConnection.toId;
      });
    },
    canCreate() {
      console.log("can create");
      console.log("picking", this.isPicking());
      console.log("exists", this.connections.some(con => {
        return con.fromId == this.newConnection.fromId && con.toId == this.newConnection.toId;
      }));
      return !this.isPicking() && !this.doesExist();
    },
    pickedId(roomId) {
      if (!this.newConnection.fromId) {
        this.newConnection.fromId = roomId;
      } else {
        this.newConnection.toId = roomId;
        this.sendRefresh();
      }
    },
    addConnectionAction(andNew) {
      console.log("addConnectionAction", this.newConnection.fromId && this.newConnection.toId)
      if (this.newConnection.fromId && this.newConnection.toId) {
        console.log("addConnectionAction")
        this.addConnection(this.newConnection);
        this.toggleNewConnection();
        if (andNew) {
          this.toggleNewConnection();
        }
      }
      this.sendRefresh();
    },
    displayRoomName(id) {
      const room = this.getRoomById(id);
      if (room) return `${room.name} (${room.id})`;
      return `nenalezená místnost - ${room.id}`;
    },
    clearFrom() {
      if (!this.isPicking()) {
        this.sendRefresh();
      }
      this.newConnection.fromId = 0;
    },
    clearTo() {
      if (!this.isPicking()) {
        this.sendRefresh();
      }
      this.newConnection.toId = 0;
    },
    sendRefresh() {
      setTimeout(()=> this.$emit('refresh'), 30);
    }
  },
  
  computed: {
    ...mapGetters('definitions', [
        'functionKinds',
        'rooms',
        'requirements',
    ]),
    ...mapGetters('configuration', [
        'getRoomById',
        'connections',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.error {
  padding: 0px 10px 10px 10px;
  margin: 0 auto;
  color: red;
  font-weight: 600;
  text-align: center;
}

.create-connection {
  background: $general-background;
  h1.new-connection {
    margin: 0;
    padding: 5px;
  }
  .node {
    flex-grow: 1;
    flex-basis: 0;
    &:not(.flags) {
      flex-grow: 0.5;
    }
    &.flags {
      //flex-grow: 0;
      //flex-basis: 140px;
      .checkbox {
        margin-top: 5px;
      }
    }
    margin: 10px;
    label {
      font-weight: bold;
    }
    .select-msg {
      background: $input-color;
      border-radius: 30px;
      text-align: center;
      line-height: 50px;
      height: 50px;
      padding: 5px;
    }
  }
  .buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding-bottom: 10px;
  }
}
</style>
