import { ConnectionTypeToName, EConnectionType, EExteriorType } from "./connections";
import { UsedRoom } from "./rooms";

export type RequirementList = Array<number>;

/**
Typ
Technický
Typologický				
 */
enum ERequirementType {
  TECHNICAL = 'technical',
  TYPOLOGICAL = 'typological',
}
const mapType = {
  "Technický": ERequirementType.TECHNICAL,
  "Typologický": ERequirementType.TYPOLOGICAL,
}


/**
Úroveň požadavku
    Lokalita
    Budova
    Typ provozu
    Provozní celek
    Místnost
    Vazba mezi prostředími
    Neaplikovatelné 
*/
enum ERequirementLevel {
  LOCATION = 'location',
  BUILDING = 'building',
  FUNCTION_TYPE = 'function-type',
  FUNCTION_UNIT = 'function-unit',
  ROOM = 'room',
  CONNECTION = 'connection',
  INAPPLICABLE = 'inapplicable',
}

const mapLevel = {
  "Lokalita": ERequirementLevel.LOCATION,
  "Budova": ERequirementLevel.BUILDING,
  "Typ provozu": ERequirementLevel.FUNCTION_TYPE,
  "Provozní celek": ERequirementLevel.FUNCTION_UNIT,
  "Místnost": ERequirementLevel.ROOM,
  "Vazba mezi prostředími": ERequirementLevel.CONNECTION,
  "Neaplikovatelné": ERequirementLevel.INAPPLICABLE,
}

function writeLevel(req: any) {
  for (const key in mapLevel) {
    if (Object.prototype.hasOwnProperty.call(mapLevel, key)) {
      if (req.level == mapLevel[key as keyof typeof mapLevel]) {
        return key;
      }
    }
  }
  throw Error(`writeLevel ${req.level}`);
}
/**
Obor (can vary):
    Akustika
    Bezpečnost při užívání staveb
    Ekologie, udržitelnost
    Hygienické požadavky
    Mechanická dolnost a stabilita
    Osvětlení, proslunění
    Požární bezpečnost
    Stavební konstrukce
    Tepelná technika, úspora energie
    Typologické požadavky
    TZB - Elektrotechnické rozvody
    TZB - Chlazení
    TZB - Kanalizace
    TZB - Plynovod
    TZB - Vodovod
    TZB - Vytápění
    TZB - Vzduchotechnika, větrání
    Zbývající požadavky					
*/

const mapTechnicalField = {
  'mechanická odolnost a stabilita': 'mechanická odolnost a stabilita',
  'požární bezpečnost': 'požární bezpečnost',
  'ochrana zdraví osob a zvířat, zdravých životních podmínek a životního prostředí': 'ochrana zdraví osob a zvířat, zdravých životních podmínek a životního prostředí',
  'ochrana proti hluku': 'ochrana proti hluku',
  'bezpečnost při užívání': 'bezpečnost při užívání',
  'úspora energie a tepelná ochrana': 'úspora energie a tepelná ochrana',
  'nelze zařadit': 'nelze zařadit',
}

/*
  TYPOLOGICKE OBORY:
============================
Dimenzační (rozměry)
Dimenzační (kvantitativní)
Dispoziční
Vybavenost
Lokalita
Technické požadavky
*/

const mapTypologicalField = {
  'Dimenzační (rozměry)': 'Dimenzační (rozměry)',
  'Dimenzační (kvantitativní)': 'Dimenzační (kvantitativní)',
  'Dispoziční': 'Dispoziční',
  'Vybavenost': 'Vybavenost',
  'Lokalita': 'Lokalita',
  'Technické požadavky': 'Technické požadavky',
}

type ERequirementFeild = any;

function writeType(req: any) {
  for (const key in mapType) {
    if (Object.prototype.hasOwnProperty.call(mapType, key)) {
      if (req.type == mapType[key as keyof typeof mapType]) {
        return key;
      }
    }
  }
  throw Error(`writeType ${req.type} ${req}`);
}

/**
Pravidlo výroku
Menší než
Menší nebo rovno než
Větší než
Větší nebo rovno než
Interval (včetně krajních hodnot)
Interval (bez krajních hodnot)
Splněno?
*/
enum ERequirementOperator {
  LESS = '<',
  LESSEQUAL = '<=',
  GREATER = '>',
  GREATEREQUAL = '>=',
  IN_EXCL = 'in-ex',
  IN_INCL = 'in-inc',
  EQUAL = '==',
}

const mapOperator = {
  'Menší než': ERequirementOperator.LESS,
  'Menší nebo rovno než': ERequirementOperator.LESSEQUAL,
  'Větší než': ERequirementOperator.GREATER,
  'Větší nebo rovno než': ERequirementOperator.GREATEREQUAL,
  'Interval (včetně krajních hodnot)': ERequirementOperator.IN_EXCL,
  'Interval (bez krajních hodnot)': ERequirementOperator.IN_INCL,
  'Splněno?': ERequirementOperator.EQUAL,
}

function writeOperator(req: any) {
  for (const key in mapOperator) {
    if (Object.prototype.hasOwnProperty.call(mapOperator, key)) {
      if (req.operator == mapOperator[key as keyof typeof mapOperator]) {
        return key;
      }
    }
  }
  throw Error(`writeOperator ${req.operator}`);
}

/**
Vyhondnoceni
 */
enum ERequirementEvaluation {
  USER = "user",
//  AUTO = "auto",
}

const mapRequirementEvaluation = {
  'ruční': ERequirementEvaluation.USER,
}



function writeRule(req: Requirement) {
  const unit = req.unit? req.unit : '';
  if (req.operator == ERequirementOperator.EQUAL) return writeOperator(req);
  if (isOperatorTernary(req.operator)) {
    return `${writeOperator(req)} [${req.requiredValue1}, ${req.requiredValue2}] ${unit}`;
  }
  return `${writeOperator(req)} ${req.requiredValue1} ${unit}`;
}

class Requirement {
  id: number;
  name: string;
  level: ERequirementLevel;
  type: ERequirementType;
  technicalField: ERequirementFeild;
  technicalFieldDEK: ERequirementFeild;
  typologicalField: ERequirementFeild;
  operator: ERequirementOperator;
  //watchedParameter: string;
  unit: string;
  requiredValue1: any;
  requiredValue2: any;
  source: string[];
  articleId?: number;
  calculatorId: string;
  //evaluation: ERequirementEvaluation;

  constructor() {
    this.id = 0;
    this.name = '';
    this.level = ERequirementLevel.CONNECTION;
    this.type = ERequirementType.TECHNICAL;
    this.technicalField = '';
    this.technicalFieldDEK = '';
    this.typologicalField = '';
    this.operator = ERequirementOperator.LESS;
    //this.watchedParameter = '';
    this.unit = '';
    this.requiredValue1 = null;
    this.requiredValue2 = null;
    //this.evaluation = ERequirementEvaluation.USER;
    this.source = [];
    this.articleId = undefined;
    this.calculatorId = '';
  }

  
  static Create(
    {
      id,
      name,
      level,
      type = ERequirementType.TECHNICAL,
      technicalField = 'nelze zařadit',
      technicalFieldDEK = 'nelze zařadit',
      typologicalField = 'nelze zařadit',
      operator = ERequirementOperator.LESS,
      //watchedParameter = '',
      unit = '',
      requiredValue1 = null,
      requiredValue2 = null,
      //evaluation = ERequirementEvaluation.USER,
      source = [],
      articleId = undefined,
      calculatorId = '',
    } : Requirement
    ) {
    const req = new Requirement();
    req.id = id;
    req.name = name;
    req.level = level;
    req.type = type;
    req.technicalField = technicalField;
    req.technicalFieldDEK = technicalFieldDEK;
    req.typologicalField = typologicalField;
    req.operator = operator;
    //req.watchedParameter = watchedParameter;
    req.unit = unit;
    req.requiredValue1 = requiredValue1;
    req.requiredValue2 = requiredValue2;
    //req.evaluation = evaluation;
    req.source = source;
    req.articleId = articleId;
    req.calculatorId = calculatorId;
    return req;
  }
}

export type IRequirement = typeof Requirement; 

function noValue(value: any) {
  return value === undefined || value === null;
}

class RequirementOwner {
  functionTypeIdx?: number;
  roomId?: number;
  fromId?: number;
  toId?: number;
  connType?: EConnectionType;

  constructor(idx?:number, roomId?:number, fromId?:number, toId?:number, connType?: EConnectionType) {
    if (idx !== undefined) this.functionTypeIdx = idx;
    if (roomId !== undefined) this.roomId = roomId;
    if (fromId !== undefined) this.fromId = fromId;
    if (toId !== undefined) this.toId = toId;
    if (connType !== undefined) this.connType = connType;
  }

  has(param:string) {
    return param in this;
  }
  /**
   * @brief Create RequirementOwner from json object.
   * @param data to create from
   * @returns new object of RequirementOwner
   */
  static Create(json : RequirementOwner) {
    if (json) { 
      return new RequirementOwner(json.functionTypeIdx, json.roomId, json.fromId, json.toId, json.connType);
    }
    return new RequirementOwner();
  }
}



type AppiedRequirementMappingKeys = 'level'|'type'|'operator'|'fulfilled'|'functionKindId';
class AppliedRequirement {
  static filterParameters:string[] = [
    'level',
    'type',
    'technicalField',
    'typologicalField',
    'fulfilled',
    'functionKindId',
    'room',
    'constructions',
    'source'
  ];

  static computedParameterDisplayName:Record<string, string> = {
    fulfilled: 'Splněnost',
    functionKindId: 'Druh provozu',
    room: 'Místnost',
    constructions: 'Konstrukce',
    rule: 'Pravidlo',
  }
  
  static mappers: Record<AppiedRequirementMappingKeys, Record<string, string> | ((val:any, getters?:any) => any)>  = {
    'level': reverseMapper(mapLevel),
    'type': reverseMapper(mapType),
    'operator': reverseMapper(mapOperator),
    'fulfilled': (val:any):any => (val? 'Ano' : 'Ne'),
    'functionKindId': (val, getters) => {
      return getters.functionKindName(val);
    }

  }

  static appliedRequirementParams : string[] = [
    'note',
    'owner',
    'fulfilled',
    'instanceId',
    'evaluation',
  ]
  instanceId: number;
  note: string;
  owner: RequirementOwner;
  fulfilled: boolean;
  evaluation: ERequirementEvaluation;
  data: Requirement & Record<string, any>;
  computed: Record<string, (getters?:any) => any>;
  // origine -> [ ]
  // origine for each level.
  // room - idx, id, 
  // functionKind - idx
  // connection - fromId, toId
  constructor(instanceId: number, requirement: Requirement, owner: RequirementOwner, fulfilled = false, note = "", evaluation = ERequirementEvaluation.USER) {
    //super();
    this.instanceId = instanceId;
    this.data = requirement;
    this.note = note;
    this.owner = owner;
    this.computed = {};
    this.fulfilled = fulfilled;
    this.evaluation = evaluation;
    this.initComputedProperties();
  }

  static Create(requirementJson: AppliedRequirement) {
    const owner = RequirementOwner.Create(requirementJson.owner);
    const requirement = Requirement.Create(requirementJson.data);
    return new AppliedRequirement(
      requirementJson.instanceId, 
      requirement, 
      owner, 
      requirementJson.fulfilled || false, 
      requirementJson.note || "", 
      requirementJson.evaluation || ERequirementEvaluation.USER
    );
  }

  initComputedProperties() {
    this.computed.functionKindId = (getters) => {
      if (this.owner && this.owner.has('functionTypeIdx') && getters) {
        const functionKind = getters.getFunctionConfiguration(this.owner.functionTypeIdx)
        return functionKind.functionKindId;
      }
      if (!getters) console.error("no getter for functionKind");
      if (this.data.level == ERequirementLevel.FUNCTION_TYPE) console.error("FT without backReference");
      return null;
    }
    this.computed.room = (getters) => {
      if (this.owner 
       && this.owner.has('roomId')
       && this.owner.has('functionTypeIdx')
       && getters) {
         const room = getters.getRoomById(this.owner.roomId);
         if (!room) {
           console.error(`No room ${this.owner.roomId}.`);
           return null;
         }
         return room.name;
      }
      if (!getters) console.error("no getter for room");
      if (this.data.level == ERequirementLevel.ROOM) console.error("room without backReference");
      return null;
    }
    this.computed.constructions = (getters) => {
      if (this.owner
       && this.owner.has('fromId')
       && this.owner.has('toId')) {
        // since every requirement binds to just one construction. Computed parameter is not necessary.
        return [this.owner.connType];
      }
      if (!getters) console.error("no getter for constructions");
      if (this.data.level == ERequirementLevel.CONNECTION) console.error("connection without backReference");
      return null;
    };
    this.computed.rule = () => {
      const unit = this.data.unit? this.data.unit : '';
      if (this.data.operator == ERequirementOperator.EQUAL) {
        return `${this.displayParam('operator')}`;
      }
      if (this.isOperatorTernary()) {
        return `${this.displayParam('operator')} [${this.data.requiredValue1}, ${this.data.requiredValue2}] ${unit}`;
      }
      return `${this.displayParam('operator')} ${this.data.requiredValue1} ${unit}`;
    };
    this.computed.identification = (getters) => {
      if (this.owner.has('roomId')) {
        const room = getters.getRoomById(this.owner.roomId) as UsedRoom;
        if (room) {
          const idx = getters.getIdxFromRoomId(room.id);
          const functionKind = getters.getFunctionConfiguration(idx);
          const functionKindName = functionKind? functionKind.functionKindName : 'neznamý typ provozu';
          return `${room.name} (${functionKindName})`
        }
        return null;
      }
      if (this.owner.has('fromId') && this.owner.has('fromId')) {
        /*
          Případně zkráceně: Vazba stropem: Parkovací stání vnitřní (Dopravní prostory) ➞ Obývací pokoj (Obytné prostory)                        
        */
        const connTypeName = `Vazba <i>${ConnectionTypeToName[this.owner.connType!]}</i>`
        const fromRoom = getters.getRoomById(this.owner.fromId) as UsedRoom;
        fromRoom.functionKind
        const fromName = fromRoom? fromRoom.name : 'místnost nenalezena';
        let fromFunctionKindName, toFunctionKindName;
        if (fromRoom) {
          const idx = getters.getIdxFromRoomId(fromRoom.id);
          const functionKind = getters.getFunctionConfiguration(idx);
          fromFunctionKindName = functionKind? functionKind.functionKindName : 'neznamý typ provozu';
        }
        let toName;
        if (this.owner.toId! < 0) {
          toName = this.owner.toId == EExteriorType.GROUND? 'terén' : 'venkovní vzduch';
          return `${connTypeName}: <i>${fromName}</i> (${fromFunctionKindName}) ➞ <i>${toName}</i>`;
        } else {
          const toRoom = getters.getRoomById(this.owner.toId);
          toName = toRoom.name;
          if (toRoom) {
            const idx = getters.getIdxFromRoomId(toRoom.id);
            const functionKind = getters.getFunctionConfiguration(idx);
            toFunctionKindName = functionKind? functionKind.functionKindName : 'neznamý typ provozu';
          }
          return `${connTypeName}: <i>${fromName}</i> (${fromFunctionKindName}) ➞ <i>${toName}</i> (${toFunctionKindName})`;
        }
      }
      if (this.owner.has('functionTypeIdx')) {
        const functionKind = getters.getFunctionConfiguration(this.owner.functionTypeIdx)
        return functionKind? functionKind.functionKindName : 'neznamý typ provozu';
      }
    };
  }

  setParam(name:string, value:any) {
    console.log("pass setParam", name, value, this.instanceId);
    if (AppliedRequirement.appliedRequirementParams.includes(name)) {
      (this as any)[name] = value;
    } else if (name in this.data) {
      //this.data[name] = value;
      const data = {...this.data};
      data[name] = value;
      this.data = data;
    } else {
      console.error('setParameter', name, value);
    }
  }

  getParam(name:string, getters?:any) {
    if (AppliedRequirement.appliedRequirementParams.includes(name)) return (this as any)[name];
    if (name in this.data) {
      return this.data[name];
    }
    // console.log('getParam',name, name in this.computed);
    // console.log(this.computed[name]);
    
    if (name in this.computed) {
      return this.computed[name](getters);
    }
  }

  displayParam(name:string, getters?:any) {
    const value = this.getParam(name, getters)
    if (name in AppliedRequirement.mappers) {
      //console.log(this.getParam(name));
      const mapper = AppliedRequirement.mappers[name as AppiedRequirementMappingKeys];
      if (typeof mapper === 'function') {
        return mapper(value, getters);
      }
      return mapper[this.getParam(name)];
    }
    return value;
  }

  hasNote() {
    return !!this.note; 
  }

  doesIntersectWithSources(inFilter: Array<string>) {
    return this.data.source.some(s => inFilter.includes(s));
  }

  isOperatorTernary() {
    return this.data.operator == ERequirementOperator.IN_INCL || this.data.operator == ERequirementOperator.IN_EXCL
  }

  listProblems() {
    const warnings = [];
    if (!this.isOperatorTernary() && noValue(this.data.requiredValue1)) {
      warnings.push('Požadavek vyžaduje vyplnit požadovanou hodnotu.');
    }
    if (this.isOperatorTernary() && (noValue(this.data.requiredValue1) || noValue(this.data.requiredValue2))) {
      warnings.push('Požadavek vyžaduje vyplnit požadovaný interval hodnot.');
    }
    return warnings;
  }
  

  isOk() {
    if (this.isOperatorTernary()) {
      return !noValue(this.data.requiredValue1) && !noValue(this.data.requiredValue2);
    }
    return !noValue(this.data.requiredValue1);
  }
}

function mapperToOptions(mapper:Record<string, string>, addDefaultAll = true) {
  const all = {name: "Vše", value: ""};
  const options = Object.entries(mapper).map(([name, value]) => ({name, value}));
  if (addDefaultAll) return [all, ...options];
  return options;
}

function reverseMapper(mapper:Record<string, string>) {
  const res:Record<string, string> = {};
  for (const key in mapper) {
    if (Object.prototype.hasOwnProperty.call(mapper, key)) {
      const element = mapper[key];
      res[element] = key;      
    }
  }
  return res;
}

function isOperatorTernary(operator:ERequirementOperator) {
  return operator == ERequirementOperator.IN_INCL || operator == ERequirementOperator.IN_EXCL
}

const levelOptions = mapperToOptions(mapLevel);
const typeOptions = mapperToOptions(mapType);
const techincalFieldOptions = mapperToOptions(mapTechnicalField);
const typologicalFieldOptions = mapperToOptions(mapTypologicalField);
const operatorOptions = mapperToOptions(mapOperator);
const requirementEvaluationOptions = mapperToOptions(mapRequirementEvaluation, false);

function isTechnical(requirement: Requirement) {
  return requirement.type === ERequirementType.TECHNICAL;
}
function isTypological(requirement: Requirement) {
  return requirement.type === ERequirementType.TYPOLOGICAL;
}
function writeField(requirement: Requirement) {
  if (isTechnical(requirement)) return requirement.technicalField;
  return requirement.typologicalField;
}

export {
  Requirement,
  RequirementOwner,
  AppliedRequirement,
  ERequirementLevel,
  ERequirementOperator,
  ERequirementType,
  mapLevel,
  mapOperator,
  mapType,
  writeLevel,
  writeType,
  writeField,
  writeOperator,
  writeRule,
  typeOptions,
  levelOptions,
  techincalFieldOptions,
  typologicalFieldOptions,
  operatorOptions,
  requirementEvaluationOptions,
  isOperatorTernary,
  isTypological,
  isTechnical,
}
