import { LinePath } from './LinePath';
import { PathOptions, Point } from './../models';

export class CurvyPath extends LinePath {

  constructor(options: PathOptions, debug = false) {
    super(options, debug);
  }

  /**
   * Returns an SVG path (or what's supposed to be in attribute `d`)
   * You can extend this function and add your own logic to draw whatever path you'd like.
   * @param endBbox bbox of the end dom element
   * @param startBbox bbox of the start dom element
   * @returns path string
   */
  getPath(): string {
    const { start, end } = this.getArrowData();
    //console.log({ width, height, start, end })

    const startX = start.x;
    const startY = start.y;
    const endX = end.x;
    const endY = end.y;

    if (this.isSameColumnElement()) {
      if (this.isSameElement()) {
        // same element
        //console.log("same element");
        const cycleSize = 42;
        const points = [
          {x: startX, y: startY},
          {x: startX + cycleSize, y: startY + cycleSize},
          {x: startX + cycleSize, y: startY - cycleSize},
          {x: startX, y: startY},
        ];
        return this.svgPath(points);
      }
      // same element
      const deflection = 42;
      const points = [
        {x: startX, y: startY},
        {x: startX + deflection, y: startY + 0},
        {x: startX + deflection, y: endY - 0},
        {x: startX, y: endY},
      ];
      return this.svgPath(points);

    }

    const points = [
      {x: startX, y: startY},
      {x:Math.abs(startX + (-startX + endX)*.5), y: startY},
      //{x:Math.abs(startX + (-startX + endX)*.5), y: Math.abs(startY + (-startY + endY)*.5)}, // center
      {x:Math.abs(startX + (-startX + endX)*.5), y:endY},
      {x: endX, y: endY}
    ];

    return this.svgPath(points);
  }

  svgPath(points: Point[]): string {
    return `
    M ${points[0].x},${points[0].y} 
    C ${points[1].x},${points[1].y} ${points[2].x},${points[2].y} ${points[3].x},${points[3].y}`;
    // C ${points[3].x},${points[3].y} ${points[3].x},${points[3].y} ${points[4].x},${points[4].y}
    // `;
  }
}
