<template>
  <div class="project-share" v-if="isProjectOpened">  

    <h1 class="title">Sdílení projektu</h1>

    <div class="flex-column">
      <div v-for="(owner, idx) in openedProject.owners" class="user flex-row" :key="idx">
        <div class="name">
          {{owner.username}} 
        </div> 
        <div v-if="idx == 0" class="tag owner">Vlastník</div>
        <div v-else class="tag owner">Spoluautor</div>
        <div v-if="isMe(owner._id)" class="tag me">Já</div>
        <div class="spring-grow"></div>
        <div class="owner-controls gap-10 flex-row" v-if="amIOwner()">
          <button 
            class="clickable"
            @click="PROJECT_SET_OWNER(owner._id)"
          >nastavit jako vlastníka</button>
          
          <button 
            class="clickable far fa-trash-alt"
            @click="PROJECT_REMOVE_USER(owner._id)" 
          > odstranit z projektu</button>
          
        </div>
      </div>
    </div>
    
    <div v-if="amIOwner()">
      další spoluautor 
      <select v-model="userToShareWith">
        <option 
          v-for="user in users" 
          :value="user._id" 
          :key="user._id" 
          v-text="user.username" 
        ></option>

      </select>
      <button @click="PROJECT_ADD_USER(userToShareWith)" class="btn btn-dark">přidat</button>
    </div>
    
  </div>
</template>

<script>
import {mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            objectFunctions: [
                "living-function",
            ],
            userToShareWith: "",
        }
    },
    computed: {
        ...mapGetters('users', ['users']),
        ...mapGetters('project', ['isProjectOpened', 'openedProject']),
        ...mapGetters('user',  ["username", "id"]),
    },
    methods: {
        ...mapActions('project',[
          'PROJECT_SET_OWNER',
          'PROJECT_REMOVE_USER',
          'PROJECT_ADD_USER',
        ]),
        isMe(id) {
          return this.id == id;
        },
        amIOwner() {
          return this.id == this.openedProject.owners[0]._id;
        }
    }
}

</script>

<style lang="scss" scoped>
.project-share {
  .user {
    height: 50px;
    line-height: 50px;
    background-color: white;
    border: solid 1px black;
    gap: 10px;
    align-items: baseline;
  }

  .name {
    padding: 3px;
    min-width: 160px;
  }

  .spring-grow {
    flex-grow: 1;
  }

  .tag  {
    line-height: 20px;
    font-weight: 100;
    padding: 3px;
    border-radius: 4px;
    background-color: rgb(140, 211, 33);
    &.me {
      background-color: pink;
    }
  }
}
</style>
