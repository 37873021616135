import { Connection, EConnectionType } from "@/@types/definitions/connections";
import { IEntityLocation } from "@/@types/definitions/entity";
import { ERequirementLevel } from "@/@types/definitions/requirements";

export interface ITreeStructureNode {
  name: string,
  location: IEntityLocation,
  children: ITreeStructureNode[],
  checked: boolean,
}

export class ConnectionNodeSharedData {
  wall = false;
  ceiling = false;
  floor = false;
  opening = false;
  virtual = false;
}

class SharedConnectionNode implements ITreeStructureNode {
  name = '';
  connection: Connection;
  caseFromId: number;
  caseToId: number;
  reversed: boolean;
  sharedData: ConnectionNodeSharedData;
  connType: EConnectionType;
  
  constructor(
      name: string,
      connection: Connection, 
      caseFromId: number, 
      caseToId: number, 
      connType: EConnectionType, 
      sharedData: ConnectionNodeSharedData) {
    this.name = name;
    this.connection = connection; 
    this.caseFromId = caseFromId;
    this.caseToId = caseToId;
    this.connType = connType;
    this.reversed = false;
    if (this.connection.fromId == caseFromId && this.connection.toId == caseToId) {
      this.reversed = false;
    } else if (this.connection.fromId == caseToId && this.connection.toId == caseFromId) {
      this.reversed = true;
    } else {
      throw new Error(
        `SharedConnectionNode: connection is not compatible with`
        + ` casefromId=${caseFromId} caseToId=${caseToId}.`);
    }
    this.sharedData = sharedData;
  }

  get location() {
    return {
      level: ERequirementLevel.CONNECTION,
      fromId: this.connection.fromId, 
      toId: this.connection.toId, 
      connType: this.connType
    }  as IEntityLocation;
  }
  get checked(): boolean {
    switch (this.connType) {
      case EConnectionType.WALL: return this.sharedData.wall;
      case EConnectionType.FLOOR: return this.sharedData.floor;
      case EConnectionType.CEILING: return this.sharedData.ceiling;
      case EConnectionType.OPENING: return this.sharedData.opening;
      case EConnectionType.VIRTUAL: return this.sharedData.virtual;
    }
    throw new Error(`Invalid connType ${this.connType}.`);
  }
  set checked(value: boolean) {
    console.log(`set checked(value: boolean) reversed ${this.reversed}`);
    
    let construction:keyof ConnectionNodeSharedData = 'wall';
    switch (this.connType) {
      case EConnectionType.WALL: construction = 'wall'; break;
      case EConnectionType.FLOOR: construction = 'floor'; break;
      case EConnectionType.CEILING: construction = 'ceiling'; break;
      case EConnectionType.OPENING: construction = 'opening'; break;
      case EConnectionType.VIRTUAL: construction = 'virtual'; break;

      default: throw new Error(`Invalid connType ${this.connType}.`);
    }
    console.log(`settings ${construction} [old ${this.sharedData[construction]} to ${value}]`)
    this.sharedData[construction] = value;
  }
  get children(): ITreeStructureNode[] {
    return [];
  }
}


export function getFunctionKindsRecursive(node: ITreeStructureNode) {
  const res = [] as IEntityLocation[];
  if (node.location.level === ERequirementLevel.FUNCTION_TYPE
    && node.checked) {
    res.push(node.location);
    return res;
  }
  if (node.children && node.children.length > 0) {
    node.children.forEach(child => {
      const subres = getFunctionKindsRecursive(child);
      res.push(...subres);
    });
  }
  return res;
}

export function getRoomsRecursive(node: ITreeStructureNode) {
  const res = [] as IEntityLocation[];
  if (node.location.level === ERequirementLevel.ROOM
    && node.checked) {
    res.push(node.location);
    return res;
  }
  if (node.children && node.children.length > 0) {
    node.children.forEach(child => {
      const subres = getRoomsRecursive(child);
      res.push(...subres);
    });
  }
  return res;
}

export function getConnectionsRecursive(node: ITreeStructureNode) {
  const res = [] as IEntityLocation[];
  if (node.location.level === ERequirementLevel.CONNECTION
    && node.checked) {
    if ((node as SharedConnectionNode).reversed == false) {
      res.push(node.location);
    }
    return res;
  }
  if (node.children && node.children.length > 0) {
    node.children.forEach(child => {
      const subres = getConnectionsRecursive(child);
      res.push(...subres);
    });
  }
  return res;
}

export function treeStructureNode (name: string, location:IEntityLocation, children:ITreeStructureNode[], checked = false) : ITreeStructureNode {
  return {
    name,
    location,
    children,
    checked,
  }
}

export function setRecursive(node:ITreeStructureNode, value: boolean) {
  if (node) {
    node.checked = value;
  }
  if (node.children && node.children.length > 0) {
    node.children.forEach(child => setRecursive(child, value));
  }
}

export function consolidateRecursive(node:ITreeStructureNode) {
  if (!node) return false;
  if (node && node.children && node.children.length == 0) {
    return node.checked;
  }
  let allSet = false;
  if (node.children && node.children.length > 0) {
    node.children.forEach(child => consolidateRecursive(child));
    allSet = !node.children.some(child => !child.checked);
  }
  node.checked = allSet;
  return allSet;
}



export function treeStructureNodeConnection(conn:Connection, fromId:number, toId:number, sharedData: ConnectionNodeSharedData) {
  let reversed = false;
  if (conn.fromId == fromId && conn.toId) reversed = false;
  else reversed = true;
  const connections = [];
  if (conn.wall)
    connections.push(new SharedConnectionNode('vazba stěnou', conn, fromId, toId, EConnectionType.WALL, sharedData));
  if (conn.ceiling)
    connections.push(new SharedConnectionNode(reversed? 'vazba podlahou' : 'vazba stropem' , conn, fromId, toId, EConnectionType.CEILING, sharedData));
  if (conn.floor)
    connections.push(new SharedConnectionNode(reversed? 'vazba stropem' : 'vazba podlahou', conn, fromId, toId, EConnectionType.FLOOR, sharedData));
  if (conn.opening)
    connections.push(new SharedConnectionNode('vazba výplní otvoru', conn, fromId, toId, EConnectionType.OPENING, sharedData));
  if (conn.virtual)
    connections.push(new SharedConnectionNode('vazba bez konstrukce', conn, fromId, toId, EConnectionType.VIRTUAL, sharedData));
  
    return connections;
}

