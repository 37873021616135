<template>
  <div class="concept-calculator">
    <div v-show="state === states.GROUND">
        <ground-calculator :cs="cs" />
    </div>
    <div v-show="state === states.BUILDING">
        <residential-apartments-building :cs="cs" />
    </div>
    <div v-if="state === states.OUTRO">
        <residential-apartments-outro :cs="cs" />
    </div>
    <div class="flex-row next-prev">
      <button @click="goPrev" v-if="hasPrev()" class="primary prev">Zpět</button>
      <button @click="goNext" v-if="hasNext()" class="primary next">Pokračovat</button>
    </div>
      
  </div>
</template>

<script>
import { composeGroundPlan } from '../groundPlan'
import { composeResidentialApartments } from './apartment'
import GroundCalculator from '../GroundCalculator.vue'
import ResidentialApartmentsBuilding from './ResidentialApartmentsBuilding.vue'
import ResidentialApartmentsOutro from './ResidentialApartmentsOutro.vue'

const states = {
    GROUND: 'ground',
    BUILDING: 'building',
    APARTMENT: 'apartment',
    ROOM: 'room',
    OUTRO: 'outro'
}

const order = [
  'ground', 'building', 'outro'
];

export default {
    components: { 
      GroundCalculator, ResidentialApartmentsBuilding, ResidentialApartmentsOutro, 
    },
    created() {
        const groundCS = composeGroundPlan();
        const residentialApartmentsCS = composeResidentialApartments(groundCS);
        this.cs = residentialApartmentsCS;
        this.state = states.GROUND;
        this.initGraph();
    },
    data() {
        return {
            cs: null,
            state: '',
            order: order,
            graph: {},
            states,
        }
    },
    methods: {
      initGraph() {
        for (let i = 0; i < this.order.length; i += 1) {
          const node = this.order[i];
          let prev = null, next = null;
          if (i > 0) prev = this.order[i-1];
          if (i+1 < this.order.length) next = this.order[i+1];
          this.graph[node] = {
            prev, next
          }
        }
      },
      hasNext() {
        return (this.state in this.graph) 
            && this.graph[this.state].next;
      },
      hasPrev() {
        return (this.state in this.graph) 
            && this.graph[this.state].prev;
      },
      goNext() {
        if (!this.hasNext()) return;
        this.state = this.graph[this.state].next;
      },
      goPrev() {
        if (!this.hasPrev()) return;
        this.state = this.graph[this.state].prev;
      }
    }
}
</script>

<style lang="scss">
.concept-calculator {
  padding: 10px;
}
.next-prev {
  align-items: flex-end;
  height: 50px;
  .prev {
    margin-right: auto;
  }
  .next {
    margin: 0 0 0 auto;
  }
}
</style>
