<template>
  <div class="room-controls">

    <i class="clickable show-on-focus fa fa-plus" v-if="isEditMode"
      v-show="!connectionKindsForLayout.every(connectionKind => getRoomFlag({name: connectionKind.name, idx, room}))"
      @click="openAddMenu()"
    ></i>

    <i v-for="connectionKind in connectionKindsForLayout" :key="connectionKind.name"
      @click="openConnectionSetting(connectionKind.name)" 
      class="toggle-icon clickable"
      :class="{...connectionKind.classes, active: getRoomFlag({name: connectionKind.name, idx, room})}" 
      v-show="getRoomFlag({name: connectionKind.name, idx, room})"
    ></i>
    <!-- <i class="clickable fa fa-eye"></i> -->
    <i class="clickable show-on-focus fas fa-link" @click.stop="openConnections()" ></i>
    <i v-if="isEditMode" @click.stop="removeRoomAction(idx, room)" class="clickable show-on-focus far fa-trash-alt"></i>
    <i v-if="isRequirementsMode" class="fas fa-tasks clickable show-on-focus" @click.stop="openRequirementsDialog({idx, room})"></i>
  </div>
</template>

<script>
import { ESchemaMode } from '@/@types/definitions/mode';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    mode: {
      type: Number,
      default: ESchemaMode.EDIT,
    },
    idx: {
      type: Number,
      required: true,
    },
    room: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapMutations("configuration", [
      "addRoom", 
      "removeRoom",
      'addConnectionToExterior',
      'removeConnectionToExterior',
    ]),
    ...mapActions("configuration", [
      'addRoomRequirement',
    ]),
    openConnections () {
      this.$emit('open-connections');
    },
    removeRoomAction(idx, room) {
      const message = `Smazat místnost ${room.name}?`
      if (confirm(message)) {
        this.removeRoom({idx, room})
        this.sendRefresh();
      }
    },
    close() {
      this.addDialog.show = false;
    },
    sendRefresh() {
      setTimeout(()=> this.$emit('refresh'), 30);
    },
    openAddMenu() {
      this.eventBus.emit('open-exterior-connection-add-menu', {
        idx: this.idx,
        room: this.room,
        mode: this.mode,
      });
    },
    openConnectionSetting(state) {
      this.eventBus.emit('open-exterior-connection-setting', {
        idx: this.idx,
        room: this.room,
        mode: this.mode,
        state,
      });
    },
    openRequirementsDialog(data) {
      console.log('open-room-requirements', data)
      this.eventBus.emit('open-room-requirements', data);
    },
  },
  computed: {
    ...mapGetters('definitions', [
      'functionKinds',
      'rooms',
      'requirements',
    ]),
  ...mapGetters('configuration', [
      'projectName',
      'functionConfigurations',
      'buildingVolumeInputMethod',
      'showFunctionKind',
      'isRoomInProject',
      'findFunctionIndexByRoomId',
      'connections',
      'functionKindRequirements',
      'roomRequirements',
      'getRoomFlag',
    ]),
    isEditMode() {
      return this.mode == ESchemaMode.EDIT;
    },
    isRequirementsMode() {
      return this.mode == ESchemaMode.REQUIREMENTS;
    },
  },
  data() {
    return {
      connectionKindsForLayout: [
        {name: 'ground', classes: {fas:true, 'fa-seedling':true}},//<i class="fas fa-seedling"></i>
        {name: 'air', classes: {fas:true, 'fa-cloud-sun':true}},
      ],
      addDialog: { 
        show: false,
        state: 'choose',
      }
    };
  }
}
</script>

<style lang="scss" scoped>

.room:hover .room-controls{
  position: relative;
  .show-on-focus {
    display: block;
  }
}
.room-controls {
  top: 0px;
  right: 0px;
  //position: absolute;
  display: inline-flex;
  gap: 10px;
  margin: 0 10px;

  .show-on-focus {
    display: none;
  }

  .popup-modal {
    .halves {
      * {/* button */
        align-self: center;
        width: 30%;
        .btn-column {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          * {
            width: 100%;
          }
        }
        i {
          font-size: 60px;
        }
      }
      
      justify-content: space-around;
    }

    .node {
      color: black;
    }
    .node.choose {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .controls {
      display: flex;
      flex-direction: row;
      justify-content: end;
      gap: 10px;
    }
  }
}
</style>
