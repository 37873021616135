export enum EDEKConnectionType {
  INTERIOR = 'interior',
  AIR = 'air',
  GROUND = 'ground',
}

export enum EConstructionType {
  WALL = 'wall',
  CEILING = 'ceiling',
  FLOOR = 'floor',
  OPENING = 'opening',
}

export enum EConnectionType {
  WALL = 'wall',
  CEILING = 'ceiling',
  FLOOR = 'floor',
  OPENING = 'opening',
  VIRTUAL = 'virtual'
}
export const ConnectionTypeToName = {
  [ EConnectionType.WALL    ]: 'stěnou',
  [ EConnectionType.CEILING ]: 'stropem',
  [ EConnectionType.FLOOR   ]: 'podlahou',
  [ EConnectionType.OPENING ]: 'výplní otvoru',
  [ EConnectionType.VIRTUAL ]: 'bez konstrukce',
}
export enum EExteriorType {
  GROUND = -1,
  AIR = -2,
}

import { RequirementList } from "./requirements"

export interface IConnection {
  fromId: number,
  toId: number,
  wall: boolean,
  floor: boolean,
  ceiling: boolean,
  opening: boolean,
  virtual: boolean,
  requirements: RequirementList,
  wallRequirements: RequirementList,
  openingRequirements: RequirementList,
  ceilingRequirements: RequirementList,
  floorRequirements: RequirementList,
  virtualRequirements: RequirementList,
}

export class Connection implements IConnection {
  fromId: number
  toId: number
  wall: boolean
  floor: boolean
  ceiling: boolean
  opening: boolean
  virtual: boolean
  requirements: RequirementList
  wallRequirements: RequirementList
  openingRequirements: RequirementList
  ceilingRequirements: RequirementList
  floorRequirements: RequirementList
  virtualRequirements: RequirementList

  constructor(fromId: number, toId: number, floor = false, wall = false, ceiling = false, opening = false, virtual = false) {
    this.fromId = fromId;
    this.toId = toId;
    this.wall = wall;
    this.floor = floor;
    this.ceiling = ceiling;
    this.opening = opening;
    this.virtual = virtual;
    this.requirements = [];
    this.wallRequirements = [];
    this.openingRequirements = [];
    this.ceilingRequirements = [];
    this.floorRequirements = [];
    this.virtualRequirements = [];
  }

  updateData(data: IConnection) {
    this.fromId = data.fromId;
    this.toId = data.toId;
    this.wall = data.wall || false;
    this.floor = data.floor || false;
    this.ceiling = data.ceiling || false;
    this.opening = data.opening || false;
    this.virtual = data.virtual || false;
    this.requirements = data.requirements || [];
    this.wallRequirements = data.wallRequirements || [];
    this.openingRequirements = data.openingRequirements || [];
    this.ceilingRequirements = data.ceilingRequirements || [];
    this.floorRequirements = data.floorRequirements || [];
    this.virtualRequirements = data.virtualRequirements || [];
    this.trimConnection();
  }

  trimConnection() {
    if (!this.wall && this.wallRequirements.length > 0) this.wallRequirements = [];
    if (!this.floor && this.floorRequirements.length > 0) this.floorRequirements = [];
    if (!this.ceiling && this.ceilingRequirements.length > 0) this.ceilingRequirements = [];
    if (!this.opening && this.openingRequirements.length > 0) this.openingRequirements = [];
    if (!this.virtual && this.virtualRequirements.length > 0) this.virtualRequirements = [];
  }

  static CreateFromJSON(data: IConnection) {
    const instance = new Connection(data.fromId, data.toId);
    instance.updateData(data)
    return instance;
  }
}
