interface AppConfig {
    "api_base_url": string | null,
    "__comment": string | undefined,
    "mode": "devel" | "production"
} 

/* eslint-disable */
const deploymentConfiguration = require("./default.json");

export default deploymentConfiguration as AppConfig; 
