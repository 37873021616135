<template>
  <div class="batch-assignment">
    <!-- Hromadné přiřazování požadavků -->

    <Section name="Úroveň požadavků" v-if="true" >
      <dropdown-input
        :value="batchAssignmentLevel"
        :options="levelOptions" 
        @input="setLevelAction"
      />
    </Section>
    

    <CollapsableAnimatedDiv heading="Aktivní (přiřazené) požadavky" v-if="batchAssignmentLevel == 'location'" >
      <AppliedRequirementList 
        :activeRequirements="locationRequirements"
        heading=""/>
    </CollapsableAnimatedDiv>

    <CollapsableAnimatedDiv heading="Aktivní (přiřazené) požadavky" v-if="batchAssignmentLevel == 'building'" >
      <AppliedRequirementList 
        :activeRequirements="buildingRequirements"
        heading=""/>
    </CollapsableAnimatedDiv>

    
    <Applicator :level="batchAssignmentLevel" ref="applicator" />

    <Selector />

    <div class="notification" v-if="batchAssignmentNotification">
      <p class="notification-text" v-text="batchAssignmentNotification"></p>
    </div>

  </div>
</template>

<script>
import CollapsableAnimatedDiv from '@/views/components/CollapsableAnimatedDiv.vue'
import { mapActions, mapGetters } from 'vuex'
import { levelOptions } from '../../../@types/definitions/requirements'
import DropdownInput from '../../components/fundamentals/DropdownInput.vue'
import Section from '../../components/Section.vue'
import AppliedRequirementList from '../AppliedRequirementList.vue'
import Applicator from './Applicator.vue'
import Selector from './Selector.vue'

export default {
  components: { DropdownInput, Applicator, Selector, Section, AppliedRequirementList, CollapsableAnimatedDiv },
  data() {
    return {
      levelOptions: levelOptions.filter(x => !['', 'function-unit', 'inapplicable'].includes(x.value))
    }
  },
  computed: {
    ...mapGetters('configuration', [
      'batchAssignmentLevel',
      'batchAssignmentNotification',
    ]),
    ...mapGetters('configuration', [
      'locationRequirements',
      'buildingRequirements',
    ]),
  },
  methods: {
    ...mapActions('configuration', [
      'setBatchAssignmentLevel',
    ]),
    setLevelAction(value) {
      console.log(value);
      this.setBatchAssignmentLevel(value);
      if (this.$refs.applicator) {
        setTimeout(() => {
          this.$refs.applicator.performFilter();
        }, 10)
      }
    },
  }
}
</script>


<style lang="scss">

.batch-assignment {
  min-height: 500px;
}

.batch-title {
  h1 {
    margin: auto 5px;
  }

}

.notification {
  display: flex;
  width: 50%;
  height: 100px;
  background-color: rgb(139, 212, 28);
  align-items: center;
  justify-content: center;
  margin: auto;

  border: 2px solid darken($secondary-color, 50%);
  p.notification-text {
    //text-align: center;
    //vertical-align: middle;
  }
}

</style>
