<template>
  <div class="collapsable-div">
    <div class="heading-stripe flex-row" @click="toggle">
      <span class="title" v-text="heading"></span>
      <i class="icon-collapsable fas fa-chevron-down" v-if="showChildren"></i>
      <i class="icon-collapsable fas fa-chevron-right" v-else></i>
    </div>
    <div class="div-content" ref="divContent" :class="{hide: showChildren == false}">
      <div class="slot-wrapper" ref="slotWrapper">
        <slot></slot>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
    },
  },
  created() {
    this.showChildren = this.opened;
  },
  data() {
    return {
      showChildren: true,
      observer: null,
    }
  },
  methods: {
    toggle() {
      this.showChildren = !this.showChildren;
      this.update();
    },
    update() {
      const el = this.$refs.divContent;
      if (el) {
        if (this.showChildren) {
          el.style.height = `${el.childNodes[0].clientHeight}px`;
          if (this.observer == null)
            this.registerMutationObserver(el.childNodes[0]);
        } else {
          el.style.height = `${0}px`;
          this.observer.disconnect();
          this.observer = null;
        }
      }
    },
    registerMutationObserver(target) {
      // create an observer instance
      const observer = new MutationObserver((mutations) => {
        console.log("mutation batch", mutations.length, mutations);
        
        this.update();
      });

      // configuration of the observer:
      const config = { childList: true, subtree: true };
      
      // pass in the target node, as well as the observer options
      observer.observe(target, config);
      this.observer = observer;
    }
  }
}
</script>

<style lang="scss">
.collapsable-div {
  margin: 0 1px;
  border: 1px solid darken($secondary-color, 20%);
  .heading-stripe {
    padding: 5px;
    background-color: $secondary-color;
    justify-content: space-between;
    line-height: 1.1rem;
  }
  .slot-wrapper {
    padding: 0 5px;
  }
  .div-content {
    transition: height 500ms;
    overflow: hidden;
    &:not(.hide) {
      min-height: 10px;
    }
    //height: 600px;
    //transform: translateX(0);
  }
  .div-content.hide {
    
    height: 0;
      //transform: translateX(-100%);
    }
  .title {
    font-size: 1.1rem;
    padding-right: 20px;
  }
}
</style>
