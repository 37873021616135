<template>
  <div class="process-navbar">
    <nav class="flex">
      <router-link to="/PC/architecture" class="nav-item flex-inline">
        <span class="progressbar-string">Schéma objektu</span>
      </router-link>

      <router-link to="/PC/requirements" class="nav-item flex-inline">
        <span class="progressbar-string">Požadavky</span>
      </router-link>
      
      <router-link to="/PC/checklist" class="nav-item flex-inline">
        <span class="progressbar-string">Přehledné seznamy</span>
      </router-link>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
//import { ToggleButton } from "./components/customElements";

export default {
  name: 'ProcessNavbar',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getText']),
  },
  methods: {
    ...mapMutations('result', ['setCurrentTab']),
    startAgain() {
      this.$store.dispatch('LOAD_INITIAL_STATE')
    },
  },
  components: {
    // ToggleButton,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss" scope>
$navbar-height: 46px;
.process-navbar {
  background: $secondary-color;
  padding: 0 0 0 0;
  //border-bottom: solid 1px rgba($color: #e0e0e0, $alpha: 1);

  .flex {
    display: flex;
    justify-content: space-around;
  }

  .nav-item {
    text-align: center;
    font: normal normal 600 16px/22px Noto Sans;
    letter-spacing: 0px;
    //color: #8B8B8B;

    color: $tab-color;
    font-weight: 500;
    font-size: 16px;
    line-height: $navbar-height;
    height: $navbar-height;
  }
  .nav-item {
    text-decoration: none;
    position: relative;
    top: 1px;
    &.router-link-active {
      color: $dark-color;
      //border-bottom: solid 2px $primary-color;
    }
  }

  .nav-item i {
    font-size: 20px;
    margin: auto 5px;
  }
}
</style>
