<template>
  
  <div class="residential-apartments-dispositions">
    <h2>Navrhované dispozice bytových jednotek:</h2>
    <div  class="flex-row">
      <button 
        v-for="disp in cs.modules" :key="disp.id"
        v-text="disp.getString('name')"
        @click="selectDispositionOnClick(disp)"
        :class="{
          active: disp.id === disposition.id,
          disposition: true,
        }"
      ></button>

      <button @click="addDispositionOnClick()" class="add-disposition">
        <i class="fas fa-plus"></i>
        Přidat dispozici
      </button>
    </div>
    <div class="disposition-definition" v-if="disposition">

      <Section name="Označení konfigurace dispozice bytové jednotky"> 
        <div class="flex-row">        
          <TextInput 
            :value="disposition.getValue('name')" 
            @input="disposition.setValue('name', $event)"
          />

          <button class="btn-calc" @click="openDispositionOnClick()">
            <i class="fas fa-edit"></i>
          </button>
          <button class="btn-remove" @click="removeDispositionOnClick()">
            <i class="far fa-trash-alt"></i>
          </button>

        </div>
      </Section>

      <Section name="Počet bytů této konfigurace v rámci navrhovaného objektu"> 
        <NumberWithUnitInput
          :value="disposition.getValue('count')" 
          @input="disposition.setValue('count', $event)"
          unit="ks"
          :min="1"
          :step="1"
        />
      </Section>
      
      <DispositionRoomsTable :disposition="disposition" />
    </div>

    <teleport to="#right-sider">
      <div class="cadastral-results">
        <SectionCollapsible name="Typ provozu: Bytový dům">
          <p>Přehled navržených bytových jednotek:</p>
          <table class="apartments">
              <tr class="heading-row">
                <th>Označení dispozice bytu</th>
                <th>Počet</th>
                <th>Užitná plocha bytu [m<sup>2</sup>]</th>
                <th>Hrubá plocha bytu [m<sup>2</sup>]</th>
              </tr>
              <tr v-for="disp in Object.values(cs.modules).filter(module => module.getValue('count') > 0)" :key="disp.id">
                <td v-text="disp.getValue('name')"></td>
                <td v-text="disp.getValue('count')"></td>
                <td v-text="formatFloat(disp.getValue('grossInternalAreaFlatA'))"></td>
                <td v-text="formatFloat(disp.getValue('grossFloorAreaFlatA'))"></td>
              </tr>
          </table>
          <RenderGui :gui="resultGui"/>
        </SectionCollapsible>
      </div>
      
      <div class="cadastral-results">
        <hr>
        <RenderGui :gui="resultSummaryGui"/>
      </div>

    </teleport>
  
    <apartment-dialog ref='apartmentDialog'/>
  </div>
 
</template>

<script>
import RenderGui from '@/GUI/RenderGui.vue';
import {
  GUI,
  Heading,
  Line,
  ParameterOutput,
  GUICollapsible,
} from '@/GUI';
import ApartmentDialog from './ApartmentDialog.vue'
import { composeDefault1kk, composeDefault2kk, composeDefault3kk } from './apartment';

import {TextInput, NumberWithUnitInput} from '@/views/components/customElements';
import SectionComp from '@/views/components/Section.vue';
import SectionCollapsible from '@/views/components/SectionCollapsible.vue';
import DispositionRoomsTable from './DispositionRoomsTable.vue';

function makeGroundCalculatorResultGUI(groundCS) {
  const results = new GUI(groundCS);
  results.add(new ParameterOutput('Celková užitná plocha všech bytů', 'grossInternalAreaFlatsBoF', 'm<sup>2</sup>'))
  results.add(new ParameterOutput('Celková hrubá podlahová plocha všech bytů', 'grossFloorAreaFlatsBoF', 'm<sup>2</sup>'))
  results.add(new ParameterOutput('Celková hrubá podlahová plocha společných prostorů v nadzemních podlažích', 'grossFloorAreaAboveGroundCommonAreasBoF', 'm<sup>2</sup>'))
  results.add(new ParameterOutput('Celková hrubá podlahová plocha nadzemních podlaží', 'grossFloorAreaAboveGroundBoF', 'm<sup>2</sup>'))
  results.add(new Line())
  results.add(new ParameterOutput('Celková hrubá podlahová plocha společných prostorů v podzemních podlažích', 'grossFloorAreaBelowGroundCommonAreasBoF', 'm<sup>2</sup>'))
  results.add(new Line())
  results.add(new ParameterOutput('Celkový obestavěný prostor', 'enclosedVolumeBoF', 'm<sup>3</sup>'))
  results.add(new Line())
  results.add(new ParameterOutput('Odhadované realizační náklady určené na základě obestavěného prostoru (bez DPH)', 'volumeCostBoF', 'Kč'))
  
  return results;
}

function makeGroundCalculatorResultSummaryGUI(groundCS) {
  /// TODO: Introduse new parameters for summaring the result
  // Now we have just one type. These shell accumulate from all in the future.
  const section = new GUICollapsible(groundCS, 'Shrnutí', {});
  section.add(new Heading(''))
  section.add(new ParameterOutput('Celková hrubá podlahová plocha nadzemních podlaží', 'grossFloorAreaAboveGroundBoF', 'm<sup>2</sup>'))
  section.add(new ParameterOutput('Celková hrubá podlahová plocha podzemních podlaží', 'grossFloorAreaBelowGroundCommonAreasBoF', 'm<sup>2</sup>'))
  section.add(new ParameterOutput('Celkový obestavěný prostor', 'enclosedVolumeBoF', 'm<sup>3</sup>'))
  section.add(new ParameterOutput('Odhadované realizační náklady určené na základě obestavěného prostoru (bez DPH)', 'volumeCostBoF', 'Kč'))
  
  const results = new GUI(groundCS);
  results.add(section);
  
  return results;
}

export default {
  components: {
    ApartmentDialog,
    Section: SectionComp,
    TextInput,
    NumberWithUnitInput,
    RenderGui,
    SectionCollapsible,
    DispositionRoomsTable
},
  props: ['cs'],
  methods: {
    selectDispositionOnClick(disposition) {
      this.disposition = disposition;
    },
    openDispositionOnClick() {
      this.$refs.apartmentDialog.open(this.disposition);
    },
    addDispositionOnClick(disp=null, doOpenDialog = true) {
      const disposition = disp || composeDefault1kk(1, 'vlastní název');
      this.cs.registerModule(disposition.id, disposition);
      this.disposition = disposition;
      if (doOpenDialog) {
        this.openDispositionOnClick();
      }
    },
    removeDispositionOnClick() {
      this.cs.unregisterModule(this.disposition.id)
      const modules = Object.values(this.cs.modules);
      if (modules.length > 0) this.disposition = modules[0];
      else this.disposition = null;
    },
  },
  created() {
    this.addDispositionOnClick(composeDefault1kk(70), false);
    this.addDispositionOnClick(composeDefault2kk(50), false);
    this.addDispositionOnClick(composeDefault3kk(30), false);
    this.resultGui = makeGroundCalculatorResultGUI(this.cs);
    this.resultSummaryGui = makeGroundCalculatorResultSummaryGUI(this.cs);
  },
  data() {
    return {
      disposition: null,
      resultGui: null,
    }
  }
}

</script>

<style lang="scss">

table.apartments {
    border-collapse: collapse;
    width: 100%;

  > tr {
    td, th {
      &:first-child {
        text-align: left;
      }
      text-align: center;
    }
  }

  > tr {
    line-height: 30px;
  }

  th.top-line {
    border-top: 2px solid black;
  }

  td,
  th {
    padding: 0 8px;
  }
  tr.heading-row {
    background: black;
    border-width: 0;
  }
  tr:not(.heading-row) {
    &:nth-child(2n+1) {
      background: #434242 ;
    }
  }
}

button.btn-calc {
  background-color: $calculator-color;
  border: 1px solid grey;
}

button.btn-remove {
  background-color: $grey-very-dark;
  color: white;
  border: 1px solid grey;
}

button.add-disposition {
  background-color: $primary-color;
  width: 200px;
  height: 40px;
  //line-height: 40px;
  color: white;
  font: normal normal 600 16px/22px Noto Sans;
  border: 1px solid #707070;
  margin-left: auto;
  i {
    margin: auto 20px auto 0px;
  }
}

button.disposition {
  min-width: 60px;
  height: 30px;
  margin: 10px;
  background-color: white;
  border: 1px solid grey;
}

button.active {
  background-color: grey;
}
.cadastral-plan {
  display: flex;
  flex-direction: row;
}
.next {
  margin: 15px;
  justify-content: end;
}
.cadastral-results {
  padding: 15px;
  background: lighten(black, 50%);
  color: white;
  .view-limits {
    align-items: baseline;
    gap: 35px;
  }

  .result-item {
    display: flex;
    p {
      flex-grow: 1;
    }
  }
  //display: flex;
  //flex-direction: row;
  
  flex-grow: 1;
}

.disposition-definition {
  background: #D9D9D9 0% 0% no-repeat padding-box;
  border: solid 1px black;
  padding: 10px;
}

</style>
