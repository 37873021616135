<template>
  <div class="architecture">

    <SpatialInterconnection :mode="ESchemaMode.EDIT" />

    <hr />
        
  </div>
</template>

<script>
import SpatialInterconnection from "./SpatialInterconnection.vue";
import { mapGetters, mapMutations } from 'vuex';
import { ESchemaMode } from '@/@types/definitions/mode'

export default {
    components: { SpatialInterconnection },
    data() {
        return {
            staticData: {
                buildingVolumeInputMethodOptions: [
                    {
                        name: 'Přímé zadání',
                        value: 'direct-input'
                    }
                ]
            },
            showDialog: false,
            projectName: "Vzorový projekt",
            ESchemaMode,
        }
    },
    computed: {
        ...mapGetters('definitions', [
            'functionKinds'
        ]),
        ...mapGetters('configuration', [
            'functionConfigurations',
            'buildingVolumeInputMethod',
        ]),
    },
    methods: {
        ...mapMutations('configuration', [
            'addRoom',
            'setBuildingVolumeInputMethod',
        ]),
        functionKindName(idx) {
            const [item] = this.functionKinds.filter(kind => kind.id === idx);
            if (item) return item.name.toLowerCase();
            return 'not found';
        },
    }
}

</script>

<style lang="scss">
.architecture {

    div.filter {
      background: #000;
      color: white;
      width: 36px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 50%;
    }
}
</style>
