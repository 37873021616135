<template>
  <h1
    class="advanced-questions-toggle-button flex-row"
    :class="{ active: value }"
    @click="onClick"
  >
    <i class="fa fa-chevron-down" />
    <p class="mode-advanced" v-text="getText('advancedQuestions')"></p>
  </h1>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['getText']),
  },
  methods: {
    onClick() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style lang="scss">
h1.advanced-questions-toggle-button {
  &:not(.active) i {
    transform: rotateZ(-90deg);
  }
  cursor: pointer;
  color: #008265;
  i {
    font-size: 1.2em;
    transition: 200ms ease-out;
    margin: auto 10px auto 0;
  }
  p {
    font: normal normal normal 14px/19px Roboto;
  }

  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #008265;
    margin: auto;
  }
  &:before {
    margin-right: 20px;
  }
  &:after {
    margin-left: 20px;
  }
}
</style>
