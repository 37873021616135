<template>
  <div class="world-side-picker">
    <div>
      <svg height="171" width="171">
        <path
          @click="click('north' , $event)"
          ref="side-1"
          data-class="north"
          d=" M 32 32 A 75 75 0 0 1 138 32"
        />
        <path
          @click="click('east' , $event)"
          ref="side-2"
          data-class="east"
          d=" M 138 32 A 75 75 0 0 1 138 138"
        />
        <path
          @click="click('south', $event)"
          ref="side-3"
          data-class="south"
          d=" M 138 138 A 75 75 0 0 1 32 138"
        />
        <path
          @click="click('west' , $event)"
          ref="side-4"
          data-class="west"
          d=" M 32 138 A 75 75 0 0 1 32 32"
        />
      </svg>
    </div>
    <div class="overlay flex-column">
      <div class="flex-row middle">
        <strong>S</strong>
      </div>
      <div class="flex-row sides">
        <strong>Z</strong>
        <strong>V</strong>
      </div>
      <div class="flex-row middle">
        <strong>J</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    "single-answer": Boolean,
    "multiple-answer": Boolean,
    value: String,
  },
  mounted() {
    console.log(Object.values(this.$refs));
    Object.values(this.$refs).forEach((item) => {
      console.log(this.value, item.dataset);
      if (item.dataset.class == this.value) {
        item.classList.add("active");
      }
    });
  },
  methods: {
    click(side, event) {
      this.$emit("input", side);
      event.target.parentNode.children.forEach((path) =>
        path.classList.remove("active")
      );
      event.target.classList.add("active");
    },
  },
};
</script>

<style lang="scss">
.world-side-picker {
  position: relative;
  path {
    transition: all 2ms;
    stroke-width: 18px;
    stroke: white;
    fill: transparent;
    cursor: pointer;
  }
  path.active {
    stroke: $primary-color;
  }
  path:hover {
    stroke: rgba($color: $primary-color, $alpha: 0.5);
  }

  .overlay {
    justify-content: space-between;
    position: absolute;
    top: 25px;
    left: 25px;
    right: 25px;
    bottom: 25px;
    .flex-row.middle {
      flex: 0 0 auto;
      justify-content: center;
    }
    .flex-row.sides {
      flex: 0 0 auto;
      justify-content: space-between;
    }
  }
}
</style>