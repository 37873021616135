<template>
  <div class="flex-row">
    <div v-if="fromLeft == false" class="stripe flex-column">
      <div class="hide-toggle" @click="hide">

      </div>
      <div :class="{
        'resize-dragger': true,
        'resizable': isHidden == false
       }" @mousedown="startResize">

      </div>
    </div>
    
    <div :class="columnClasses" :style="{ width: columnWidth + 'px' }" >
      <slot name="content"></slot>
      {{ $slots }}
    </div>
    <div v-if="fromLeft" class="stripe flex-column">
      <div class="hide-toggle" @click="hide">

      </div>
      <div :class="{
        'resize-dragger': true,
        'resizable': isHidden == false
       }" @mousedown="startResize">

      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    minWidth: {
      type: Number,
      default: 0
    },
    maxWidth: {
      type: Number,
      default: Infinity
    },
    fromLeft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startX: 0,
      startWidth: 0,
      columnWidth: 0,
      columnWidthMemo: 0,
      isHidden: false,
    }
  },
  computed: {
    columnClasses() {
      return {
        'resizable': true,
        'min-width': this.isHidden,
        'max-width': this.columnWidth >= this.maxWidth
      }
    }
  },
  methods: {
    startResize(event) {
      this.startX = event.clientX
      this.startWidth = this.columnWidth
      document.documentElement.addEventListener('mousemove', this.resize)
      document.documentElement.addEventListener('mouseup', this.stopResize)
    },
    resize(event) {
      if (this.isHidden) return;
      let newWidth = 0;
      if (this.fromLeft) {
        newWidth = this.startWidth + (event.clientX - this.startX)
      } else {
        newWidth = this.startWidth + (this.startX - event.clientX)
      }
      this.columnWidth = Math.min(Math.max(newWidth, this.minWidth), this.maxWidth)
    },
    hide() {
      if (this.isHidden) {
        this.isHidden = false;
        this.columnWidth = this.columnWidthMemo;
      } else {
        this.isHidden = true;
        this.columnWidthMemo = this.columnWidth;
        this.columnWidth = 0;
      }
    },
    stopResize() {
      document.documentElement.removeEventListener('mousemove', this.resize)
      document.documentElement.removeEventListener('mouseup', this.stopResize)
    }
  },
  mounted() {
    this.columnWidth = this.$el.clientWidth
  }
}
</script>

<style lang="scss" scoped>
.resizable {
  overflow: auto;
  cursor: col-resize;
}

.min-width {
  min-width: 0 !important;
}

.max-width {
  max-width: 100% !important;
}
.stripe {
  width: 12px;
  background-color: blue;
  .hide-toggle {
    height: 12px;
    background-color: black;
  }
  .resize-dragger {
    &.resizable{ 
      cursor: w-resize; 
    }
    height: 100%;
    background-color: yellow;
  }
}
</style>
