<template>
  <div class="tree-view" >
    <div>
      <p class="clickable">
        <span v-if="!isLeaf" @click="showChildren = !showChildren">
          <!-- <i class="icon-collapsable far fa-minus-square" v-if="showChildren"></i>
          <i class="icon-collapsable far fa-plus-square" v-else></i> -->

          <i class="icon-collapsable fas fa-chevron-down" v-if="showChildren"></i>
          <i class="icon-collapsable fas fa-chevron-right" v-else></i>
        </span>
        <span @click="apply(node)">
          <input 
            type="checkbox" 
            :checked="node.checked" 
            @input.stop="" 
            @change.stop="" 
          />
          <span v-text="node.name"></span>
        </span>
        
      </p>
    </div>

    <div class="sublevel leaf" v-if="node && node.children && node.children.length === 0 && showChildren">
      
    </div>
    <div class="sublevel collapsable-item" v-if="node && node.children && node.children.length" v-show="showChildren">
      <TreeStructure v-for="(child, idx) in node.children" :node="child" :key="idx" :root="false" :depth="depth+1" :apply="apply"/>
    </div>
  </div>
</template>

<script>

export default {
  components: {  },
  name: "TreeStructure",
  props: {
    node: {
      type: Object,
    },
    root: {
      type: Boolean,
      default: true,
    },
    depth: {
      type: Number,
      default: 0,
    }, 
    apply: {
      type: Function,
    }
  },
  mounted() {
    if (this.root) {
      this.showChildren = true;
    }
  },
  computed: {
    isLeaf() {
      return this.node && this.node.children && this.node.children.length === 0;
    }
  },
  data() {
    return {
      showChildren : true,
    };
  }
}
</script>

<style lang="scss">
.tree-view {
  .icon-collapsable {
    padding: 5px;
  }
}
.sublevel {
  padding-left: 2.5em;
}
</style>
