<template>
  <div class="checkbox" :class="{disabled: disabled}" :style="cssVariables">
    <label class="container"
      ><slot></slot>
      <input 
        type="checkbox" 
        :checked="value" 
        @input.stop="" 
        @change.stop="onChange()" 
        :disabled="disabled"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: [Boolean, Number],
    width: {
      type: Number,
      default: 0,
    },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    onChange() {
      this.$emit('input', !this.value);
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    cssVariables() {
      if (this.width) {
        return {
          '--checkbox-width': `${this.width}px`,
        };
      }
      return {};
    }
  }
};
</script>

<style lang="scss">
.checkbox {
  /* Customize the label (the container) */
  .container {
    min-width: var(--checkbox-width);
      width: var(--checkbox-width);
    display: block;
    position: relative;
    padding-left: 32px;
    height: 25px;
    //margin-bottom: 12px;
    cursor: pointer;
    //font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  &.disabled {
    .container, input { cursor: default; }
    
    input ~ .checkmark {
      background-color: unset;
    }

    .container:hover input ~ .checkmark {
      background-color: unset;
    }

    .container input:checked ~ .checkmark {
      background-color: lightgrey;
    }
  }
  
  

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: $primary-color;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

}
</style>
