<template>
  <div v-if="itsMe()">
    <i @click="openCalc()" class="clickable fas fa-calculator"></i>
    <Dialog heading="Tepelná stabilita" class="temperature-stability" :show="show" @close="closeCalc()">
      <Section name="typ budovy">
        <DropdownInput
          :options="staticData.buildingTypeOptions"
          :value="buildingType"
          @input="buildingType = $event"
          small
        />
      </Section>

      <Section name="budova vybavena strojním chlazením">
        <ToggleButton
          :value="mechanicalRefrigeration"
          @input="setMechanicalRefrigeration"
        />
      </Section>
      <Section
        name="Požadovaná hodnota nejvyšší denní teploty vzduchu v místnosti v letním období"
      >
        {{ result() }}°C
      </Section>
      <div class="controls">
        <button @click="show = false" class="secondary">zrušit</button>
        <button @click="use()"  class="primary">použít</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '../components/Dialog.vue'
import DropdownInput from '../components/fundamentals/DropdownInput.vue'
import ToggleButton from '../components/fundamentals/ToggleButton.vue'
import Section from '../components/Section.vue'
export default {
  components: { Dialog, DropdownInput, Section, ToggleButton },
  props: {
    desiredCalculator: {
      type: String,
      default: "no-calc"
    }
  },
  data() {
    return {
      show: false,
      buildingType: 'non-manufacturing',
      mechanicalRefrigeration: false,
      staticData: {
        buildingTypeOptions: [
          {
            value: 'non-manufacturing',
            name: 'Nevýrobní',
          },
          {
            value: 'to25',
            name: 'Ostatní vnitřním zdrojem tepla do 25 W/m3 včetně',
          },
          {
            value: 'from25',
            name: 'Ostatní vnitřním zdrojem tepla nad 25 W/m3',
          },
        ],
      },
    }
  },
  methods: {
    itsMe() {
      return this.desiredCalculator == "TemperatureStability"
    },
    openCalc() {
      this.show = true
    },
    closeCalc() {
      this.show = false
    },
    setMechanicalRefrigeration(event) {
      console.log(event)
      this.mechanicalRefrigeration = event
    },
    result() {
      if (this.mechanicalRefrigeration) return 32
      return {
        'non-manufacturing': 27,
        to25: 29.5,
        from25: 31.5,
      }[this.buildingType]
    },
    use () {
      this.show = false;
      const requiredValue1 = this.result()
      this.$emit("set-requirement", {requiredValue1});
    },
  },
}
</script>

<style lang="scss">
.temperature-stability {
  .section {
    .flex-inline {
      align-items: baseline;
    }
  }

  .controls {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}
</style>
