<template>
  <div v-if="itsMe()">
    <i @click="openCalc()" class="clickable fas fa-calculator"></i>
    <Dialog heading="Součinitel redukce počtu stání Kp" class="parking-reduction-coef" :show="show" @close="closeCalc()">
      <Section name="Počet obyvatel v obci (městě), kde bude stavba realizována:">
        <DropdownInput
          :options="staticData.populationClassOptions"
          :value="populationClass"
          @input="populationClass = $event"
        />
      </Section>
      <div v-if="populationClass == 'Do50K'" style="height:300px">
        <h1>Charakter území:</h1>
        <DropdownInput
            :options="staticData.areaCharacterBelow50KOptions"
            :value="areaCharacter"
            @input="areaCharacter = $event"
        />
      </div>
      <div v-else-if="populationClass == 'Nad50K'" style="height:300px">
        <h1>Charakter území:</h1>
        <DropdownInput
            :options="staticData.areaCharacterAbove50KOptions"
            :value="areaCharacter"
            @input="areaCharacter = $event"
        />
      </div>
      <div v-else style="height:300px"></div>
      <Section
        name="Součinitel redukce počtu stání Kp"
      >
        <strong v-text="result()"></strong>
      </Section>
      <div class="controls">
        <button @click="show = false" class="secondary">zrušit</button>
        <button @click="use()"  class="primary">použít</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '../components/Dialog.vue'
import DropdownInput from '../components/fundamentals/DropdownInput.vue'
import Section from '../components/Section.vue'

const populationClassOptions = [
{	name: 'Obce do 5 000 obyvatel', A: 1, B:	1,	C: 1, value: 'Do5K'},
{	name: 'Obce (města) do 50 000 obyvatel', A: 1, B:	0.8, C:	0.4, value: 'Do50K'},
{	name: 'Obce (města) nad 50 000 obyvatel', A: 1, B:	0.6, C:	0.25, value: 'Nad50K'},
]

const areaCharacterBelow50KOptions = [
{value: 'A', name: 'Veškeré stavby mimo centrum města (mimo historické jádro, městskou památkovou rezervaci apod.), nízká kvalita obsluhy území veřejnou dopravou'},
{value: 'B', name: 'Stavby v centru obce, ale mimo historické jádro, městskou památkovou rezervaci, dobrá kvalita obsluhy území veřejnou dopravou'},
{value: 'C', name: 'Stavby v historickém jádru, v památkové rezervaci'},
]

const areaCharacterAbove50KOptions = [
{value: 'A', name: 'Stavby s nadměstským významem na hranici souvislé zástavby, nízká kvalita obsluhy veřejnou dopravou'},
{value: 'B', name: 'Stavby celoměstského i nadměstského významu uvnitř zastavěného území obce, mimo centrum města (mimo historické jádro, městskou památkovou rezervaci apod.), dobrá kvalita obsluhy území veřejnou dopravou'},
{value: 'C', name: 'Stavby v centru obce, v historickém jádru, v památkové rezervaci, velmi dobrá kvalita obsluhy veřejnou dopravou'},
]

export default {
  components: { Dialog, DropdownInput, Section },
  props: {
    desiredCalculator: {
      type: String,
      default: "no-calc"
    }
  },
  data() {
    return {
      show: false,
      populationClass: 'Do5K',
      areaCharacter: 'A',
      staticData: {
        populationClassOptions,
        areaCharacterBelow50KOptions,
        areaCharacterAbove50KOptions
      },
    }
  },
  methods: {
    itsMe() {
      return this.desiredCalculator == "ParkingSpacesReductionCoef"
    },
    openCalc() {
      this.show = true
    },
    closeCalc() {
      this.show = false
    },
    setMechanicalRefrigeration(event) {
      console.log(event)
      this.mechanicalRefrigeration = event
    },
    result() {
      if (this.populationClass === 'Do5K') return 1;
      const [info] = populationClassOptions.filter(record => record.value === this.populationClass)
      if (this.populationClass === 'Do50K') {
        return info[this.areaCharacter];
      } else if (this.populationClass === 'Nad50K') {
        return info[this.areaCharacter];
      }
    },
    use () {
      this.show = false;
      const requiredValue1 = this.result()
      this.$emit("set-requirement", {requiredValue1});
    },
  },
}
</script>

<style lang="scss">
.parking-reduction-coef {
  .section {
    .flex-inline {
      align-items: baseline;
    }
  }

  .controls {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}
</style>
