<template>
  <div 
    class="dropdown-input" 
    :class="{disabled: disabled}" 
    ref="dropdown" 
    :style="cssVariables"
  >
    <div 
      :class="{expanded: isExpanded, 'select-selected': true}"
      @click.stop="selectedOnClick()"
    >
      <div class="active-content flex-row">
        <span v-text="showCurrent()"></span>
        <div class="icon-wrapper">
          <i class="icon fa fa-angle-down"></i>
        </div>
      </div>

      <div class="disable-text">
        <p>V provozu již máte přidané místnosti.</p>
      </div>
    </div>

    <div class="select-items" v-show="isExpanded" >
      <div 
        v-for="(option, idx) in options" 
        v-text="option.name" 
        @click.stop="setValue(option.value)"
        class="item"
        :class="{active: isActive(option.value)}" 
        :key="idx"
      > </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserInput",
  props: {
    options: {
      type: Object,
      required: true,
    },
    value: {
      type: [Number, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  methods: {
    hasEmptyOption() {
      return this.options.some(option => option.value === '');
    },
    showCurrent() {
      if (!this.value && !this.hasEmptyOption()) return '';
      const [current] = this.options.filter(option => option.value === this.value);
      if (current) {
        return current.name;
      }
      return "unknown"
    },
    setValue(value) {
      this.$emit("input", value);
      this.isExpanded = false;
    },
    isActive(value) {
      return value === this.value;
    },
    documentClicked() {
      if (this.isExpanded) {
        this.isExpanded = false
      }
    },
    selectedOnClick() {
      if (this.disabled) {
        this.isExpanded = false;
        return
      }
      this.isExpanded = this.isExpanded? false : true;
    }
  },
  created() {
    document.addEventListener('click', this.documentClicked);
    //this.setConfigData();
    //this.setOptionsHeight();
  },
  beforeUdate() {
    // this.setOptionsHeight();
  },
  unmounted() {
    document.removeEventListener('click', this.documentClicked);
  },
  computed: {
    cssVariables() {
      if (this.small) {
        return {
          '--width': `${this.width? this.width: 140}px`,
          '--height': `30px`,
          '--font': 'Roboto',
          '--icon-size': '1.1em',
        };
      }
      return {
        '--width': `${this.width? this.width: 300}px`,
        '--height': `50px`,
        '--font': 'Roboto',
        '--icon-size': '1.3em',
      };
    }
  },
};
</script>

<style lang="scss" scope>
.dropdown-input {
  //flex: 1 1 0;
  font-family: var(--font);
  position: relative;
  .select-selected {
    position: relative;
    min-width: var(--width);
    font-size: 0.9em;
    background: $input-color;
    //border-radius: calc(var(--height) / 2);
    //min-height: var(--height);
    //line-height: var(--height);
    padding: 0px;
    
    .active-content {
      justify-content: space-between;
      padding: 10px;

      > span {
        flex-grow: 1;
        text-align: center;
        margin: auto 0 auto 14px;
        vertical-align: middle;
      }
      > .icon-wrapper {
        width: 30px;
        text-align: center;

        > .icon {
          flex-grow: 0;
          //line-height: var(--height);
          //height: var(--height);
          width: 14px;
          font-size: var(--icon-size);
          transition: all 300ms ease-out;
        }
      }
      
    }

    &.expanded .icon {
      transform: rotateX(-180deg);
    }

    .disable-text {
        display: none;
    }
  }
  
  &.disabled {
    color: #666;
    > .select-selected:hover {
      cursor: not-allowed;
      .disable-text {
        display: block;
        position: absolute;
        color: white;
        background: rgba($color: #333, $alpha: 1);
        z-index: 1;
        text-align: center;
        border-radius: 6px;
        padding: 6px;
        p {
          line-height: 1.2em;
        }
      }
    }
  }

  // drop down expand
  .select-items {
    z-index: 2;
    position: absolute;
    min-width: var(--width);//$user-input-min-width;
    //border-radius: calc(var(--height) / 2);
    border: solid 1px $input-color;
    overflow-y: scroll;
    //overflow-x: hidden;
    max-height: 200px;
    cursor: pointer;
    
    > .item {
      padding: 10px;
      background: white;
      //min-height: var(--height);
      //line-height: var(--height);
      //padding: 0px;
      text-align: center;
      vertical-align: middle;
      //white-space: nowrap;
      //overflow: hidden;

      &:hover {
        background: $secondary-color;
        //background: lightblue;
        
      }
    }
  }

}
</style>
