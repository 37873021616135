<template>
  <h1 class="sub-entity-title">Kontrola projektu</h1>
  
  <router-view></router-view>

</template>


<script>

export default {
   
}
</script>


<style lang="scss">
.sub-entity-title {
  text-align: end;
}

</style>
