<template>
  <div class="visualization-popup" v-if="show" >
    <teleport to="#modal">

      <div class="popup-container" v-bind="$attrs" :style="cssVariables">
        <div class="popup-modal">
          <div class="flex-row popup-heading">
            <h1 v-text="heading"></h1>
            <i @click="onClose" class="close far fa-window-close"></i>
          </div>
          <slot> </slot>
        </div>
      </div>

    </teleport>
  </div>
</template>

<script>
export default {
  emit: {close:null},
  name: 'Dialog',
  props: {
    heading: {type: String},
    show: {type: Boolean},
    width: {type: Number, default: 1232},
  },
  data() {
    return {};
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
  computed: {
    cssVariables() {
      return {
        '--width': `${this.width?this.width:1232}px`,
      }
    }
  },
};
</script>

<style lang="scss">
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-modal {
  max-width: 100%;
  min-height: 100px;
  background-color: $popup-background;
  padding: 20px 26px;
  border-radius: 0px;
  border: 1px solid black;
  text-align: left;
  box-shadow: 0px 5px 9px #00000033;
}

.popup-modal {
  width: var(--width);
  max-width: 95vw;
  max-height: 85vh;
  overflow-y: auto;

  h1 {
    font: normal normal bold 20px/27px Noto Sans;
    //color: #000000de;
  }

  .popup-heading {
    justify-content: space-between;
    h1 {
      margin-top: 0;
    }

    .close {
      align-self: flex-start;
      transition: 300ms;
      cursor: pointer;
      &:hover {
        color: $primary-color;
        //transform: rotateZ(90deg);
      }
    }
  }
  
  .controls-modal {
    button.primary {
      margin: 50px 0 0 0;
      float: right;
    }
  }
}

span.black-border-span {
  border: 1px solid black;
}
</style>
