<template>
  <div class="project" v-if="isProjectOpened">  

    <h1 class="title">Aktivní moduly</h1>
    <div class="modules flex-row">
      <div 
        class="modules-item flex-column space-between"
        @click="openConcepts"
        v-if="openedProject.concepts.length > 0"
      >
        <h3>Koncepční příprava</h3>
        <i class="big-icon fas fa-calculator"></i>
    
        <div>
          <span class="number">
            {{ openedProject.concepts.length }}
          </span>
          <div class="number-desc">Počet uložených konfigurací</div>
        </div>
      </div>

      <div 
        class="modules-item flex-column space-between"
        @click="openConfigurations"
        v-if="openedProject.configurations.length > 0"
      >
        <h3>Kontrola projektu</h3>
        <i class="big-icon fas fa-pencil-ruler"></i>
    
        <div>
          <span class="number">
            {{ openedProject.configurations.length }}
          </span>
          <div class="number-desc">Počet uložených verzí</div>
        </div>
      </div>

      <div 
        class="modules-item flex-column space-between"
        @click="openModels3D"
        v-if="openedProject.models3D.length > 0"
      >
        <h3>3D Model</h3>
        <i class="big-icon fas fa-desktop"></i>
        <div>
          <span class="number">
            {{ openedProject.models3D.length }}
          </span>
          <div class="number-desc">Počet uložených modelů</div>
        </div>
      </div>
    </div>
    
    
    
    <h1 class="title">Neaktivní moduly</h1>

    <div class="modules flex-row">
      <div 
        class="modules-item flex-column space-between"
        @click="openConcepts"
        v-if="openedProject.concepts.length == 0"
      >
        <h3>Koncepční příprava</h3>
        <i class="big-icon fas fa-calculator"></i>
    
        <div>
          <span class="number"> 0 </span>
          <div class="number-desc">Počet uložených konfigurací</div>
        </div>
      </div>

      <div 
        class="modules-item flex-column space-between"
        @click="openConfigurations"
        v-if="openedProject.configurations.length == 0"
      >
        <h3>Kontrola projektu</h3>
        <i class="big-icon fas fa-pencil-ruler"></i>
    
        <div>
          <span class="number"> 0 </span>
          <div class="number-desc">Počet uložených verzí</div>
        </div>
      </div>

      <div 
        class="modules-item flex-column space-between"
        @click="openModels3D"
        v-if="openedProject.models3D.length == 0"
      >
        <h3>3D Model</h3>
        <i class="big-icon fas fa-desktop"></i>
        <div>
          <span class="number"> 0 </span>
          <div class="number-desc">Počet uložených modelů</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
    components: {
      
    },
    data() {
        return {
            objectFunctions: [
                "living-function",
            ],
        }
    },
    computed: {
        ...mapGetters('project', ['isProjectOpened', 'openedProject']),
    },
    methods: {
        ...mapMutations('configuration', [
            'setProjectName',
            'addFunctionConfiguration',
            'setFunctionKind',
        ]),
        openConcepts() {
          this.$router.push('/project/concepts');
        },
        openConfigurations() {
          this.$router.push('/project/configurations');
        },
        openModels3D() {
          this.$router.push('/project/models3d');
        }
    }
}

</script>

<style lang="scss">
.project {
  padding: 40px 100px;
    .gap10 {
      gap: 10px;
    }

    .modules {
      gap: 65px;
    }
    .modules-item {
      position: relative;
      border: solid 1px black;
      padding: 20px;
      height: 200px;
      width: 400px;
      background-color: white;
      h3 {
        font: normal normal bold 16px/22px Noto Sans;
      }

      i.big-icon {
        font-size: 40px;
        position: absolute;
        right: 25px;
      }

      .number {
        font: normal normal 600 32px/43px Noto Sans;
      }
      .number-desc {
        font: normal normal 600 16px/22px Noto Sans;
      }
    }
    h1.title {
        //font: normal normal bold 26px/35px Roboto;
        //letter-spacing: 0.42px;
    }
    .around {
        margin-bottom: 20px;
    }
    .input-row {
        justify-content: space-around;
    }
    .group {
        display: inline-flex;
        line-height: 50px;
    }
}

/* i.big-icon {
        font-size: 40px;
} */
</style>
