<template>
  <div 
    class="numeric-input" 
    :class="{small: small}"
    :style="cssVariables"
  >
    <input 
      type="number" 
      :value="value" 
      @input.stop="onInput" 
      ref="inputElement" 
      class="user-input-style"
    />
  </div>
</template>

<script>
export default {
  name: "UserInput",
  props: {
    value: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: -1e10
    },
    max: {
      type: Number,
      default: 1e10
    },
    small: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    }

  },
  methods: {
    clip(num) {
      return Math.min(this.max, Math.max(this.min, num));
    },
    onInput() {
      this.$emit("input", this.clip(parseFloat(this.$refs.inputElement.value)));
    },
  },
  computed: {
    cssVariables() {
      if (this.small) {
        return {
          '--width': `${this.width? this.width: 140}px`,
          '--icon-size': '1.1em',
        };
      }
      return {
      };
    }
  },
};
</script>

<style lang="scss" scope>
/* .small {
  flex: 1 1 1;
  .user-input-style {
    min-width: var(--width);
    width: var(--width);
    height: 40px;
    line-height: 40px;
  }
} */
</style>
