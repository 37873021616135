<template>
  <div class="access-issue">
      <div class="flex-column">
          
      
      <h1>Nemáte oprávnění používat aplikaci konfigurátor budov, zakupte si prosím oprávnění.</h1>
      
      <Profile noavatar />
      </div>
  </div>
</template>

<script>
import Profile from './Profile.vue'
export default {
  components: { Profile },
    
}
</script>

<style lang="scss">
.access-issue {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    h1 {
        margin: 10%;
        //width: 80%;
        vertical-align: middle;
        font-family: 'Roboto Mono';
        padding: auto;
        color: rgb(46, 46, 46);
    }
}
</style>