<template>
  <div class="settings">
      <h1>Nastavení obecného konfigurátoru</h1>
      <button @click="updateDefinitions()" class="primary">aktualizovat definice</button>

      <div v-show="errors && errors.length > 0">
        <h2>Během poslední aktualizace definic, byly nalezeny následující chyby.</h2>
        <div class="error" :key="idx" v-for="(error, idx) in errors" >
          <p>
            <span v-text="idx+1"></span>.
            <span v-text="error"></span>
          </p>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    methods: {
        updateDefinitions() {
            this.$store.dispatch('definitions/UPDATE_DEFINITIONS');
        }
    },
    computed: {
      ...mapGetters('definitions', [
        'errors',
      ]),
    }
}
</script>

<style>

</style>
