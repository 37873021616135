<template>
  <div class="container flex-row">
    <div class="calculators">
      <h1 class="title">Kalkulačky</h1>
      <div class="flex-column">
          <div class="calculator flex-row">
            <div>
              TemperatureStability
            </div>
            <Calculator name="TemperatureStability" @set-requirement="displayChanges({calculatorId: 'TemperatureStability', changes: $event})"/>
          </div>
          <div class="calculator flex-row">
            <div>
              ImposedLoads
            </div>
            <Calculator name="ImposedLoads" @set-requirement="displayChanges({calculatorId: 'ImposedLoads', changes: $event})"/>
          </div>
          <div class="calculator flex-row">
            <div>
              ThermalTransmittance
            </div>
            <Calculator name="ThermalTransmittance" @set-requirement="displayChanges({calculatorId: 'ThermalTransmittance', changes: $event})"/>
          </div>
          <div class="calculator flex-row">
            <div>
              RadonVolumeActivity
            </div>
            <Calculator name="RadonVolumeActivity" @set-requirement="displayChanges({calculatorId: 'RadonVolumeActivity', changes: $event})"/>
          </div>
          <div class="calculator flex-row">
            <div>
              FloorContactTemperatureDrop
            </div>
            <Calculator name="FloorContactTemperatureDrop" @set-requirement="displayChanges({calculatorId: 'FloorContactTemperatureDrop', changes: $event})"/>
          </div>
          <div class="calculator flex-row">
            <div>
              QuantityWC
            </div>
            <Calculator name="QuantityWC" @set-requirement="displayChanges({calculatorId: 'QuantityWC', changes: $event})"/>
          </div>
          <div class="calculator flex-row">
            <div>
              ParkingSpacesReductionCoef
            </div>
            <Calculator name="ParkingSpacesReductionCoef" @set-requirement="displayChanges({calculatorId: 'ParkingSpacesReductionCoef', changes: $event})"/>
          </div>
          <div class="calculator flex-row">
            <div>
              ParkingSpacesRegisteredCarsCoef
            </div>
            <Calculator name="ParkingSpacesRegisteredCarsCoef" @set-requirement="displayChanges({calculatorId: 'ParkingSpacesRegisteredCarsCoef', changes: $event})"/>
          </div>
      </div>
    </div>
    <div class="calc-test" v-show="event.calculatorId">
      <h1 class="title">Výsledek z kalkulačky</h1>
      <div class="flex-column">
        <p>Kalkulačka <strong v-text="event.calculatorId"></strong> nastavuje následující hodnoty:</p>
        <div v-for="(value, param) in event.changes" :key="param">
          <span v-text="param"></span>
          = <span v-text="value"></span>
        </div>
      </div>
    </div>
    <calculator-dialog />
  </div>
</template>

<script>
import Calculator from '../calculators/Calculator.vue'
import CalculatorDialog from '../calculators/CalculatorDialog.vue';

export default {
  components: { Calculator, CalculatorDialog, },
  methods: {
    displayChanges({calculatorId, changes}) {
      this.event.calculatorId = calculatorId;
      this.event.changes = changes;
      setTimeout(()=> {
        this.event.calculatorId = null;
      }, 5000);
    }
  },
  data() { 
    return {
      event: {
        calculatorId:null,
        changes:null,
      }
    };
  },
}
</script>

<style lang="scss">
.calculators {
  flex-basis: 50%;
    .calculator {
        gap: 50px; 
    }
}
.calc-test {
  h1 {
    margin: 5px 0;
  }
  background: rosybrown;
  padding: 20px;
  border-radius: 20px;
}
</style>
