<template>
  <h1 class="heading-level" v-if="level == 1"><slot></slot></h1>
  <h2 class="heading-level" v-else-if="level == 2"><slot></slot></h2>
  <h3 class="heading-level" v-else-if="level == 3"><slot></slot></h3>
  <h4 class="heading-level" v-else-if="level == 4"><slot></slot></h4>
  <h5 class="heading-level" v-else-if="level == 5"><slot></slot></h5>
  <h6 class="heading-level" v-else-if="level >= 6"><slot></slot></h6>
</template>

<script>
export default {
  props: {
    level: {
      type: Number,
      default: 1,
    }
  }
}
</script>

<style>

h1.heading-level,
h2.heading-level,
h3.heading-level,
h4.heading-level,
h5.heading-level,
h6.heading-level { margin: 0px;}

h1.heading-level { font-size: 26px;}
h2.heading-level { font-size: 24px;}
h3.heading-level { font-size: 21px;}
h4.heading-level { font-size: 18px;}
h5.heading-level { font-size: 16px;}
h6.heading-level { font-size: 14px;}

</style>
