<template>
  <div class="note">
    <i 
      class="clickable far fa-sticky-note"
      @click="openDialog()"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    requirement: Object,
  },
  methods: {
    openDialog() {
      this.eventBus.emit('open-note-dialog', this.requirement);
    }
  },
}
</script>

<style lang="scss" scoped>
.note {
  display: flex;
  justify-content: center;
}
</style>
