<template>
  <div class="login flex-row">
      <form class="flex-column" @input="cleanErrors">
        <h1>Přihlášení</h1>
        <Section name="Jméno">
            <input 
                v-model="user.username" 
                type="text" 
                class="user-input-style"
                :disabled="loginProcessing"
            />
        </Section>
        <!-- <div class="flex-row">
            <label>Jméno</label>
            <input v-model="user.username" type="text">
        </div> -->
        <span class="error" v-show="hasError('username')" v-text="getError('username')"></span>

        <Section name="Heslo">
           
            <input 
                v-model="user.password" 
                type="password"  
                class="user-input-style"
                :disabled="loginProcessing"
            />
        </Section>
        <span class="error" v-show="hasError('password')" v-text="getError('password')"></span>
        <button 
            class="primary login-button"
            @click.prevent="onLogin()" 
            :disabled="loginProcessing"
        >PŘIHLÁSIT</button>
      </form>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import Section from './components/Section.vue';
export default {
  components: { Section },
    data() {
        return {
            user: {
                username: "",
                password: "",
            },
        };
    },
    methods: {
        onLogin() {
            this.$store.dispatch('LOGIN', this.user);
            //api.post("/api/auth/login", this.user)
            /* .then(data => {
                this.setRefreshToken(data.refreshToken);
                this.setAccessToken(data.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken)
                localStorage.setItem('accessToken', data.accessToken)
                this.setUser("wienerberger");
                this.$store.dispatch("LOAD_STATIC_DATA");
            }).catch(errors => this.errors = errors); */
        },
        hasError(param) {
            return this.getLoginError(param);
        },
        getError(param) {
            return this.getLoginError(param);
        },
        cleanErrors() {
            this.clearLoginErrors();
        },
        ...mapMutations(["setRefreshToken", "setAccessToken", "setUser", "clearLoginErrors"])
    },
    computed: {
        ...mapGetters([
            "loginErrors", 
            "getLoginError",
            "loginProcessing",
        ])
    }
}
</script>

<style lang="scss">
.login {
    height: 100%;
    justify-content: center;
    align-items: center;
    form {
        width: 400px;
        //justify-content: center;
        h1 {
            text-align: center;
            font-size: 5em;
            font-weight: 400;
            margin: 0;
        }
        .flex-inline {
            align-items: baseline;
        }
        .error {
            padding: 5px 10px ;
            color: red;
        }
        button {
            margin-top: 8px;
            width: 400px;
            height: $primary-button-height;
        }
    }
}
</style>
