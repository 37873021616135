import api from '.';
//import { IArticle } from '../articles';

export interface IArticle {
  id: number;
  title: string;
  body?: string;
}

export interface IUpdateArticlePayload {
  title: string,
  body: string,
}

class ArticleService {
  private apiBaseUrl = '/api/articles';

  async getAllArticles(): Promise<IArticle[]> {
    return api.get<IArticle[]>(`${this.apiBaseUrl}/`);
  }

  async createArticle(payload: IUpdateArticlePayload): Promise<IArticle> {
    return api.post<IArticle>(this.apiBaseUrl, payload);
  }

  async getArticleByHumanId(humanArticleId: string): Promise<IArticle> {
    return api.get<IArticle>(`${this.apiBaseUrl}/by-human-id/${humanArticleId}`);
  }

  async getArticleById(articleId: string): Promise<IArticle> {
    return api.get<IArticle>(`${this.apiBaseUrl}/${articleId}`);
  }

  async updateArticle(id: string, payload: IUpdateArticlePayload): Promise<IArticle> {
    return api.put<IArticle>(`${this.apiBaseUrl}/${id}`, payload);
  }

  async deleteArticle(id: string): Promise<void> {
    await api.delete(`${this.apiBaseUrl}/${id}`);
  }

  async exportAllArticles(): Promise<void> {
    return await api.get(`${this.apiBaseUrl}/export`, { responseType: 'blob' });
  }
}


export default new ArticleService();
