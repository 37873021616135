<template>
  <div class="template-selection">
    <h1>Šablony</h1>
    <table>
      <tr class="heading-row">
        <th>název šablony</th>
        <th>poznámka</th>
        <th>autor</th>
      </tr>

      <tr 
        v-for="(temp, idx) in templates" 
        :key="idx"
        class="data-row"
        :class="{selected: isSelected(temp._id)}"
        @click="onClick(temp._id)"
      >
        <td>
          <div v-text="temp.name"></div>
        </td>
        <td>{{ temp.description }}</td>
        <td>{{ temp.author?temp.author.username : '' }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import api from '@/store/api';
export default {
  emits: ['select-template'],
  components: { },
  mounted() {
    this.getAll();  
  },
  computed: mapGetters(['actionStatus']),
  methods: {
    ...mapActions('template',[]),
    async getAll() {
      const templates = await api.get('/api/templates');
      this.templates = templates;
    },
    onClick(templateId) {
      this.$emit('select-template', templateId);
      this.selectedTemplateId = templateId;
    },
    isSelected(templateId) {
      return this.selectedTemplateId == templateId;
    },
  },
  data() {
    return {
      templates: [],
      selectedTemplateId: ''
    };
  }
}
</script>

<style lang="scss">
.template-selection {
  .flex-inline {
    gap: 10px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    line-height: 30px;
  }
  td,
  th {
    border: 1px solid white;
    padding: 0 8px;
  }
  td.check {
    background: white;
  }
  .heading-row {
    background: $primary-color;
    color: white;
    border-width: 0;
  }
  .data-row {
    background: $secondary-color;
    &:nth-child(2n+1) {
      background: white;
    }
  }
  tr.data-row.selected {
    border-left: 5px solid $primary-color;
  }
  tr.data-row:hover {
    background-color: pink;
    cursor: pointer;
  }
}
</style>
