<template>
  <tr class="data-row">
    <td class="art id">{{article.id}}</td>
    <td class="art title">{{article.title}}</td>
    <td class="art options">
      <i class="far fa-edit clickable" @click="editArticle()"></i>
      <i class="far fa-trash-alt clickable" @click="removeArticle()"></i>
    </td>
  </tr>
</template>

<script>

export default {
  props: {
    article: {
      type: Object
    }
  },
  methods: {
    editArticle() {
      this.$emit("edit");
    },
    removeArticle() {
      if (confirm(`Opravdu chcete smazat článek "${this.article.title}"?`)) {
        this.$emit("remove");
      }
    },
  },
}
</script>

<style lang="scss">

.data-row:nth-child(2n+1) {
  background-color: white;
}

.data-row:hover {
  background-color: lighten($color: $primary-color, $amount: 40%);
}

.options {
  display: flex;
  gap: 10px;
}
</style>
