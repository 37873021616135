import { AppliedRequirement } from "@/@types/definitions/requirements";
import { IRoom, IFunctionKind } from "@/@types/definitions/rooms";
import { ActionContext, Module } from "vuex";
import { IState } from ".";
import api from "./api";

const distributionParametersBlacklist = [
  'calculatorId',
  'articleId',
  'requiredValue1',
  'requiredValue2',
  'operator',
  'unit',
  'source',
];

const stateDefinition = {
  rooms: Array<IRoom>(),
  functionKinds: Array<IFunctionKind>(),
  requirements: Array<any>(),
  requirementParameters: Array<any>(),
  errors: Array<string>(),
  constructionIds: Array<any>(),
  constructions: Array<any>(),
  constructionsLoading: true,
}

type State = typeof stateDefinition;

const definitions:Module<State, IState> = {
  namespaced: true,
  state: ():State => (stateDefinition),
  getters: {
    rooms: (state:State):IRoom[] => state.rooms,
    functionKinds: (state:State):IFunctionKind[] => state.functionKinds,
    functionKindName: (state:State) => (functionKindId:number) => {
      const [item] = state.functionKinds.filter(kind => kind.id === functionKindId);
      if (item) return item.name;
      return `not found '${functionKindId}'`;
    },
    requirements: (state:State):any[] => state.requirements,
    requirementParameters: (state:State) => state.requirementParameters,
    requirementParameterOptions: (state:State) => [...state.requirementParameters
      .filter(p => !distributionParametersBlacklist.includes(p.name))
      .map((p:any) => ({name: p.displayName, value: p.name})), {name: "Splněnost", value: 'fulfilled'}],
    requirementParameterDisplayName: (state:State) => (name:string) => { 
      const [parameter] = state.requirementParameters.filter(p => p.name == name);
      if (!parameter) {
        if (name in AppliedRequirement.computedParameterDisplayName) {
          return AppliedRequirement.computedParameterDisplayName[name];
        }
        return name;
      }
      return parameter.displayName;
    },
    getRequirementParameter: (state:State) => (parameterName: string) => { 
      const [parameter] = state.requirementParameters.filter((par:any) => par.name == parameterName);
      return parameter;
    },
    getParameterOptions: (state:State, getters:any) => (parameterName: string) => { 
      const parameter = getters.getRequirementParameter(parameterName);
      return [ 
        {name: "Vše", value: ""}, 
        ...parameter.values.filter((val:string) => !!val).map((val:string) => ({name:val, value:val}))
      ];
    },
    errors: (state:State):string[] => state.errors,
    constructionIds: (state:State) => state.constructionIds,
    constructions: (state:State) => state.constructions,
    constructionsMerged: (state:State) => {
      return state.constructionIds.map(constructId => {
        /* const [item] = state.constructions.filter((con:any) => con.deksoftId == item.id) as Array<any>;
        let res = {...constructId, name: constructId.nazev, deksoftId: constructId.id};
        if (item) {
          console.log(item);
          
          res = {...res, ...item};
        }
        return res; */
        const match = state.constructions.find((item => item.deksoftId == constructId.id))
        /* while (constructId.id < state.constructions[idx].deksoftId) {
          idx += 1;
        } */
        if (match && constructId.id == match.deksoftId) {
          return {nazev: constructId.name, id: constructId.id, ...match};
        } else {
          return {deksoftId: constructId.id, ...constructId};
        } //*/
      })
    },
    constructionsLoading: (state:State) => state.constructionsLoading,
  },
  mutations: {
    setRooms: (state:State, val:IRoom[]):void => { state.rooms = val; },
    setFunctionKinds: (state:State, val:IFunctionKind[]):void => { state.functionKinds = val; },
    setRequirements: (state:State, val:any[]):void => { state.requirements = val; },
    addRequirementTemplate: (state:State, val:any):void => { state.requirements.push(val) },
    setRequirementParameters: (state:State, val:any[]) => { state.requirementParameters = val; },
    clearErrors: (state:State) => { state.errors = []; },
    setErrors: (state:State, val:string[]) => { state.errors = val; },
    setConstructionIds: (state:State, val:any[]) => { state.constructionIds = val; },
    setConstructions: (state:State, val:any[]) => { state.constructions = val; },
    setConstructionsLoading: (state:State, val:boolean) => { state.constructionsLoading = val; },
  },
  actions: {
    async UPDATE_DEFINITIONS(context: ActionContext<State, IState>) {
      context.commit('clearErrors');
      const errors = await api.get('/api/update-definitions');
      context.commit('setErrors', errors);
      context.dispatch('LOAD_INITIAL_STATE', {}, {root: true});
    }
  },
};

export default definitions;
