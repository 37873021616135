<template>
  <div class="connection-filter-controls">
    <h3 class="h-label">filtr vazeb:</h3>
    <div class="filter-pair">
      <checkbox-input :value="showAll" @input="callAction('setShowAll', $event)" />
      <label :class="{active: showAll}">vše</label>
    </div>
    <div class="filter-pair">
      <checkbox-input :value="showWalls" @input="callAction('setShowWalls', $event)" /> 
      <label :class="{active: showWalls}">stěnou</label>
    </div>
    <div class="filter-pair">
      <checkbox-input :value="showCeilings" @input="callAction('setShowCeilings', $event)" /> 
      <label :class="{active: showCeilings}">stropem</label>
    </div>
    <div class="filter-pair">
      <checkbox-input :value="showFloors" @input="callAction('setShowFloors', $event)" /> 
      <label :class="{active: showFloors}">podlahou</label>
    </div>
    <div class="filter-pair">
      <checkbox-input :value="showOpenings" @input="callAction('setShowOpenings', $event)" /> 
      <label :class="{active: showOpenings}">výplní otvoru</label>
    </div>
    <div class="filter-pair">
      <checkbox-input :value="showNoConstructions" @input="callAction('setShowNoConstructions', $event)" /> 
      <label :class="{active: showNoConstructions}">bez konstrukce</label>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CheckboxInput from '../components/fundamentals/CheckboxInput.vue'

export default {
  components: { CheckboxInput },
  computed: {
    ...mapGetters('prezentation', [
      'showWalls',
      'showCeilings',
      'showFloors',
      'showNoConstructions',
      'showOpenings',
      'showAll',
      
    ]),
  },
  methods: {
    ...mapMutations('prezentation', [
      'setShowWalls',
      'setShowCeilings',
      'setShowFloors',
      'setShowNoConstructions',
      'setShowOpenings',
      'setShowAll',
    ]),
    callAction(action, value) {
      this[action](value);
      this.$emit("refresh");
    },
  },
  data() {
    return {
      wall: true,
      ceiling: false,
      floor: false,
      noConstruction: false,
      opening: false,
      all: false,
    }
  }
}
</script>

<style lang="scss">
.connection-filter-controls {
  display: inline-flex;
  align-items: baseline;
  gap: 4px;
  .h-label {
    display: inline;
  }
  .filter-pair {
    display: inline-flex;
    align-items: center;
    label {
      //margin: 0 10px 0 0px;
    }
    label.active {
      font-weight: 600;
    }
  }
}
</style>
