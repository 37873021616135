<template>
  <div>

    <i @click="doShowDialog" class="clickable fa fa-save"></i>
    <!-- 
      TEMPLATE CREATIONS IS ALSO AVAILABLE FOR REGULAR USERS FOR NOW!
      -->

    <Dialog heading="Uložit kontrolu projektu" class="save-configuration dlg" :show="showDialog" @close="close()" :width="640" >
      <!-- EXISTING PROJECT -->
      <div>
        <!-- <div class="row" > 
          <h3>[existující] Uložit jako novou kontrolu projektu</h3>
          <toggle-button :value="isNew" @input="isNew = $event" />
        </div> -->
        <div class="row" v-if="!isNew">
          <h3>Název kontroly projektu</h3>
          <text-input :value="openedPC.name" @input="setName" small :width="300" disabled/>
        </div>
        <div class="row" v-else>
          <h3>Název kontroly projektu</h3>
          <text-input :value="name" @input="setName" small :width="300"/>
        </div>
      </div>
      
      <Section name=""> 
        <strong class="info" v-show="false">Uloženo.</strong>
      </Section>
      
      <div class="controls">
        <button @click="close()" class="primary">Zavřít</button>
        <button @click="saveIt()" class="primary" :disabled="actionStatusIsPending">Uložit</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Dialog from '../components/Dialog.vue';
import TextInput from '../components/fundamentals/TextInput.vue';
import Section from '../components/Section.vue';

export default {
  components: { Dialog, TextInput, Section },
  computed: {
    ...mapGetters(['actionStatusIsPending']),
    ...mapGetters('PC', ['isPCOpened', 'openedPC']),
  },
  methods: {
    ...mapActions( 'PC',[
      'SAVE_PC',
    ]),
    async saveIt() {
      let name = this.name;
      if (this.isPCOpened && !this.isNew) {
        name = this.openedPC.name;
      }
      try {
        const data = await this.SAVE_PC({name, isNew: this.isNew});
        this.isNew = false;
        console.log(data);
      } catch (err) {
        console.error(err);
      }
      this.saveInvoked = true;
    },
    setName(name) {
      this.name = name;
    },
    close () {
      this.showDialog = false;
      this.saveInvoked = true;
    },
    doShowDialog() {
      this.showDialog = true;
      if (this.isPCOpened) {
        this.isNew = false;
        this.name = this.openedPC.name;
      } else {
        this.isNew = true;
        this.name = "Název"
      }
    },
  },
  data() {
    return {
      isNew: false,
      showDialog: false,
      name: 'Nazev',
      saveInvoked: false,
    };
  }
}
</script>

<style lang="scss">
.save-configuration {
  .dlg {
    color: black;
  }
  .row {
    width: 100%;
    color: black;
    display: inline-flex;
    justify-content: space-between;
    align-items: baseline;
    //gap: 50px;
    
  }
  .controls {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
  button:disabled {
    background: grey;
  }
}
</style>
