<template>
  <div class="articles">
    <h1 class="title">Informativní články</h1>
    <div class="edit-article" v-show="editInfo.show"> 
      <Section name="název článku">
        <TextInput :value="article.title" @input="article.title = $event" :width="500" />
      </Section>
      <div class="editor-wrapper">
        <div class="art-editor" ref="editorContainer"></div>
      </div>
      <div class="controls-row">
        <button class="primary" @click="saveArticle">uložit</button>
        <button class="primary" @click="editorCancel">zrušit</button>
      </div>
    </div>

    <div v-show="!editInfo.show" class="controls-row">
      <button class="primary" @click="exportArticles()">export articles</button>
      <button class="primary" @click="addArticle()">přidat nový článek</button>
    </div>
    <div>
    </div>
    
    <table class="centered">

      <tr class="header-row">
        <th class="heading id">ID</th>
        <th class="heading title">Titulek článku</th>
        <th class="heading options">Možnosti</th>
      </tr>

      <Article 
        v-for="(article, idx) in articles" 
        :article="article" 
        :key="idx"
        @edit="editArticle(article)"
        @remove="removeArticle(article._id)"
      ></Article>
    </table>
  </div>
</template>

<script>
import Article from "./articles/Article.vue";
import Quill from 'quill';

// highlight.js style
import 'highlight.js/styles/tomorrow.css'

// import theme style
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import htmlEditButton from "quill-html-edit-button";

Quill.register({
  "modules/htmlEditButton": htmlEditButton
})

import TextInput from '../components/fundamentals/TextInput.vue';
import Section from '../components/Section.vue';
import articleService from "@/store/api/article.service";

var options = {
  //debug: 'info',
  modules: {
    // ...
    htmlEditButton: {}
  },
  placeholder: 'Zde napiště článek...',
  readOnly: false,
  theme: 'snow'
};

const resetArticle = () => ({
  _id: null,
  id: 0,
  title: '',
  body: '',
})

export default {
  components: {Article, TextInput, Section},
  mounted() {
    
    const editorContainer = this.$refs.editorContainer;
    this.editor = new Quill(editorContainer, options);
    this.fetchAll();
  },
  methods: {
    refreshEditor() {
      const editorContainer = this.$refs.editorContainer;
      console.log('editorContainer', editorContainer.previousElementSibling);
      const toolbar = editorContainer.previousElementSibling;
      console.log('toolbar', toolbar)
      if (toolbar) toolbar.remove();
      this.editor = new Quill(editorContainer, options);
    },
    addArticle() {
      this.article = resetArticle();
      this.$refs.editorContainer.innerHTML = this.article.body? this.article.body : "<p> <br /> </p>";
      this.refreshEditor();
      //this.editor.setContents([{ insert: '\n' }]);
      this.editInfo.show = true;
    },
    async fetchAll() {
      this.articles = await articleService.getAllArticles();
    },
    async saveArticle() {
      this.article.body = this.editor.root.innerHTML;
      if (this.article._id) {
        await articleService.updateArticle(this.article._id, this.article);
      } else {
        await articleService.createArticle(this.article);
      }
      this.editInfo.show = false;
      await this.fetchAll();
    },
    async removeArticle(articleId) {
      const res = await articleService.deleteArticle(articleId);
      this.articles = this.articles.filter(art => art._id != articleId);
      console.log(res);
    },
    async editArticle(article) {
      console.log("editArticle", article);
      if (this.editInfo.show) {
        if (confirm("Máte v editoru něco rozdělaného. Přejete si to uložit a pokračovat?")) {
          await this.saveArticle();
        } else {
          return;
        }
      }
      this.article = await articleService.getArticleById(article._id);

      // method 1
      //const delta = this.editor.clipboard.convert(this.article.body);
      //this.editor.setContents(delta, 'silent');

      // method 2
      // this.editor.setContents([{ insert: '\n' }]);
      // this.editor.clipboard.dangerouslyPasteHTML(this.article.body);
      // method 3
      this.$refs.editorContainer.innerHTML = this.article.body? this.article.body : "<p> <br /> </p>";
      this.refreshEditor();
      this.editInfo.show = true;
    },
    editorCancel() {
      this.editor.setContents([{ insert: '\n' }]);
      this.article = resetArticle();
      this.editInfo.show = false;
    },
    async exportArticles() {
      const data = await articleService.exportAllArticles();

      const date = new Date();
      const ddmmyyyy = date.ddmmyyyy().replaceAll(/[. ]/g, '-')
      const filename = `articles-backup-${ddmmyyyy}.json`

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = window.URL.createObjectURL(data);
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); 
    }
  },
  data() {
    return {
      editor: null,
      text: '',
      articles: [
        {id:1, title: "Náš první článek o složitém požadavku"},
        {id:2, title: "Náš první článek o složitém požadavku"},
        {id:3, title: "Náš první článek o složitém požadavku"},
      ],
      article: resetArticle(),
      editInfo: {
        show: false,
      }
    }
  }
}
</script>

<style lang="scss">

.articles {
  .controls-row {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding: 10px 0;
  }
  table {
    width: 80vw;
    margin: 0 10vw;

  }
  .header-row {
    background-color: $primary-color;
    color: white;
  }
  
  .heading {
    background-color: $primary-color;
    color: white;
    padding: 5px;
  }
  .art {
    padding: 5px;
  }
  .art.id {
    text-align: center;
  }
  .title {
    width: 99%;
  }
}
</style>
