<template>
  <div v-if="itsMe()">
    <i @click="openCalc()" class="clickable fas fa-calculator"></i>
    <Dialog heading="Počet toalet" class="quantity-wc" :show="show" @close="closeCalc()">
      <Section name="Druh provozu">
        <DropdownInput
          :options="functionKindOptions"
          :value="functionKind"
          @input="setFunctionKind($event)"
          :width="700"
          small
        />
      </Section>
      
      <Section name="Specifikace provozu" v-if="functionKind == 13 || functionKind == 2">
        <DropdownInput
          :options="getFunctionKindSpecificationOptions"
          :value="functionKindSpecification"
          @input="functionKindSpecification = $event"
          :width="700"
          small
        />
      </Section>

      <Section name="Detailní zadaní">
        <ToggleButton
          :value="detailedSpecification"
          @input="detailedSpecification = $event"
        />
      </Section>

      <div v-if="detailedSpecification">
        <Section name="Počet uživatelů - muži">
          <NumberWithUnitInput
            :value="maleCount"
            @input="maleCount = $event"
            :step="1"
            :min="0"
            :width="250"
            unit="&nbsp;"
          />
        </Section>

        <Section name="Počet uživatelů - ženy">
          <NumberWithUnitInput
            :value="femaleCount"
            @input="femaleCount = $event"
            :step="1"
            :min="0"
            :width="250"
            unit="&nbsp;"
          />
        </Section>

        <Section name="Počet uživatelů - invalidi">
          <NumberWithUnitInput
            :value="disabledCount"
            @input="disabledCount = $event"
            :step="1"
            :min="0"
            :width="250"
            unit="&nbsp;"
          />
        </Section>
      </div>
      <div v-else>
        <Section name="Počet uživatelů celkem">
          <NumberWithUnitInput
            :value="simplifiedTotalCount"
            @input="simplifiedTotalCount = $event"
            :step="1"
            :min="0"
            :width="250"
            unit="&nbsp;"
          />
        </Section>
      </div>
      <div v-if="detailedSpecification">
        <Section name="Počet WC - muži">
          <div class="result-group">
            <div class="value" v-text="result('male')"></div>
            <button @click="use('male')"  class="black-btn">použít</button>
          </div>
        </Section>

        <Section name="Počet WC - ženy">
          <div class="result-group">
            <div class="value" v-text="result('female')"></div>
            <button @click="use('female')"  class="black-btn">použít</button>
          </div>
        </Section>

        <Section name="Počet WC - invalid">
          <div class="result-group">
            <div class="value" v-text="result('disabled')"></div>
            <button @click="use('disabled')"  class="black-btn">použít</button>
          </div>
        </Section>

        <Section name="Počet WC - součet">
          <div class="result-group">
            <div class="value" v-text="result('sumup')"></div>
            <button @click="use('sumup')"  class="black-btn">použít</button>
          </div>
        </Section>
      </div>
      <div v-else>
        <Section name="Počet WC - dohromady">
          <div class="result-group">
            <div class="value" v-text="result('simplified')"></div>
            <button @click="use('simplified')"  class="black-btn">použít</button>
          </div>
        </Section>
      </div>
      
      <div class="controls">
        <button @click="show = false" class="primary">zavřít</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ERequirementLevel } from '../../@types/definitions/requirements';
const functionKindSpecificationSportOptions = [
  {name: 'Bez rozlišení', value: 10000},
  {name: 'Koupaliště (bez rozlišení)', value: 3},
  {name: 'Koupaliště kryté, umělé', value: 4},
  {name: 'Koupaliště nekryté, umělé', value: 5},
  {name: 'Sauny', value: 6},
]

const functionKindSpecificationOfficeOptions = [
  {name: 'Bez rozlišení', value: 10000},
  {name: 'Administrativní budovy - kancelářská pracoviště', value: 1},
  {name: 'Administrativní budovy - jednací a shromažďovací prostory', value: 2},
]

const functionKindSpecificationOthersOptions = [
  {name: 'Bez rozlišení', value: 10000},
]

import Dialog from '../components/Dialog.vue'
import DropdownInput from '../components/fundamentals/DropdownInput.vue'
import NumberWithUnitInput from '../components/fundamentals/NumberWithUnitInput.vue';
import ToggleButton from '../components/fundamentals/ToggleButton.vue';
import Section from '../components/Section.vue'
export default {
  components: { 
    Dialog,
    DropdownInput,
    Section,
    NumberWithUnitInput,
    ToggleButton,
  },
  props: {
    desiredCalculator: {
      type: String,
      default: "no-calc"
    },
    owner: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    if (this.owner && this.owner.functionTypeIdx != null) {
      const entity = {
        level: ERequirementLevel.FUNCTION_TYPE,
        idx: this.owner.functionTypeIdx,
      };
      this.functionKind = this.functionConfigurations[entity.idx].functionKindId;
      this.detailedSpecification = this.hasEntityParameter(entity, 'detailedUserCounts');
      if (this.detailedSpecification) {
        this.femaleCount = this.getEntityParameter(entity, 'femaleCount') || 0
        this.maleCount = this.getEntityParameter(entity, 'maleCount') || 0;
        this.disabledCount = this.getEntityParameter(entity, 'disabledCount') || 0;
      } else {
        this.simplifiedTotalCount = this.getEntityParameter(entity, 'userCounts') || 0;
      }
    }
  },
  data() {
    return {
      show: false,
      functionKind: 0,
      functionKindSpecification: 10000,
      detailedSpecification: false,
      femaleCount: 1,
      maleCount: 1,
      disabledCount: 1,
      simplifiedTotalCount: 3,
    }
  },
  computed: {
    ...mapGetters('definitions', ['functionKinds']),
    ...mapGetters('configuration', [
      'projectName',
      'functionConfigurations',
    ]),
    ...mapGetters('configuration', [
      'getEntityParameter',
      'hasEntityParameter',
    ]),
    functionKindOptions() {
      return this.functionKinds.map(fk => ({name: fk.name, value: fk.id}));
    },
    getFunctionKindSpecificationOptions() {
      if (this.functionKind == 13) return functionKindSpecificationSportOptions;
      if (this.functionKind ==  2) return functionKindSpecificationOfficeOptions;
      return functionKindSpecificationOthersOptions;
    },
  },
  methods: {
    itsMe() {
      return this.desiredCalculator == "QuantityWC"
    },
    openCalc() {
      this.show = true
    },
    closeCalc() {
      this.show = false
    },
    countWCFemale() {
      const count = this.femaleCount;
      const fun_if = (cond, t, f) => (cond? t : f);
           
      switch (this.functionKindSpecification) {
        case 1: return fun_if(count<=10,1,fun_if(count<=30,2,fun_if(count<=50,3,fun_if(count<=80,4,Math.ceil((count-80)/30)+4))));
        case 2: return fun_if(count<=50,2,Math.ceil((count-50)/50)+2);
        case 3: return Math.ceil(count/50);
        case 4: return Math.ceil(count/50);
        case 5: return Math.ceil(count/50);
        case 6: return Math.ceil(count/20);
        case 10000: return Math.ceil(count/20);
      }
      throw new Error("Invalid functiKindSpecification.");
    },
    countWCMale() {
      const count = this.maleCount;
      const fun_if = (cond, t, f) => (cond? t : f);
      
      switch (this.functionKindSpecification) {
        case 1: return fun_if(count<=10,1,fun_if(count<=50,2,fun_if(count<=100,3,Math.ceil((count-100)/50)+3)));
        case 2: return fun_if(count<=100,1,Math.ceil((count-100)/100)+1);
        case 3: return Math.ceil(count/100);
        case 4: return Math.ceil(count/100);
        case 5: return Math.ceil(count/100);
        case 6: return Math.ceil(count/40);
        case 10000: return Math.ceil(count/40);
      }
      throw new Error("Invalid functiKindSpecification.");
    },
    countWCDisabled() {
      const count = this.disabledCount;
      switch (this.functionKindSpecification) {
        case 1: return Math.ceil(count/10);
        case 2: return Math.ceil(count/20);
        case 3: return Math.ceil(count/20);
        case 4: return Math.ceil(count/20);
        case 5: return Math.ceil(count/20);
        case 6: return Math.ceil(count/20);
        case 10000: return Math.ceil(count/20);
      }
      throw new Error("Invalid functiKindSpecification.");
    },
    countWCSumUp() {
      return this.countWCFemale(this.femaleCount)
            +this.countWCMale(this.maleCount)
            +this.countWCDisabled(this.disabledCount);
    },
    countWCSimplified() {
      return Math.ceil(this.simplifiedTotalCount/25);
    },
    setFunctionKind(value) {
      this.functionKind = value;
      this.functionKindSpecification = 10000;
    },
    result(kind) {
      switch (kind) {
        case 'male': return this.countWCMale();
        case 'female': return this.countWCFemale();
        case 'disabled': return this.countWCDisabled();
        case 'sumup': return this.countWCSumUp();
        case 'simplified': return this.countWCSimplified();
        default: return "-";
      }
    },
    use (kind) {
      const result = this.result(kind);
      if (!isNaN(parseInt(result))) {
        this.show = false;
        const requiredValue1 = result;
        this.$emit("set-requirement", {requiredValue1});
      } else {
        confirm('Pro zadanou kombinaci neexistuje nelze určit číselný výsledek.');
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.quantity-wc {
  .section {
    .flex-inline {
      align-items: baseline;
    }
  }
  .result-group {
    display: inline-flex;
    align-items: baseline;
    .value {
      font-weight: 600;
      font-size: 1.2em;
      margin: 0 20px;
    }
  }
  .floor-category {
    width: 700px;
    justify-content: space-around;
    span {
      text-align: center;
    }
  }
  .controls {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}
</style>
