<template>
  <div>
    <Dialog heading="Konfigurace dispozice bytové jednotky" class="apartment-dialog" :show="showDialog" @close="onClose()">
      <div class="flex-column">
        

        <Section v-if="apartmentCSCopy" name="Označení konfigurace dispozice bytové jednotky"> 
          <div class="flex-row">        
            <TextInput 
              :value="apartmentCSCopy.getValue('name')" 
              @input="apartmentCSCopy.setValue('name', $event)"
            />
          </div>
        </Section>

        <render-gui :gui="headingGui" />

        <DispositionRoomsTable :disposition="apartmentCSCopy" />
        
        <render-gui :gui="gui" />

      </div>

      <button @click="onClose()" class="primary">zavřít</button>
      <button @click="onApply()" class="primary">aplikovat změny</button>

      <Dialog heading="Chyba konfigurace dispozice bytové jednotky" :show="errors.length > 0" @close="errors = []">
        <p>V zadané konfiguraci chybí následující místnosti:</p>
        <ul>
          <li v-for="(error, index) in errors" :key="index" v-text="error"></li>
        </ul>
        <p>Přidejte tyto místnosti pro možnost uložení konfigurace dispozice.</p>
      </Dialog>
    </Dialog>
  </div>
</template>

<script>
import RenderGui from '@/GUI/RenderGui.vue';
import {
  GUI,
  ParameterOutput,
  Line,
  Section,
  Heading,
  LabeledCounterParameter,
  CustomSelectParameter,
} from '@/GUI/';

function makeHeadingGui(cs) {
  const gui = new GUI(cs);
  gui.add(new ParameterOutput('Počet obytných místností v konfiguraci', 'numberAccommodationRoomsFlatA', 'ks'));
  //gui.add(new ParameterOutput('Samostatná kuchyně?', 'separateKitchenFlatA', '-'));
  gui.add(new CustomSelectParameter('Samostatná kuchyně?', 'separateKitchenFlatA', [{value: 1, name: 'Ano'}, {value: 0, name: 'Ne'}], {readonly: true}));
  return gui;
}

function makeApartmentGui(cs) {
  const gui = new GUI(cs);
  
  const sectionClasses = {'flex-row': true, 'flex-row-4': true, 'gap-10': true};
  gui.add(new Line());
  gui.add(new Heading('Obývací pokoj'));
  gui.add((new Section(cs, sectionClasses))
      .add(new LabeledCounterParameter('bez stolování', 'livingRoomWithoutDiningTable.count'))
      .add(new LabeledCounterParameter('se stolováním', 'livingRoomWithDiningTable.count'))
      .add(new LabeledCounterParameter('bez stolování s lůžkem', 'livingRoomWithBedWithoutDiningTable.count'))
      .add(new LabeledCounterParameter('se stolováním s lůžkem', 'livingRoomWithBedWithDiningTable.count')))
  gui.add(new Line());
  gui.add(new Heading('Ložnice'))
  gui.add((new Section(cs, sectionClasses))
      .add(new LabeledCounterParameter('s 1 lůžkem', 'bedroomSingleBed.count'))
      .add(new LabeledCounterParameter('se 2 lůžky', 'bedroomTwinBeds.count')))
  gui.add(new Line());
  gui.add(new Heading('Kuchyně'))
  gui.add((new Section(cs, sectionClasses))
      .add(new LabeledCounterParameter('pracovní', 'kitchen.count'))
      .add(new LabeledCounterParameter('se stolováním', 'kitchenWithDiningTable.count'))
      .add(new LabeledCounterParameter('obytná, náhrada OP', 'habitableKitchen.count'))
      .add(new LabeledCounterParameter('obytná s lůžkem, náhrada OP', 'habitableKitchenSingleBed.count')))
  gui.add(new Line());
  gui.add(new Heading('Sociální zařízení'))
  gui.add((new Section(cs, sectionClasses))
      .add(new LabeledCounterParameter('Koupelna', 'bathroom.count'))
      .add(new LabeledCounterParameter('WC', 'WC.count'))
      .add(new LabeledCounterParameter('Koupelna s WC', 'bathroomWithWC.count')))
      .add(new LabeledCounterParameter('Sprcha', 'shower.count'))
  gui.add(new Line());
  gui.add(new Heading('Komunikace'))
  gui.add((new Section(cs, sectionClasses))
      .add(new LabeledCounterParameter('Chodba', 'hall.count'))
      .add(new LabeledCounterParameter('Předsíň', 'vestibule.count'))
      .add(new LabeledCounterParameter('Schodiště bytové', 'staircase.count')))

  gui.add(new Heading('Ostatní typy místností'))
  gui.add((new Section(cs, sectionClasses))
      .add(new LabeledCounterParameter('Jídelna', 'diningRoom.count'))
      .add(new LabeledCounterParameter('Komora', 'pantry.count'))
      .add(new LabeledCounterParameter('Pracovna', 'study.count'))
      .add(new LabeledCounterParameter('Šatna', 'WIC.count')))
  gui.add(new Line());
  return gui;
}
const default1kk = composeDefault2kk()
const headingGui = makeHeadingGui(default1kk)
const apartmentGui = makeApartmentGui(default1kk);


import Dialog from '@/views/components/Dialog.vue'
import { composeDefault2kk } from './apartment';
import { TextInput } from '@/views/components/customElements';
import SectionVue from '@/views/components/Section.vue';
import DispositionRoomsTable from './DispositionRoomsTable.vue';
export default {
  components: {
    Dialog,
    RenderGui,
    TextInput,
    Section: SectionVue,
    DispositionRoomsTable
},
  data() {
    return {
      showDialog: false,
      headingGui: headingGui,
      gui: apartmentGui,
      apartmentCS: default1kk,
      apartmentCSCopy: null,
      errors: [],
    }
  },
  methods: {
    open(apartmentCS) {
      //console.log("open dialog", apartmentCS);
      this.apartmentCS = apartmentCS;
      this.apartmentCSCopy = apartmentCS.clone();
      this.headingGui = makeHeadingGui(this.apartmentCSCopy);
      this.gui = makeApartmentGui(this.apartmentCSCopy);
      this.showDialog = true;
    },
    getErrors() {
      const errors = [];
      if (this.apartmentCSCopy.getValue('missingBathroom') > 0) {
        errors.push('Koupelna');
      }
      if (this.apartmentCSCopy.getValue('missingToiletSeparated') > 0) {
        errors.push('WC samostatně');
      }
      const missingToilet = this.apartmentCSCopy.getValue('missingToilet');
      if (missingToilet > 0) {
        if (missingToilet > 0.21 && missingToilet < 0.81) {
          errors.push('WC');
        } else if (missingToilet > 1) {
          errors.push('2x WC (alespoň jedno samostatně)');
        }
      }
      return errors;
    },
    onApply() {
      this.errors = this.getErrors(); 
      if (this.errors.length > 0) {
        console.log(this.errors);
      } else {
        /// TODO: save
        this.apartmentCS.soakIn(this.apartmentCSCopy);
        this.showDialog = false;
      }
    },
    onClose() {
      this.showDialog = false;
    },
  }
}
</script>

<style lang="scss">

</style>
