import router from "@/router";
import { ActionContext, Module } from "vuex";
import projectService from "./api/project.service";
import { EActionStatus, IState } from "./index";

interface IUser {
  username: string;
  _id: string;
}

export interface IProject {
  _id: string,
  name: string,
  address: string,
  owners:  IUser[],
  stages: {CC: boolean, PC: boolean, M3D: boolean},
  concepts: string[],
  configurations: string[],
  models3D: string[],
  published: boolean,
  createdAt: Date,
  updatedAt: Date,
}

const newProject: IProject = {
  _id: '',
  name: '',
  address: '',
  owners: [],
  stages: {
    CC: false,
    PC: false,
    M3D: false,
  },
  concepts: [],
  configurations: [],
  models3D: [],
  published: false,
  createdAt: new Date(),
  updatedAt: new Date(),
};

type INewProject = typeof newProject;

const state = {
  isOpened: false,
  ... newProject,
};

type State = typeof state;

const project:Module<State, IState> = {
  namespaced: true,
  state: ():State => state,
  getters: {
    openedProject: (state:State) => state,
    unpopulatedOwners: (state:State) => {
      return state.owners.map(owner => owner._id);
    },
    stateCopy: (state:State) => {
      return JSON.parse(JSON.stringify(state))
    },
    projectId: (state:State) => state._id,
    concepts: (state:State) => state.concepts,
    configurations: (state:State) => state.configurations,
    models3D: (state:State) => state.models3D,
    isProjectOpened: (state:State) => state.isOpened,
  },
  mutations: {
    openProject: (state:State, project:any) => { 
      state._id = project._id;
      state.name = project.name || 'no name';
      state.address = project.address || 'no address';
      state.owners = project.owners;
      state.stages = project.stages;
      state.concepts = project.concepts;
      state.configurations = project.configurations;
      state.models3D = project.models3D;
      state.published = project.published;
      state.createdAt = project.createdAt;
      state.updatedAt = project.updatedAt;
      state.isOpened = true;
    },
    updateState: (state:State, project:IProject) => { 
      if (project._id)  state._id = project._id;
      if (project.name)   state.name = project.name;
      if (project.address)  state.address = project.address;
      if (project.owners)   state.owners = project.owners;
      if (project.stages)   state.stages = project.stages;
      if (project.concepts)   state.concepts = project.concepts;
      if (project.configurations)   state.configurations = project.configurations;
      if (project.models3D)   state.models3D = project.models3D;
      if (project.published)  state.published = project.published;
      if (project.createdAt)  state.createdAt = project.createdAt;
      if (project.updatedAt)  state.updatedAt = project.updatedAt;
    },
    closeProject: (state:State) => { 
      state._id = "";
      state.name = "";
      state.isOpened = false;
    },
    setName: (state:State, name:string) => { 
      state.name = name;
    },
    setAddressName: (state:State, address:string) => { 
      state.address = address;
    },
  },
  actions: {
    async GET_ALL(context: ActionContext<State, IState>, filters) {
      try {
        return await projectService.getAllProjects(filters);
      } catch {
        return []
      }
    },

    async PROJECT_SET_OWNER(context: ActionContext<State, IState>, owner:string) {
      const owners = context.getters.unpopulatedOwners as string[];
      const newOwnerIndex = owners.findIndex(item => owner == item)
      if (newOwnerIndex < 0) {
        console.error('PROJECT_SET_OWNER');
        return;
      }
      owners[newOwnerIndex] = owners[0];
      owners[0] = owner;
      await context.dispatch('SAVE_PROJECT', {owners});
    },
    async PROJECT_REMOVE_USER(context: ActionContext<State, IState>, owner:string) {
      const ownersOrig = context.getters.unpopulatedOwners as string[];
      const owners = ownersOrig.filter(item => owner != item)
      if (ownersOrig.length == owners.length) {
        console.error('PROJECT_REMOVE_USER');
        return;
      }
      await context.dispatch('SAVE_PROJECT', {owners});
    },
    async PROJECT_ADD_USER(context: ActionContext<State, IState>, owner:string) {
      const owners = context.getters.unpopulatedOwners as string[];
      const newOwnerIndex = owners.findIndex(item => owner == item)
      if (newOwnerIndex >= 0) {
        console.error('PROJECT_ADD_USER');
        return;
      }
      owners.push(owner);
      await context.dispatch('SAVE_PROJECT', {owners});
    },

    async RENAME_PROJECT(context: ActionContext<State, IState>, name:string) {
      await context.dispatch('SAVE_PROJECT', {name});
    },

    async CHANGE_ADDRESS_PROJECT(context: ActionContext<State, IState>, address:string) {
      await context.dispatch('SAVE_PROJECT', {address});
    },

    async CREATE_PROJECT(context: ActionContext<State, IState>, data: {name: string, address: string, owners: any}) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      try {
        const userId = context.rootGetters['user/id']
        data.owners = [userId];
        const res = await projectService.createProject(data);
        await context.dispatch('OPEN_PROJECT', res._id);
        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

    async SAVE_PROJECT(context: ActionContext<State, IState>, projectData: INewProject) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      const prevState = context.getters.stateCopy;
      try {
        const projectId = context.getters.projectId;
        context.commit('updateState', projectData);
        const res = await projectService.updateProject(projectId, projectData);
        context.commit('updateState', res);
        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch (error) {
        context.commit('updateState', prevState);
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },
    /*
    async DUPLICATE(context: ActionContext<State, IState>, _id:string) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      try {
        console.error('This action Project.DUPLICATE is not supported.');
        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    }, */

    async OPEN_PROJECT(context: ActionContext<State, IState>, id:string) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      try {
        const project = await projectService.getProjectById(id);
        
        //context.commit('configuration/set', savedConfiguration.configuration, {root: true});
        context.commit('openProject', project);
        context.commit('setActionStatus', EActionStatus.OK, {root: true});
        context.commit('PC/setListOfPC', [], {root: true});
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

    async REMOVE_PROJECT(context: ActionContext<State, IState>, id:string) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      try {
        await projectService.deleteProject(id);
        context.commit('setActionStatus', EActionStatus.OK, {root: true});
        router.push('/project-manager');
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

  },
};

export default project;
