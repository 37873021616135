type State = any;
const result = {
  namespaced: true,
  state: () => ({
    resultPanel: {
        currentTab: 'requirements',
    },
  }),
  getters: {
    currentTab: (state:State) => state.resultPanel.currentTab,
  },
  mutations: {
    setCurrentTab: (state:State, val:string) => { state.resultPanel.currentTab = val; },
  },
  actions: {},
};

export default result;
