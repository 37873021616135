<template>
  <div>
    <h1>concepts list</h1>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  methods: {

  },
  computed: {
    ...mapGetters('project', [
      'concepts'
    ])
  }
}
</script>


<style lang="scss">


</style>
