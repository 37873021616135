<template>
<div class="users-admin">
  <h1 class="title">Uživatelé</h1>
  
  <button @click="openCreate">přidat uživatele</button>
  
  <Dialog heading="Vytvořit uživatele" :show="showCreate" @close="closeCreate">
    <div class="flex-row">
      <label class="create-user-label">přihlašovací jméno:</label> 
      <input v-model="user.username" type="text">
    </div>
    
    <div class="flex-row">
      <label class="create-user-label">role: </label>
      <select v-model="user.role">
        <option value="admin">administrátor</option>
        <option value="user">oprávněný uživatel</option>
        <option value="none">neoprávněný uživatel</option>
      </select>
    </div>

    <div class="flex-row">
      <label class="create-user-label">heslo: </label>
      <input v-model="user.password" type="text">
    </div>

    <div class="buttons-right flex-row">
      <button @click="createUser()">přidat</button>
      <button @click="closeCreate">zrušit</button>
    </div>
  </Dialog>

  
  <Dialog heading="Upravit uživatele" :show="showUpdate" @close="closeUpdate">
    <p>
      Uživatel id {{ user._id }}
    </p>
    <div class="flex-row">
      <label class="create-user-label">přihlašovací jméno:</label> 
      <input v-model="user.username" type="text">
    </div>
    
    <div class="flex-row">
      <label class="create-user-label">role: </label>
      <select v-model="user.role">
        <option value="admin">administrátor</option>
        <option value="user">oprávněný uživatel</option>
        <option value="none">neoprávněný uživatel</option>
      </select>
    </div>

    <div class="flex-row">
      <label class="create-user-label">heslo: </label>
      <input v-model="user.password" type="text">
    </div>

    <div class="buttons-right flex-row">
      <button @click="updateUser()">přidat</button>
      <button @click="closeUpdate">zrušit</button>
    </div>
  </Dialog>
  
  
  <div class="flex-column">
    <div v-for="(user, idx) in users" class="user-row flex-row" :key="idx">
      <div class="name">
        {{user.username}} 
      </div> 
      <div v-if="isAdmin(user)" class="tag admin">{{ user.role }}</div>
      <div v-if="isUser(user)" class="tag user">{{ user.role }}</div>
      <div v-if="isNone(user)" class="tag none">{{ user.role }}</div>
      <div v-if="isMe(user._id)" class="tag me">Já</div>
      <div class="spring-grow"></div>
      <div class="user-controls gap-10 flex-row" >
        <button 
          class="clickable"
          @click="openUpdate(user)"
        >změnit</button>
        
        <button 
          class="clickable far fa-trash-alt"
          @click="deleteUser(user._id)" 
        > odstranit uživatele</button>
        
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { EActionStatus } from '@/store';
import { mapActions, mapGetters } from 'vuex';
import Dialog from '../components/Dialog.vue';

export default {
    name: "users",
    computed: {
        ...mapGetters(['actionStatus']),
        ...mapGetters("users", ["users"]),
        ...mapGetters("user", ["username", "id"]),
    },
    methods: {
        ...mapActions("users", [
            "GET_USERS",
            "CREATE_USER",
            "UPDATE_USER",
            "DELETE_USER",
        ]),

        isMe(id) {
            return this.id == id;
        },
        isAdmin(user) {
            return user.role == "admin";
        },
        isUser(user) {
            return user.role == "user";
        },
        isNone(user) {
            return user.role == "none";
        },

        // create user dialog
        openCreate(){
          this.showCreate = true;
          this.user = {
            _id: null,
            username: '',
            password: '',
            role: 'user'
          }
        },
        closeCreate(){
          this.showCreate = false;
          this.user = {
            _id: null,
            username: '',
            password: '',
            role: 'user'
          }
        },
        async createUser() {
          await this.CREATE_USER({
            username: this.user.username,
            role: this.user.role,
            password: this.user.password,
          });
          console.log('actionStatus', this.actionStatus)
          if (this.actionStatus == EActionStatus.OK) {
            // TODO: jump to the schema when posible
            await this.GET_USERS();
            this.closeCreate();
          }
        },

        // Update user
        openUpdate(user){
          this.showUpdate = true;
          this.user = {
            _id: user._id,
            username: user.username,
            password: '',
            role: user.role
          }
        },
        closeUpdate(){
          this.showUpdate = false;
          this.user = {
            _id: null,
            username: '',
            password: '',
            role: 'user'
          }
        },
        async updateUser() {

          if (! this.user.username) {
            delete this.user.username;
          }

          if (! this.user.role) {
            delete this.user.role;
          }
          
          if (! this.user.password) {
            delete this.user.password;
          }

          await this.UPDATE_USER({
            _id: this.user._id,
            username: this.user.username,
            role: this.user.role,
            password: this.user.password,
          });

          console.log('actionStatus', this.actionStatus)
          if (this.actionStatus == EActionStatus.OK) {
            // TODO: jump to the schema when posible
            await this.GET_USERS();
            this.closeUpdate();
          }
        },

        async deleteUser(userId) {
          await this.DELETE_USER(userId);
          if (this.actionStatus == EActionStatus.OK) {
            await this.GET_USERS();
          }
        },
    },
    data() {
      return {
        user: {
          _id: null,
          username: 'user',
          password: 'pw',
          role: 'user'
        },
        showCreate: false,
        showUpdate: false,
      }
    },
    components: { Dialog }
}
</script>

<style lang="scss">
.users-admin {
  margin: 20px;
  .user-row {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: white;
    border: solid 1px black;
    gap: 10px;
    align-items: baseline;
  }

  .name {
    padding: 3px;
    min-width: 160px;
  }

  .tag  {
    &.me {
      background-color: pink;
    }
    &.admin {
      background-color: rgb(219, 58, 85);
    }
    &.none {
      background-color: rgb(164, 163, 163);
    }
  }  
}

.spring-grow {
  flex-grow: 1;
}

/* Dialogs */
label.create-user-label {
  width: 200px;
  text-align: right;
  padding-right: 10px;
}
.buttons-right {
    justify-content: flex-end;
} 
</style>
