<template>
  <div class="requirements">

    <div>
      <BatchAssignmentDialogWrapper />

      <Section name="Lokalita">
        <RequirementsDialog 
          :activeRequirements="locationRequirements"
          @add-requirement="addLocationRequirement({requirement: $event})"
          level="location"
          activator="button"
        />
      </Section>

      <Section name="Budova">
        <RequirementsDialog 
          :activeRequirements="buildingRequirements" 
          @add-requirement="addBuildingRequirement({requirement: $event})"
          level="building"
          activator="button"
        />
      </Section>
      <hr />
      <SpatialInterconnection :mode="ESchemaMode.REQUIREMENTS"/>

      <hr />
    </div>
  </div>
</template>

<script>
import { ESchemaMode } from '@/@types/definitions/mode'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Section from '../components/Section.vue'
import BatchAssignmentDialogWrapper from './batchAssignment/BatchAssignmentDialogWrapper.vue'
import RequirementsDialog from './RequirementsDialog.vue'
import SpatialInterconnection from './SpatialInterconnection.vue'

export default {
  components: { 
    Section, 
    RequirementsDialog, 
    SpatialInterconnection, 
    BatchAssignmentDialogWrapper 
  },
  methods: {
    ...mapMutations('configuration', [
      'setLocationRequirements',
      'setBuildingRequirements',
      'setFunctionKindRequirements',
    ]),
    functionKindName(idx) {
        const [item] = this.functionKinds.filter(kind => kind.id === idx);
        if (item) return item.name.toLowerCase();
        return 'not found';
    },
    ...mapActions('configuration', [
      'addLocationRequirement',
      'addBuildingRequirement',
    ])
  },
  computed: {
    ...mapGetters('definitions', [
      'functionKinds'
    ]),
    ...mapGetters('configuration', [
      'locationRequirements',
      'buildingRequirements',
      'functionConfigurations',
      'functionKindRequirements',
    ]),
    ...mapGetters('prezentation', [
      'showChecklist'
    ])
  },
  data() {
    return { ESchemaMode };
  }
}
</script>

<style lang="scss">
.requirements {
  h1 {
    //color: grey;
  }
}
</style>
