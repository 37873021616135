<template>
  <button class="primary" @click="showDialog()"> 
    <i class="fa fa-plus"></i>
    Založit nový projekt
  </button>

  <Dialog 
      :show="project.show" 
      heading="Založení nového projektu" 
      @close="project.show = false"
      width="1065"
  >
    <div class="new-project-dialog flex-column">
      <p class="p-label">Název projektu</p>
      <text-input :value="project.name" @input=" project.name = $event" />
      <p class="p-label">Adresa</p>
      <text-input :value="project.address" @input="project.address = $event" />
      
      
      <div class="flex-row centered">
        <button class="primary" @click="onSubmit()" :disabled="project && project.name == ''">
          Založit nový projekt
        </button>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex';
import Dialog from '../components/Dialog.vue'
import TextInput from '../components/fundamentals/TextInput.vue'
//import ApartmentTest from './ApartmentTest.vue';

export default {
  components: { 
    Dialog,
    TextInput,
    //TemplateTest,
    //ApartmentTest,
  },
  methods: {
    ...mapActions('project', ['CREATE_PROJECT']),
    showDialog() {
      this.project.show = true;
      this.project.name = '';
      this.project.address = '';
    },
    async onSubmit() {
      const newProject = {
        name: this.project.name,
        address: this.project.address,
      };

      try {
        await this.CREATE_PROJECT(newProject)
      } catch {
        console.error('The new project is not either project check nor concept calculator.');
        return;
      }

      this.$router.push('/project');
    },
  },
  data() {
    return {
      project: {
        show: false,
        name: '',
        address: '',
      },
    }
  }
}
</script>

<style lang="scss">
.new-project-dialog {
  .modules {
    //background: pink;
    gap: 25px;
    > .modul-icon {
      img {
        width: 100%;
      }
      //margin: 25px;
      border: 1px solid;
      padding: 25px;
      background: white;
      cursor: pointer;
      &.selected {
        background: $secondary-color;
      }
      &:hover {
        background: $secondary-color;
      }
    }
  }

  .centered {
    button.primary {
      margin-top: 28px;
    }
  }

  .user-input-style {
    width: 100%;
    height: 40px;
    font: normal normal 600 16px/22px Noto Sans;
  }

}
</style>
