import { CalculationSystem } from "@/calculations/calculationSystem";

interface IProps {
  min?: number,
  max?: number,
  step?: number,
  readonly?: boolean,
  showIfParameter?: string,
  calculatorId?: string,
}

class GuiComponent {
  type: string;
  props: IProps = {
    min: 0,
    max: Infinity,
    step: 1,
    readonly: false,
    showIfParameter: '',
  }
  constructor (type : string, props: IProps = {}) {
    this.type = type;
    this.setProps(props);
  }

  setProps(props:IProps) {
    if (props.min !== undefined) this.props.min = props.min;
    if (props.max !== undefined) this.props.max = props.max;
    if (props.step !== undefined) this.props.step = props.step;
    if (props.readonly !== undefined) this.props.readonly = props.readonly;
    if (props.showIfParameter !== undefined) this.props.showIfParameter = props.showIfParameter;
    if (props.calculatorId !== undefined) this.props.calculatorId = props.calculatorId;
  }
}

class Heading extends GuiComponent {
  text: string;
  constructor(text:string) {
    super('heading');
    this.text = text;
  }
}

class Line extends GuiComponent {
  constructor() {
    super('line');
  }
}

class Parameter extends GuiComponent {
  text: string;
  parameterName: string;
  unit: string;
  //output: boolean;
  constructor (text: string, parameterName: string, unit='-', props:IProps = {}) {
    super('parameter', props);
    this.text = text;
    this.parameterName = parameterName;
    this.unit = unit;
  }
}

class CheckboxParameter extends Parameter {
  constructor (text: string, parameterName: string) {
    super(text, parameterName);
    this.type = 'checkboxParameter';
  }
}

class SelectParameter extends GuiComponent {
  text: string;
  parameterName: string;
  constructor (text: string, parameterName: string, props:IProps={}) {
    super('selectParameter', props);
    this.text = text;
    this.parameterName = parameterName;
  }
}

type IOption = {
  value: any,
  name: string,
}

class CustomSelectParameter extends GuiComponent {
  text: string;
  parameterName: string;
  options: IOption[];
  constructor (text: string, parameterName: string, options:IOption[], props:IProps={}) {
    super('customSelectParameter', props);
    this.text = text;
    this.parameterName = parameterName;
    this.options = options;
  }
}

class ParameterOutput extends Parameter {
  constructor (text: string, parameterName: string, unit='-') {
    super(text, parameterName, unit);
    this.type = 'outputParameter';
  }
}

class ActivableParameter extends GuiComponent {
  text: string;
  parameterName: string;
  unit: string;
  constructor (text: string, parameterName: string, unit='-', props:IProps={}) {
    super('activableParameter', props);
    this.text = text;
    this.parameterName = parameterName;
    this.unit = unit;
  }
}

class LabeledCounterParameter extends GuiComponent {
  text: string;
  parameterName: string;
  unit: string;
  //output: boolean;
  constructor (text: string, parameterName: string, unit='-', props:IProps={}) {
    super('labeledCounterParameter', props);
    this.text = text;
    this.parameterName = parameterName;
    this.unit = unit;
  }
}

class ToggleButtonComponent extends GuiComponent {
  
  parameterName: string;
  text: string;
  
  constructor (text: string, parameterName: string) {
    super('toggleButtonComponent');
    
    this.parameterName = parameterName;
    this.text = text;   
  }
}

class GUI extends GuiComponent {
  cs: CalculationSystem;
  components: GuiComponent[];
  constructor (calculationSystem?: CalculationSystem, parameterName = 'gui', props:IProps={}) {
    super(parameterName, props);
    if (calculationSystem) {
      this.cs = calculationSystem;
    } else { 
      this.cs = new CalculationSystem();
    }
    this.components = [];
  }

  bindCalculationSystem(cs: CalculationSystem) {
    this.cs = cs;
  }

  add(component: GuiComponent) {
    this.components.push(component);
    return this;
  }
  // delegation
  isActive(parameterName: string) {
    return this.cs.isActive(parameterName);
  }
  setActivity(parameterName: string, value:boolean) {
    this.cs.setActivity(parameterName, value);
  }
  getValue(parameterName: string) {
    return this.cs.getValue(parameterName);
  }
  setValue(parameterName: string, value: any) {
    this.cs.setValue(parameterName, value);
  }
  is(parameterName: string, alias: string) {
    return this.cs.is(parameterName, alias);
  }
  showIf(comp: GuiComponent) {
    if (comp.props.showIfParameter !== undefined
     && comp.props.showIfParameter) {
      return this.getValue(comp.props.showIfParameter);
    }
    return true;
  }
}

class Section extends GUI {
  classes: Record<string, boolean>;
  constructor (calculationSystem: CalculationSystem, classes: Record<string, boolean>, props:IProps={}) {
    super(calculationSystem, 'section', props);
    this.classes = classes || {};
  }
}

class GUICollapsible extends GUI {
  classes: Record<string, boolean>;
  name: string;
  constructor (calculationSystem: CalculationSystem, name: string, classes: Record<string, boolean>, props:IProps={}) {
    super(calculationSystem, 'sectionCollapsible', props);
    this.classes = classes || {};
    this.name = name;
  }
}

export {
  Heading,
  Line,
  Parameter,
  CheckboxParameter,
  SelectParameter,
  ParameterOutput,
  ActivableParameter,
  LabeledCounterParameter,
  ToggleButtonComponent,
  GUI,
  Section,
  GUICollapsible,
  CustomSelectParameter,
}
