<template>
  <div class="input-group" >
    <div class="calculator-segment" v-if="calculatorId">
      <CalculatorDialogInplace :name="calculatorId" @set-requirement="forwardValue($event)" />
    </div>
    
    <input 
      value="111"
      @input.stop="onEdit($event)"
      :class="{ 
        edited: changed, 
        wrong: offRange,
      }"
      ref="inputEl"
      type="number"
      :step="step"
      :min="min"
      :max="max"
      :disabled="disabled"
      class="group"
    />

    <span class="input-group-text" v-html="unit">
    </span>
  </div>
</template>

<script>
import CalculatorDialogInplace from '@/views/calculators/CalculatorDialogInplace.vue';

export default {
    props: {
        initialValue: {
            type: Number,
            default: 1,
        },
        calculatorId: {
            type: String,
            required: false,
        },
        unit: String,
        changed: {
            type: Boolean,
            default: false,
        },
        step: {
            type: Number,
            default: 0.001,
        },
        min: {
            type: Number,
            default: -10000000000
        },
        max: {
            type: Number,
            default: 10000000000
        },
        /* small: {
          type: Boolean,
          default: false,
        }, */
        disabled: {
            type: Boolean,
            default: false,
        },
        width: {
            type: Number,
            default: 80,
        },
    },
    methods: {
        clip(num) {
            return Math.min(this.max, Math.max(this.min, num));
        },
        forwardValue(calcEvent) {
          const value = calcEvent && calcEvent.requiredValue1 
          const valueClipped = this.clip(value);
          //console.log('forwardValue value', value);
          this.$emit("input", this.clip(valueClipped));
          if (this.$refs.inputEl) {
            this.$refs.inputEl.value = valueClipped;
        }
        },
        onEdit(event) {
            this.lastEventValue = event.target.value;
            if (this.disabled) {
                event.target.value = this.currentValue;
                return;
            }
            let value = parseFloat(event.target.value);
            if (Number.isNaN(value)) {
                value = 0;
                event.target.value = "";
            }
            if (event.target.value < this.min) {
                this.$refs.inputEl.value = this.min;
            }
            this.$emit("input", this.clip(value));
        }
    },
    mounted() {
        this.lastEventValue = this.initialValue;
        if (this.$refs.inputEl) {
            this.$refs.inputEl.value = this.initialValue;
        }
        else {
            console.error("this.$refs.", this.$refs);
        }
    },
    data() {
        return {
            lastEventValue: 0,
        };
    },
    computed: {
        offRange() {
            return this.lastEventValue < this.min
                || this.lastEventValue > this.max;
        }
    },
    components: { CalculatorDialogInplace }
}
</script>

<style lang="scss">
input.wrong {
  border-color: grey;
  border-width: 2px;
}
.calculator-segment {
  background-color: $calculator-color;
  width: 29px;
  display: flex;
  justify-content: center;
  align-items: middle;
  line-height: 100%;
  .calculator i {
    line-height: 40px;
    text-align: centered;
    vertical-align: middle;
    &:hover {
      color: white;
    }
  }
}
</style>
