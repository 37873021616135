
import NumberWithUnitInputOneWay from '@/views/components/fundamentals/NumberWithUnitInputOneWay.vue'
import SectionCollapsible from '@/views/components/SectionCollapsible.vue'
import CheckboxInput from '../views/components/fundamentals/CheckboxInput.vue'
import LabeledCounter from '../views/components/fundamentals/LabeledCounter.vue'
import NumberWithUnitOutput from '../views/components/fundamentals/NumberWithUnitOutput.vue'
import ToggleButton from '../views/components/fundamentals/ToggleButton.vue'


export default {
  name: 'render-gui',
  components: {
    //NumberWithUnitInput,
    CheckboxInput,
    LabeledCounter,
    ToggleButton,
    NumberWithUnitOutput,
    NumberWithUnitInputOneWay,
    SectionCollapsible
},
  props: ['gui'],

}
