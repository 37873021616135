import { Connection, EConnectionType, EExteriorType } from "@/@types/definitions/connections";
import { IEntityLocation } from "@/@types/definitions/entity";
import { Requirement, ERequirementLevel } from "@/@types/definitions/requirements";
import { UsedRoom } from "@/@types/definitions/rooms";
import { ActionContext } from "vuex";
import { IState } from "../index"; 
import { ConnectionNodeSharedData,
         consolidateRecursive,
         getConnectionsRecursive,
         getFunctionKindsRecursive,
         getRoomsRecursive,
         ITreeStructureNode, 
         setRecursive, 
         treeStructureNode, 
         treeStructureNodeConnection, 
        } from "./batchAssignmentTreeStructure";
import { IConfigurationState } from "./configuration";
type State = IConfigurationState;


export const batchAssignment:Record<string, any> = {
  state: {
    level: 'building',
    functionConfigurationIdxs: [] as number [],
    roomIdxs: [] as number[],
    connectionIdxs: [] as number[],
    treeStructureFunctionKinds: {},
    treeStructureRooms: {},
    treeStructureConnections: {},
    requirementSet: {} as Record<number, Requirement>,
    numberOfAssignmentsMade: 0 as number,
    notification: '' as string,
  },
  getters: {
    batchAssignmentLevel: (state: State) => state.batchAssignment.level,
    batchAssignmentTreeStructureFunctionKinds: (state:State) => state.batchAssignment.treeStructureFunctionKinds,
    batchAssignmentTreeStructureRooms: (state:State) => state.batchAssignment.treeStructureRooms,
    batchAssignmentTreeStructureConnections: (state:State) => state.batchAssignment.treeStructureConnections,
    batchAssignmentSelectedFunctinKinds: (state:State) => {
      return getFunctionKindsRecursive(state.batchAssignment.treeStructureFunctionKinds);
    },
    batchAssignmentSelectedRooms: (state:State) => {
      return getRoomsRecursive(state.batchAssignment.treeStructureRooms);
    },
    batchAssignmentSelectedConnections: (state:State) => {
      return getConnectionsRecursive(state.batchAssignment.treeStructureConnections);
    },
    batchAssignmentSelectedConnectionsPreview: (state: State, getters: any, rootState: IState, rootGetters: any) => {
      const selectedConnections = getters.batchAssignmentSelectedConnections as IEntityLocation [];
      const getRoomById = rootGetters['configuration/getRoomById'] as (id:number) => UsedRoom;
      return selectedConnections.map(location => {
        const from = getRoomById(location.fromId!).name;
        let to;
        if      (location.toId! == EExteriorType.AIR)     to = 'vzduch';
        else if (location.toId! == EExteriorType.GROUND)  to = 'terén';
        else    to = getRoomById(location.toId!).name;
        let how = '';
        switch (location.connType!) {
          case EConnectionType.WALL: how = 'zdí'; break;
          case EConnectionType.FLOOR: how = 'podlahou'; break;
          case EConnectionType.CEILING: how = 'stropem'; break;
          case EConnectionType.OPENING: how = 'otvorem'; break;
          case EConnectionType.VIRTUAL: how = 'bez konstrukce'; break;
        }
        return {
          from, to, how
        };
      })

    }, 
    batchAssignmentRequirementSet: (state:State) => {
      return state.batchAssignment.requirementSet;
    },
    batchAssignmentRequirementIsSet: (state:State) => (requirementId:number) => {
      return state.batchAssignment.requirementSet[requirementId];
    },
    numberOfAssignmentsMade: (state:State) => state.batchAssignment.numberOfAssignmentsMade,
    batchAssignmentNotification: (state:State) => state.batchAssignment.notification,
  },
  mutations: {
    setLevel(state: State, value:string) {
      state.batchAssignment.level = value;
    },
    resetState(state: State, connections : Connection[]) {
      state.batchAssignment.requirementSet = {};

      state.batchAssignment.treeStructureFunctionKinds = treeStructureNode(
        'Lokalita', 
        {level: ERequirementLevel.LOCATION}, 
        [
          treeStructureNode('Budova', 
            {level: ERequirementLevel.BUILDING}, 
            [
              ...state.functionConfigurations.map((item, idx) => treeStructureNode(
                item.functionKindName,
                {
                  level: ERequirementLevel.FUNCTION_TYPE,
                  idx,
                },
                []
              ))
            ])
        ])

      state.batchAssignment.treeStructureRooms = treeStructureNode(
        'Lokalita', 
        {level: ERequirementLevel.LOCATION}, 
        [
          treeStructureNode('Budova', 
            {level: ERequirementLevel.BUILDING}, 
            [
              ...state.functionConfigurations.map((item, idx) => treeStructureNode(
                item.functionKindName,
                {
                  level: ERequirementLevel.FUNCTION_TYPE,
                  idx,
                },
                item.rooms.map(room => treeStructureNode(
                  room.name, 
                  {
                    level: ERequirementLevel.ROOM,
                    roomId: room.id,
                    idx,
                  }, 
                  [], 
                  false)
                )
              ))
            ])
        ])

      const connectionMemo = new Map<number, Map<number, Connection>>();
      const connectionNodeMemo = new Map<number, Map<number, ConnectionNodeSharedData>>();
      connections.forEach(conn => {
        if (!connectionMemo.has(conn.fromId)) {
          connectionMemo.set(conn.fromId, new Map<number, Connection>());
          connectionNodeMemo.set(conn.fromId, new Map<number, ConnectionNodeSharedData>());
        }
        const memoDict = connectionMemo.get(conn.fromId);
        const nodeMemoDict = connectionNodeMemo.get(conn.fromId);
        if (!memoDict!.has(conn.toId)) {
          memoDict!.set(conn.toId, conn);
          nodeMemoDict!.set(conn.toId, new ConnectionNodeSharedData);
        }
      })
      
      const connectionExists = (fromId:number, toId:number) => 
        connectionMemo.has(fromId) && connectionMemo.get(fromId)!.has(toId)
      ||connectionMemo.has(toId) && connectionMemo.get(toId)!.has(fromId);
      
      const connectionExpands = (fromId:number, toId:number) => {
        let conn: Connection | undefined;
        let sharedData: ConnectionNodeSharedData | undefined;
        if (connectionMemo.has(fromId) && connectionMemo.get(fromId)!.has(toId)) {
          conn = connectionMemo.get(fromId)!.get(toId);
          sharedData = connectionNodeMemo.get(fromId)!.get(toId);
        } else if (connectionMemo.has(toId) && connectionMemo.get(toId)!.has(fromId)) {
          conn = connectionMemo.get(toId)!.get(fromId);
          sharedData = connectionNodeMemo.get(toId)!.get(fromId);
        }
        
        return treeStructureNodeConnection(conn!, fromId, toId, sharedData!);
      }
      
      state.batchAssignment.treeStructureConnections = treeStructureNode(
        'Lokalita', 
        {level: ERequirementLevel.LOCATION}, 
        [
          treeStructureNode('Budova', 
            {level: ERequirementLevel.BUILDING}, 
            [
              ...state.functionConfigurations.map((item, idx) => treeStructureNode(
                item.functionKindName,
                {
                  level: ERequirementLevel.FUNCTION_TYPE,
                  idx,
                },
                item.rooms.map(room => {
                  const childs = [];
                  if (connectionExists(room.id, EExteriorType.GROUND)) {
                    childs.push(treeStructureNode('Vazba k exterieru - teren', {level: ERequirementLevel.INAPPLICABLE},
                      connectionExpands(room.id, EExteriorType.GROUND))
                    );
                  }
                  if (connectionExists(room.id, EExteriorType.AIR)) {
                    childs.push(treeStructureNode('Vazba k exterieru - air', {level: ERequirementLevel.INAPPLICABLE},
                      connectionExpands(room.id, EExteriorType.AIR))
                    );
                  }
                  childs.push(treeStructureNode('Vazba k vnitřním prostorům', {level: ERequirementLevel.INAPPLICABLE},
                                state.functionConfigurations.map((item2, idx2) => treeStructureNode(
                                  item2.functionKindName,
                                  {
                                    level: ERequirementLevel.FUNCTION_TYPE,
                                    idx,
                                  },
                                  item2.rooms.filter(room2 => connectionExists(room.id, room2.id)).map(room2 => treeStructureNode(
                                    room2.name,
                                    {
                                      level: ERequirementLevel.ROOM,
                                      roomId: room2.id,
                                      idx:idx2,
                                    },
                                    connectionExpands(room.id, room2.id))
                                ))),
                              ));
                  return treeStructureNode(
                            room.name, 
                            {
                              level: ERequirementLevel.ROOM,
                              roomId: room.id,
                              idx,
                            }, 
                            childs, 
                            false);
                })
              ))
            ])
        ])
    },
    batchAssignmentRequirementClick(state: State, requirement: Requirement) {
      if (Object.prototype.hasOwnProperty.call(state.batchAssignment.requirementSet, requirement.id)) {
        delete state.batchAssignment.requirementSet[requirement.id];
      } else {
        state.batchAssignment.requirementSet[requirement.id] = requirement;
      }
    },
    batchAssignmentTreeStructureFunctionKindsCheck(state: State, node:ITreeStructureNode) {
      const value = !node.checked;
      setRecursive(node, value);
      consolidateRecursive(state.batchAssignment.treeStructureRooms);
    },
    batchAssignmentTreeStructureRoomsCheck(state: State, node:ITreeStructureNode) {
      const value = !node.checked;
      setRecursive(node, value);
      consolidateRecursive(state.batchAssignment.treeStructureRooms);
    },
    batchAssignmentTreeStructureConnectionsCheck(state: State, node:ITreeStructureNode) {
      const value = !node.checked;
      setRecursive(node, value);
      consolidateRecursive(state.batchAssignment.treeStructureConnections);
    },
    setNumberOfAssignmentsMade(state:State, value:number) { 
      state.batchAssignment.numberOfAssignmentsMade = value;
    },
    increaseNumberOfAssignmentsMade(state:State, value:number) { 
      state.batchAssignment.numberOfAssignmentsMade += value;
    },
    setNotification(state:State, value:string) {
      state.batchAssignment.notification = value;
    },
    clearNotification(state:State) {
      state.batchAssignment.notification = '';
    }
  },
  actions: {
    setBatchAssignmentLevel(context: ActionContext<State, IState>, value:number) {
      const connections = context.rootGetters['configuration/connections']
      // prevent reseting level when changing to same level.
      if (context.state.batchAssignment.level == value) return;
      context.commit('setLevel', value)
      context.commit('resetState', connections)
    },
    resetBatchAssignmentState(context: ActionContext<State, IState>) {
      const connections = context.rootGetters['configuration/connections']
      
      context.commit('resetState', connections)
    },
    executeBatchAssignmentLocation(context: ActionContext<State, IState>) {
      const requirementTemplateSet = context.getters['batchAssignmentRequirementSet'] as Record<number, Requirement>;
      const requirementTemplates = Object.values(requirementTemplateSet) as Requirement[];
      requirementTemplates.forEach(requirement => {
        context.dispatch('configuration/addLocationRequirement', {requirement}, {root: true});
        context.commit('increaseNumberOfAssignmentsMade', 1);
      });
    },
    executeBatchAssignmentBuilding(context: ActionContext<State, IState>) {
      const requirementTemplateSet = context.getters['batchAssignmentRequirementSet'] as Record<number, Requirement>;
      const requirementTemplates = Object.values(requirementTemplateSet) as Requirement[];
      requirementTemplates.forEach(requirement => {
        context.dispatch('configuration/addBuildingRequirement', {requirement}, {root: true});
        context.commit('increaseNumberOfAssignmentsMade', 1);
      });
    },
    executeBatchAssignmentFunctionKinds(context: ActionContext<State, IState>) {
      const functionKinds = context.getters['batchAssignmentSelectedFunctinKinds'] as IEntityLocation[];
      const requirementTemplateSet = context.getters['batchAssignmentRequirementSet'] as Record<number, Requirement>;
      const requirementTemplates = Object.values(requirementTemplateSet) as Requirement[];
      functionKinds.forEach(functionKind  => {
        const idx = functionKind.idx;
        requirementTemplates.forEach(requirement => {
          context.dispatch('configuration/addFunctionKindRequirement', {idx, requirement}, {root: true});
          context.commit('increaseNumberOfAssignmentsMade', 1);
        })
      });
    },
    executeBatchAssignmentRooms(context: ActionContext<State, IState>) {
      const rooms = context.getters['batchAssignmentSelectedRooms'] as IEntityLocation[];
      const requirementTemplateSet = context.getters['batchAssignmentRequirementSet'] as Record<number, Requirement>;
      const requirementTemplates = Object.values(requirementTemplateSet) as Requirement[];
      rooms.forEach(roomLocation  => {
        const idx = roomLocation.idx;
        const room = {
          id: roomLocation.roomId,
          idx,
        }
        requirementTemplates.forEach(requirement => {
          context.dispatch('configuration/addRoomRequirement', {idx, room, requirement}, {root: true});
          context.commit('increaseNumberOfAssignmentsMade', 1);
        })
      });
    },
    executeBatchAssignmentConnections(context: ActionContext<State, IState>) {
      const connections = context.getters['batchAssignmentSelectedConnections'] as IEntityLocation[];
      const requirementTemplateSet = context.getters['batchAssignmentRequirementSet'] as Record<number, Requirement>;
      const requirementTemplates = Object.values(requirementTemplateSet) as Requirement[];
      context.dispatch('configuration/addRequirementBatch', {
        entityLocations : connections, 
        requirementTemplates: requirementTemplates
      }, {
        root: true
      });
    },
    executeBatchAssignmentProxy(context: ActionContext<State, IState>) {
      const level = context.getters['batchAssignmentLevel'] as ERequirementLevel;
      context.commit('setNumberOfAssignmentsMade', 0);
      switch (level) {
        case ERequirementLevel.LOCATION: context.dispatch('executeBatchAssignmentLocation'); break;
        case ERequirementLevel.BUILDING: context.dispatch('executeBatchAssignmentBuilding'); break;
        case ERequirementLevel.FUNCTION_TYPE: context.dispatch('executeBatchAssignmentFunctionKinds'); break;
        case ERequirementLevel.ROOM: context.dispatch('executeBatchAssignmentRooms'); break;
        case ERequirementLevel.CONNECTION: context.dispatch('executeBatchAssignmentConnections'); break;
      }
      const numberOfAssignmentsMade = context.getters.numberOfAssignmentsMade;
      const notificationMessage = `Bylo úspěšně přiřazeno ${numberOfAssignmentsMade} požadavků.`;
      // set to the same level is going to reset BatchAssignment state (selected requirement, tree structure, etc)
      context.dispatch('resetBatchAssignmentState');
      context.commit('setNotification', notificationMessage);
      setTimeout(() => {
        context.commit('clearNotification');
      }, 5000);
    }
  }
}
