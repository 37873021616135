import { ActionContext, createStore } from "vuex";
import api from "./api";
import user from './user';
import users from './users';
import prezentation from './prezentation';
import definitions from "./definitions";
import configuration from "./configuration/configuration";
import result from "./result";
import PC from "./PC";
import project from "./project";

export enum EActionStatus {
  IDDLE,
  PENDING,
  OK,
  FAIL,
}

const state = {
    loadingContent: true,
    loginProcessing: false,
    actionStatus: EActionStatus.IDDLE,
    loginErrors: {} as Record<string, string>,
}

type IState = typeof state;

const storeDefinition = {
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        user,
        users,
        prezentation,
        definitions,
        configuration,
        result,
        PC,
        project,
    },
    state,
    getters: {
        loadingContent: (state:IState) => state.loadingContent,
        loginProcessing: (state:IState) => state.loginProcessing,
        loginErrors: (state:IState) => state.loginErrors,
        getLoginError: (state:IState) => (item:string) => state.loginErrors[item],
        actionStatus: (state:IState) => state.actionStatus,
        actionStatusIsPending: (state:IState) => state.actionStatus == EActionStatus.PENDING,
    },
    mutations: {
        setLoadingContent: (state:IState, val:boolean) => { state.loadingContent = val; },
        setLoginProcessing: (state:IState, val:boolean) => { state.loginProcessing = val; },
        setLoginErrors: (state:IState, val:Record<string, string>) => { state.loginErrors = val; },
        clearLoginErrors: (state:IState) => {state.loginErrors = {}; },
        setActionStatus: (state:IState, val:EActionStatus) => { state.actionStatus = val; },
        cleanActionStatus: (state:IState) => { state.actionStatus = EActionStatus.IDDLE; },
    },
    actions: {
        async LOGIN(context : ActionContext<IState, IState>, credentials : any) {
            context.commit("clearLoginErrors");
            context.commit("setLoginProcessing", true);
            try {
                const data : any = await api.post("/api/auth/login", credentials)
                context.commit('user/setRefreshToken', data.refreshToken);
                context.commit('user/setAccessToken', data.accessToken);
                const {id, username, role} = data.user;
                context.commit('user/setUser', {id, username, role});
                context.commit("setLoginProcessing", false);
                context.dispatch("LOAD_INITIAL_STATE");
            } catch(error) {
                context.commit("setLoginProcessing", false);
                context.commit('setLoginErrors', error);
            }
        },
        async TRY_RESTORE_LOGIN(context: ActionContext<IState, IState>) {
          const refreshToken = localStorage.getItem('refreshToken');
          if (!refreshToken) return;
          // when there is refresh token, try to take restore login
          const accessToken = localStorage.getItem('accessToken');
          context.commit('user/setRefreshToken', refreshToken);
          context.commit('user/setAccessToken', accessToken);
          context.commit("setLoginProcessing", true);
          try {
            const {id, username, role} : any = await api.get("/api/auth/get-user");
            context.commit('user/setUser', {id, username, role});
            context.dispatch("LOAD_INITIAL_STATE");
            context.commit("setLoginProcessing", false);
          } catch {
            context.commit("setLoginProcessing", false);
          }
        },
        LOGOUT (context: ActionContext<IState,IState>) {
            const data = {
              refreshToken: context.rootGetters['user/refreshToken'],
            };
            api.post("/api/auth/logout", data).then(() => {
              context.commit("user/clearTokens");
              context.commit("user/unsetUser");
              localStorage.clear();
            });
        },
        async LOAD_INITIAL_STATE(context: ActionContext<IState, IState>) {
            context.commit('setLoadingContent', true);
            
            const rooms : any = await api.get("/api/rooms");
            const requirements : any = await api.get("/api/requirements")
            const requirementParameters : any = await api.get("/api/requirement-parameters");
            
            context.commit('definitions/setRooms', rooms.rooms);
            context.commit('definitions/setFunctionKinds', rooms.functionKinds);
            context.commit('definitions/setRequirements', requirements);
            context.commit('definitions/setRequirementParameters', requirementParameters);
            context.commit('setLoadingContent', false);

            context.dispatch('users/GET_USERS');

        },
        async LOAD_CONSTRUCTIONS(context: ActionContext<IState, IState>, payload: {connectionType: string, constructionType: string, filters:any[]}) {
          const params = {
            connectionType: payload.connectionType, 
            constructionType: payload.constructionType,
            filters: payload.filters,
          };
          context.commit('definitions/setConstructionsLoading', true);
          const ids:any = await api.post('/api/deksoft-ids', params);
          context.commit('definitions/setConstructionIds', ids);
          const constructions = await api.post('/api/deksoft-constructions', params);
          context.commit('definitions/setConstructions', constructions);
          context.commit('definitions/setConstructionsLoading', false);
        },
    },
    plugins: [],
};

const store = createStore(storeDefinition);

export default store;
export {IState}
