<template>
  <div v-if="itsMe()">
    <i @click="openCalc()" class="clickable fas fa-calculator"></i>
    <Dialog heading="Součinitel vlivu stupně automobilizace Ka" class="parking-registration-cars-coef" :show="show" @close="closeCalc()">
      <Section name="Stupeň automobilizace:">
        <DropdownInput
          :options="staticData.motorizationLevelDefinitionOptions"
          :value="motorizationLevelDefinition"
          @input="motorizationLevelDefinition = $event"
        />
      </Section>

      <div v-if="motorizationLevelDefinition == 'tables'" >
        <DropdownInput
            :options="staticData.motorizationLevelOptions"
            :value="motorizationLevel"
            @input="motorizationLevel = $event"
        />
      </div>
      <div v-else-if="motorizationLevelDefinition == 'custom'" >
        <Section name="Zadejte počet vozidel na 1 000 obyvatel v dané lokalitě">
          <NumberWithUnitInput :value="numberOfCars" @input="numberOfCars = $event" :unit="ks" />
        </Section>
      </div>

      <div class="center">
        <img :src="require('@/assets/map.png')" >
      </div>

      <Section
        name="Součinitel vlivu stupně automobilizace Ka"
      >
        <strong v-text="result()"></strong>
      </Section>
      <div class="controls">
        <button @click="show = false" class="secondary">zrušit</button>
        <button @click="use()"  class="primary">použít</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { NumberWithUnitInput } from '../components/customElements'
import Dialog from '../components/Dialog.vue'
import DropdownInput from '../components/fundamentals/DropdownInput.vue'
import Section from '../components/Section.vue'

const motorizationLevelDefinitionOptions = [
  {value: 'tables', name:'Normové hodnoty'},
  {value: 'custom', name:'Definovat vlastní'},
  {value: 'unknown', name:'Neznámý'},
]

const motorizationLevelOptions = [
  {name: 'Více než 700 vozidel / 1 000 obyvatel', 	value: 700,	ratio: 1.43, Ka: 1.75},
  {name: 'Přibližně 600 vozidel / 1 000 obyvatel', 	value: 600,	ratio: 1.67, Ka: 1.50},
  {name: 'Přibližně 500 vozidel / 1 000 obyvatel', 	value: 500,	ratio: 2.00, Ka: 1.25},
  {name: 'Přibližně 400 vozidel / 1 000 obyvatel', 	value: 400,	ratio: 2.50, Ka: 1.00},
  {name: 'Přibližně 333 vozidel / 1 000 obyvatel', 	value: 333,	ratio: 3.00, Ka: 0.84},
  {name: 'Méně než 290 vozidel / 1 000 obyvatel', 	value: 290,	ratio: 3.45, Ka: 0.73},
]


export default {
  components: { Dialog, DropdownInput, Section, NumberWithUnitInput },
  props: {
    desiredCalculator: {
      type: String,
      default: "no-calc"
    }
  },
  data() {
    return {
      show: false,
      motorizationLevelDefinition: 'tables',
      motorizationLevel: 700,
      numberOfCars: 573,
      staticData: {
        motorizationLevelDefinitionOptions,
        motorizationLevelOptions,
      },
    }
  },
  methods: {
    itsMe() {
      return this.desiredCalculator == "ParkingSpacesRegisteredCarsCoef"
    },
    openCalc() {
      this.show = true
    },
    closeCalc() {
      this.show = false
    },
    result() {
      if (this.motorizationLevelDefinition == 'unknown') return 1.75;
      if (this.motorizationLevelDefinition == 'tables') {
        const [record] = motorizationLevelOptions.filter(item => item.value == this.motorizationLevel);
        return record.Ka;
      }
      if (this.motorizationLevelDefinition == 'custom') {
        let hiIdx = 0;
        let loIdx = motorizationLevelOptions.length-1;
        let hi = motorizationLevelOptions[hiIdx];
        let lo = motorizationLevelOptions[loIdx];
        const noCars = this.numberOfCars;
        if (noCars > hi.value) return hi.Ka;
        if (noCars < lo.value) return lo.Ka;
        
        while (noCars <= motorizationLevelOptions[hiIdx+1].value) {
          hiIdx = hiIdx +1;
          hi = motorizationLevelOptions[hiIdx];
        }
        while (noCars >= motorizationLevelOptions[loIdx-1].value) {
          loIdx = loIdx -1;
          lo = motorizationLevelOptions[loIdx];
        }
        if (lo.value == hi.value) return hi.Ka;
        return (noCars - lo.value)/(hi.value-lo.value)*(hi.Ka-lo.Ka)+lo.Ka;
      }
      return 0;
    },
    use () {
      this.show = false;
      const requiredValue1 = this.result()
      this.$emit("set-requirement", {requiredValue1});
    },
  },
}
</script>

<style lang="scss">
.center {
  text-align: center;
}
.parking-registration-cars-coef {
  .section {
    .flex-inline {
      align-items: baseline;
    }
  }

  .controls {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}
</style>
