<template>
  <div class="residential-apartments-results indent">
    <h1>Export výsledné konfigurace objektu</h1>
    <div class="indent">
      <p>Stáhnout dokument s výslednou konfigurací objektu:</p>
      <div class="indent flex-row">
        <button class="btn black-btn">Formát PDF</button>
        <button class="btn black-btn">Formát XLS</button>
      </div>
      <p>Generovat odkaz k prohlížení výsledné konfigurace objektu:</p>
      <div class="indent">
        <button class="btn black-btn">Odkaz</button>
      </div>
    </div >
      
    <h1>Převod výsledné konfigurace objektu do modulu "Kontrola projektu"</h1>
    <div class="indent">
      <p>
        Vytvořenou konfiguraci objektu z koncepční kalkulačky je možné dále využít pro vytvoření funkčního schématu objektu pro další modul Obecného konfigurátoru "Kontrola projektu":
      </p>
      <div class="indent">
        <button class="btn black-btn">Pokračovat do modulu "Kontrola projektu"</button>
      </div>
    </div >

    <h1>Celý výpočet (pro testování)</h1>
    <json-viewer v-if="dataCS"
        :value="dataCS.exportToJSON()"
        :expand-depth=3
        copyable
        boxed        
        sort
      ></json-viewer>
      
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer-next';


export default {
  components: {
    JsonViewer
},
  props: ['cs'],
  methods: {
  },
  created() {
    this.dataCS = this.cs;
  },
  data() {
    return {
      dataCS: null,
    }
  }
}

</script>

<style lang="scss">
.residential-apartments-results {
  padding: 20px;
  .indent {
    padding: 3px 20px;
  }
}
</style>
