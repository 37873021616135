<template>
  <button class="new-version secondary"
    @click="open()"
  >
    <i class="fas fa-plus"></i>
    <span>
      Nová verze
    </span>
  </button>
  

  <Dialog heading="Nastavení nového projektu" :show="show" :width="1065" @close="close()">

    <p class="p-label">Výběr způsobu vytváření schématu objektu:</p>
    <div class="flex-column gap-10">

      <div class="flex-row new-configuration-type" :class="selectedClass(CreateOptions.EMPTY)" @click="select(CreateOptions.EMPTY)">
        <i class="big-icon far fa-file"></i>
        <div class="flex-column text">
          <h1 class="name">Prázdný soubor</h1>
          <p class="description">Při zvolení této možnosti bude uživatel vytvářet schéma objektu úplně od začátku.</p>
        </div>
      </div>


      <div class="flex-row new-configuration-type" :class="selectedClass(CreateOptions.TEMPLATE)" @click="select(CreateOptions.TEMPLATE)">
        <i class="big-icon far fa-file-alt"></i>
        <div class="flex-column text">
          <h1 class="name">Načtení šablony</h1>
          <p class="description">Schéma objektu se převezme z předpřipravené šablony pro typické objekty.</p>
        </div>
      </div>


      <div class="flex-row new-configuration-type" :class="selectedClass(CreateOptions.CALCULATOR)" @click="select(CreateOptions.CALCULATOR)">
        <i class="big-icon fas fa-calculator"></i>
        <div class="flex-column text">
          <h1 class="name">Využití koncepčních kalkulaček</h1>
          <p class="description">Schéma objektu se vytváří automaticky na základě údajů zadaných v modulu „Koncepční příprava“.</p>
        </div>
      </div>


      <div class="flex-row new-configuration-type" :class="selectedClass(CreateOptions.MODEL)" @click="select(CreateOptions.MODEL)">
        <i class="big-icon fab fa-unity"></i>
        <div class="flex-column text">
          <h1 class="name">Načtení 3D modelu objektu</h1>
          <p class="description">Automatické vytváření schématu objektu načtením 3D modelu ve formátu .ifc</p>
        </div>
      </div>
      
    </div>

    <div class="flex-row centered">
      <button 
          class="primary bottom-button-margin" 
          @click="onSubmit()" 
          :disabled="!(isSelected(CreateOptions.EMPTY) || isSelected(CreateOptions.TEMPLATE))"
      >
        Pokračovat
      </button>
    </div>
    
  </Dialog>

</template>

<script>
import Dialog from '../../components/Dialog.vue';

const CreateOptions = {
  EMPTY: 1,
  TEMPLATE: 2,
  CALCULATOR: 3,
  MODEL: 4,
}

export default {
    data() {
        return {
            show: false,
            createOption: 0,
            CreateOptions,
        };
    },
    methods: {
        // dialog open / close
        open() {
            this.show = true;
        },
        close() {
            this.show = false;
        },
        // selection
        select(stuff) {
          this.createOption = stuff;
        },
        isSelected(stuff) {
          return this.createOption == stuff;
        },
        selectedClass(stuff) {
          return {
            selected: this.isSelected(stuff)
          }
        },
        // perform submit
        async onSubmit() {
          if (this.isSelected(CreateOptions.EMPTY)) {
            this.$router.push('/project/configurations/empty');
          
          } else if (this.isSelected(CreateOptions.TEMPLATE)) {
            this.$router.push('/project/configurations/template');
          
          } else if (this.isSelected(CreateOptions.CALCULATOR)) {
            undefined;
            
          } else if (this.isSelected(CreateOptions.MODEL)) {
            undefined;
            
          }
        },
    },
    components: { Dialog }
}

</script>

<style lang="scss">
.new-configuration-type {
  height: 70px;
  background: white;
  position: relative;
  gap: 10px;
  border: 1px solid black;

  &.selected {
    background: #7777777F;
  }

  i.big-icon {
    font-size: 50px;
    margin: auto 10px;
  }

  .text {
    align-self: center;
    h1 {
      margin: 0;
    }
    p.description {
      margin: 0;
      color: black;
      font: normal normal 600 16px/22px Noto Sans;
    }
  }



}

button.new-version {
  position: relative;
  line-height: 34px;
  width: 160px;
  i {
    position: absolute;
    font-size: 34px;
    //position: absolute;
    left: 10px;
    right: 0;
    text-align: left;
    //padding-right: 20px;
  }
  span {
    padding-left: 30px;
  }

}

.bottom-button-margin {
  margin-top: 28px;
}

</style>
