import { EConnectionType, IConnection } from "@/@types/definitions/connections";
import { Requirement, AppliedRequirement, RequirementOwner, ERequirementLevel, RequirementList } from "@/@types/definitions/requirements";

import { IConfigurationState } from "./configuration";
import _ from "lodash";

type State = IConfigurationState;

interface IDetachedState {
  helper: {
    nextRequirementId: number
  },
  requirementCollection: AppliedRequirement[],
  requirementById: Record<number, AppliedRequirement>,
}


export function createRequirement(state:IDetachedState, {requirement, location } : {requirement: Requirement, location: IEntityLocation}) {
  const owner = new RequirementOwner(location.idx, location.roomId, location.fromId, location.toId, location.connType)
  // console.log("createRequirement", owner );
  const instanceId = state.helper.nextRequirementId ++;
  const newRequirement = new AppliedRequirement(instanceId, Requirement.Create(requirement), owner);
  // console.log("createRequirement", newRequirement);
  state.requirementCollection.push(newRequirement);
  state.requirementById[newRequirement.instanceId] = newRequirement;
}

export function setRequirementParameter(state:State, {parameter, value, instanceId} : {parameter: keyof AppliedRequirement, value: never, instanceId:number}) {
  const [requirement] = state.requirementCollection.filter(req => req.instanceId == instanceId);
  console.log('setRequirementParameter', parameter, value, instanceId, 'to', requirement);
  
  requirement.setParam(parameter, value);
}

export function setRequirementParameters(state:State, {changes, instanceId} : {changes: Record<string, any>, instanceId:number}) {
  if (!instanceId) return;
  const [requirement] = state.requirementCollection.filter(req => req.instanceId == instanceId);
  console.log('setRequirementParameters', changes, 'to', requirement);
  
  for (const parameter in changes) {
    if (Object.prototype.hasOwnProperty.call(changes, parameter)) { 
      const value = changes[parameter];
      requirement.setParam(parameter, value);
    }
  }
}

export function assignRequirement(state:State, requirement: AppliedRequirement) {
  const requirementList = getRequirementListFromLevelAndOwner(state, requirement.data.level, requirement.owner);
  const requirementId = requirement.instanceId;

  if (!requirementList.includes(requirementId)) {
    requirementList.push(requirementId); 
  } else {
    console.error(`Achieve to add requirement ${requirementId} multiple times`);
  }
}

function getRequirementListFromLevelAndOwner(state: State, level:ERequirementLevel, owner:RequirementOwner) : RequirementList {
  switch (level) {
    case ERequirementLevel.BUILDING: {
      return state.building.requirements;
    }
    case ERequirementLevel.LOCATION: {
      return state.location.requirements;
    }
    case ERequirementLevel.FUNCTION_TYPE: {
      const idx:any = owner.functionTypeIdx;
      return state.functionConfigurations[idx].requirements;
    }
    case ERequirementLevel.ROOM: {
      const idx:any = owner.functionTypeIdx;
      const roomId:any = owner.roomId;
      const [roomHere] = state.functionConfigurations[idx].rooms.filter(r => r.id == roomId);
      if (!roomHere) {
        throw new Error("Room does not exists");
      }
      return roomHere.requirements;
    }
    case ERequirementLevel.CONNECTION: {
      const fromId:any = owner.fromId;
      const toId:any = owner.toId;
      const connType = owner.connType;
      const [connection] = state.connections.filter(con => {
        return con.fromId == fromId && con.toId == toId
        || con.toId == fromId && con.fromId == toId;
      });
      
      if (!connection) {
        console.error(`Connection ${fromId} -- ${toId} does not exists.`);
      }
      
      let collectionName : keyof IConnection = 'requirements';
      if (connType == EConnectionType.WALL) {
        collectionName = 'wallRequirements';
      } else if (connType == EConnectionType.FLOOR) {
        collectionName = 'floorRequirements';
      } else if (connType == EConnectionType.CEILING) {
        collectionName = 'ceilingRequirements';
      } else if (connType == EConnectionType.OPENING) {
        collectionName = 'openingRequirements';
      } else if (connType == EConnectionType.VIRTUAL) {
        collectionName = 'virtualRequirements';
      }
      return connection[collectionName]
    }

    default: 
      throw new Error("Invalid level");
  }
}

export function removeRequirement(state:State, requirementInstanceId:number) {

  const [requirement] = state.requirementCollection.filter(req => req.instanceId == requirementInstanceId);
  state.requirementCollection = state.requirementCollection.filter((req:AppliedRequirement) => req.instanceId != requirementInstanceId);
  const requirementList = getRequirementListFromLevelAndOwner(state, requirement.data.level, requirement.owner);
  _.remove(requirementList, (insId:number) => insId == requirementInstanceId)
}


import { ActionContext } from "vuex";
import { IState } from "..";
import { IEntityLocation } from "@/@types/definitions/entity";
// TODO: Refactor IEntityLocation instead of Owner on AppliedRequirement
export function addRequirementUniversal(
            context: ActionContext<State, IState>, 
            payload: { requirement: Requirement, location: IEntityLocation }
        ) {
  const requirementId = context.getters.nextRequirementId;
  payload.requirement = {...payload.requirement }
  context.commit("createRequirement", payload);
  const appliedRequirement = context.getters.getRequirementById(requirementId)
  context.commit("assignRequirement", appliedRequirement);
}


export function addRequirementUniversalIndependent(
            context: ActionContext<State, IState>, 
            payload: { requirement: Requirement, location: IEntityLocation }
        ) {
  const requirementId = context.getters.nextRequirementId;
  payload.requirement = {...payload.requirement }
  context.commit("createRequirement", payload);
  const appliedRequirement = context.getters.getRequirementById(requirementId)
  context.commit("assignRequirement", appliedRequirement);
}


function separateStateForBatchAssignment(state: State) {
  const detachedState : IDetachedState = {
    helper: {
      nextRequirementId: state.helper.nextRequirementId 
    },
    requirementCollection: [ ... state.requirementCollection],
    requirementById: { ... state.requirementById },
  };
  return detachedState;
}

export function updateStateAfterBatchAssignment(state: State, detachedState : IDetachedState) {
  state.helper.nextRequirementId = detachedState.helper.nextRequirementId;
  state.requirementCollection = detachedState.requirementCollection;
  state.requirementById = detachedState.requirementById;
}

export function addRequirementBatch(context: ActionContext<State, IState>, payload: {
            entityLocations : IEntityLocation[], 
            requirementTemplates: Requirement[]
    }) {
  const detachedState = separateStateForBatchAssignment(context.state);
  payload.requirementTemplates.forEach(requirement => {
    payload.entityLocations.forEach(location => {
      
      const requirementId = detachedState.helper.nextRequirementId;
      
      createRequirement(detachedState, {
        requirement: {...requirement },
        location: location
      });
      
      
      const appliedRequirement = detachedState.requirementById[requirementId];
      context.commit("assignRequirement", appliedRequirement);
      context.commit('increaseNumberOfAssignmentsMade', 1);

    });
  });
  context.commit('updateStateAfterBatchAssignment', detachedState);
}
