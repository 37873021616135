<template>
  <div class="calculator" v-if="name">
    <i @click="openCalculator()" class="clickable fas fa-calculator"></i>
  </div>
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    owner: {
      type: Object,
    },
    requirementId: {
      type: [Number, String],
    },
  },
  methods: {
    openCalculator() {
      this.eventBus.emit('open-calculator', {
        name: this.name,
        owner: this.owner,
        requirementId: this.requirementId,
      });
    }
  }
}
</script>

<style>

</style>
