<template>
  <tr
    class="data-row"
  >
    <td>
      <div class="flex-row space-between">
        {{room.name}}
      </div>
    </td>
    
    <ConnectionSettings :mode="mode" :fromId="room.id" :toId="EExteriorType.GROUND" />
    <ConnectionSettings :mode="mode" :fromId="room.id" :toId="EExteriorType.AIR" />
    
  </tr>
</template>

<script>
import { Connection, EExteriorType } from '@/@types/definitions/connections';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ConnectionSettings from './ConnectionSettings.vue';

export default {
  components: { ConnectionSettings },
  props: {
    fromId: Number,
    room: Object,
    mode: Number,
  },
  data() {
    return {
      EExteriorType,
    };
  },
  computed: {
    ...mapGetters('configuration', [
      'getConnection',
    ]),
    connection () {
      return this.getConnection(this.fromId, this.room.id);
    },
    exists () {
      return this.connection != null;
    },
    floor () {
      if (this.connection.fromId == this.fromId) {
        return this.connection.floor;
      }
      return this.connection.ceiling;
    },
    ceiling () {
      if (this.connection.fromId == this.fromId) {
        return this.connection.ceiling;
      }
      return this.connection.floor;
    },
  },
  methods: {
    ...mapMutations("configuration", [
      'addConnection',
      'updateConnection',
      'removeConnection',
    ]),
    ...mapActions("configuration", [
      'addConnectionRequirement',
    ]),
    setConnection(what, value) {
      const clone = {...this.connection}
      if (clone.fromId != this.fromId) {
        if (what == 'floor') what = 'ceiling';
        else if (what == 'ceiling') what = 'floor';
      }
      clone[what] = value;
      this.updateAction(clone);
    },
    updateAction(clone) {
      console.log("updateConnecitonAction", clone.fromId, clone.toId);
      if (clone.fromId && clone.toId) {
        this.updateConnection(clone); 
      }
    },
    // remove or create connection
    removeConnectionClick() {
      console.log('remove connection');
      const message = "Opravdu si přejete smazat vazbu obsahující požadavky?";
        if (this.connection.requirements.length == 0
         || (this.connection.requirements.length > 0
          && confirm(message))) {
            this.removeConnection({
              fromId: this.connection.fromId, 
              toId: this.connection.toId
            });
        }
    },
    createConnectionClick() {
      console.log('new connection');
      const newConnection = new Connection(this.fromId, this.room.id);
        this.addConnection(newConnection);
    },
  }
}
</script>

<style lang="scss">
.check-cell {
  display: flex;
  justify-content: center;
}
.width100 {
  width: 100%;
}
</style>
