<template>
  <div class="action-status" v-if="actionStatus == EActionStatus.pending">
    <div class="container">
      <div class="lds-ripple"><div></div><div></div></div>
    </div>
  </div>
  <div class="action-status" v-else-if="actionStatus == EActionStatus.OK">
    <div class="container">
      <div class="success-sign">
        <h1 class="outcome">Hotovo</h1>
        <button @click="cleanActionStatus()" class="secondary">OK</button>
      </div>
    </div>
  </div>
  <div class="action-status" v-else-if="actionStatus == EActionStatus.FAIL">
    <div class="container">
      <div class="fail-sign">
        <h1 class="outcome">Operace selhala</h1>
        <button @click="cleanActionStatus()" class="secondary">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { EActionStatus } from '../store'
export default {
  computed: {
    ...mapGetters(['actionStatus'])
  },
  methods: {
    ...mapMutations(['cleanActionStatus']),
  },
  data() {
    return {EActionStatus}
  }
}
</script>

<style lang="scss">
.action-status {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;

  background: rgba($color: #000, $alpha: 0.3);

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
  }
  h1.outcome {
    font-size: 6em;
  }
  .success-sign, .fail-sign {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: rgba($color: #fff, $alpha: 1.7);
    padding: 22px 65px;
  }
  .success-sign {
    //color: darken(lawngreen, 20%);
    color: $primary-color;
    
  }
  .fail-sign {
    color: rgba(red, 80%);
  }


  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

}
</style>
