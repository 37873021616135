<template>
  <div class="room-requirement-opener">

    <RequirementsDialog
      :activeRequirements="roomRequirements({idx, room})"
      @add-requirement="addRoomRequirement({idx, room, requirement: $event})"
      level="room"
      :headingIdentificator="headingIdentificator"
      activator="none"
      ref="reqDialog"
    />
    
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RequirementsDialog from './RequirementsDialog.vue'
export default {
  components: { RequirementsDialog },
  name: 'RoomRequirementsOpener',
  mounted() {
    this.eventBus.on('open-room-requirements', ({idx, room}) => {
      this.idx = idx;
      this.room = room;
      //console.log(this.$refs.reqDialog);
      this.$refs.reqDialog.openDialog();
    });
  },
  unmounted() {
    this.eventBus.off('open-room-requirements');
  },
  computed: {
    ...mapGetters('configuration', [
      'roomRequirements',
    ]),
    ...mapGetters('definitions', [
      'functionKindName',
    ]),
    ...mapGetters('configuration', [
      'functionConfigurations',
    ]),
    headingIdentificator() {
      const functionKindId = this.functionConfigurations[this.idx].functionKindId;
      return `${this.room.name} (${this.functionKindName(functionKindId)})`;
    },
  },
  methods: {
    ...mapActions("configuration", [
      'addRoomRequirement',
    ]),
  },
  data () {
    return {
      idx: 0,
      room: 0,
    }
  },
}
</script>

<style>

</style>
