<template>
  <div class="applicator">
    
    <CollapsableAnimatedDiv heading="Požadavky vybrané k přířazení" >
      <table class="requirement-templates" v-if="Object.keys(batchAssignmentRequirementSet).length > 0">
        <tr class="heading-row">
          <th></th>
          <th>id</th>
          <th>název požadavku</th>
          <th>článek</th>
          <th>typ</th>
          <th>obor</th>
        </tr>
        <tr 
          v-for="(req, rid) in Object.values(batchAssignmentRequirementSet)"
          :key="rid"
          class="data-row"
        >
          <td><input type="checkbox" :checked="batchAssignmentRequirementIsSet(req.id)" @click="batchAssignmentRequirementClick(req)" /></td>
          <td>{{ req.id }}.</td>
          <td>{{ req.name }}</td>
          <td><ShowArticle v-if="req.articleId" :articleId="req.articleId" /></td>
          <td v-text="writeType(req)"></td>
          <td v-text="writeField(req)"></td>
        </tr>
      </table>
    </CollapsableAnimatedDiv>
    <CollapsableAnimatedDiv heading="Seznam šablon požadavků" >
      <div class="filtration">
        <!-- <label>filtrace požadavků</label> -->
        <div class="filters flex-row">
          <div class="flex-inline">
            <p class="filter-label">dle typu</p> 
            <dropdown-input 
              :value="filterData.type" 
              @input="setFilter('type', $event)" 
              :options="typeOptions" small/>
          </div>
          <div class="flex-inline grow" v-show="filterData.type != 'typological'">
            <p class="filter-label">dle tech. oboru</p> 
            <dropdown-input 
              :value="filterData.technicalField" 
              @input="setFilter('technicalField', $event)" 
              :options="getParameterOptions('technicalField')" small/>
          </div>
          <div class="flex-inline grow" v-show="filterData.type != 'technical'">
            <p class="filter-label">dle typ. oboru</p> 
            <dropdown-input 
              :value="filterData.typologicalField" 
              @input="setFilter('typologicalField', $event)" 
              :options="getParameterOptions('typologicalField')" small/>
          </div>
          <div class="flex-inline">
            <p class="filter-label">text</p> 
            <text-input 
              :value="filterData.text" 
              @input="setFilter('text', $event)" 
              :width="266" small/>
          </div>
          <button @click.stop="clearFilters()" class="clear-filters btn black-btn">zrušit filtraci</button>
        </div>
      </div>

      <table class="requirement-templates" v-if="filteredRequirements.length > 0">
        <tr class="heading-row">
          <th></th>
          <th>id</th>
          <th>název požadavku</th>
          <th>článek</th>
          <th>typ</th>
          <th>obor</th>
          <!-- <th>akce</th> -->
        </tr>
        <tr 
          v-for="(req, rid) in filteredRequirements.slice((page.currentPage-1)*page.pageSize, (page.currentPage)*page.pageSize)"
          :key="rid"
          class="data-row"
        >
          <td><input type="checkbox" :checked="batchAssignmentRequirementIsSet(req.id)" @click="batchAssignmentRequirementClick(req)" /></td>
          <td>{{ req.id }}.</td>
          <td>{{ req.name }}</td>
          <td><ShowArticle v-if="req.articleId" :articleId="req.articleId" /></td>
          <td v-text="writeType(req)"></td>
          <td v-text="writeField(req)"></td>
          <!-- <td>
            <button 
              class="btn black-btn" 
              @click="batchAssignmentRequirementClick(req)" 
              :disabled="batchAssignmentRequirementIsSet(req.id)"
            >
              <i class="fa fa-plus"></i>
            </button>
          </td> -->
        </tr>
      </table>

      <div class="flex-row" v-if="filteredRequirements.length ==  0">
        <p class="error">Žádný výsledek neodpovídá filtraci.</p>
      </div>

      <div class="pagination" v-if="page.maxPage > 1">
        <span>strana: 
          
          <i 
            @click="pagePrev()" 
            v-show="page.currentPage > 1"
            class="clickable fas fa-chevron-left">
             
            <!-- <i class="clickable fas fa-chevron-left"></i> -->
          </i>
          {{page.currentPage}} / {{ page.maxPage }}
          <i 
            @click="pageNext()" 
            v-show="page.currentPage < page.maxPage" 
            class="clickable fas fa-chevron-right">

            <!-- <i class="clickable fas fa-chevron-right"></i> -->
          </i>
          
        </span>
      </div>

      <CustomRequirement
        :level="level"
        @take-requirement="addRequirement($event)" 
      />
    </CollapsableAnimatedDiv>

    
    
    
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  writeLevel,
  writeType,
  writeField,
  writeRule,
  typeOptions,
  isTechnical,
  isTypological,
} from '@/@types/definitions/requirements'
import DropdownInput from '../../components/fundamentals/DropdownInput.vue'
import TextInput from '../../components/fundamentals/TextInput.vue'

import CustomRequirement from '../CustomRequirement.vue';
import ShowArticle from '../../components/showArticle.vue'
import CollapsableAnimatedDiv from '@/views/components/CollapsableAnimatedDiv.vue'

export default {
  inheritAttrs: false,
  props: {
    level: {
      type: String,
      default: '',
    },
    activeRequirements: {
      type: Array,
    },
    activator: {
      type: String,
      default: 'icon',
    }
  },
  components: {
    /* CheckboxInput,*/
    DropdownInput,
    TextInput,
    CustomRequirement,
    ShowArticle,
    CollapsableAnimatedDiv,
},
  computed: {
    ...mapGetters('definitions', [
      'requirements',
      'getParameterOptions',
    ]),
    ...mapGetters('configuration', [
      'batchAssignmentRequirementIsSet',
      'batchAssignmentRequirementSet',
    ])
  },
  methods: {
    ...mapMutations('configuration', [
      'batchAssignmentRequirementClick',
    ]),
    openDialog() {
      this.showDialog = true;
    },
    pageNext() {
      this.page.currentPage = Math.min(this.page.maxPage, this.page.currentPage + 1);
    },
    pagePrev() {
      this.page.currentPage = Math.max(1, this.page.currentPage - 1);
    },
    pageFirst() {
      this.page.currentPage = 1;
    },
    setFilter(parameter, value) {
      this.filterData[parameter] = value;
      this.performFilter();
    },
    clearFilters() {
      this.filterData.type = '';
      this.filterData.technicalField = '';
      this.filterData.typologicalField = '';
      this.filterData.text = '';
      this.performFilter();
    },
    filterFunction(requirement) {
      if (this.level) {
        if (requirement.level != this.level) return false;
      }
      if (this.filterData.type) {
        if (requirement.type != this.filterData.type) return false;
      }
      if (this.isTechnical(this.filterData) && this.filterData.technicalField) {
        console.log(this.filterData.technicalField);
        console.log(requirement.technicalField);
        if (requirement.technicalField != this.filterData.technicalField) return false;
      }
      if (this.isTypological(this.filterData) && this.filterData.typologicalField) {
        if (requirement.typologicalField != this.filterData.typologicalField) return false;
      }
      if (this.filterData.text) {
        const regex = new RegExp(this.filterData.text.normalize("NFD").replace(/\p{Diacritic}/gu, ""), 'i');
        const name = requirement.name.normalize("NFD").replace(/\p{Diacritic}/gu, "");
        if (name.search(regex) < 0) return false;
      }

      return true;
    },
    performFilter(toFirstPage = true) {
      console.log('performFilter', this.level);
      this.filteredRequirements = this.requirements.filter(this.filterFunction);
      const size = this.filteredRequirements.length;
      this.page.maxPage = Math.ceil(size / this.page.pageSize);
      if (toFirstPage) this.pageFirst();
    },
    addRequirement(req) {
      this.batchAssignmentRequirementClick(req);
      this.performFilter(false);
    },
    
    setValueFromCalculator(req, val) {
      console.log("setValue from calculator", req, val);
      const r = {...req};
      r.requiredValue1 = val;
      this.changedRequirement(r);
    },
    changedRequirement(req) {
      console.log('changedRequirement');
      console.log(req);
      console.log(req.id);
      const array = [];
      this.activeRequirements.forEach((r) => {
        if (r.id == req.id) {
          //const rIndepenedent = { ...req }
          array.push(req);
        } else {
          array.push(r)
        }
      })
      //this.$emit('set:requirements', array)
    },
    isTechnical,
    isTypological,
    writeLevel,
    writeType,
    writeField,
    writeRule,
  },
  data() {
    return {
      showDialog: false,
      filterData: {
        type: "",
        technicalField: "",
        typologicalField: "",
        text: "",
      },
      filteredRequirements: [],
      page : {
        currentPage: 1,
        maxPage: 1,
        pageSize: 10,
      },
      typeOptions,
    }
  },
  created() {
    this.performFilter();
  }
}
</script>


<style lang="scss">
.filtration {
    .filters {
      //justify-content: space-between;
      gap: 25px;
      .clear-filters {
        //line-height: 30px;
        align-self: center;
        //vertical-align: middle;
      }
    }
    .flex-inline {
      align-items: baseline;
      gap: 10px;
      p.filter-label {
        flex: 1 1 1;
      }
    }

    .dropdown-input {
      flex-grow: 1;
    }
    .grow {
      flex-grow: 1;
    }
  }

.pagination {
  padding: 10px 0;
  i.clickable {
    background-color: darken($primary-color, 0%);
    width: 20px;
    height: 20px;
    text-align: center;
    color: white;
    border: solid 1px darken($primary-color, 0%);
    &:hover {
      background-color: white;
      width: 20px;
      height: 20px;
      text-align: center;
      color: darken($primary-color, 0%);
      border: solid 1px darken($primary-color, 0%);
    }
  }
}

.applicator {
  .visualization-popup {
    color: black;
  }
  .custom-requirement {
    display: flex;
    flex-flow: row-reverse;
  }
  input[type="checkbox"] {
    width: 24px;
    height: 24px;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    line-height: 30px;
  }
  th::first-letter, td::first-letter {
    text-transform: uppercase;
  }
  td,
  th {
    border: 1px solid white;
    padding: 0 8px;
  }
  td.check {
    background: white;
  }
  .heading-row {
    background: darken($secondary-color, 50%);
    color: white;
    border-width: 0;
  }
  .data-row {
    background: $secondary-color;
    //font-family: Roboto;
    &:nth-child(2n+1) {
      background: white;
    }
  }
  .controls {
    
    .clickable {
      padding: 0 10px;
    }
  }
  .btn {
    line-height: 30px;
    //height: 30px;
    padding: 0 5px;
  }
} 
</style>

