<template>
  <div>
    <Dialog :heading="getTitle()" class="article-view" :show="show" @close="show=false">
      <Loading v-if="articleLoading" />
      <div v-else-if="error" class="article-content">
        <h2>Článek není k dispozici</h2>
      </div>
      <div v-else class="article-content" v-html="article.body"></div>
      <div class="controls">
        <button @click="show = false" class="primary">zavřít</button>
      </div>
    </Dialog> 
  </div>
</template>

<script>
import articleService from '@/store/api/article.service';
import Loading from '../Loading.vue';
import Dialog from './Dialog.vue';
  
export default {
  components: {
    Dialog,
    Loading,
  },
  mounted() {
    console.log('open-article mounted');
    this.eventBus.on('open-article', async articleId => {
      console.log('open-article ', articleId);
      this.articleId = articleId;
      this.show = true;
      await this.open();
    })
  },
  unmounted() {
    this.eventBus.off('open-article');
  },
  data() {
    return {
      show: false,
      articleId: 0,
      article: {
        title: "",
        body: ""
      },
      articleLoading: false,
      error: false,
    };
  },
  methods: {
    getTitle() {
      if (this.articleLoading) {
        return 'Článek se načítá';
      } else if (this.error) {
        return "Chyba"; 
      }
      return this.article.title;
    },
    async open() {
      this.show = true;
      this.articleLoading = true;
      this.error = false;
      try {
        const article = await articleService.getArticleByHumanId(this.articleId);
        this.article = article;
      } catch (error) {
        console.error(error);
        this.error = true;
      }
      this.articleLoading = false;
    }
  },
}
</script>

<style lang="scss">
.popup-container.article-view {
  .controls {
    display: flex;
    justify-content: end;  
  }
  .popup-modal {
    background-color: white;
  }
}
</style>
