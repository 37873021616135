<template>
  <div 
    class="filter-setup" 
    :class="{active: getFilterValues(parameter.name).length}"
    v-show="parameter.values.length > 0"
  >
    
    <h5 class="filter-label">
      <span v-text="parameter.displayName"></span>
      <i 
        class="clear-filter clickable far fa-trash-alt"
        v-show="getFilterValues(parameter.name).length"
        @click="clearFilterValues(parameter.name)"
      ></i>
    </h5>
  
    <div class="value-list"> 
      <CheckboxArray 
        v-for="value in parameter.values" 
        :value="value.value"
        :class="{active: getFilterValues(parameter.name).includes(value.value)}"
        :list="getFilterValues(parameter.name)" 
        @set="setFilterValue({parameter: parameter.name, value: $event})" 
        @unset="unsetFilterValue({parameter: parameter.name, value: $event})" 
        :key="value.value"
        
      >
        {{value.name}}
      </CheckboxArray>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CheckboxArray from '../../components/fundamentals/CheckboxArray.vue'
export default {
  components: { CheckboxArray },
  props: {
    parameter: {
      type: Object,
      required: true,
    },
    /* allValues: {
      type: Array,
      required: true,
    } */
  },
  methods: {
    ...mapMutations('prezentation', [
      'setFilterValue',
      'unsetFilterValue',
      'clearFilterValues',
      'ensureFilterValues',
    ]),
  },
  computed: {
    ...mapGetters('prezentation', [
      'getFilterValues',
    ]),
    ...mapGetters('definitions', [
      'requirementParameters',
    ]),
  }
}
</script>

<style lang="scss">
.filter-setup {
  width: 100%;
  display: inline-flex;
  align-items: baseline;
  justify-content: start;
  gap: 50px;
  i.clear-filter {
    margin: 0 10px;
  }
  .filter-label {
    width: 250px;
    margin: auto 4px auto 4px;
  }
  .value-list {
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: baseline;
    color: black;
    min-height: 34px;
    overflow-y: hidden;
    background: white;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;

    label {
      height: unset;
    }
    
    .checkbox:not(.active) {
      display: none;
    }
    .checkbox.active {
      opacity: 1;
      display: block;
    }

    &:hover {
      overflow-y: visible;
      max-height: unset;
      .checkbox {
        display: block;
        opacity: 1;
      }
    }
  }
  &:not(.active) {
    .value-list:not(:hover) .checkbox { 
      &:first-child {
        opacity: 0.5;
        display: block;
      }
      &:not(:first-child) {
        display: none;
      }
    }
  }
}
</style>
