<template>

  <NewConfigurationDialog />

  <div class="PC-list flex-column gap-10 ">
    
    <div class="PC-header flex-row">
      <div class="name">název kontroly projektu</div>
      <div class="description">poznámka k verzi</div>
      <div class="author">autor</div>
      <div class="actions">akce</div>
    </div>

    <div 
      class="PC-item flex-row"
      v-for="(temp, idx) in listOfPC" 
      :key="idx"
    >
      <div class="name">

        <EditableText 
        :text="temp.name" 
        class="name-span"
        @edit-text="renameTemplate(temp._id, $event)" 
        />

          <span class="tag" v-if="temp.isTemplate">
            template
          </span>
      </div>

      
      <EditableText
        class="description ellipsis"
        :text="temp.description" 
        @edit-text="changeDescription(temp._id, $event)" 
      />
      <!-- <div class="date" v-text="new Date(project.createdAt).ddmmyyyy()"></div> -->
      
      <div class="author" v-text="temp.author.username"></div>

      <div class="actions flex-inline">
        <button 
          class="btn black-btn"
          @click="openPC(temp._id)"
        >
          otevřít
        </button>

        <button 
          class="btn black-btn"
          @click="duplicateTemplate(temp._id, `${temp.name} Copy`)"
        >
          duplikovat
        </button>

        <button 
          class="btn black-btn"
          @click="removeTemplate(temp._id)"
        >
          smazat
        </button>


        <button 
          v-if="temp.isTemplate"
          class="btn black-btn"
          @click="SET_IS_TEMPLATE({id:temp._id, isTemplate:false})"
        >
          zrušit publikování
        </button>

        <button 
          v-else
          class="btn black-btn"
          @click="SET_IS_TEMPLATE({id:temp._id, isTemplate:true})"
        >
          publikovat jako šablonu
        </button>
      </div>
    </div>
  </div>




<!--   <button class="btn"
    @click="LIST_PC()"
  >vylistovat PC</button>
 -->

</template>


<script>
import { EActionStatus } from '@/store';
import { mapActions, mapGetters } from 'vuex';
import { EditableText } from '../../components/customElements';
import NewConfigurationDialog from './CreateConfigurationNavigationDialog.vue'

export default {
    computed: {
        ...mapGetters(['actionStatus']),
        ...mapGetters("project", [
            "openedProject"
        ]),
        ...mapGetters("PC", [
            "listOfPC",
        ]),
    },
    created() {
      this.getAll();
    },
    methods: {
        ...mapActions("PC", [
            "LIST_PC",
            'OPEN_PC', 
            'DUPLICATE_PC', 
            'RENAME_PC', 
            'REMOVE_PC',
            'SET_IS_TEMPLATE',
            'CHANGE_DESCRIPTION_PC',
        ]),

        async getAll() {
          this.LIST_PC();
        },
        async removeTemplate(_id) {
          await this.REMOVE_PC(_id);
          await this.getAll();
        },
        async duplicateTemplate(_id, name) {
          await this.DUPLICATE_PC({id:_id, name: name});
          await this.getAll();
        },
        async renameTemplate(_id, name) {
          await this.RENAME_PC({_id, name});
          await this.getAll();
        },
        async changeDescription(id, description) {
          await this.CHANGE_DESCRIPTION_PC({id, description});
          await this.getAll();
        },

        async openPC(id) {
          await this.OPEN_PC(id);
          if (this.actionStatus == EActionStatus.OK) {
            // TODO: jump to the schema when posible
            this.$router.push('/PC/architecture');
          }
          // route
        }
    },
    components: { EditableText, NewConfigurationDialog },
    
}
</script>


<style lang="scss">

.PC-header,
.PC-item {
  max-width: calc(100vw - 600px);
  .name {
    flex: 0.3 0 200px;
    font: normal normal bold 20px/27px Noto Sans;
    line-height: 64px;
    .tag {
      display: inline-block;
    }
  }
  .description {
    flex: 0.7 0.5 200px;
  }
  
  .editable {
    display: inline-block;
    padding: 0 20px;
    //width: 100%;
  }
  
  .author {
    flex: 0 0 100px;
  }
  .actions {
    flex: 0 0 525px;
    //padding: 0 20px;
  }
  background-color: white;
  gap: 10px;
  border: 1px solid black;
  height: 64px;
  line-height: 64px;
}

.PC-header {
  div {
    padding: 0 20px;
  };
  .actions, .author {
    padding: 0px;
  }
}

.PC-header {
  background-color: unset;
  border: unset;
}

 

</style>
