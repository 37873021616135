<template>
  <div class="building-library">
    <h2 class="title">Napojení na Stavební knihovnu (zatím nelze změnit)</h2>
    <h2 class="title">Vnitřní vazba</h2>
    <Section name="Vnitřní stěna / příčka">
      <TextInput :value="linkes.interior.wall" :width="900" small/>
    </Section>

    <Section name="Vnitřní stěna / příčka">
      <TextInput :value="linkes.interior.ceiling" :width="900" small/>
    </Section>

    <Section name="Vnitřní podlaha">
      <TextInput :value="linkes.interior.floor" :width="900" small/>
    </Section>

    <Section name="Vnitřní výplň otvoru">
      <TextInput :value="linkes.interior.opening" :width="900" small/>
    </Section>


    <h2 class="title">Vazba s exteriérem - vzduch</h2>
    <Section name="Venkovní stěna">
      <TextInput :value="linkes.air.wall" :width="900" small/>
    </Section>

    <Section name="Střecha (strop)">
      <TextInput :value="linkes.air.ceiling" :width="900" small/>
    </Section>

    <Section name="Podlaha nad exteriérem">
      <TextInput :value="linkes.air.floor" :width="900" small/>
    </Section>

    <Section name="Výplň otvoru">
      <TextInput :value="linkes.air.opening" :width="900" small/>
    </Section>


    <h2 class="title">Vazba s exteriérem - zemina</h2>
    <Section name="Stěna k terénu">
      <TextInput :value="linkes.ground.wall" :width="900" small/>
    </Section>

    <Section name="Strop k zemině">
      <TextInput :value="linkes.ground.ceiling" :width="900" small/>
    </Section>

    <Section name="Podlaha na zemině">
      <TextInput :value="linkes.ground.floor" :width="900" small/>
    </Section>


    <h2 class="title">Filtry</h2>
    <div class="filter-container flex-column">
      <div class="flex-inline filter" v-for="filter in filters" :key="filter.filter">
        <input class="filter-name" :value="filter.name" />
        <input class="filter-type" :value="filter.type" />
        <input class="filter-filter" :value="filter.filter" />
      </div>
    </div>
  </div> 
</template>

<script>
import TextInput from '../components/fundamentals/TextInput.vue';
import Section from '../components/Section.vue';
/*

1) Vnitřní vazba**
  -Vnitřní stěna / příčka:
  https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][296]=296&filter[other_data.dek_categories][value][297]=297&filter[other_data.dek_categories][value][283]=283&filter[other_data.dek_categories][value][272]=272&filter[other_data.dek_categories][value][284]=284&filter[other_data.dek_categories][value][285]=285&filter[other_data.dek_categories][value][286]=286&filter[other_data.dek_categories][value][287]=287&filter[other_data.dek_categories][value][288]=288
  -Vnitřní strop:
  https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][319]=319&filter[other_data.dek_categories][value][320]=320&filter[other_data.dek_categories][value][321]=321&filter[other_data.dek_categories][value][322]=322&filter[other_data.dek_categories][value][323]=323&filter[other_data.dek_categories][value][324]=324
  -Vnitřní podlaha: To je strop z druhé strany, nebo si tam představuješ něco speciálního?
  -Vnitřní výplň otvoru:
  https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][371]=371&filter[other_data.dek_categories][value][373]=373

**2) Vazba s exteriérem - vzduch**
  -Venkovní stěna:
  https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][295]=295&filter[other_data.dek_categories][value][283]=283&filter[other_data.dek_categories][value][272]=272&filter[other_data.dek_categories][value][284]=284&filter[other_data.dek_categories][value][285]=285&filter[other_data.dek_categories][value][286]=286&filter[other_data.dek_categories][value][288]=288
  -Střecha:
  https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][353]=353&filter[other_data.dek_categories][value][354]=354&filter[other_data.dek_categories][value][355]=355&filter[other_data.dek_categories][value][350]=350&filter[other_data.dek_categories][value][351]=351&filter[other_data.dek_categories][value][352]=352
  -Podlaha nad exteriérem: Pro tu asi aktuálně nemáme kategorii
  -Výplň otvoru:
  https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][367]=367&filter[other_data.dek_categories][value][370]=370&filter[other_data.dek_categories][value][372]=372&filter[other_data.dek_categories][value][378]=378&filter[other_data.dek_categories][value][379]=379&filter[other_data.dek_categories][value][380]=380&filter[other_data.dek_categories][value][381]=381

3) Vazba s exteriérem - zemina**
  -Stěna k terénu:
  https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][294]=294
  -Strop k zemině: Nemáme
  -Podlaha na zemině:
  https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][344]=344&filter[other_data.dek_categories][value][341]=341

*/

const linkes = {
  interior: {
    wall: 'https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][296]=296&filter[other_data.dek_categories][value][297]=297&filter[other_data.dek_categories][value][283]=283&filter[other_data.dek_categories][value][272]=272&filter[other_data.dek_categories][value][284]=284&filter[other_data.dek_categories][value][285]=285&filter[other_data.dek_categories][value][286]=286&filter[other_data.dek_categories][value][287]=287&filter[other_data.dek_categories][value][288]=288',
    ceiling: 'https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][319]=319&filter[other_data.dek_categories][value][320]=320&filter[other_data.dek_categories][value][321]=321&filter[other_data.dek_categories][value][322]=322&filter[other_data.dek_categories][value][323]=323&filter[other_data.dek_categories][value][324]=324',
    floor: 'https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][319]=319&filter[other_data.dek_categories][value][320]=320&filter[other_data.dek_categories][value][321]=321&filter[other_data.dek_categories][value][322]=322&filter[other_data.dek_categories][value][323]=323&filter[other_data.dek_categories][value][324]=324',
    opening: 'https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][371]=371&filter[other_data.dek_categories][value][373]=373',
  },
  air: {
    wall: 'https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][295]=295&filter[other_data.dek_categories][value][283]=283&filter[other_data.dek_categories][value][272]=272&filter[other_data.dek_categories][value][284]=284&filter[other_data.dek_categories][value][285]=285&filter[other_data.dek_categories][value][286]=286&filter[other_data.dek_categories][value][288]=288',
    ceiling: 'https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][353]=353&filter[other_data.dek_categories][value][354]=354&filter[other_data.dek_categories][value][355]=355&filter[other_data.dek_categories][value][350]=350&filter[other_data.dek_categories][value][351]=351&filter[other_data.dek_categories][value][352]=352',
    floor: '',
    opening: 'https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][367]=367&filter[other_data.dek_categories][value][370]=370&filter[other_data.dek_categories][value][372]=372&filter[other_data.dek_categories][value][378]=378&filter[other_data.dek_categories][value][379]=379&filter[other_data.dek_categories][value][380]=380&filter[other_data.dek_categories][value][381]=381',
  },
  ground: {
    wall: 'https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][294]=294',
    ceiling: '',
    floor: 'https://deksoft.eu/www/bimplugin/?lang=cz&country=1&filter[other_data.dek_categories][value][344]=344&filter[other_data.dek_categories][value][341]=341',
    opening: '',
  },
}

const filters = [
  {
    type: 'numeric',
    name: 'neprůzvučnost od',
    filter: 'filter[data_akustika.commercialData.1.value][max]'
  },
  {
    type: 'numeric',
    name: 'neprůzvučnost do',
    filter: 'filter[data_akustika.commercialData.1.value][min]'
  },
]

export default {
  components: { Section, TextInput },
  data() {
    return {
      linkes,
      filters,
    };
  },
}
</script>

<style lang="scss">
.building-library {
  .flex-inline {
    align-items: baseline;
  }
  .text-input>input {
    padding: 0 15px;
  }

  .filter-container {
    .filter {
      height: 2.3em;
      gap: 20px;
      input { line-height: 1.3em; }
      .filter-name { width: 250px; }
      .filter-type { width: 120px; }
      .filter-filter { flex-grow: 1; }
    }
  }
}
</style>
