<template>
  <div class="output-group" :style="cssVariables" >
        <output 
          :value="formatInteger(value)"
          class="group"
        />

        <span class="output-group-text" v-html="unit">
        </span>
    </div>
</template>

<script>

export default {
    props: {
        value: {
          type: Number,
          required: true,
        },
        unit: String,
        /* small: {
          type: Boolean,
          default: false,
        }, */
        width: {
            type: Number,
            default: 80,
        },
    },
    data() {
      return {};
    },
    methods: {
        
    },
    computed: {
      cssVariables() {
        const variables = {};
        if (this.width) {
          variables['--output-width'] = `${this.width}px`;
        }
        return variables;
      }
    }
}
</script>

<style lang="scss">
.output-group {
  //border-radius: 4px;
  //border: auto;
  position: relative;
  display: flex;
  &:focus {
    outline: none;
    border: none;
  }
  output.group {
    border: $secondary-color solid 1px;
    text-align: center;
    margin: auto 0;
    padding: 0px 5px;
    flex-grow: 1;
    min-width: var(--output-width);
    height: 40px;
    line-height: 40px;
  }
    
  .output-group-text {
    //border: $secondary-color solid 1px;
    //display: -webkit-box;
    //display: -ms-flexbox;
    //display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    //padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    //line-height: 1.5;
    //color: #495057;
    text-align: center;
    white-space: nowrap;
    //background-color: #e9ecef;
    // border: 1px solid #ced4da;
    //border-radius: 0.25rem;
    min-width: 42px;
    height: 40px;
    line-height: 40px;
  }
}
</style>
