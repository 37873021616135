<template>
  <table class="disposition" v-if="disposition">
      <tr class="heading-row">
        <th>Název místnosti</th>
        <th>Počet</th>
        <th>Minimální plocha [m<sup>2</sup>/místn.]</th>
        <th>Návrh užitné plochy [m<sup>2</sup>]</th>
      </tr>
      <tr v-for="room in Object.values(disposition.modules).filter(module => module.getValue('count') > 0)" :key="room.id">
        <td v-text="room.getValue('name')"></td>
        <td v-text="room.getValue('count')"></td>
        <td v-text="formatFloat(room.getValue('roomMinArea'))"></td>
        <td v-text="formatFloat(room.getValue('roomArea'))"></td>
      </tr>
      <tr class="heading-row">
        <th >Celkem</th>
        <th ></th>
        <th ></th>
        <th class="top-line" v-text="formatFloat(disposition.getValue('grossInternalAreaFlatA'))"></th>
      </tr>
  </table>
</template>

<script>
export default {
  props: ['disposition'],
}
</script>

<style lang="scss">
table.disposition {
    border-collapse: collapse;
    width: 100%;

  > tr {
    td, th {
      &:first-child {
        text-align: left;
      }
      text-align: center;
    }
  }

  > tr {
    line-height: 30px;
  }

  th.top-line {
    border-top: 2px solid black;
  }

  td,
  th {
    //border: 1px solid white;
    padding: 0 8px;
  }
  td.check {
    background: white;
  }
  tr.heading-row {
    background: #7777777F;
    color: black;
    border-width: 0;
  }
  tr:not(.heading-row) {
    //background: #ccc;
    //font-family: Roboto;
    &:nth-child(2n+1) {
      //background: #ccc;
      background: #F2F2F2 ;
    }
  }
}
</style>
