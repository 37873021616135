<template>
  <div class="checklist">
    <h1>Odškrtávací seznam</h1>
    <teleport to="#right-sider">
      <ChecklistControls />

    </teleport>
    
    <!-- <AppliedRequirementList :activeRequirements="getSortedRequirements.map(req => req.instanceId)" /> -->
    <TreeView :node="getRequirementTree" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChecklistControls from '../result/ChecklistControls/ChecklistControls.vue';
import TreeView from './requirements/TreeView.vue';

export default {
  components: { TreeView, ChecklistControls },
  methods: {
    filterFc(requirement) {
      for (const param in requirement) {
        const list = this.getFilterValue(param);
        if (list.length > 0 && !list.includes(requirement[param])) return false;
      }
      return true;
    }
  },
  computed: {
    ...mapGetters('configuration', [
      'getRequirementCollection',
      'getRequirementFiltered',
      'getSortedRequirements',
      'getRequirementTree',
    ]),
    ...mapGetters('prezentation', [
      'getFilterValues',
    ]),
  }
}
</script>

<style lang="scss">
#right-sider > .checklist-controls {
  width: 600px;
  float: right;
}

.checklist {
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    line-height: 30px;
  }
  td,
  th {
    border: 1px solid white;
    padding: 0 8px;
  }
  td.check {
    background: white;
  }
  .heading-row {
    background: $primary-color;
    color: white;
    border-width: 0;
  }
  .data-row {
    background: $secondary-color;
    //font-family: Roboto;
    &:nth-child(2n+1) {
      background: white;
    }
  }
}
</style>
