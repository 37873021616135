<template>
  <button @click="openDialog()" class="btn primary">
    <i class="fas fa-plus"></i>
    Přidat typ provozu
  </button>
  <Dialog heading="Přidat typ provozu" :show="show" @close="close()">
    <Section v-for="(order, idx) in functionConfigurations" :key="idx" :name="`${idx + 1}. typ provozu`">
      <div class="flex-column">
        <div class="group">
          <DropdownInput :value="functionConfigurations[idx].functionKindId"
            @input="setFunctionKind({ idx, functionKindId: $event })" :options="functionKindOptions"
            :disabled="functionConfigurations[idx].rooms.length > 0" />
        </div>
      </div>
    </Section>

    <div class="vertical-gap"></div>

    <Section name=" ">
      <button @click="addFunctionConfigurationClick()" class="primary">Přidat další typ provozu</button>
    </Section>


  </Dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Section from "../../components/Section.vue"
import DropdownInput from "../../components/fundamentals/DropdownInput.vue";
import Dialog from '@/views/components/Dialog.vue';

export default {
  components: {
    Section,
    DropdownInput,
    Dialog
  },
  data() {
    return {
      objectFunctions: [
        "living-function",
      ],
      show: false,
    }
  },
  computed: {
    ...mapGetters('definitions', ['functionKinds']),
    ...mapGetters('configuration', [
      'projectName',
      'functionConfigurations',
    ]),
    functionKindOptions() {
      return this.functionKinds.map(kind => ({
        name: kind.name,
        value: kind.id
      }))
    }
  },
  methods: {
    ...mapMutations('configuration', [
      'setProjectName',
      'addFunctionConfiguration',
      'setFunctionKind',
    ]),
    openDialog() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    sendRefresh() {
      setTimeout(() => this.$emit('refresh'), 30);
    },
    addFunctionConfigurationClick() {
      this.addFunctionConfiguration({
        functionKindId: this.functionKinds[0].id,
        functionKindName: this.functionKinds[0].name,
      });
      this.sendRefresh();
    },
  }
}

</script>

<style lang="scss" scoped>
.group {
  display: inline-flex;
  line-height: 50px;
}

.vertical-gap {
  height: 8rem;
}
</style>
