<template>
  <div class="text-input" :class="{small: small}" :style="cssVariables">
    <input 
      type="text" 
      :value="value" 
      @input.stop="onInput" 
      ref="inputElement" 
      class="user-input-style"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    onInput() {
      this.$emit("input", this.$refs.inputElement.value);
    },
  },
  computed: {
    cssVariables() {
      if (this.small) {
        return {
          '--input-text-width': `${this.width? this.width: 140}px`,
        };
      }
      return {
      };
    }
  },
};
</script>

<style lang="scss" scope>
/* .small {
  flex: 1 1 1;
  .user-input-style {
    min-width: var(--input-text-width);
    width: var(--input-text-width);
    height: 40px;
    line-height: 40px;
  }
} */
.user-input-style {
    min-width: var(--input-text-width);
    width: var(--input-text-width);
    height: 30px;
    line-height: 30px;
  }
</style>
