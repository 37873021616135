
import { ActionContext, Module } from "vuex";
import userService, { IUpdateUserPayload } from "./api/users.service";
import {EActionStatus, IState} from "./index";


export interface IUserData {
  id:string,
  username:string,
  role:string
}

const state = {
  users: [] as IUserData[]
};

type State = typeof state;

const users:Module<State, IState> = {
  namespaced: true,
  state: ():State => state,
  getters: {
    users: state => state.users
  },
  mutations: {
    setUsers: (state:State, users:IUserData[]) => {
      state.users = users;
    },
  },
  actions: {
    async GET_USERS(context: ActionContext<State, IState>) {
      try {
        const users = await userService.getAllUsers();
        context.commit('setUsers', users);
      } catch {
        context.commit('setUsers', []);
      }
    },

    async GET_USER(context: ActionContext<State, IState>, userId) {
      try {
        const users = await userService.getUserById(userId);
        context.commit('setUsers', users);
      } catch {
        context.commit('setUsers', []);
      }
    },

    async CREATE_USER(context: ActionContext<State, IState>, payload: IUpdateUserPayload) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      try {
        await userService.createUser(payload);
        context.commit('setActionStatus', EActionStatus.OK, {root: true});        
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

    async UPDATE_USER(context: ActionContext<State, IState>, payload: IUpdateUserPayload&{_id: string}) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      try {
        await userService.updateUser( payload._id, payload);
        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

    async DELETE_USER(context: ActionContext<State, IState>, userId: string) {
      context.commit('setActionStatus', EActionStatus.PENDING, {root: true});
      try {
        await userService.deleteUser(userId);
        context.commit('setActionStatus', EActionStatus.OK, {root: true});
      } catch {
        context.commit('setActionStatus', EActionStatus.FAIL, {root: true});
      }
    },

  },
};

export default users;

