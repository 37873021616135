<template>
  <div class="card-selection">
    <div class="scrollable no-scrollbar" ref="container">
      <div class="flex-row">
        <div
          v-for="card in options"
          class="choice flex-column"
          @click="click(card._id)"
          :key="card._id"
          :data-class="card._id"
          :style="{ width: `${width}px` }"
          :class="{
            active: value === card._id,
          }"
          ref="optionElements"
        >
          <div 
            class="card-image-wrapper"
            :style="{ width: `${width}px` }"
          >
            <img :src="card.image" :width="width" :height="height" />
          </div>
          <div class="textual-content">
            <div class="card-stripe flex-row">
              
              <img
                :src="getManufacturerByID(card.manufacturerRef).logo"
                width="40px"
                height="40px"
                class="logo"
              />
              <div class="names flex-column">
                <h1 v-text="card.name" v-show="card.name"></h1>
                <p v-show="card.manufacturerRef" v-text="getManufacturerByID(card.manufacturerRef).name"></p>
              </div>
              <i v-show="value == card._id" class="fa fa-edit button" @click.stop="onEdit(card)"></i>
              <i v-show="value != card._id" class="fa fa-edit"></i>
            </div>
            <p class="brief" v-text="card.brief"></p>
          </div>
          <div class="overlay">
            <i class="fas fa-check-circle"></i>
          </div>
        </div>
        
      </div>
    </div>
    <div class="button-set">
      <div
        @click="moveRight"
        ref="rightArrow"
        :class="{ arrow: true, 'arrow-right': true, hide: hideRight }"
      >
        <i class="fa fa-chevron-right"></i>
      </div>
      <div
        @click="moveLeft"
        ref="leftArrow"
        :class="{ arrow: true, 'arrow-left': true, hide: hideLeft }"
      >
        <i class="fa fa-chevron-left"></i>
      </div>
    </div>
    <div class="flex-row" v-show="options.length <= 0">
      <div class="flex-column warning">
        <h2 class="title">Nenalezena žádná možnost</h2> 
        <p>Pro aktuálně zvolenou konfiguraci bohužel nebylo v{{ '\xa0' }}tuto chvíli nalezeno řešení. To by mohlo negativně ovlivnit výpočet ceny. Prosím zvolte jinou konfiguraci. </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    "single-answer": Boolean,
    "multiple-answer": Boolean,
    // scrollable: Boolean,
    options: { type: Array, required: true },
    width: { type: String, default: "320" },
    height: String,
    value: { type: String, required: true },
  },
  data() {
    return {
      scrollable: null,
      hideLeft: false,
      hideRight: false,
    };
  },
  mounted() {
    if (this.value !== "") {
      console.log("Card with value of Array", this);
    }
    this.scrollable = this.$refs.container;
    this.onScroll();
    this.scrollable.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);
  },
  deactivated() {
    this.scrollable.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  },
  computed: mapGetters(['getManufacturerByID']),
  methods: {
    click(option) {
      if (this.singleAnswer) {
        this.choose(option);
      } else {
        console.warn(
          "multiple-choice without answer type specification 'multiple-answer' or 'single-answer'"
        );
        this.choose(option);
      }
    },
    choose(option) {
      if (this.value != option) {
        this.$emit("input", option);
        this.$emit("change", option);
      }
    },
    moveRight() {
      this.scrollable.scrollLeft += (2 * this.scrollable.clientWidth) / 3;
    },
    moveLeft() {
      this.scrollable.scrollLeft -= (2 * this.scrollable.clientWidth) / 3;
    },
    onScroll() {
      const left = this.scrollable.scrollLeft;
      const scrollWidth = this.scrollable.scrollWidth;
      const clientWidth = this.scrollable.clientWidth;

      if (left === 0) {
        this.hideLeft = true;
      } else {
        this.hideLeft = false;
      }
      //console.log("onScroll", left, scrollWidth, clientWidth);
      if (left === scrollWidth - clientWidth) {
        this.hideRight = true;
      } else {
        this.hideRight = false;
      }
    },
    onResize() {
      this.onScroll(); // actually scroll detects both ends, it just need to be called on resize.
      /*      
      const scrollWidth = this.scrollable.scrollWidth;
      const clientWidth = this.scrollable.clientWidth;
      console.log("resize", scrollWidth, clientWidth);
      if (scrollWidth === clientWidth) { //no scrollbar*
        this.hideLeft = this.hideRight = false;
      } else {
        this.onScroll();
      }*/
    },
    selectByIndex(index) {
      if (index < this.options.length) {
        this.choose(this.options[index]._id);
      }
    },
    onEdit(card) {
      this.$emit("editCard", card);
    },
  },
};
</script>

<style lang="scss" scope>
.card-selection {
  position: relative;
  .warning {
    border-radius: 10px;
    //border: $primary-color solid 2px;
    padding: 20px;
    text-align: center;
    width: 100%;
    background: lighten($color: $primary-color, $amount: 40%);// rgb(253, 227, 178);

    >.title {
      font-weight: 400;
      margin: 10px auto;
    }

    p {
      font-weight: 400;

      font-size: 1.2em;
      margin-top: 0;
    }
  }
  .arrow {
    position: absolute;
    width: 56px;
    height: 56px;
    background-color: $primary-color;
    text-align: center;
    border-radius: 50%;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    transition: opacity 500ms ease-out;
    box-shadow: 0px 5px 3px 0px rgba($color: #000000, $alpha: 0.08);

    &.hide {
      opacity: 0;
    }
    i {
      color: white;
      font-size: 20px;
      line-height: 56px;
      margin: auto;
    }
    &.arrow-right {
      right: 0;
    }
    &.arrow-left {
      left: 0;
    }
  }
  .scrollable {
    scroll-behavior: smooth;
    overflow: auto;
  }

  .choice {
    margin: 0 10px;
    position: relative;
    background: white;
    .card-image-wrapper {
      width: 320px;
        //overflow: hidden;
      > img {
        //max-height: 320px;
      }
    }
    

    .textual-content {
      height: 140px;
      margin: 10px;
      overflow: hidden;
    }

    .brief {
      //color: $secondary-color;
      height: 66px;
      text-overflow: ellipsis;
      color: #00000099;
      margin: 0;
    }

    .card-stripe {
      margin: 12px 0;
      img.logo {
        padding-right: 10px;
      }
      .names {
        h1 {
          flex-grow: 0;
          margin: 0;
        }
        p {
          flex-grow: 0;
          margin: 0;
        }
      }
      i {
        //margin-left: auto;
        margin: 0 0 auto auto;
        font-size: 22px;
        color: lighten($secondary-color, 40%);
        &.button {
          color: $secondary-color;
          cursor: pointer;
        }
      }
    }
  }
  .overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($color: $primary-color, $alpha: 0.12);
    i {
      float: right;
      padding: 6px 6px;
      color: $primary-color;
      font-size: 1.5em;
    }
  }

  .choice.active .overlay {
    display: block;
  }
}
</style>
