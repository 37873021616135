<template>
  <div v-if="itsMe()">
    <i @click="openCalc()" class="clickable fas fa-calculator"></i>
    <Dialog heading="Užitná zatížení pozemních staveb" class="imposed-loads"  :show="show" @close="closeCalc()">
      <Section name="Kategorie prostoru">
        <DropdownInput
          :options="staticData.categoryOptions"
          :value="category"
          @input="setCategory($event)"
          :width="700"
          small
        />
      </Section>

      <Section name="Podkategorie">
        <DropdownInput
          :options="getSubacategoryOptions()"
          :value="subcategory"
          @input="subcategory = $event"
          :width="700"
          small
        />
      </Section>
      <Section
        name="Užitné zatížení <strong>q</strong>k"
      >
        {{ result() }} kN/m2
      </Section>
      <div class="controls">
        <button @click="show = false" class="secondary">zrušit</button>
        <button @click="use()"  class="primary">použít</button>
      </div>
    </Dialog>
  </div>
</template>

<script>
const categoryOptions = [
 {name: 'A: Plochy pro domácí a obytné činnosti', value: 'A',},
 {name: 'B: Kancelářské plochy', value: 'B',},
 {name: 'C: Plochy, kde dochází ke shromažďování lidí (kromě kategorií A, B, D)', value: 'C',},
 {name: 'D: Obchodní prostory', value: 'D',},
 {name: 'E: Skladovací prostory', value: 'E',},
 {name: 'F: Dopravní a parkovací plochy pro lehká vozidla (≤ 30 kN tíhy)', value: 'F',},
 {name: 'G: Dopravní a parkovací plochy pro středně těžká vozidla (> 30 kN tíhy, ≤ 160 kN tíhy)', value: 'G',},
 {name: 'H: Nepřístupné střechy s výjimkou běžné údržby', value: 'H',},
 {name: 'I: Přístupné střechy v souladu s kategorií A až D', value: 'I',},
]

const subcategoryOptions = [
  {name: 'A1: Stropní konstrukce', value: 'A1'},
  {name: 'A2: Schodiště', value: 'A2'},
  {name: 'A3: Balkóny', value: 'A3'},
  {name: 'B: Bez rozlišení', value: 'B'},
  {name: 'C1: Plochy se stoly atd.', value: 'C1'},
  {name: 'C2: Plochy se zabudovanými sedadly', value: 'C2'},
  {name: 'C3: Plochy bez překážek pro pohyb osob', value: 'C3'},
  {name: 'C4: Plochy určené k pohybovým aktivitám', value: 'C4'},
  {name: 'C5: Plochy, kde může dojít ke koncentraci lidí', value: 'C5'},
  {name: 'D1: Plochy v malých obchodech', value: 'D1'},
  {name: 'D2: Plochy v obchodních domech', value: 'D2'},
  {name: 'E1: Plochy pro skladovací účely', value: 'E1'},
  {name: 'E2: Plochy pro průmyslové využití', value: 'E2'},
  {name: 'F: Bez rozlišení', value: 'F'},
  {name: 'G: Bez rozlišení', value: 'G'},
  {name: 'H: Bez rozlišení', value: 'H'},
  {name: 'I: Bez rozlišení', value: 'I'},
]

const results = {
  A1: 1.5,
  A2: 3,
  A3: 3,
  B: 2.5,
  C1: 3,
  C2: 4,
  C3: 5,
  C4: 5,
  C5: 5,
  D1: 5,
  D2: 5,
  E1: 7.5,
  E2: 'Individuálně',
  F: 2.5,
  G: 5,
  H: 0.4,
  I: 'Zatřiďte znovu dle A až D',
}

import Dialog from '../components/Dialog.vue'
import DropdownInput from '../components/fundamentals/DropdownInput.vue'
import Section from '../components/Section.vue'
export default {
  components: { Dialog, DropdownInput, Section },
  props: {
    desiredCalculator: {
      type: String,
      default: "no-calc"
    }
  },
  data() {
    return {
      show: false,
      buildingType: 'non-manufacturing',
      category: 'A',
      subcategory: '',
      staticData: {
        categoryOptions,
        subcategoryOptions,
      },
    }
  },
  methods: {
    itsMe() {
      return this.desiredCalculator == "ImposedLoads"
    },
    openCalc() {
      this.show = true
    },
    closeCalc() {
      this.show = false
    },
    setCategory(category) {
      this.category = category;
      if (!this.subcategory.startsWith(this.category)) {
        this.subcategory = this.getSubacategoryOptions()[0].value;
      }
    },
    getSubacategoryOptions() {
      return this.staticData.subcategoryOptions.filter(subcategory => subcategory.name.startsWith(this.category)) || [];
    },
    result() {
      if (this.subcategory.startsWith(this.category)) {
        return results[this.subcategory];
      }
      return "-";
    },
    use () {
      const result = this.result();
      if (!isNaN(parseInt(result))) {
        this.show = false;
        const requiredValue1 = result;
        this.$emit("set-requirement", {requiredValue1});
      } else {
        confirm('Pro zadanou kombinaci neexistuje nelze určit číselný výsledek.');
      }
    },
  },
}
</script>

<style lang="scss">
.imposed-loads {
  .section {
    .flex-inline {
      align-items: baseline;
    }
  }

  .controls {
    display: flex;
    justify-content: end;
    gap: 10px;
  }
}
</style>
