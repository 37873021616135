<template>
  <div class="checklist-controls">
    <h1 class="titl">Nastavení</h1>
    <h3>Rozdělení do stromové struktury</h3>
    <Distribution />
    <br>

    <h3>Filtrování</h3>
    <FilterSetup 
      v-for="parameter in getParameterAnalysis" 
      :parameter="parameter"
      :key="parameter.name"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Distribution from './Distribution.vue'
import FilterSetup from './FilterSetup.vue'
export default {
  components: { FilterSetup, Distribution },
  computed: {
    ...mapGetters('configuration', [
      'getParameterAnalysis',
    ]),
    ...mapGetters('prezentation', [
      'getFilterValuesMap'
    ]),
  },
  methods: {
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss">
.checklist-controls {
  padding: 10px;
  border-left: 5px solid black;
  color: white;
  background-color: #777777;
  height: 100%;
}
</style>
