<template>
  <div class="section-collapsible">
    <div class="flex-inline">
      <h3>
        <span v-html="name"></span>
        <i class="fa fa-info-circle" v-show="information" :class="{ tooltip: information }">
          <span class="tooltiptext" v-html="information"></span>
        </i>
      </h3>
      <ToggleButton :value="isVisible" @input="isVisible = $event" />
    </div>
    <div class="collapsible-part" v-show="isVisible">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from './customElements';

export default {
    name: "Section",
    props: {
        name: {
            required: true,
            type: String,
        },
        information: {
            type: String,
        },
        advanced: {
            type: Boolean,
            default: false,
        },
        tab: {
            type: String,
            default: "household",
        },
    },
    data() {
      return {
        isVisible: true,
      }
    },
    components: { ToggleButton }
};
</script>

<style lang="scss">
.section-collapsible {
  .flex-inline {
    width: 100%;
    //justify-content: space-between;
    gap: 20px;
    align-items: baseline;
    h3 {
      margin: 0;
    }
  }

  .collapsible-part {
    margin-left: 20px;
  }

  /** Tool Tip */
  .tooltip {
    position: relative;
    display: inline-block;
    //border-bottom: 1px dotted black;
  }

  .tooltip .tooltiptext {
    font: 400 16px/23px roboto;
    //font-size: 16px;
    //font-weight: 300;
    color: black;
    width: 480px;
    text-align: center;
    padding: 16px;
    visibility: hidden;
    background-color: white;
    color: black;
    border-color:black;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 110%;
  }

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}
</style>
